import React from 'react';
import { AllVideosView } from './AllVideosView';

interface IAddVideoProps {}

const AddVideos: React.FC<IAddVideoProps> = ({}) => {
  return (
    <div>
      <AllVideosView />
    </div>
  );
};

export default AddVideos;
