import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskType } from '../constants/models/TaskType';
import { setErrorMsg } from './alertSlice';
import * as REQUESTS from '../api/taskTypeRequests';

export interface taskTypeState {
  loading: boolean;
  error: string | null;
  taskType: TaskType | null;
  taskTypes: TaskType[];
}

export const initialState: taskTypeState = {
  loading: false,
  error: null,
  taskType: null,
  taskTypes: [],
};

const taskTypeSlice = createSlice({
  name: 'taskType',
  initialState,
  reducers: {
    taskTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    setTaskType(state, action: PayloadAction<TaskType | null>) {
      state.taskType = action.payload;
    },
    setTaskTypes(state, action: PayloadAction<{ data: TaskType[] }>) {
      const { data } = action.payload;
      state.taskTypes = data.map((taskType) => taskType);
    },
    taskTypeFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    taskTypeComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  taskTypeStart,
  setTaskType,
  setTaskTypes,
  taskTypeComplete,
  taskTypeFailure,
} = taskTypeSlice.actions;

export default taskTypeSlice.reducer;

export const getTaskTypeById =
  (taskTypeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskTypeStart());
      //const { program } = store.getState().program;
      //if (program && program.id) {
      const taskType = await REQUESTS.getTaskTypeById(taskTypeId);
      dispatch(setTaskType(taskType));
      //}

      dispatch(taskTypeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskTypeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getTaskTypesForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(taskTypeStart());
    const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
  
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const taskTypes = await REQUESTS.getTaskTypesForProgram(programId);
      dispatch(setTaskTypes({ data: taskTypes }));
    }
    dispatch(taskTypeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(taskTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};


export const getTaskTypesForProgramRefresh = (program_pass_id:string): AppThunk => async (dispatch) => {
  try {
    dispatch(taskTypeStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    let program_de_id=program_pass_id;
   
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const taskTypes = await REQUESTS.getTaskTypesForProgram(programId);
      dispatch(setTaskTypes({ data: taskTypes }));
    }
    dispatch(taskTypeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(taskTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createTaskType =
  (name: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskTypeStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;
      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const taskType = await REQUESTS.createTaskType(name, programId);
        dispatch(setTaskType(taskType));
        dispatch(getTaskTypesForProgram());
      }
      dispatch(taskTypeComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(taskTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  };

export const deleteTaskType =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskTypeStart());
      await REQUESTS.deleteTaskType(id);
      dispatch(getTaskTypesForProgram());
      dispatch(taskTypeComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(taskTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  };
