import { withFormik } from 'formik';
import { connect } from 'react-redux';
// import { updateDetails } from '../../../slices/amplifyAuthSlice';
import {
  createIngredient,
  updateIngredient,
} from '../../../slices/studentSlice';

import { AddOrEditGroceryItemForm } from './AddOrEditGroceryItemForm';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import { RootState } from '../../../app/rootReducer';
import { Ingredient } from '../../../constants/models/Ingredient';
import { AppThunk } from '../../../app/store';
import { History } from 'history';


interface IDispatchProps {
  createIngredient: (
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ) => AppThunk;
  updateIngredient: (
    id: string,
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History

  ) => AppThunk;

  // createStudentAsProgramManager: (
  //   name: string,
  //   email: string,
  //   address: string,
  //   street: string,
  //   city: string,
  //   state: string,
  //   country: string,
  //   postalCode: number,
  //   about: string,
  //   diagnoses: string[],
  //   counselors: string[],
  //   history: History
  // ) => AppThunk;
}

export interface EnhancedAddOrEditGroceryItemFormValues {
  name: string;
  category: string;
  unit: string;
  quantity: number;
  price: number;
}

export interface EnhancedAddOrEditGroceryItemFormProps
  extends RouteComponentProps {
  ingredient: Ingredient | null;
  handleClose: () => void;
  groceryListId: string;
  createIngredient: (
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ) => void;
  updateIngredient: (
    id: string,
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ) => void;

  // createStudentAsProgramManager: (
  //   name: string,
  //   email: string,
  //   address: string,
  //   street: string,
  //   city: string,
  //   state: string,
  //   country: string,
  //   postalCode: number,
  //   about: string,
  //   diagnoses: string[],
  //   counselors: string[],
  //   history: History
  // ) => void;
}

export const EnhancedAddOrEditGroceryItemForm = withFormik<
  EnhancedAddOrEditGroceryItemFormProps,
  EnhancedAddOrEditGroceryItemFormValues
>({
  mapPropsToValues: ({ ingredient }) => ({
    name: ingredient !== null ? ingredient.name : '',
    category:
      ingredient !== null
        ? ingredient.category === 'any'
          ? ''
          : ingredient.category
        : '',
    unit:
      ingredient !== null
        ? ingredient.unit === 'any'
          ? ''
          : ingredient.unit
        : '',
    quantity: ingredient !== null ? ingredient.quantity : 1,
    price: ingredient !== null ? ingredient.price : 0.0,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required!'),
    category: Yup.string(),
    // .required('Category is required!'),
    unit: Yup.string(),
    // .oneOf(['Kg', 'g', 'l', 'ml'])
    // .required('Unit is required!'),
    quantity: Yup.number().required('Quantity is required!'),
    price: Yup.number().required('Price is required!'),
  }),
  handleSubmit: async (values, { setSubmitting, resetForm, props }) => {
    const {
      history,
      createIngredient,
      updateIngredient,
      ingredient,
      groceryListId,
      handleClose,
      // updateDetails,
      // showSnack,
      // udpateStudent,
      // navigation,
      // user,
      // student,
    } = props;
    const { name, category, price, quantity, unit } = values;
    console.log(values);
    if (ingredient) {
      await updateIngredient(
        ingredient.id,
        name,
        category === '' ? 'any' : category,
        unit === '' ? 'any' : unit,
        quantity,
        price,
        groceryListId,
        ingredient.isCompleted,
        history,
      );
      if (handleClose) handleClose();
    } else {
      await createIngredient(
        name,
        category === '' ? 'any' : category,
        unit === '' ? 'any' : unit,
        quantity,
        price,
        groceryListId,
        false,
        history
      );
      if (handleClose) handleClose();
    }

    setSubmitting(false);
    resetForm();
  },
  displayName: 'AddOrEditGroceryItemForm',
  enableReinitialize: true,
})(AddOrEditGroceryItemForm);

const mapStateToProps = (state: RootState) => ({
  ingredient: state.student.ingredient,
});

export default connect(mapStateToProps, {
  createIngredient,
  updateIngredient,
})(EnhancedAddOrEditGroceryItemForm);
