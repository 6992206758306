import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import EnhancedEnterEmailForm from './EnhancedEnterEmailForm';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

import GRAPHIC from '../../../assets/images/Forgot_Password.svg';
import EnhancedForgotPasswordForm from './EnhancedForgotPasswordForm';
import theme from '../../../app/muiTheme';

interface IForgotPasswordProps {}

const ForgotPassword: React.FC<IForgotPasswordProps> = ({}) => {
  const { step } = useSelector((state: RootState) => {
    return {
      step: state.auth.forgotPasswordStep,
    };
  }, shallowEqual);
  let renderedForm;

  switch (step) {
    case 0:
      renderedForm = <EnhancedEnterEmailForm />;
      break;
    case 1:
      renderedForm = <EnhancedForgotPasswordForm />;
      break;
    default:
      renderedForm = <EnhancedEnterEmailForm />;
  }
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div style={{ paddingTop: '20vh', background: '#F5FAFE' }}>
      <Container
        style={{ paddingLeft: '0.4em', paddingRight: '0.4em', height: '80vh' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <div style={{ padding: '1em' }}>{renderedForm}</div>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ marginLeft: '3.5em' }}>
                <img src={GRAPHIC} style={{ maxHeight: 450 }} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
