import React from 'react';
import {
  setSelected,
  addMembersToGroup,
  removeFromSelectedUsers,
  addToSelectedUsers,
} from '../../slices/chatRoomSlice';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { CheckOutlined } from '@mui/icons-material';
import { RootState } from '../../app/rootReducer';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    bottom: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
interface ContactSelectedListItemProps {
  contact: any;
  badgeVisible?: boolean;
}

export const ContactSelectedListItem: React.FC<
  ContactSelectedListItemProps
> = ({ contact, badgeVisible }) => {
  const user = contact;
  const dispatch = useDispatch();
  const { selectedUsers } = useSelector((state: RootState) => {
    return {
      selectedUsers: state.chatRoom.selectedUsers,
    };
  }, shallowEqual);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        margin: '5px 10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!badgeVisible) {
              if (
                // @ts-ignore
                !selectedUsers.find((contact: any) => contact.id === user.id)
              ) {
                dispatch(setSelected(true));
                dispatch(addToSelectedUsers(user));
              } else {
                dispatch(removeFromSelectedUsers(user));
              }
            }
          }}
        >
          <StyledBadge
            invisible={badgeVisible}
            // style={{ color: "white", backgroundColor: SECONDARY }}
            overlap='circular'
            badgeContent={
              <Close style={{ color: 'white', fontSize: '10px' }} />
            }
            color='secondary'
            className='avatar'
          >
            <img
              src={user.imageUrl}
              style={{ width: 50, height: 50, borderRadius: 50 }}
            />
          </StyledBadge>
        </div>
        {/* {!badgeVisible && (
            <div
              style={{
                padding: '1px',

                // borderWidth: 1,
                // width: 10,
                // height: 10,
                position: 'absolute',
                bottom: 0,
                right: -5,
                backgroundColor: 'rgb(242,242,242)',
                borderRadius: '50px',
              }}
            >
              <Badge
                style={{
                  color: 'white',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'flex',
                  width: 20,
                  height: 20,
                  backgroundColor: 'gray',
                }}
                onClick={() => {
                  // @ts-ignore
                  !selectedUsers.find((contact) => contact.id === user.id)
                    ? dispatch(addToSelectedUsers(user))
                    : dispatch(removeFromSelectedUsers(user));
                }}
              >
                <Close style={{ color: 'white' }} />
              </Badge>
            </div>
          )} */}
        <div style={{ color: 'gray', margin: '5px 0px', textAlign: 'center' }}>
          {user.name}
        </div>
      </div>
    </div>
  );
};
