import store, { AppThunk } from '../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipeTagType } from '../constants/models/RecipeTagType';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/recipeTagTypeRequests';

export interface recipeTagTypeState {
  loading: boolean;
  error: string | null;
  recipeTagType: RecipeTagType | null;
  recipeTagTypes: RecipeTagType[];
}

export const initialState: recipeTagTypeState = {
  loading: false,
  error: null,
  recipeTagType: null,
  recipeTagTypes: [],
};

const recipeTagTypeSlice = createSlice({
  name: 'RecipeTagType',
  initialState,
  reducers: {
    RecipeTagTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    setRecipeTagType(state, action: PayloadAction<RecipeTagType | null>) {
      state.recipeTagType = action.payload;
    },
    setRecipeTagTypes(state, action: PayloadAction<{ data: RecipeTagType[] }>) {
      const { data } = action.payload;
      state.recipeTagTypes = data.map((recipeTagType) => recipeTagType);
    },
    recipeTagTypeFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    RecipeTagTypeComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  RecipeTagTypeStart,
  setRecipeTagType,
  setRecipeTagTypes,
  RecipeTagTypeComplete,
  recipeTagTypeFailure,
} = recipeTagTypeSlice.actions;

export default recipeTagTypeSlice.reducer;

export const getRecipeTagTypeById =
  (RecipeTagTypeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(RecipeTagTypeStart());
      //const { program } = store.getState().program;
      //if (program && program.id) {
      const RecipeTagType = await REQUESTS.getRecipeTagTypeById(RecipeTagTypeId);
      dispatch(setRecipeTagType(RecipeTagType));
      //}

      dispatch(RecipeTagTypeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeTagTypeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getRecipeTagTypesForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(RecipeTagTypeStart());
    const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
  
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const recipeTagTypes = await REQUESTS.getRecipeTagTypesForProgram(programId);
      dispatch(setRecipeTagTypes({ data: recipeTagTypes }));
    }
    dispatch(RecipeTagTypeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeTagTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipeTagTypesForProgramRefresh = (program_pass_id:string): AppThunk => async (dispatch) => {
  try {
    dispatch(RecipeTagTypeStart());
    
    let program_de_id=program_pass_id;
    
  
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const recipeTagTypes = await REQUESTS.getRecipeTagTypesForProgram(programId);
      dispatch(setRecipeTagTypes({ data: recipeTagTypes }));
    }
    dispatch(RecipeTagTypeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeTagTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createRecipeTagType =
  (type: string, recipetypeUrl:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(RecipeTagTypeStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const RecipeTagType = await REQUESTS.createRecipeTagType(type, recipetypeUrl, programId);
        dispatch(setSuccessMsg(`Tag type added successfully !`));

        dispatch(setRecipeTagType(RecipeTagType));
        dispatch(getRecipeTagTypesForProgram());
      }
      dispatch(RecipeTagTypeComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(recipeTagTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  };

export const deleteRecipeTagType =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(RecipeTagTypeStart());
      await REQUESTS.deleteRecipeTagType(id);
      dispatch(setSuccessMsg(`Tag  type deleted Successfully !`));
      dispatch(getRecipeTagTypesForProgram());
      dispatch(RecipeTagTypeComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(recipeTagTypeFailure(error));
      dispatch(setErrorMsg(error));
    }
  };
