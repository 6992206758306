import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';

import { FormikProps } from 'formik';
import { EnhancedUpdatePasswordFormValues } from './EnhancedUpdatePasswordForm';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { DisplayFormikState } from '../../../components/DisplayFormikState';

const useStyles = makeStyles(formStyles);

interface IUpdatePasswordFormProps {}

export const UpdatePasswordForm: React.FC<
  IUpdatePasswordFormProps & FormikProps<EnhancedUpdatePasswordFormValues>
> = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { authLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
    };
  }, shallowEqual);
  return (
    <div>
      <div style={{ margin: '0px 0px 22px' }}>
        <Typography variant='body1' className={classes.loginText}>
          UPDATE PASSWORD
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Set New Password
        </Typography>
        <Typography variant='body1' className={classes.loginText}></Typography>
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter your password
          </Typography>
          <TextField
            id='password'
            placeholder='Enter Password'
            type='password'
            name='password'
            variant='outlined'
            size='small'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={'off'}
            error={(errors.password && touched.password) as boolean}
            helperText={errors.password && touched.password && errors.password}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Re-enter your password
          </Typography>
          <TextField
            placeholder='Confirm password'
            type='password'
            variant='outlined'
            size='small'
            name='passwordConfirmation'
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              (errors.passwordConfirmation &&
                touched.passwordConfirmation) as boolean
            }
            autoComplete={'off'}
            helperText={
              errors.passwordConfirmation &&
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <Button
          className={classes.secondaryButton}
          variant='contained'
          color='primary'
          type='submit'
          disabled={authLoading}
          fullWidth
        >
          Update password
        </Button>
      </form>
    </div>
  );
};
