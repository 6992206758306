import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_RECIPE_INGREDIENT_VIEW } from '../../../constants/routes';
import { getrecipeStepById, getrecipeStepByIdRefresh } from '../../../slices/recipeStepsSlice';
import EnhancedEditRecipeStepForm from './EnhancedEditRecipeStepForm';

interface IEditRecipesStepProps {}

const EditRecipeStep: React.FC<IEditRecipesStepProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { stepId } = useParams<{ stepId: string | undefined }>();
  const { recipeStep, recipeStepLoading } = useSelector((state: RootState) => {
    return {
      recipeStep: state.recipeStep.recipeStep,
      recipeStepLoading: state.recipeStep.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_INGREDIENT_VIEW ? true : false;

  if (stepId) {
    localStorage.setItem('urlRoute', "edit_step");
    localStorage.setItem('urlId', stepId);
  }
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    if(user_id)
    {
      if (stepId) {
        dispatch(getrecipeStepByIdRefresh(stepId));

      }
    }
    else
    {
      if (!isAddStudent && stepId) {
        dispatch(getrecipeStepById(stepId));
      }
    }
    
    return () => {};
  }, [isAddStudent, stepId, dispatch]);


  if(recipeStep)
  {
    console.warn("grc title"+JSON.stringify(recipeStep));
    localStorage.setItem('userData', JSON.stringify(recipeStep));
  }

  let renderedForm;
  // if (!isAddStudent && recipeStep) {
  //    console.log("re data-->"+recipeStep);

  //   const {
  //     stepTitle,
  //     stepUrl,
  //     stepDescription,
  //     recipeId,
       
  //   } = recipeStep;
  //   renderedForm = (
  //     <EnhancedEditRecipeStepForm
  //     stepTitle={stepTitle}
  //     stepUrl={stepUrl}
  //     stepDescription={stepDescription}
  //     recipeId={recipeId}
      
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedEditRecipeStepForm />;
 // }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditRecipeStep;
