import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../app/rootReducer';
import {
  getAllUsers,
  createChatRoom,
  setFilteredAllUsers,
  addToSelectedUsers,
  addMembersToGroup,
  setSelected,
  removeFromSelectedUsers,
  emptySelectedUsers,
  getUserChatRooms,
  createGroupChatRoom,
} from '../../slices/chatRoomSlice';
import { MESSAGING } from '../../constants/routes';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  makeStyles,
  InputAdornment,
  Fab,
  IconButton,
  Button,
} from '@material-ui/core';
import { SearchOutlined, ChevronRight, ChevronLeft } from '@material-ui/icons';
import formStyles from '../../assets/jss/components/formStyles';
import { ContactListItem } from '../../components/Messaging/ContactListItem';
import { InfoListItem } from '../../components/Messaging/InfoListItem';
import { GroupContactListItem } from '../../components/Messaging/GroupContactListItem';
import { CircularProgress, Divider } from '@mui/material';
import { ContactSelectedListItem } from '../../components/Messaging/ContactSelectedListItem';
import { GroupPhoto } from '../../components/Messaging/GroupPhoto';
import { setErrorMsg } from '../../slices/alertSlice';
const SECONDARY = '#0da9e5';
const useStyles = makeStyles(formStyles);

interface IAddGroup {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenGroupModal: Dispatch<SetStateAction<boolean>>;
  setOpenAddGroupModal: Dispatch<SetStateAction<boolean>>;
}

export const AddGroup: React.FC<IAddGroup> = ({
  setOpenAddGroupModal,
  setOpenGroupModal,
  setOpenModal,
}) => {
  const classes = useStyles();
  const {
    chatRoom: {
      allUsers,
      filteredAllUsers,
      chatRoom,
      groupUsers: users,
      selected,
      selectedUsers,
    },
    auth: { graphQlUser: currentUser },
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      auth: state.auth,
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [photoLoading, setPhotoLoading] = useState<boolean>(false);
  const [image, setImage] = useState<null | string>(null);

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };
  const handleAddGroup = async () => {
    if (query.length === 0) {
      alert('Please provide group title!');
    } else {
      setLoading(true);
      await dispatch(
        createGroupChatRoom(
          selectedUsers,
          currentUser,
          query,
          'New Group',
          image
            ? image
            : 'https://image.winudf.com/v2/image/Y29tLmFwcC53aGF0c2FwcC5kcC5wcm9maWxlLnBpYy5kb3dubG9hZC5zYXZlcl9pY29uXzBfYTRmYmNhODM/icon.png?w=&fakeurl=1',
          history
        )
      );
      dispatch(setSelected(false));
      dispatch(emptySelectedUsers());
      setQuery('');
      setOpenAddGroupModal(false);
      history.push(MESSAGING);
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUserChatRooms(currentUser));
  }, []);

  if (
    allUsers === null ||
    filteredAllUsers === null ||
    filteredAllUsers === undefined
  ) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading...
      </div>
    );
  }
  return (
    <div>
      <Grid
        container
        justify='center'
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent
              style={{
                position: 'relative',
                flex: 1,
                padding: '0px',
                // backgroundColor: "#EEEEEE",
              }}
            >
              <Button
                variant='outlined'
                color='secondary'
                startIcon={<ChevronLeft />}
                style={{ margin: '5px' }}
                onClick={() => {
                  setOpenAddGroupModal(false);
                  setOpenGroupModal(true);
                }}
              >
                Go Back
              </Button>
              <div
                style={{
                  backgroundColor: 'white',
                  height: '110px',
                }}
              >
                <div>
                  <Typography
                    variant='h5'
                    style={{ margin: '1rem auto', textAlign: 'center' }}
                    component='h2'
                  >
                    New Group
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <GroupPhoto
                    image={image}
                    setImage={setImage}
                    setExternalLoading={setPhotoLoading}
                    externalLoading={photoLoading}
                  />
                  <TextField
                    onChange={handleChange}
                    value={query}
                    style={{
                      width: '70%',

                      background: 'rgba(255,255,255,1)',
                    }}
                    variant='standard'
                    size='small'
                    InputLabelProps={{
                      classes: {
                        root: classes.heading,
                        focused: classes.cssFocused,
                      },
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <SearchOutlined />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    placeholder='Add group title'
                  />
                </div>
                <div
                  style={{
                    color: '#AAAAAA',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    margin: '0px 15px',
                    // marginRight: '3rem',
                    // marginBottom: '1rem',
                    marginLeft: '4.4rem',
                    marginTop: '-0.5rem',
                  }}
                >
                  Provide a group subject and optional group icon
                </div>
                <Fab
                  size='medium'
                  style={{
                    position: 'absolute',
                    margin: 10,
                    right: -3,
                    top: '29%',
                    backgroundColor:
                      query.length === 0 || photoLoading ? 'gray' : SECONDARY,
                  }}
                  color='primary'
                  disabled={loading}
                  onClick={
                    query.length === 0
                      ? () => {
                          dispatch(setErrorMsg('Please provide group title'));
                        }
                      : photoLoading
                      ? () => {
                          dispatch(setErrorMsg('Please wait!'));
                        }
                      : handleAddGroup
                  }
                  aria-label='next'
                >
                  {loading ? (
                    <CircularProgress style={{ color: 'white' }} />
                  ) : (
                    <ChevronRight />
                  )}
                </Fab>
              </div>
              <div
                style={{
                  backgroundColor: '#EEEEEE',
                  paddingBottom: '2rem',
                  marginTop: '1rem',
                  height: '350px',
                  maxHeight: '350px',
                  overflow: 'scroll',
                }}
              >
                <div
                  style={{
                    color: '#AAAAAA',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    margin: '15px',
                    paddingTop: '0.5rem',
                  }}
                >
                  Participants: {selectedUsers.length}
                </div>
                <Grid container spacing={3}>
                  {selectedUsers.map((contact: any, i: number) => (
                    <Grid item md={4} key={i}>
                      <ContactSelectedListItem
                        contact={contact}
                        badgeVisible={true}
                        key={i}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
