import { Program } from '../constants/models/Program';
import { PROGRAM_ROUTE } from '../constants/routes';
import API from './api';
export async function getProgramById(id: string) {
  try {
    const res = await API.get<{ data: Program; success: boolean }>(
      `${PROGRAM_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createProgram(
  schoolName: string,
  numberOfStudents: number,
  programType: string,
  programManagerId: string
) {
  try {
    const res = await API.post<{ data: Program; success: boolean }>(
      `${PROGRAM_ROUTE}`,
      { schoolName, numberOfStudents, programType, programManagerId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateProgram(
  id: string,
  schoolName: string,
  numberOfStudents: number,
  programType: string
) {
  try {
    const res = await API.post<{ data: Program; success: boolean }>(
      `${PROGRAM_ROUTE}/${id}/updateProgram`,
      { schoolName, numberOfStudents, programType }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteProgram(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${PROGRAM_ROUTE}/${id}/deleteProgram`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
