import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Grid,
  Select,
  OutlinedInput,
} from '@material-ui/core';

import formStyles from '../../../assets/jss/components/formStyles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedAddOrEditStudentBySuperAdminFormValues } from './EnhancedAddOrEditStudentBySuperAdminForm';
import { useHistory, useLocation, useParams } from 'react-router';
import { ADD_STUDENT } from '../../../constants/routes';
import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { getCounselorsForProgram } from '../../../slices/counselorSlice';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getOrganisationById } from '../../../api/programManagerRequests';
import { setorganisation } from '../../../slices/organisationSlice';


interface IAddOrEditStudentBySuperAdminFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const AddOrEditStudentBySUperAdminForm: React.FC<
  IAddOrEditStudentBySuperAdminFormProps & FormikProps<EnhancedAddOrEditStudentBySuperAdminFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();


  const isAddStudent = location.pathname === ADD_STUDENT;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const { studentLoading, diagnoses, counselors } = useSelector(
    (state: RootState) => {
      return {
        studentLoading: state.student.loading,
        diagnoses: state.diagnosis.diagnoses,
        counselors: state.counselor.counselors,
      };
    },
    shallowEqual
  );
  const { organisationId } = useParams<{ organisationId: string | undefined }>();
  const userData = localStorage.getItem('userData');
  const [orgarr_nxt,setorgarrNxt]=useState(Object);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchMyAPI() {
      if(userData)
      {
    
       const userDt = JSON.parse(userData);
       setorgarrNxt(userDt)
      }
      if(organisationId)
      {
          const orgDatanxt = await getOrganisationById(organisationId);
          if(orgDatanxt)
          {
           // localStorage.setItem('userData', JSON.stringify(orgDatanxt));
           setorganisation(orgDatanxt)

            //
  
           //let orgnewdata=JSON.parse(orgDatanxt)
            //
                      
              //setname(orgDatanxt[0].name)
          }
        
      }
    }
    fetchMyAPI()
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(getDiagnosesForProgram());
   // dispatch(getCounselorsForProgram());
    return () => {};
  }, []);//diagnoses.length, counselors.length

  let renderedDiagnosesOptions = diagnoses.map((diagnosis) => {
    return {
      value: diagnosis.id,
      label: diagnosis.name,
    };
  });
  console.log("diagnosis - > "+renderedDiagnosesOptions);

  let renderedCounselorOptions = counselors.map((counselor) => {
    return {
      value: counselor.id,
      label: counselor.name,
    };
  });
  
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid><br/>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          {/* Students */}
          User
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          {isAddStudent ? 'Add User ' : 'Add User '}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {isAddStudent ? 'Enter student details' : ''} */}
          {isAddStudent ? 'Enter user details' : ''}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter user name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Name'
                type='text'
                name='name'
                variant='outlined'
                size='small'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.name && touched.name) as boolean}
                helperText={errors.name && touched.name && errors.name}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter user email <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='emailAddress'
                placeholder='Email'
                type='email'
                name='email'
                variant='outlined'
                size='small'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.email && touched.email) as boolean}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        
  
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {/* { counselors.length>0? */}
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Choose Admin
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions={renderedCounselorOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'counselors',
                    option.map((option) => option.value)
                  );
                }}
              />
              {/* <Select
                labelId="select-role"
                value={values.counselors}
                onChange={handleChange('counselors')}
                multiple
                input={<OutlinedInput label="Counselors" />}
              >
                {counselors.map((counselor) => (
                  <MenuItem key={counselor.name} value={counselor.id}>
                    {counselor.name}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
            </Grid>
         
        { diagnoses.length>0?
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Choose Diagnosis
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions={renderedDiagnosesOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'diagnoses',
                    option.map((option) => option.value)
                  );
                }}
              />
              {/* <Select
                labelId="select-role"
                multiple
                value={values.diagnoses}
                onChange={handleChange('diagnoses')}
                input={<OutlinedInput label="Diagnosis" />}
              >
                {diagnoses.map((diagnosis) => (
                  <MenuItem key={diagnosis.name} value={diagnosis.id}>
                    {diagnosis.name}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>:""
        }
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Address
              </Typography>
              <TextField
                id='address'
                placeholder='Address'
                type='text'
                name='address'
                variant='outlined'
                size='small'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.address && touched.address) as boolean}
                helperText={errors.address && touched.address && errors.address}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                City
              </Typography>
              <TextField
                placeholder='City'
                type='text'
                name='city'
                variant='outlined'
                size='small'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.city && touched.city) as boolean}
                helperText={errors.city && touched.city && errors.city}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  State/Province/Region
                </Typography>
                <TextField
                  id='state'
                  placeholder='State'
                  type='text'
                  name='state'
                  variant='outlined'
                  size='small'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.state && touched.state) as boolean}
                  helperText={errors.state && touched.state && errors.state}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Country
                </Typography>
                <TextField
                  placeholder='Country'
                  type='text'
                  name='country'
                  variant='outlined'
                  size='small'
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.country && touched.country) as boolean}
                  helperText={
                    errors.country && touched.country && errors.country
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Postal Code
                </Typography>
                <TextField
                  placeholder='Postal Code'
                  type='text'
                  name='postalCode'
                  variant='outlined'
                  size='small'
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.postalCode && touched.postalCode) as boolean}
                  helperText={
                    errors.postalCode && touched.postalCode && errors.postalCode
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                About
              </Typography>
              <TextField
                placeholder='About'
                type='text'
                name='about'
                variant='outlined'
                size='small'
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.about && touched.about) as boolean}
                helperText={errors.about && touched.about && errors.about}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <Typography variant='subtitle2'>
              {false
                ? `The login credentials will be sent to the student's email
              ${values.email}`
                : ''}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={studentLoading}
              // fullWidth
            >
              {isAddStudent ? 'Add' : 'Add'} User
            </Button>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
