import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  FormControl,
  IconButton,
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { addStudentGroceryList, ADD_TASK, PARENT_STUDENT_PROFILES, STUDENT_PROFILES, SUPERADMIN_STUDENT_PROFILES } from '../../../constants/routes';
// import { TaskRow } from './TaskRow';
// import { DeleteTaskModal } from './DeleteTaskModal';
// import { ViewStudentDetails } from './ViewStudentDetails';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { setSelectedDate } from '../../../slices/taskSlice';
import { getReportForStudent } from '../../../slices/studentSlice';
import { CircularProgress } from '@mui/material';
//import { IngredientRow } from './IngredientRow';
//import { AddIngredientModal } from './AddIngredientModal';
import {
  AddCircle,
  AddCircleOutline,
  PlusOneOutlined,
} from '@material-ui/icons';
import { PreGroceryRow } from './PreGroceryRow';
import store, { AppThunk } from '../../../app/store';


interface IViewPreGroceryListTableProps {}

const useStyles = makeStyles(formStyles);

const PreGroceryListTable: React.FC<IViewPreGroceryListTableProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { student, groceryLists } = useSelector((state: RootState) => {
    return {
      tasks: state.task.tasks,
      student: state.student.student,
      groceryLists: state.groceryLists.groceryLists,
    };
  }, shallowEqual);


  const { studentId } = useParams<{ studentId: string }>();

  // useEffect(() => {
  //   return () => {};
  // }, [reportUrl]);

  let renderedIngredients;
  if (groceryLists.length === 0) {
    renderedIngredients = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No grocery list found.{' '}
            {/* <Link to={ADD_TASK} className={classes.link}>
              Add Task?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else if (
    groceryLists!== undefined &&
    groceryLists.length > 0
  ) {
    renderedIngredients = groceryLists.map((groceryList, index) => {
      return <PreGroceryRow groceryList={groceryList} index={index} />;
    });
  } else {
    renderedIngredients = (
      <StyledTableRow >
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Button
          color='primary'
          startIcon={
            <ArrowBackIosIcon
              fontSize='small'
              style={{ paddingRight: '0px' }}
            />
          }
          onClick={(e) => {
            const { program } = store.getState().program;
            const { parent } = store.getState().parent;
            const { superAdmin } = store.getState().superAdmin;
            if(parent?.id)
            {
              //history.go(-1);
              history.push(PARENT_STUDENT_PROFILES);

            }
            else if(superAdmin?.id)
            {
              //history.go(-1);
              history.push(SUPERADMIN_STUDENT_PROFILES);

            }
            else
            {
              history.push(STUDENT_PROFILES);
            }
          }}
          size='small'
        >
          Back
        </Button>
        <Button
          title=' Add Grocery List'
          color='primary'
              variant='contained'
              style={{marginLeft:'50px' }}
              startIcon={
                <AddCircleOutline
                  fontSize='small'
                  style={{ paddingRight: '0px'}}
                />
              }
              onClick={(e) => {
                // history.go(-1);
                history.push(addStudentGroceryList(studentId));
              }}
              size='small'
            >
              Add Grocery List
          </Button>
       
        
      </div>
      <TableContainer component={Paper}>
        <Table aria-label='customized table' style={{overflowX:"hidden",overflowY:"scroll",height:"500px;"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>
                <em>Image</em>
              </StyledTableCell>
              <StyledTableCell align='center' style={{ textAlign: 'center' }}>
                <em>Title</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Description</em>
              </StyledTableCell>
              
              <StyledTableCell align='center'>
                <em>Actions</em>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedIngredients}</TableBody>
        </Table>
      </TableContainer><br/><br/>
      {/* <AddIngredientModal openModal={openModal} setOpenModal={setOpenModal} /> */}
    </>
  );
};

export default PreGroceryListTable;
