import { FormikProps } from 'formik';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
  IconButton,
  Avatar
} from '@material-ui/core';
import React, {useState,useEffect } from 'react';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedAddRecipeTagFormValues } from './EnhancedAddRecipeTagForm';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { DropzoneDialog } from 'material-ui-dropzone';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import Resizer from "react-image-file-resizer";
import async from 'react-select/dist/declarations/src/async/index';

interface IAddRecipeTagFormProps {}
const useStyles = makeStyles(formStyles);

export const AddRecipeTagForm: React.FC<
  IAddRecipeTagFormProps & FormikProps<EnhancedAddRecipeTagFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange,setFieldValue } =
    props;
  const { recipeTagTypeLoading } = useSelector((state: RootState) => {
    return {
      recipeTagTypeLoading: state.recipeTagType.loading,
    };
  }, shallowEqual);

  let renderedAltName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  renderedAltName = values.type;
  renderedAvatar =
    values.recipetypeUrl !== 'no-url' ? values.recipetypeUrl : renderedAvatar;
    const [openModal, setOpenModal] = useState(false);
    let url:string[];
    const [imgerr, setImgErr] = useState("");
    const [img,setImg] = useState("");
  function chkImageValidate()
  {
    if(!values.recipetypeUrl)
    {
        setImgErr("Image is required");
    }
    else
    {
      setImgErr("");

    }
  }
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant="body1" className={classes.loginText}>
          ADD Cookbook TAG
        </Typography>
        <Typography variant="h4" className={classes.welcomeBackText}>
         TAG Type List
        </Typography>
        <Typography variant="body1" className={classes.loginText}>
          Manage Cookbook Tag for your program.
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >



          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <IconButton onClick={() => setOpenModal(true)} >
                  {/* <Avatar
                    src={renderedAvatar}
                    alt={renderedAltName}
                    style={{ width: 56, height: 56 }}
                  /> */}
                  {values.recipetypeUrl && values.recipetypeUrl !== 'no-url'? 
                  <img src={values.recipetypeUrl} style={{ width: 56, height: 56 }}/>:
                  <img src={SIMG} style={{ width: 56, height: 56 }}/>
                }
                </IconButton>
                <Typography
                  variant='subtitle1'
                  style={{ marginLeft: '0.6rem' }}
                  className={classes.label}
                >
                  Add Picture
                  <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
                </Typography>
              </div>
              <span style={{color:'red'}}>{imgerr}</span>
            </FormControl>
          </Grid>
          <Grid md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant="subtitle1" className={classes.label}>
                Enter Tag 
              </Typography>
              <TextField
                label="Tag "
                type="text"
                placeholder=""
                name="type"
                variant="outlined"
                size="small"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.type && touched.type) as boolean}
                helperText={errors.type && touched.type && errors.type}
                style={{ marginRight: '0.4em' }}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid md={5} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={recipeTagTypeLoading}
                onClick={(e)=>
                chkImageValidate()
                }
              >
                Add
              </Button>
            </FormControl>

          </Grid>
        </Grid>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={openModal}
          //onDrop={()=>chkImageValidate()}
          onClose={() => setOpenModal(false)}
          onSave={async (files) => {
            try {
              if (files.length > 0) {
                // for(let i=0;i<files.length;i++)
                // {
                let file = files[0];
                Resizer.imageFileResizer(
                  file,
                  680,
                  420,
                  "JPEG",
                  100,
                  0,
                  async(uri:any) => {
                    //console.log(uri);
                    console.warn("Form data view--->" + JSON.stringify(uri));
                   let fdata= getImageUrl(uri);
                   //setImg(fdata);
                   const formData = new FormData();
                   console.warn("Form data view--->" + JSON.stringify(img));
                   formData.append('file', fdata);
                   //dispatch(addRecipeToS3(formData));
                   let urldatanxt = await addRecipeToS3(formData);
                   // setImage(url);
                   // url.push(urldata);
                   //console.log("res data---->"+urldatanxt)
                   //let jurl=JSON.stringify(urldatanxt)
                   setFieldValue('recipetypeUrl', urldatanxt);
                   setImgErr("");
                  },
                  "base64",
                  200,
                  200
                );

               
                // }
                // setFieldValue('recipeUrl', url);

              }
              setOpenModal(false);
            } catch (err) {
              console.log(err);
            }
          }}
        
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </form>
    </div>
  );
};
