import React, { Fragment } from 'react';
import { FormikProps } from 'formik';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { deleteQuestion, setIsModalOpen } from '../../../slices/questionSlice';
import { TransitionProps } from '@material-ui/core/transitions';
import theme from '../../../app/theme';

import { EnhancedQuestionEditorFormValues } from './EnhancedQuestionEditorForm';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import formStyles from '../../../assets/jss/components/formStyles';

interface QuestionEditorFormProps {}

const useStyles = makeStyles(formStyles);

export const QuestionEditorForm: React.FC<
  QuestionEditorFormProps & FormikProps<EnhancedQuestionEditorFormValues>
> = (props) => {
  const classes = useStyles();
  const debug = false;
  const dispatch = useDispatch();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setTouched,
    setSubmitting,
  } = props;

  const { questionLoading, isModalOpen } = useSelector((state: RootState) => {
    return {
      questionLoading: state.question.loading,
      isModalOpen: state.question.isModalOpen,
    };
  }, shallowEqual);

  const handleClose = () => {
    dispatch(deleteQuestion(values.id));
    dispatch(setIsModalOpen(false));
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: theme.palette.primary.main,
              backgroundColor: 'white',
            }}
          >
            Edit Question
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: 'white',
            }}
          >
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <FormControl required className={classes.formControl}>
              <Typography variant="subtitle1" className={classes.label}>
                Question{' '} <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder="Title"
                type="text"
                name="questionText"
                variant="outlined"
                size="small"
                value={values.questionText}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.questionText && touched.questionText) as boolean}
                helperText={
                  errors.questionText &&
                  touched.questionText &&
                  errors.questionText
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
            <FormControl required className={classes.formControl}>
              <Typography variant="subtitle1" className={classes.label}>
                Answer{' '} <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder="Answer"
                type="text"
                name="answer"
                variant="outlined"
                size="small"
                value={values.answer}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.answer && touched.answer) as boolean}
                helperText={errors.answer && touched.answer && errors.answer}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: 'white',
            }}
          >
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleSubmit();
                dispatch(setIsModalOpen(false));
              }}
              disabled={questionLoading}
              // fullWidth
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
