import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import headerStyles from '../../assets/jss/components/headerStyles';
import { useHistory } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { logout, logout_next } from '../../slices/authSlice';
import { SatelliteSharp } from '@material-ui/icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  PROGRAM_MANAGER,
  COUNSELOR,
  STUDENT,
  USER_ROLE,
} from '../../constants/userRoles';
import {
  EDIT_PROGRAM_MANAGER,
  EDIT_COUNSELOR,
  EDIT_PARENT,
  EDIT_SUPER_ADMIN,
} from '../../constants/routes';
import theme from '../../app/muiTheme';
import { getCounselorByIdRefresh } from '../../slices/counselorSlice';
import { getProgramManagerByIdRefresh } from '../../slices/programManagerSlice';
import { getSuperAdminByIdRefresh } from '../../slices/superAdminSlice';
import { getParentByIdRefresh } from '../../slices/parentSlice';
interface IHeaderProps {
  onDrawerToggle: () => void;
}

const useStyles = makeStyles(headerStyles);

export const Header: React.FC<IHeaderProps> = ({ onDrawerToggle }) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const { role, programManager, counselor, parent,superAdmin } = useSelector(
    (state: RootState) => {
      return {
        role: state.auth.role,
        programManager: state.programManager.programManager,
        counselor: state.counselor.counselor,
        parent: state.parent.parent,
        superAdmin: state.superAdmin.superAdmin,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    return () => {};
  }, []);
  let renderedName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  let route: string = EDIT_PROGRAM_MANAGER;

  if (role === USER_ROLE.SUPER_ADMIN) {
    renderedName = superAdmin?.name;
    renderedAvatar =
    superAdmin?.imageUrl !== 'no-url'
        ? superAdmin?.imageUrl
        : renderedAvatar;
    
    route = EDIT_SUPER_ADMIN;
  } 
  else if (role === USER_ROLE.PROGRAM_MANAGER) {
    renderedName = programManager?.name;
    renderedAvatar =
      programManager?.imageUrl !== 'no-url'
        ? programManager?.imageUrl
        : renderedAvatar;
    route = EDIT_PROGRAM_MANAGER;
  } else if (role === USER_ROLE.COUNSELOR) {
    renderedName = counselor?.name;
    renderedAvatar =
      counselor?.imageUrl !== 'no-url' ? counselor?.imageUrl : renderedAvatar;
    route = EDIT_COUNSELOR;
  } else if (role === USER_ROLE.PARENT) {
    renderedName = parent?.name;
    renderedAvatar =
      parent?.imageUrl !== 'no-url' ? parent?.imageUrl : renderedAvatar;
    route = EDIT_PARENT;
  }


  return (
    <React.Fragment>
      <AppBar color='inherit' position='sticky' elevation={0}>
        <Toolbar style={{ height: '4rem' }}>
          <Grid container spacing={1} alignItems='center'>
            {matches ? null : (
              <Grid item>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs />
            <Grid item>
              <Link
                className={classes.link}
                href='3'
                variant='body2'
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(logout_next(history));
                }}
              >
                LOGOUT
              </Link>
            </Grid>
            {/* <Grid item>
              <Tooltip title='No new notification'>
                <IconButton color='inherit'>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <IconButton
                onClick={async() => {

                  if (role === USER_ROLE.SUPER_ADMIN) {
                    if(superAdmin){
                      await dispatch(getSuperAdminByIdRefresh(superAdmin.id));
                      history.push(EDIT_SUPER_ADMIN);
                      }
                  } 
                  else if (role === USER_ROLE.PROGRAM_MANAGER) {
                    if(programManager){
                    await dispatch(getProgramManagerByIdRefresh(programManager.id));
                    history.push(EDIT_PROGRAM_MANAGER);
                    }
                   
                  } else if (role === USER_ROLE.COUNSELOR) {
                    if(counselor){
                    await dispatch(getCounselorByIdRefresh(counselor.id));
                    history.push(EDIT_COUNSELOR);
                    }
                   
                  } else if (role === USER_ROLE.PARENT) {
                    if(parent){
                      await dispatch(getParentByIdRefresh(parent.id));
                      history.push(EDIT_PARENT);
                      }
                  }
                
                  
                  

                }}
                color='inherit'
                className={classes.iconButtonAvatar}
              >
                <Avatar src={renderedAvatar} alt={renderedName} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
