import React from 'react';
import NFlyteLogo from '../../assets/images/NFlyte_Square.png';
// import "./styles/main.css";

interface HomeProps {}

const HomePage: React.FC<HomeProps> = () => {
  // const darkTheme = document.body.classList.contains("dark-theme");

  return (
    <div className='home'>
      <div className='home__img-wrapper'>
        {/* <img
					src={darkTheme ? introImgDark : introImgLight}
					alt=""
					className="home__img"
				/> */}
        <img src={NFlyteLogo} alt='' className='home__img' />
      </div>

      <h1 className='home__title'> Select a contact to start chatting </h1>
      {/* <p className="home__text">Add subtext later</p> */}
      <p className='home__text'>
        {/* <Icon id="laptop" className="home__icon" /> */}
      </p>
    </div>
  );
};

export default HomePage;
