import Config from "../config/config";
import CryptoES from "crypto-es";

//Encrypt Text
export const encryptMessage = (text: string) => {
  // return text;
  return CryptoES.AES.encrypt(
    text,
    Config.messaging.ENCRYPTION_PARAPHRASE
  ).toString();
};

//Decrypt Text
export const decryptMessage = (ciphertext: string) => {
  const bytes = CryptoES.AES.decrypt(
    ciphertext,
    Config.messaging.ENCRYPTION_PARAPHRASE
  );
  const originalText = bytes.toString(CryptoES.enc.Utf8);
  // const originalText = ciphertext;
  return originalText;
};
