import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { ADD_RECIPE_INGREDIENT_VIEW } from '../../constants/routes';
import { getrecipeIngredientById } from '../../slices/recipeingredientSlice';
import EnhancedAddOrEditRecipeIngredientForm from './EnhancedAddOrEditRecipeIngredientForm';
import IngidiansList from './IngidiansList';

interface IAddRecipesProps {}

const AddRecipeIngredient: React.FC<IAddRecipesProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { recipeingredientId } = useParams<{ recipeingredientId: string | undefined }>();
  const { recipeIngredient } = useSelector((state: RootState) => {
    return {
      recipeIngredient: state.recipeIngredient.recipeIngredient,
      recipeIngredientLoading: state.recipeIngredient.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_INGREDIENT_VIEW ? true : false;
  useEffect(() => {
    if (!isAddStudent && recipeingredientId) {
      dispatch(getrecipeIngredientById(recipeingredientId));
    }
    return () => {};
  }, [isAddStudent, recipeingredientId, dispatch]);
  let renderedForm;
  // if (!isAddStudent && recipeIngredient) {
  //   const {
  //     recipeingredientUrl,
  //     ingredientTitle,
  //       quantity,
  //       unit,
  //       unitType,
  //       recipeId,
  //   } = recipeIngredient;
  //   renderedForm = (
  //     <EnhancedAddOrEditRecipeIngredientForm
  //     recipeingredientUrl={recipeingredientUrl}
  //     ingredientTitle={ingredientTitle}
  //     quantity={quantity}
  //     unit={unit}
  //     unitType={unitType}
  //     recipeId={recipeId}
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedAddOrEditRecipeIngredientForm />;
  // }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
        <IngidiansList />
      </Container>
    </div>
  );
};
export default AddRecipeIngredient;
