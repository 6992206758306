import React, { useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import formStyles from '../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
    getIngidiansListForStudent,
    deleterecipeIngredient,
    getrecipeIngredientById,
    setrecipeIngredients,
} from '../../slices/recipeingredientSlice';
import { StyledTableRow } from '../../components/StyledTableRow';
import { StyledTableCell } from '../../components/StyledTableCell';
import Button from '@mui/material/Button';

import {
  addRecipeIngredients,    
  ADD_RECIPE,    
  editRecipeIngredient

} from '../../constants/routes';

import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
//import { USER_ROLE } from '../../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';
import { useParams } from 'react-router';


interface IIngidiansListProps {}

const useStyles = makeStyles(formStyles);

export const IngidiansList: React.FC<IIngidiansListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { recipeId } = useParams<{ recipeId: string }>();

  const { recipeIngredients } = useSelector(
    (state: RootState) => {
      return {
        recipeIngredients: state.recipeIngredient.recipeIngredients,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(getIngidiansListForStudent(recipeId));
    localStorage.setItem('urlRoute', "recipe_ingredient_list");
    localStorage.setItem('urlId', recipeId);
    return () => {};
  }, [recipeIngredients.length]);
  console.warn("list----->"+recipeIngredients);
//drag ref
const dragItem=React.useRef<any>(null);
const dragOverItem=React.useRef<any>(null);
//handle sort
async function handleSort() {
   //duplicate items
   let _stepItems=[...recipeIngredients]

   //remove and save the drag item content
   const dragItemContent=_stepItems.splice(dragItem.current,1)[0]
 
   //switch the position
   _stepItems.splice(dragOverItem.current,0,dragItemContent)
 
   //reset the position ref
   dragItem.current=null
   dragOverItem.current=null;
 
   //update the actual array
   //setSteparr(_stepItems)
   await dispatch(setrecipeIngredients({data:_stepItems}))
   //getCheckLiast() 
}
// const handleSort=()=>{
 

// }

  // drag start
// const onDragEnd=(e:React.DragEvent<HTMLDivElement>,index:number)=>{
//   //console.log("drag end"+index)
//   }
async function getCheckLiast(e:any) {
  e.preventDefault()
 // 

}
  let renderedStudents;
  if (recipeIngredients.length > 0) {
    renderedStudents =
    recipeIngredients.map((recipeIngredient, index) => {
            const { id, ingredientTitle, quantity, recipeingredientUrl, unit, unitType, isCompleted} = recipeIngredient;
            return (
              <StyledTableRow key={index}
              style={{cursor:'move'}}
              draggable
               onDragStart={(e)=>dragItem.current=index}
               onDragEnter={(e)=>dragOverItem.current=index}
               onDragEnd={handleSort}
               onDragOver={(e)=>{getCheckLiast(e)}}
              >
                <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                          {recipeingredientUrl !== '' ? (
                              <a href={recipeingredientUrl} target='_blank'>
                                <img
                                  src={recipeingredientUrl}
                                  width="30" height="30"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                    </StyledTableCell>
                <StyledTableCell>{ingredientTitle}</StyledTableCell>
                <StyledTableCell>{quantity}</StyledTableCell>
                <StyledTableCell>{unit}</StyledTableCell>
               
                <StyledTableCell>
                  <IconButton
                     onClick={ async () => {
                      await dispatch(getrecipeIngredientById(id));
                      //await dispatch(getIngidiansListForStudent(recipeId));
                      history.push(editRecipeIngredient(id));
                    }}
                  >
                    <EditIcon style={{ color: 'green' }} />
                    </IconButton> | 
                    <IconButton
                    onClick={async (e) => {
                      await dispatch(deleterecipeIngredient(id,recipeId,history));
                    }}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                </StyledTableCell>
                
              </StyledTableRow>
            );
          });
  } else if (recipeIngredients.length === 0) {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Ingredients found.
           
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          
            {/* <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.push(ADD_RECIPE);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            <Grid item md={2} xs={12}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                  history.push(addRecipeIngredients(recipeId));
              }}
              startIcon={<AddIcon style={{ alignItems: 'right' }}/>}
            >
              
              Add Ingridient
            </Button>
            </Grid> */}
        </Grid>

      {/* <Typography variant='h6'>
        Recipes
      </Typography> */}
      <br />
     
     
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead> 
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Unit</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedStudents}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default IngidiansList;