import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../../constants/formMessages';
import { EditProfileForm } from './EditCounselorProfileForm';
import { updateCounselor } from '../../../../slices/counselorSlice';
import { User } from '../../../../constants/models/User';
import { RootState } from '../../../../app/rootReducer';
import { USER_ROLE } from '../../../../constants/userRoles';

interface IDispatchProps {
  updateCounselor: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    // about: string,
    imageUrl: string | undefined,

    // diagnoses: string[],
    // counselors: string[],
    history: History,
    useProgramRoute: boolean
  ) => AppThunk;
}

export interface EnhancedEditCounselorProfileFormValues {
  name: string;
  email: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
  imageUrl: string;
  // diagnoses: string[];
  // counselors: string[];
}

export interface EnhancedEditCounselorProfileFormProps
  extends RouteComponentProps {
  name?: string;
  email?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: number;
  imageUrl?: string;
  counselor: User;

  updateCounselor: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History,
    useProgramRoute: boolean
  ) => AppThunk;
}

const EnhancedEditCounselorProfileForm = withFormik<
  EnhancedEditCounselorProfileFormProps,
  EnhancedEditCounselorProfileFormValues
>({
  mapPropsToValues: (props) => {
    let user = props.counselor;

    return {
      name: user ? user.name : '',
      email: user ? user.email : '',
      address: user ? user.address : '',
      street: user ? user.street : '',
      city: user ? user.city : '',
      state: user ? user.state : '',
      country: user ? user.country : '',
      postalCode: user ? user.postalCode : 0,
      imageUrl: user ? user.imageUrl : 'no-url',
      // diagnoses: [],
      // counselors: [],
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
    address: Yup.string().notRequired(),
    street: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    imageUrl: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, updateCounselor, counselor } = props;
    const {
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      imageUrl,
      // diagnoses,
      // counselors,
    } = values;

    updateCounselor(
      counselor?.id,
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      // about,
      imageUrl,
      // diagnoses,
      // counselors,
      history,
      true
    );

    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditProfileForm);

const EnhancedEditCounselorProfileFormWithRouter = withRouter(
  EnhancedEditCounselorProfileForm
);

const mapStateToProps = (state: RootState) => ({
  counselor: state.counselor.counselor,
});

export default connect(mapStateToProps, {
  updateCounselor,
  // @ts-ignore
})(EnhancedEditCounselorProfileFormWithRouter);
