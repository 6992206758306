import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';

import { updatePassword } from '../../../slices/authSlice';

import {
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORDS_MUST_MATCH,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_SHORT,
} from '../../../constants/formMessages';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { update } from 'lodash';

interface IDispatchProps {
  updatePassword: (newPassword: string, history: History) => AppThunk;
}

export interface EnhancedUpdatePasswordFormValues {
  password: string;
  passwordConfirmation?: string;
}

export interface EnhancedUpdatePasswordFormProps extends RouteComponentProps {
  password?: string;
  passwordConfirmation?: string;
  updatePassword: (newPassword: string, history: History) => void;
}

const EnhancedUpdatePasswordForm = withFormik<
  EnhancedUpdatePasswordFormProps,
  EnhancedUpdatePasswordFormValues
>({
  mapPropsToValues: (props) => ({
    password: '',
    passwordConfirmation: '',
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .required(PASSWORD_REQUIRED)
      .min(6, PASSWORD_TOO_SHORT),
    passwordConfirmation: Yup.string()
      .required(CONFIRM_PASSWORD_REQUIRED)
      .oneOf([Yup.ref('password')], PASSWORDS_MUST_MATCH),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, updatePassword } = props;
    const { password } = values;
    updatePassword(password, history);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(UpdatePasswordForm);

const EnhancedUpdatePasswordFormWithRouter = withRouter(
  EnhancedUpdatePasswordForm
);

export default connect<null, IDispatchProps>(null, { updatePassword })(
  EnhancedUpdatePasswordFormWithRouter
);
