import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  IMAGE_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  PREPARATION_TIME_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { EditRecipeForm } from './EditRecipeForm';
import { updaterecipe } from '../../../slices/recipeSlice';
import { Recipe } from '../../../constants/models/Recipe';
import { RootState } from '../../../app/rootReducer';



interface IDispatchProps {
    
    updaterecipe: (
    id: string | undefined,
    recipeUrl: string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    userId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedEditRecipeFormValues {
    recipeUrl: string;
    recipeTitle: string;
    recipeDescription: string;
    TypeOfRecipe: string,
    tags: string[];
    preparationTime: string;
    userId: string;
}

export interface EnhancedEditRecipeFormProps extends RouteComponentProps {
    recipeUrl?: string;
    recipeTitle?: string;
    recipeDescription?: string;
    TypeOfRecipe?: string;
    tags?: string[];
    preparationTime?: string;
    userId?: string;
    recipe?: Recipe;
    updaterecipe: (
    id: string | undefined,
    recipeUrl: string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    userId: string,
     history: History
  ) => void;
}

const EnhancedEditRecipeForm = withFormik<
  EnhancedEditRecipeFormProps,
  EnhancedEditRecipeFormValues
>({
  mapPropsToValues: (recipe) => ({
    recipeUrl: recipe.recipeUrl ? recipe.recipeUrl : '',
    recipeTitle: recipe.recipeTitle ? recipe.recipeTitle : '',
    recipeDescription: recipe.recipeDescription ? recipe.recipeDescription : '',
    TypeOfRecipe: recipe.TypeOfRecipe ? recipe.TypeOfRecipe : '',
    tags: recipe.tags ? recipe.tags : [],
    preparationTime: recipe.preparationTime ? recipe.preparationTime : '',
    userId: recipe.userId ? recipe.userId : '',
   
  }),
  validationSchema: Yup.object().shape({
    recipeUrl: Yup.string().required(IMAGE_REQUIRED),
    recipeTitle: Yup.string().required(NAME_REQUIRED),
    recipeDescription: Yup.string().required(DESCRIPTION_REQUIRED),
    TypeOfRecipe: Yup.string().notRequired(),
    //tags: Yup.array().of(Yup.string().notRequired()),
    preparationTime: Yup.string().required(PREPARATION_TIME_REQUIRED),
    userId: Yup.string().notRequired(),
    // city: Yup.string().notRequired(),
    // country: Yup.string().notRequired(),
    // postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, updaterecipe, recipe } = props;
    const {
        recipeUrl,
        recipeTitle,
        recipeDescription,
        TypeOfRecipe,
        tags,
        preparationTime,
        userId
    } = values;
    updaterecipe(
      recipe?.id,
         recipeUrl,
        recipeTitle,
        recipeDescription,
        TypeOfRecipe,
        tags,
        preparationTime,
        userId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditRecipeForm);

const EnhancedEditRecipeFormWithRouter = withRouter(
  EnhancedEditRecipeForm
);

const mapStateToProps = (state: RootState) => ({
  recipe: state.recipe.recipe,
});
export default connect(mapStateToProps, {
  updaterecipe,
  // @ts-ignore
})(EnhancedEditRecipeFormWithRouter);


