// /* eslint-disable */
// // WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:5ad2307e-5e4f-464b-b416-0945ac49cf5c",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_eSjADbzCH",
//     "aws_user_pools_web_client_id": "4il66tjun8bo2tv4vku04hu62h",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://qeceejqevvhpzn4qzwgdvcma7u.appsync-api.us-east-1.amazonaws.com/graphql",
//     "aws_appsync_region": "us-east-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
// };

// export default awsmobile;

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
//old userpool
// const awsmobile = {
// 	aws_project_region: 'us-east-1',
// 	aws_cognito_identity_pool_id: '',
// 	aws_cognito_region: 'us-east-1',
// 	aws_user_pools_id: 'us-east-1_PGYalroNI',
// 	aws_user_pools_web_client_id: '5krjkjd5d3mnm6daqb4cbsjtrp',
// 	oauth: {},
// 	aws_appsync_graphqlEndpoint:
// 	  'https://qeceejqevvhpzn4qzwgdvcma7u.appsync-api.us-east-1.amazonaws.com/graphql',
// 	aws_appsync_region: 'us-east-1',
// 	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
// };

//latest aws userpool
const awsmobile = {
	aws_project_region: 'us-east-1',
	aws_cognito_identity_pool_id: '',
	aws_cognito_region: 'us-east-1',
	aws_user_pools_id: 'us-east-1_O5shNvuAc',
	aws_user_pools_web_client_id: '71jh719r1tkea0sqhgclbnrr4f',
	oauth: {},
	aws_appsync_graphqlEndpoint:
	  'https://qeceejqevvhpzn4qzwgdvcma7u.appsync-api.us-east-1.amazonaws.com/graphql',
	aws_appsync_region: 'us-east-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsmobile;
