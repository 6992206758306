import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
  Dialog,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import { EnhancedAddImageFormValues } from './EnhancedAddImageForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  addLibraryImageToS3,
  setImageBinary,
  setImageUrl,
  setIsImageModalOpen,
} from '../../../slices/imageSlice';
import { DropzoneArea } from 'material-ui-dropzone';
import theme from '../../../app/theme';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import formStyles from '../../../assets/jss/components/formStyles';
import Resizer from "react-image-file-resizer";
import { addImageToS3 } from '../../../slices/commonSlice';


interface IAddImageFormProps {}

const useStyles = makeStyles(formStyles);

export const AddImageForm: React.FC<
  IAddImageFormProps & FormikProps<EnhancedAddImageFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setTouched,
    setSubmitting,
  } = props;

  const [disabled, setIsDisabled] = useState<boolean>();
  const { imageLoading, isImageModalOpen, imageUrl } = useSelector(
    (state: RootState) => {
      return {
        imageLoading: state.image.loading,
        isImageModalOpen: state.image.isImageModalOpen,
        imageUrl: state.commonLists.commonUrl
      };
    },
    shallowEqual
  );

  localStorage.setItem('urlRoute', "images");


  useEffect(() => {
    setFieldValue('imageUrl', imageUrl);
    return () => {};
  }, [imageUrl]);
  const handleClose = () => {
    dispatch(setIsImageModalOpen(false));
  };
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={isImageModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: theme.palette.primary.main,
              backgroundColor: 'white',
            }}
          >
            Add Image 
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: 'white',
            }}
          >
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <FormControl required className={classes.formControl}>
              <Typography variant="subtitle1" className={classes.label}>
                Description{' '} <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder="Description"
                type="text"
                name="description"
                variant="outlined"
                size="small"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.description && touched.description) as boolean}
                helperText={
                  errors.description &&
                  touched.description &&
                  errors.description
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
            <FormControl required className={classes.formControl}>
              <Typography variant="subtitle1" className={classes.label}>
                Image URL{' '} 
              </Typography>
              {!imageUrl.startsWith('https') ? (
                <TextField
                  placeholder="Eg. https://youtu.be/url"
                  type="text"
                  name="imageUrl"
                  variant="outlined"
                  size="small"
                  value={values.imageUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disabled}
                  error={(errors.imageUrl && touched.imageUrl) as boolean}
                  helperText={
                    errors.imageUrl && touched.imageUrl && errors.imageUrl
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              ) : (
                <Typography variant="subtitle2">
                  <a href={imageUrl} target="_blank">
                    {imageUrl}
                  </a>
                </Typography>
              )}
            </FormControl>
            <FormControl required className={classes.formControl}>
              <Typography variant="subtitle1" className={classes.label}>
                Upload{' '} <span style={{color:'red'}}>*</span>
              </Typography>
              <DropzoneArea
                acceptedFiles={['image/*']}
                filesLimit={1}
                onChange={(files) => {
                  if (files.length > 0) {
                    let file = files[0];

                    const formData = new FormData();
                    formData.append('file', file);
                    setIsDisabled(true);
                    dispatch(addImageToS3(formData,files));
                  } else {
                    setIsDisabled(false);
                  }
                }}
                onDelete={(e) => {
                  dispatch(setImageUrl(''));
                  setIsDisabled(true);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: 'white',
            }}
          >
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleSubmit();
                dispatch(setIsImageModalOpen(false));
              }}
              disabled={imageLoading || values.imageUrl === ''}
              // fullWidth
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
