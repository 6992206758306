import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  setRecipeId,
  setStart,
  setEnd,
  setTaskLocation,
  createTasksForStudents,
  updateTaskForStudent,
  setOpenEditModal,
} from '../../../slices/taskSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { EditAdditionalTaskDetailsForm } from './EditAdditionalTaskDetailsForm';
import { RootState } from '../../../app/rootReducer';

interface IDispatchProps {
  setOpenEditModal: ActionCreatorWithPayload<boolean, string>;
  setStart: ActionCreatorWithPayload<Date, string>;
  setEnd: ActionCreatorWithPayload<Date, string>;
  setTaskLocation: ActionCreatorWithPayload<string, string>;
  setRecipeId: ActionCreatorWithPayload<string, string>;
  updateTaskForStudent: (history: History) => AppThunk;
}

export interface EnhancedEditAdditionalTaskDetailsFormValues {
  start: Date;
  end: Date;
  date: Date;
  taskLocation: string;
  recipeId: string;
  isRecurring?: boolean;
  custome_noti: string[];

}

export interface EnhancedEditAdditionalTaskDetailsFormProps
  extends RouteComponentProps {
  isRecurring?: boolean;
  start?: Date;
  end?: Date;
  date?: Date;
  taskLocation?: string;
  recipeId?: string;
  custome_noti?: string[];
  setStart: ActionCreatorWithPayload<Date, string>;
  setEnd: ActionCreatorWithPayload<Date, string>;
  setTaskLocation: ActionCreatorWithPayload<string, string>;
  setRecipeId: ActionCreatorWithPayload<string, string>;
  updateTaskForStudent: (history: History) => void;
  setOpenEditModal: ActionCreatorWithPayload<boolean, string>;
}

const EnhancedEditAdditionalTaskDetailsForm = withFormik<
  EnhancedEditAdditionalTaskDetailsFormProps,
  EnhancedEditAdditionalTaskDetailsFormValues
>({
  mapPropsToValues: ({ start, date, end, taskLocation, isRecurring, custome_noti, recipeId }) => ({
    start: start ? new Date(start) : new Date(),
    end: end
      ? new Date(end)
      : new Date(new Date().setHours(new Date().getHours() + 1)),
    date: end ? new Date(end) : new Date(),
    taskLocation: taskLocation ? taskLocation : '',
    recipeId: recipeId ? recipeId : '',
    custome_noti:custome_noti ? custome_noti : [],

  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const {
      setStart,
      setEnd,
      setTaskLocation,
      setRecipeId,
      updateTaskForStudent,
      history,
      setOpenEditModal,
      isRecurring,
    } = props;
    const { start, end, date, taskLocation, recipeId } = values;
    // start.setDate(date.getDate());
    // start.setFullYear(date.getFullYear());
    // start.setMonth(date.getMonth());
    // end.setDate(date.getDate());
    // end.setFullYear(date.getFullYear());
    // end.setMonth(date.getMonth());
    let startTime = new Date(start);
    startTime.setDate(date.getDate());
    startTime.setFullYear(date.getFullYear());
    startTime.setMonth(date.getMonth());

    let endTime = new Date(end);
    endTime.setDate(date.getDate());
    endTime.setFullYear(date.getFullYear());
    endTime.setMonth(date.getMonth());
    // setStart(start);
    // setEnd(end);
    setStart(startTime);
    setEnd(endTime);
    setTaskLocation(taskLocation);
    setRecipeId(recipeId);

    if (isRecurring) {
      setOpenEditModal(true);
    } else {
      updateTaskForStudent(history);
    }

    // createTasksForStudents(history);

    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditAdditionalTaskDetailsForm);

const EnhancedEditAdditionalTaskDetailsFormWithRouter = withRouter(
  EnhancedEditAdditionalTaskDetailsForm
);

const mapStateToProps = (state: RootState, ownProps: any = {}) => ({
  start: state.task.start,
  end: state.task.end,
  date: state.task.end,
  taskLocation: state.task.taskLocation,
  recipeId: state.task.recipeId,
  isRecurring: state.task.isRecurring,
});

export default connect(mapStateToProps, {
  setStart,
  setEnd,
  setTaskLocation,
  setRecipeId,
  setOpenEditModal,
  updateTaskForStudent,

  //@ts-ignore
})(EnhancedEditAdditionalTaskDetailsFormWithRouter);
