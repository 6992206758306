import React, { useState } from 'react';
import { StyledTableCell } from '../../components/StyledTableCell';
import { StyledTableRow } from '../../components/StyledTableRow';
import Checkbox from '@mui/material/Checkbox';

import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Typography,
  TableHead,
  Tooltip,
  Chip,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';

import { useHistory, useParams } from 'react-router';

import { Ingredient } from '../../constants/models/Ingredient';
import { categoryList } from '../../constants/category';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import {
  deleteIngredient,
  getIngredient,
  markIngredientAsComplete,
  markIngredientAsIncomplete,
} from '../../slices/studentSlice';
import { Edit } from '@material-ui/icons';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/ModeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import { CircularProgress } from '@mui/material';
import { DeleteIngredientModal } from './DeleteIngredientModal';
import { EditIngredientModal } from './EditIngredientModal';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
interface IIngredientRowProps {
  index: number;
  ingredient: Ingredient;
}

export const IngredientRow: React.FC<IIngredientRowProps> = ({
  ingredient,
  index,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { recipeingredientUrl,ingredientTitle, name, isCompleted, category, unit, quantity, price, id } = ingredient;
  let renderedState;
  let renderedChip;
  const [checked, setChecked] = useState(isCompleted);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { groceryId } = useParams<{ groceryId: string }>();

let titlenm;
if(name)
{
  titlenm=name;
}
else
{
  titlenm=ingredientTitle;
}
let categorynm;
if(category)
{
  categorynm=category;
}

  const getCategoryIcon = (categorynm: string) => {
    const foundCategory = categoryList.find((c) => c.name === categorynm)
      ? categoryList.find((c) => c.name === categorynm)
      : { icon: HealthAndSafetyIcon, color: 'blue' };
    const CategoryIcon = foundCategory?.icon;
    return <CategoryIcon style={{ color: foundCategory?.color }} />;
  };
  if (!isCompleted) {
    renderedChip = (
      <Tooltip title='This item is not completed' arrow>
        <Chip
          size='medium'
          icon={<PendingActionsIcon style={{ color: 'grey' }} />}
          label='Incomplete'
          variant='outlined'
          //color="primary"
          style={{ borderColor: 'grey', color: 'grey' }}
        />
      </Tooltip>
    );
  } else {
    renderedChip = (
      <Tooltip title='Item completed!' arrow>
        <Chip
          size='medium'
          icon={<DoneAll style={{ color: 'green' }} />}
          label={`Completed`}
          variant='outlined'
          //color="primary"
          style={{ borderColor: 'green', color: 'green' }}
        />
      </Tooltip>
    );
  }
  return (
    <React.Fragment>
      <StyledTableRow >

        <StyledTableCell align='center' style={{ border: 'none' }}>
          {titlenm}
        </StyledTableCell>
        {category?
        <StyledTableCell
          align='center'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
          }}
        >
          
          {category} {category !== 'any' && getCategoryIcon(category)}
        </StyledTableCell>:""
        }
        <StyledTableCell align='center' style={{ border: 'none' }}>
          {quantity.toFixed(2)}
        </StyledTableCell>
        <StyledTableCell align='center' style={{ border: 'none' }}>
          {unit}
        </StyledTableCell>
        <StyledTableCell align='center' style={{ border: 'none' }}>
          {parseFloat(price.toString()).toFixed(2)}
        </StyledTableCell>
        <StyledTableCell align='center' style={{ border: 'none' }}>
          {renderedChip}
        </StyledTableCell>
        <StyledTableCell
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
          }}
          align='center'
        >
          <Tooltip
            title={isCompleted ? 'Mark as incomplete' : 'Mark as complete'}
            arrow
          >
            <IconButton
              // className={classes.reportButton}
              // variant='contained'
              // color='primary'
              size='small'
              style={{
                // backgroundColor: loading
                //   ? 'gray'
                //   : isCompleted
                //   ? 'tomato'
                //   : 'green',
                // color: loading ? 'black' : 'white',
                marginRight: '5px',
              }}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                if (!isCompleted) {
                  await dispatch(markIngredientAsComplete(id,groceryId,history));
                } else {
                  await dispatch(markIngredientAsIncomplete(id,groceryId,history));
                }
                setLoading(false);
              }}
              // fullWidth
            >
              {loading ? (
                <CircularProgress style={{ color: '#A3A3A3' }} size='0.6rem' />
              ) : !isCompleted ? (
                <DoneIcon style={{ color: loading ? 'black' : 'green' }} />
              ) : (
                <CloseIcon style={{ color: loading ? 'black' : 'tomato' }} />
              )}
            </IconButton>
          </Tooltip>
          <IconButton
            size='small'
            onClick={async () => {
              await dispatch(getIngredient(id));
              setOpenEditModal(true);
            }}
          >
            <Edit
              style={{
                color: '#0092BC',
                // 'gold'
              }}
            />
          </IconButton>
          <IconButton
            size='small'
            onClick={() => {
              // dispatch(deleteIngredient(id));
              setOpenModal(true);
            }}
          >
            <DeleteIcon style={{ color: 'tomato' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <EditIngredientModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
      />
      <DeleteIngredientModal
        id={id}        
        groceryId={groceryId}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </React.Fragment>
  );
};
