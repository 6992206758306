import store, { AppThunk } from '../app/store';
import { viewSteps,ADD_RECIPE, editNutrintion, addRecipeSteps, viewStudentGroceryList } from './../constants/routes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/groceryRequests';
//import { recipe } from '../constants/models/recipe';
import { GroceryList } from '../constants/models/GroceryList';
import { History } from 'history';
import { useParams } from 'react-router';


export interface groceryState {
  loading: boolean;
  error: string | null;
  groceryList: GroceryList | null;
  groceryLists: GroceryList[];
  stepUrl: string;
  isrecipeStepModalOpen: boolean;
}

export const initialState: groceryState = {
  loading: false,
  error: null,
  groceryList: null,
  groceryLists: [],
  stepUrl: '',
  isrecipeStepModalOpen: false,
};

const grocerySlice = createSlice({
  name: 'recipeStep',
  initialState,
  reducers: {
    setGroceryStart(state) {
      state.loading = true;
      state.error = null;
    },
    setGroceryList(state, action: PayloadAction<GroceryList | null>) {
      state.groceryList = action.payload;
    },
    setGroceryLists(state, action: PayloadAction<{ data: GroceryList[] }>) {
      const { data } = action.payload;
      state.groceryLists = data.map((groceryList) => groceryList);
      console.warn("state-->"+state.groceryLists);
    },
    setIsrecipeStepModalOpen(state, action: PayloadAction<boolean>) {
      state.isrecipeStepModalOpen = action.payload;
    },
    setrecipeStepUrl(state, action: PayloadAction<string>) {
      state.stepUrl = action.payload;
    },
    recipeGroceryFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    recipeGroceryComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  setGroceryStart,
  setGroceryList,
  setGroceryLists,
  setIsrecipeStepModalOpen,
  setrecipeStepUrl,
  recipeGroceryFailure,
  recipeGroceryComplete,
} = grocerySlice.actions;

export default grocerySlice.reducer;

export const getGroceryListForStudent =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        const { counselor } = store.getState().counselor;
        const { programManager } = store.getState().programManager;
        const { superAdmin } = store.getState().superAdmin;

        let program_de_id="";
        if(superAdmin?.id)
        {
          program_de_id=superAdmin.id;
        } 
        else if(parent?.id)
        {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
        else if(counselor?.id)
        {
          program_de_id=counselor.id;
        }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const data = await REQUESTS.getStudentGroceryList(studentId);
        if (data.length > 0) {
          console.log(data);
          dispatch(setGroceryLists({ data: data }));
         }// else {
        //   const newData = await REQUESTS.createStudentGroceryList(
        //     programId,
        //     studentId
        //   );
        //   console.log(newData);
        //   if(newData)
        //   {
        //     const data2 = await REQUESTS.getStudentGroceryList(studentId);
        //     if (data2.length > 0) {
        //       console.log(data2);
        //       dispatch(setGroceryList({ data: data2 }));
        //     }
        //   }
        //   //dispatch(setGroceryList(newData));
        // }

        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  export const getGroceryListForStudentRefresh =
  (studentId: string,program_pass_id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      dispatch(setGroceryLists({ data: [] }));
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        const { counselor } = store.getState().counselor;
        const { programManager } = store.getState().programManager;

      let program_de_id=program_pass_id;
      
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const data = await REQUESTS.getStudentGroceryList(studentId);
        if (data.length > 0) {
          console.log(data);
          dispatch(setGroceryLists({ data: data }));
         }
         
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const createGroceryList =
  (
    title: string,
    description: string,
    groceryImage: string,
    studentId:string,
    history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
    let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await REQUESTS.createGroceryList(
          title,
          description,
          groceryImage,
          studentId
          
        );
        console.log(data);
        stdid=data.student.id;

        dispatch(setGroceryList(null));
        dispatch(setSuccessMsg(`Grocery list added!`));

      }
      dispatch(recipeGroceryComplete());
      history.push(viewStudentGroceryList(stdid));

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getGroceryListRefresh =
  (program_pass_id: string,id: string,history:History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      let program_de_id=program_pass_id;
      
      let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await REQUESTS.getGroceryList(id);
        //console.log(data);
        dispatch(setGroceryList(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getGroceryList =
  (id: string,history:History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await REQUESTS.getGroceryList(id);
        //console.log(data);
        dispatch(setGroceryList(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getGroceryListIngredients =
  (id: string,history:History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      console.warn("groceryId------>"+id);
      let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await REQUESTS.getGroceryListIngredients(id);
        //console.log(data);
        dispatch(setGroceryList(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getGroceryListIngredientsRefresh =
  (id: string,pid: string,history:History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      let program_de_id=pid;
     
      console.warn("groceryId------>"+id);
      let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await REQUESTS.getGroceryListIngredients(id);
        //console.log(data);
        dispatch(setGroceryList(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };


export const updateGroceryListById =
  (
    id: string,
    title: string,
    description: string,
    groceryImage: string,
   history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      let stdid;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;

        const data = await REQUESTS.updateGroceryListById(
          id,
          title,
          description,
          groceryImage,
         
        );
        console.log(data);
        stdid=data.student.id;
       // dispatch(setGroceryList(null));
        
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }

        // dispatch(setStudents({ data: students }));
        dispatch(setSuccessMsg(`Grocery list updated!`));

      }
      dispatch(recipeGroceryComplete());
      history.push(viewStudentGroceryList(stdid));
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteGroceryList =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setGroceryStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await REQUESTS.deleteGroceryList(id);
        console.log(data);
        dispatch(setGroceryList(null));
        if (student != null) {
          dispatch(getGroceryListForStudent(student.id));
        }
        dispatch(setSuccessMsg(`Grocery list deleted!`));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(recipeGroceryComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeGroceryFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
