import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { RootState } from '../../app/rootReducer';

import { LOGIN } from '../../constants/routes';
import { USER_ROLE } from '../../constants/userRoles';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import setAuthToken from '../../utils/setAuthToken';
import { getChildForParent, getParentById } from '../../slices/parentSlice';
import { getCounselorById } from '../../slices/counselorSlice';
import { getProgramForCounselor, getProgramForCounselorRefresh } from '../../slices/programManagerSlice';
import { getCheckAuthCounselor, setIsAuthenticated, setRole } from '../../slices/authSlice';
import { getStudentById } from '../../slices/studentSlice';


interface ICounselorRouteProps extends RouteProps {}

const CounselorRoute: React.FC<ICounselorRouteProps> = ({
  children,
  ...rest
}) => {
  const { isAuthenticated, role } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
      isAuthenticated: state.auth.isAuthenticated,
      role: state.auth.role,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const graphQlUserr = localStorage.getItem('graphQlUser');
  console.warn("check auth local"+authlocal);
  const authlocaltoken = localStorage.getItem('token');
  const grapUser = localStorage.getItem('userEmail');
  const grapUserPassword = localStorage.getItem('password');
  const user_id = localStorage.getItem('user_id');
  const dispatch=useDispatch();
  const history=useHistory();
  const [stateAuth,setStateAuth] = useState(false)

  let chkauth:string;

  if(isAuthenticated)
  {
    chkauth="yes";

  }
  else
  {
    chkauth="no";

  }
if(authlocal)
{
  

}
else
{
  //setIsAuthenticated(true);
}
//console.log("auth local --->"+authlocal);
const urlRoute = localStorage.getItem('urlRoute');
  const urlId = localStorage.getItem('urlId');

let userr_id:string;
if(chkauth==="no")
{
  if(user_id && authlocaltoken )
  {
    if(authlocal && authlocal==="1")
    {
        console.log("counselor route calling...");
        userr_id=user_id;
        setAuthToken(authlocaltoken);
        dispatch(setRole(USER_ROLE.COUNSELOR));
        dispatch(getCounselorById(user_id));
        dispatch(getProgramForCounselorRefresh(user_id, history));
        dispatch(setIsAuthenticated(true));

        if(urlRoute==="edit_user" && urlId)
        {
          dispatch(getStudentById(urlId));

        }
    }
    
  }
}

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: LOGIN,
                state: {
                  from: location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default CounselorRoute;
