import React from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import EnhancedAddOrEditEventForm from './EnhancedAddOrEditEventForm';
import useMediaQuery from '@mui/material/useMediaQuery';

import GRAPHIC from '../../../assets/images/onboarding.svg';
import theme from '../../../app/muiTheme';

interface IAddOrEditEventProps {}

let renderedForm = <EnhancedAddOrEditEventForm />;

const AddOrEditEvent: React.FC<IAddOrEditEventProps> = ({}) => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div style={{ paddingTop: '1vh' }}>
      <Container
        style={{ paddingLeft: '0.4em', paddingRight: '0.4em', height: '80vh' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <div style={{ padding: '1em' }}>{renderedForm}</div>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ paddingTop: '10vh', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddOrEditEvent;
