export const PROGRAM_MANAGER = 'PROGRAM_MANAGER';
export const COUNSELOR = 'COUNSELOR';
export const STUDENT = 'STUDENT';
export const PARENT = 'PARENT';
export const SUPER_ADMIN = 'SUPER_ADMIN';

export enum USER_ROLE {
  PROGRAM_MANAGER,
  COUNSELOR,
  STUDENT,
  PARENT,
  SUPER_ADMIN
}
