import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import {
  setChatRoom,
  setChatRoomUser,
  setUser,
} from '../../slices/chatRoomSlice';
import { decryptMessage } from '../../utils/encryption';
import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import { CheckOutlined } from '@mui/icons-material';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const SECONDARY = '#0da9e5';

interface GroupMemberListItemProps {
  contact: any;
  groupAdmin: any;
  chatRoomId: string;
}

export const GroupMemberListItem: React.FC<GroupMemberListItemProps> = ({
  contact,
  groupAdmin,
  chatRoomId,
}) => {
  const user = {
    id: contact.id,
    name: `${contact.name}`,
    email: contact.email,
    about: contact.about,
    imageUrl: contact.imageUrl,
  };
  let renderedImage;

  renderedImage = (
    <img
      src={
        user.imageUrl
          ? user.imageUrl
          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
      }
      alt={
        user.imageUrl
          ? user.imageUrl
          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
      }
      className='avatar'
    />
  );
  return (
    <div
      className='sidebar-contact'
      // to={`/chat/${chatRoom.id}`}
      // onClick={onClick}
    >
      <div
        className='sidebar-contact__avatar-wrapper'
        style={{ position: 'relative' }}
      >
        {renderedImage}
      </div>
      <div className='sidebar-contact__content'>
        <div className='sidebar-contact__top-content'>
          <h2 className='sidebar-contact__name'>
            {' '}
            {user.id === groupAdmin.id ? 'You' : user.name}{' '}
          </h2>
          {user.id === groupAdmin.id && (
            <span className='sidebar-contact__time'>
              <Chip
                style={{ borderColor: SECONDARY, color: SECONDARY }}
                label='Admin'
                variant='outlined'
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
