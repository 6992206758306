import { createStyles, Theme } from '@material-ui/core';

const navbarStyles = (theme: Theme) =>
  createStyles({
    root: {
      '& > * ': {
        marginTop: theme.spacing(4),
        width: '25ch',
        heigh: '2vh',
      },
    },
    navbar: {
      zIndex: 1000,
      position: 'fixed',
      top: 0,
      width: '100%',
      padding: '0.2rem 2rem',
      '&:before': {
        content: `""`,
        position: 'absolute',
        background: 'inherit',
        backgroundColor: '#F5FAFE',
        zIndex: -1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        //boxShadow: 'inset 0 0 2000px rgba(255, 255, 255, .5)',
        overflow: 'hidden',
      },
      minHeight: '0vh',
      transition: 'min-height 400ms ease-in-out',
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'right',
    },
    title: {
      fontFamily: 'helvetica',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    navlink: {
      position: 'relative',
      margin: '1rem',
      fontWeight: 600,
      paddingRight: '1rem',
      paddingLeft: '1rem',
      color: 'black',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&:hover:before': {
        transform: 'scaleX(1)',
        transition: 'transform 300ms ease-in-out',
      },
      '&:before': {
        content: `""`,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -5,
        height: 3,
        backgroundColor: theme.palette.primary.main,
        transform: 'scaleX(0)',
      },
      whiteSpace: 'nowrap',
    },
    navlink_active: {
      position: 'relative',
      margin: '1rem',
      fontWeight: 600,
      paddingRight: '1rem',
      paddingLeft: '1rem',
      color: 'black',
      '&:before': {
        content: `""`,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -5,
        height: 3,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
      },
      whiteSpace: 'nowrap',
    },
    toggle: {
      color: theme.palette.primary.main,
      padding: '3px 7px',
      margin: 20,
      border: '2px solid',
      backgroundColor: 'inherit',
      borderRadius: 4,
    },
    logo: {
      width: '40px',
      flex: '1',
      margin: '0 0.5rem 0 0 ',
      //transform: 'scale(0)',
      //transition: 'transform 200ms ease-in-out',
    },
    n: {
      color: theme.palette.primary.main,
    },
    flyte: {
      color: theme.palette.secondary.main,
    },
    paper: { background: 'rgba(255, 255, 255, 0.85)' },
  });

export default navbarStyles;
