import React, { useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Container,
  Chip,
  Link,
  Button,
  Divider,
  IconButton,
} from '@material-ui/core';
import viewStudentDetailsStyles from '../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById } from '../../../slices/studentSlice';
import { editStudent } from '../../../constants/routes';
import { Ingredient } from '../../../constants/models/Ingredient';
import EnhancedAddOrEditGroceryItemForm from './EnhancedAddOrEditGroceryItemForm';
interface AddOrEditGroceryItemProps {
  // ingredient?: Ingredient | null | undefined;
  handleClose: () => void;
}

const useStyles = makeStyles(viewStudentDetailsStyles);

export const AddOrEditGroceryItem: React.FC<AddOrEditGroceryItemProps> = ({
  // ingredient,
  handleClose,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { groceryList } = useSelector(
    (state: RootState) =>
    {
      return {
        groceryList: state.groceryLists.groceryList,
      };
    }, 
    shallowEqual
  );
  useEffect(() => {
    return () => {};
  }, [groceryList]);
  return (
    <div style={{ padding: '1rem', width: '100%' }}>
      {/* <Paper
        className={classes.paper}
        style={{ padding: '1rem', width: '90%' }}
      > */}
      <Typography
        variant='h5'
        component='h5'
        gutterBottom
        style={{ textAlign: 'center' }}
      >
        {groceryList !== null ? 'Edit Ingredient' : 'Add Ingredient'}
      </Typography>
      {groceryList !== null && (
        // @ts-ignore
        <EnhancedAddOrEditGroceryItemForm
          // groceryItem={ingredient}
          groceryListId={groceryList.id}
          handleClose={handleClose}
        />
      )}
      {/* </Paper> */}
    </div>
  );
};
