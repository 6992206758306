import { createStyles, Theme } from '@material-ui/core';
const dashboardMenuStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: 256,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: theme.spacing(3, 2),
      background: '#fafafa',
    },
    footer: {
      padding: theme.spacing(2),
      background: '#fafafa',
    },
  });
export default dashboardMenuStyles;
