import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootState } from '../../app/rootReducer';
import { ChatMessage } from '../../components/Messaging/ChatMessage';
import { getChatRoomMessages, updateMessage } from '../../slices/chatRoomSlice';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateMessage, onUpdateMessage } from '../../graphql/subscriptions';
import Header from '../../components/Messaging/Header';
import ChatInput from '../../components/Messaging/ChatInput';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


interface ChatRoomProps {}
const ChatRoom: React.FC<ChatRoomProps> = ({}) => {
  const params = useParams();
  const history = useHistory();


  let {
    chatRoom: {
      lastMessage,
      chatRoomMessages,
      chatRoomUser,
      chatRoom,
      user,
      groupUsers: users,
    },
    currentUser,
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      currentUser: state.auth.graphQlUser,
    };
  }, shallowEqual);
  const dispatch = useDispatch();

  // @ts-ignore
  const chatRoomId = chatRoom.id;

  const [loading, setLoading] = useState<boolean>(false);

  const myRef = useRef<HTMLDivElement>(null);
  const messageList = (item: any, index: number) => {
    const message = item;

    return (
      <ChatMessage
        message={message}
        lastMessage={index - 1 >= 0 ? chatRoomMessages[index - 1] : undefined}
        nextMessage={
          index + 1 <= chatRoomMessages.length - 1
            ? chatRoomMessages[index + 1]
            : message
        }
        name={
          // @ts-ignore
          message.user.name
          // // @ts-ignore
          // chatRoom.isGroup
          //   ? // @ts-ignore
          //     users.find((u: any) => u.id === message.user.id)
          //     ? // @ts-ignore
          //       users.find((u: any) => u.id === message.user.id).name
          //     : // @ts-ignore
          //       ''
          //   : // @ts-ignore
          //     user?.name
        }
        // @ts-ignore
        isGroup={chatRoom.isGroup}
        chattingWith={message.user}
        index={index}
        myRef={myRef}
      />
    );
  };

  const scrollToBottom = () => {
    // const scrollHeight = myRef.current?.scrollHeight;
    // const height = myRef.current?.clientHeight;
    // // @ts-ignore
    // const maxScrollTop = scrollHeight - height;
    // // @ts-ignore
    // myRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    myRef.current?.scrollIntoView(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getChatRoomMessages(chatRoomId, user, currentUser));
    setLoading(false);
    scrollToBottom();
  }, []);
  useEffect(() => {
    setLoading(true);
    dispatch(getChatRoomMessages(chatRoomId, user, currentUser));
    setLoading(false);
    scrollToBottom();
  }, [user, chatRoomId]);
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateMessage)
      // @ts-ignore
    ).subscribe({
      next: (data: any) => {
        const newMessage = data.value.data.onCreateMessage;
        if (newMessage.chatRoomId !== chatRoomId) {
          console.log('In another room');
          return;
        }
        if (
          // @ts-ignore
          !chatRoomMessages.find((message) => message.id === newMessage.id)
        ) {
          dispatch(updateMessage(newMessage.chatRoomId, newMessage));
          scrollToBottom();
          // dispatch(getChatRoomMedia(newMessage.chatRoomId));
        }
      },
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateMessage)
      // @ts-ignore
    ).subscribe({
      next: (data: any) => {
        const newMessage = data.value.data.onUpdateMessage;
        if (newMessage.chatRoomId !== chatRoomId) {
          console.log('In another room');
          return;
        }
        if (
          // @ts-ignore
          chatRoomMessages.find((message) => message.id === newMessage.id)
        ) {
          // playSound();
          // dispatch(updateMessageReadDetails(newMessage.chatRoomId, newMessage));
          // dispatch(getChatRoomMedia(newMessage.chatRoomId));
          // dispatch(updateMessageReadDetails(newMessage, currentUser));
          dispatch(getChatRoomMessages(chatRoomId, user, currentUser));
          scrollToBottom();
        }
      },
    });
    return () => subscription.unsubscribe();
  }, []);
  return (
    <div>
      
      <div className='chat'>
        <div className='chat__body'>
          <div className='chat__bg'></div>

          <Header
            user={
              // @ts-ignore
              chatRoom?.isGroup
                ? {
                    // @ts-ignore
                    groupName: chatRoom?.groupName,
                    // @ts-ignore
                    groupImageUri: chatRoom?.groupImageUri,
                    // @ts-ignore
                    description: chatRoom?.groupDescription,
                  }
                : user
            }
            // @ts-ignore
            isGroup={chatRoom?.isGroup}
            // openProfileSidebar={() => openSidebar(setShowProfileSidebar)}
            // openSearchSidebar={() => openSidebar(setShowSearchSidebar)}
          />
          <div
            ref={myRef}
            className='chat__content'
            style={{
              backgroundColor: 'white',
              // overflow: 'scroll'
            }}
          >
            {loading ? 'Loading...' : chatRoomMessages.map(messageList)}
          </div>
          <footer className='chat__footer'>
            <ChatInput
              chatRoomId={chatRoomId}
              user={currentUser}
              disabled={loading}
            />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
