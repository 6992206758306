import { PROGRAM_ROUTE } from './../constants/routes';
import { TASK_TYPE_ROUTE } from '../constants/routes';
import { TaskType } from './../constants/models/TaskType';
import API from './api';

export async function getTaskTypeById(id: string) {
  try {
    const res = await API.get<{ data: TaskType; success: boolean }>(
      `${TASK_TYPE_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getTaskTypesForProgram(programId: string) {
  try {
    const res = await API.get<{ data: TaskType[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${TASK_TYPE_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createTaskType(name: string, programId: string) {
  try {
    const res = await API.post<{ data: TaskType; success: boolean }>(
      `${TASK_TYPE_ROUTE}`,
      { name, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateTaskType(
  id: string,
  name: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: TaskType; success: boolean }>(
      `${TASK_TYPE_ROUTE}/${id}/updateTaskType`,
      { name, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteTaskType(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${TASK_TYPE_ROUTE}/${id}/deleteTaskType`
    );
  } catch (err) {
    throw err;
  }
}
