import { RecurringTask } from '../constants/models/RecurringTask';
import {
  COUNSELOR_ROUTE,
  PROGRAM_ROUTE,
  RECURRING_TASK_ROUTE,
} from '../constants/routes';
import { SubTaskItem } from '../slices/taskSlice';

import API from './api';

export async function getRecurringTaskById(id: string) {
  try {
    const res = await API.get<{ data: RecurringTask; success: boolean }>(
      `${RECURRING_TASK_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getRecurringTasksForProgram(programId: string) {
  try {
    const res = await API.get<{ data: RecurringTask[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${RECURRING_TASK_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getRecurringTasksForCounsellor(userId: string) {
  try {
    const res = await API.get<{ data: RecurringTask[]; success: boolean }>(
      `${COUNSELOR_ROUTE}/${userId}${RECURRING_TASK_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateRecurringTask(
  id: string,
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  // instructions: string[],
  subTasks: SubTaskItem[],
  days: number[],
  notiTime: string[],
  taskTypeId: string,
  recipeId: string
) {
  try {
    const res = await API.post<{ data: RecurringTask; success: boolean }>(
      `${RECURRING_TASK_ROUTE}/${id}/updateRecurringTask`,
      {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        subTasks,
        days,
        taskTypeId,
        notiTime,
        recipeId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteRecurringTask(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECURRING_TASK_ROUTE}/${id}/deleteRecurringTask`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
