import React from 'react';
import FaceIcon from '@mui/icons-material/Face';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  ADD_DOCUMENTS,
  ADD_IMAGES,
  ADD_QUESTIONS,
  ADD_STUDENT,
  ADD_TASK,
  ADD_VIDEOS,
  ADD_RECIPE,
  COUNSELOR_STUDENT_PROFILES,
  MESSAGING,
  STUDENT_PROFILES,
  ADD_RECIPE_TAG,
  COUNSELORS_STUDENT_PROFILES
} from '../../../constants/routes';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import AddVideo from '@mui/icons-material/VideoLibrary';
import { Chat } from '@material-ui/icons';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { BiCookie } from 'react-icons/bi';


export const counselorRoutes = [
  {
    id: 'Program',
    children: [
      {
        // id: 'Student Profiles',
        id: 'User Profiles',
        route: COUNSELORS_STUDENT_PROFILES,
        // route: COUNSELOR_STUDENT_PROFILES,
        icon: <FaceIcon />,
      },
      // {
      //   id: 'Messaging',
      //   route: MESSAGING,
      //   icon: <Chat />,
      // },
      {
        id: 'Cookbook',
        route: ADD_RECIPE,
        icon: <BiCookie />,
      },
      // {
      //   id: 'Cook Book Tags',
      //   route: ADD_RECIPE_TAG,
      //   icon: <i className="fa fa-tag"></i>,
      // },
    ],
  },
  {
    id: 'Library',
    children: [
      {
        id: 'Questions',
        route: ADD_QUESTIONS,
        icon: <QuestionAnswerIcon />,
      },
      {
        id: 'Images',
        route: ADD_IMAGES,
        icon: <AddPhoto />,
      },
      {
        id: 'Videos',
        route: ADD_VIDEOS,
        icon: <AddVideo />,
      },
      {
        id: 'Documents',
        route: ADD_DOCUMENTS,
        icon: <UploadFileIcon />,
      },
      
    ],
  },
];
