import { EventModel } from './../constants/models/EventModel';
import { EVENT_TYPE } from '../constants/eventType';
import { EVENT_ROUTE, PROGRAM_ROUTE } from './../constants/routes';
import API from './api';

export async function getEventById(id: string) {
  try {
    const res = await API.get<{ data: EventModel; success: true }>(
      `${EVENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getEventsForProgram(programId: string) {
  try {
    const res = await API.get<{ data: EventModel[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${EVENT_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createEvent(
  title: string,
  description: string,
  eventType: EVENT_TYPE,
  start: Date,
  end: Date,
  userId: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: EventModel; success: boolean }>(
      `${EVENT_ROUTE}`,
      {
        title,
        description,
        eventType,
        start,
        end,
        userId,
        programId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateEvent(
  id: string,
  title: string,
  description: string,
  eventType: EVENT_TYPE,
  start: Date,
  end: Date
) {
  try {
    const res = await API.post<{
      data: EventModel;
      success: boolean;
    }>(`${EVENT_ROUTE}/${id}`, {
      title,
      description,
      eventType,
      start,
      end,
    });
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteEvent(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${EVENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getEventsForResidents(programId: string) {
  try {
    const res = await API.get<{ data: EventModel[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${EVENT_ROUTE}/residents`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getEventsForStaff(programId: string) {
  try {
    const res = await API.get<{ data: EventModel[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${EVENT_ROUTE}/staff`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
