import { withFormik } from 'formik';
import { connect } from 'react-redux';
import store, { AppThunk } from '../../../app/store';
import * as Yup from 'yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Document } from '../../../constants/models/Document';
import {
  setIsDocumentModalOpen,
  createDocument,
} from '../../../slices/documentSlice';
import { AddDocumentForm } from './AddDocumentForm';

export interface EnhancedAddDocumentFormValues {
  id: string;
  name: string;
  documentUrl: string;
}

export interface IDispatchProps {
  setIsDocumentModalOpen: ActionCreatorWithPayload<boolean, string>;
  createDocument: (name: string, documentUrl: string) => AppThunk;
}

export interface EnhancedAddDocumentFormProps {
  setIsDocumentModalOpen: ActionCreatorWithPayload<boolean, string>;
  createDocument: (name: string, documentUrl: string) => void;
}

const EnhancedAddDocumentForm = withFormik<
  EnhancedAddDocumentFormProps,
  EnhancedAddDocumentFormValues
>({
  mapPropsToValues: (props) => ({
    id: '',
    name: '',
    documentUrl: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { createDocument, setIsDocumentModalOpen } = props;
    const { id, name, documentUrl } = values;
    createDocument(name, documentUrl);
    setIsDocumentModalOpen(false);
    setSubmitting(false);
  },
})(AddDocumentForm);

export default connect<null, IDispatchProps>(null, {
  createDocument,
  setIsDocumentModalOpen,
})(EnhancedAddDocumentForm);
