import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { registerOrganisation } from '../../../slices/authSlice';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_SHORT,
  PASSWORDS_MUST_MATCH,
  EMAIL_NOT_LONG_ENOUGH,
  SCHOOL_NAME_REQUIRED,
} from '../../../constants/formMessages';
import { RegisterOrganisationForm } from './RegisterOrganisationForm';
import { USER_ROLE } from '../../../constants/userRoles';

interface IDispatchProps {
  registerOrganisation: (
    name: string,
    email: string,
    password: string,
    role: USER_ROLE,
    schoolName: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedRegisterOrganisationFormValues {
  email: string;
  name: string;
  password: string;
  passwordConfirmation: string;
  role: USER_ROLE;
  schoolName: string;
}

export interface EnhancedRegisterOrganisationFormProps extends RouteComponentProps {
  email?: string;
  name?: string;
  phoneNumber?: string;
  schoolName?: string;
  role?: USER_ROLE;
  registerOrganisation: (
    name: string,
    email: string,
    password: string,
    role: USER_ROLE,
    schoolName: string,
    history: History
  ) => void;
}

const EnhancedRegisterOrganisationForm = withFormik<
  EnhancedRegisterOrganisationFormProps,
  EnhancedRegisterOrganisationFormValues
>({
  mapPropsToValues: (props) => ({
    email: props.email ? props.email : '',
    name: props.name ? props.name : '',
    password: '',
    passwordConfirmation: '',
    role: props.role ? props.role : USER_ROLE.PARENT,
    schoolName: props.schoolName ? props.schoolName : '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
    name: Yup.string().required(NAME_REQUIRED),
    password: Yup.string()
      .required(PASSWORD_REQUIRED)
      .min(6, PASSWORD_TOO_SHORT),
    passwordConfirmation: Yup.string()
      .required(CONFIRM_PASSWORD_REQUIRED)
      .oneOf([Yup.ref('password')], PASSWORDS_MUST_MATCH),
    schoolName: Yup.string().notRequired(),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { registerOrganisation, history } = props;
    const { name, email, password, schoolName, role } = values;
    registerOrganisation(name, email, password, role, schoolName, history);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(RegisterOrganisationForm);

const EnhancedRegisterOrganisationFormWithRouter = withRouter(EnhancedRegisterOrganisationForm);

export default connect<null, IDispatchProps>(null, { registerOrganisation })(
  EnhancedRegisterOrganisationFormWithRouter
);
