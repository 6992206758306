import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { RootState } from '../../app/rootReducer';

import { LOGIN,PARENT_STUDENT_PROFILES,COUNSELOR_PROFILES } from '../../constants/routes';
import { USER_ROLE } from '../../constants/userRoles';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import setAuthToken from '../../utils/setAuthToken';
//import { getCheckAuthProgramManager, loginWithGraphQl, setIsAuthenticated, setRole } from '../../slices/authSlice';
import { getChildForParent, getParentById } from '../../slices/parentSlice';
import { getProgramForProgramManager, getProgramForProgramManagerRefresh, getProgramManagerById } from '../../slices/programManagerSlice';
import { getCheckAuthProgramManager, setIsAuthenticated, setRole } from '../../slices/authSlice';

import { getStudentTasksForDate } from '../../slices/taskSlice';
import { getStepListForStudent } from '../../slices/recipeStepsSlice';
import { getrecipeNutritionById } from '../../slices/recipeNutritionSlice';
import { getIngidiansListForStudent } from '../../slices/ingridiansSlice';
import { getProgramForSuperAdmin, getProgramForSuperAdminRefresh, getSuperAdminById, getSuperAdminByIdRefresh } from '../../slices/superAdminSlice';


interface ISuperAdminRouteProps extends RouteProps {}

const SuperAdminRoute: React.FC<ISuperAdminRouteProps> = ({
  children,
  ...rest
}) => {
  const { isAuthenticated, role } = useSelector((state: RootState) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      role: state.auth.role,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const graphQlUserr = localStorage.getItem('graphQlUser');
  console.warn("check auth local"+authlocal);
  const authlocaltoken = localStorage.getItem('token');
  const grapUser = localStorage.getItem('userEmail');
  const grapUserPassword = localStorage.getItem('password');
  const user_id = localStorage.getItem('user_id');
  const dispatch=useDispatch();
  const history=useHistory();
  const [stateAuth,setStateAuth] = useState(false)
  let chkauth:string;

  if(isAuthenticated)
  {
    chkauth="yes";

  }
  else
  {
    chkauth="no";

  }
  
if(authlocal)
{

}
else
{
  //setIsAuthenticated(true);
}

let userr_id:string;
if(chkauth==="no")
{
  if(user_id && authlocaltoken )
  {
    if(authlocal && authlocal==="4")
    {
        console.log("super admin route calling...");
        userr_id=user_id;
        setAuthToken(authlocaltoken);
        dispatch(setRole(USER_ROLE.SUPER_ADMIN));
        dispatch(getSuperAdminByIdRefresh(user_id));
        dispatch(getProgramForSuperAdmin(user_id, history));
        dispatch(setIsAuthenticated(true));

    }
  
  }
}
 
  return (
    <Route
    {...rest}
    render={({ location }) => {
      if (isAuthenticated) {
        return children;
      // } else if (!isAuthenticated && isAuthLocal?.length && authlocaltoken) {
      //   return children;
      } else {
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              state: {
                from: location,
              },
            }}
          />
        );
      }
    }}
  />
    // <Route
    //   {...rest}
    //   render={({ location }) => {
           

    //         if (isAuthenticated && authlocaltoken) {
    //           if(purl==="http://localhost:3000/#/program-manager/counselor/profiles")
    //           {
    //                 return (
    //                   <Redirect
    //                     to={{
    //                       pathname: PARENT_STUDENT_PROFILES,
    //                       state: {
    //                         from: location,
    //                       },
    //                     }}
    //                   />
    //                 );
    //           }
    //           else if(purl==="http://localhost:3000/#/program-manager/counselor/profiles")
    //           {
    //             return (
    //               <Redirect
    //                 to={{
    //                   pathname: COUNSELOR_PROFILES,
    //                   state: {
    //                     from: location,
    //                   },
    //                 }}
    //               />
    //             );
    //           }
    //           else
    //           {
    //             return children;
    //           }
    //         } 
    //         else 
    //         {
    //           if(purl==="http://localhost:3000/#/program-manager/counselor/profiles")
    //           {
    //                 return (
    //                   <Redirect
    //                     to={{
    //                       pathname: PARENT_STUDENT_PROFILES,
    //                       state: {
    //                         from: location,
    //                       },
    //                     }}
    //                   />
    //                 );
    //           }
    //           else if(purl==="http://localhost:3000/#/program-manager/counselor/profiles")
    //           {
    //             return (
    //               <Redirect
    //                 to={{
    //                   pathname: COUNSELOR_PROFILES,
    //                   state: {
    //                     from: location,
    //                   },
    //                 }}
    //               />
    //             );
    //           }
    //           else
    //           {
    //             return (
    //               <Redirect
    //                 to={{
    //                   pathname: LOGIN,
    //                   state: {
    //                     from: location,
    //                   },
    //                 }}
    //               />
    //             );
    //           }
             

    //         }
    //   }}
    // />
  );
};

export default SuperAdminRoute;
