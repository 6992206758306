import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { TransitionGroup } from 'react-transition-group';
import * as DAYS from '../../../constants/days';
import { Collapse } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { setDays } from '../../../slices/taskSlice';

interface IUnitPickerProps {
  setFieldValue: (field: string, value: string) => void;
  value: string;
  field: string;
}
export const SECONDARY = '#0092BC';

const UNITS_ARRAY = [
  {
    key: 'Kg',
    label: 'Kg',
  },
  {
    key: 'g',
    label: 'g',
  },
  {
    // key: 'l',
    // label: 'l',
    key:'lb',
    label: 'lb',
  },
  {
    // key: 'ml',
    // label: 'ml',
    key: 'oz',
    label: 'oz'
  },
];

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
    '&:first-child': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles((theme) => ({
  root: {
    background: 'white',
    color: 'black',
    '&$selected': {
      color: 'black',
      background: theme.palette.primary.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    '&:hover$selected': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: 'unset',
    fontSize: '0.75rem',
  },
  selected: {},
}))(ToggleButton);

export const UnitPicker: React.FC<IUnitPickerProps> = ({
  setFieldValue,
  value,
  field,
}) => {
  const dispatch = useDispatch();

  return (
    // <TransitionGroup>
    //   <Collapse>
    //     <StyledToggleButtonGroup
    //       size='small'
    //       arial-label='Days of the week'
    //       value={formikValue}
    //       onChange={(event, value) => {
    //         if (value === formikValue) {
    //           setFieldValue(field, '');
    //         } else {
    //           setFieldValue(field, value);
    //         }
    //       }}
    //       style={{ paddingLeft: '0px' }}
    //     >
    //       {UNITS_ARRAY.map((unit, index) => (
    //         <StyledToggle key={unit.key} value={index} aria-label={unit.key}>
    //           {unit.label}
    //         </StyledToggle>
    //       ))}
    //     </StyledToggleButtonGroup>
    //   </Collapse>
    // </TransitionGroup>
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // alignItems: 'center',
          justifyContent: 'space-evenly',
          margin: '10px 30px',
        }}
      >
        {UNITS_ARRAY.map((unit, index) => {
          return (
            <div
              style={{ margin: '0 10px', borderRadius: 50, cursor: 'pointer' }}
              onClick={() => {
                if (value === unit.key) {
                  setFieldValue(field, '');
                } else {
                  setFieldValue(field, unit.key);
                }
                // if (days.includes(index)) {
                //   dispatch(removeDay(index));
                // } else {
                //   dispatch(setIsRecurring(true));
                //   dispatch(addDay(index));
                // }
              }}
              key={index}
            >
              <div
                style={{
                  borderRadius: 50,
                  width: 60,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 30,
                  border: `1px solid ${SECONDARY}`,
                  backgroundColor: value === unit.key ? SECONDARY : 'white',
                }}
              >
                <div
                  style={{
                    color: value === unit.key ? 'white' : SECONDARY,
                  }}
                >
                  {unit.label}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
