import { createStyles, Theme } from '@material-ui/core';

const dashboardCardStyles = (theme: Theme) =>
  createStyles({
    davBox: {
      position: 'relative',
      padding: '25px 45px',
      background: theme.palette.secondary.main,
      boxShadow: '0 1px 3px 0 rgb(0 0 0 / 9%)',
      borderRadius: '2px',
      margin: '10px 0',
    },
    title: {
      fontSize: '18px',
      textAlign: 'left',
      margin: 0,
      color: '#fff',
      fontWeight: 600,
    },
    davBoxIcon: {
      position: 'absolute',
      right: '-10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '60px',
      height: '60px',
      fontSize: '30px',
      color: '#fff',
      display: 'table',
      verticalAlign: 'middle',
      textAlign: 'center',
      background: theme.palette.primary.main,
      boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    },
    icon: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',
    },
    img: {
      position: 'relative',
    },
  });

export default dashboardCardStyles;
