import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import formStyles from '../../../assets/jss/components/formStyles';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import EnhancedConfirmUserForm from './EnhancedConfirmUserForm';
import { resendConfirmationCode } from '../../../slices/authSlice';
import GRAPHIC from '../../../assets/images/register_graphic.svg';
import theme from '../../../app/muiTheme';

const useStyles = makeStyles(formStyles);

interface IConfirmUserProps {}

const ConfirmUser: React.FC<IConfirmUserProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleResendClick = () => {
    dispatch(resendConfirmationCode());
  };

  return (
    <div style={{ paddingTop: '20vh', background: '#F5FAFE' }}>
      <Container
        style={{ paddingLeft: '0.4em', paddingRight: '0.4em', height: '80vh' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <div style={{ padding: '1em' }}>
              <EnhancedConfirmUserForm />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ paddingTop: '10vh', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ConfirmUser;
