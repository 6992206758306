import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_RECIPE_VIEW } from '../../../constants/routes';
import { getRecipeById, getRecipeByIdRefresh, setRecipe } from '../../../slices/recipeSlice';
import EnhancedEditRecipeForm from './EnhancedEditRecipeForm';
import { getrecipeTags, getrecipeTagsRefresh, setTag, setTags } from '../../../slices/tagSlice';
import * as REQUESTS from '../../../api/recipeRequests';


interface IEditRecipesProps {}

const EditRecipes: React.FC<IEditRecipesProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { recipeId } = useParams<{ recipeId: string | undefined }>();
  const { recipe, recipeLoading } = useSelector((state: RootState) => {
    return {
      recipe: state.recipe.recipe,
      recipeLoading: state.recipe.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_VIEW ? true : false;
console.log("recipe---->"+recipe);
  if (recipeId) {
    localStorage.setItem('urlRoute', "edit_recipe");
    localStorage.setItem('urlId', recipeId);
  }
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
  const data=window.localStorage.getItem('userData');
  if(data)
  {
    dispatch(setRecipe(JSON.parse(data)));
  }
  }, []);

  // useEffect(() => {
  //   if (recipeId) {
  //           dispatch(getRecipeById(recipeId));
  //           if(recipe)
  //           {
  //             window.localStorage.setItem('userData',JSON.stringify(recipe))
  //           }
  //         }
    
  //   }, []);

  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
      if (recipeId) {
        const recipe_nxt = await REQUESTS.getRecipeById(recipeId,user_id);
       //dispatch(setTags({data:recipe_nxt.tags}));

      dispatch(setRecipe(recipe_nxt));
     // window.localStorage.setItem('userData',JSON.stringify(recipe_nxt))
       //dispatch(getRecipeByIdRefresh(recipeId,user_id));
       //dispatch(getrecipeTagsRefresh(user_id));
       const tags_nxt = await REQUESTS.getRecipeTags(user_id);
        dispatch(setTags({ data: tags_nxt }));
        

      }
    }
    else
    {
      if (!isAddStudent && recipeId) {
        dispatch(getRecipeById(recipeId));
      }
    }
  }
  
  fetchMyAPI()
    return () => {};
  }, [isAddStudent, recipeId, dispatch]);


  if(recipe)
  {
    console.warn("grc title"+JSON.stringify(recipe));
  }

  let renderedForm;
  if (!isAddStudent && recipe) {
     console.log("re data-->"+recipe);
     localStorage.setItem('userData',JSON.stringify(recipe))
    const {
        recipeTitle,
        recipeUrl,
        recipeDescription,
        TypeOfRecipe, 
        tags,
        preparationTime,
        //recipeId,
        userId,
    } = recipe;
    renderedForm = (
      <EnhancedEditRecipeForm
      recipeTitle={recipeTitle}
      recipeUrl={recipeUrl}
      recipeDescription={recipeDescription}
      TypeOfRecipe={TypeOfRecipe}
      tags={tags}
      preparationTime={preparationTime}
      //recipeId={recipeId}
      //userId={userId}
      
      />
    );
  } else {
    renderedForm = <EnhancedEditRecipeForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditRecipes;
