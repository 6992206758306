import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { History } from 'history';
import * as Yup from 'yup';
import { AppThunk } from '../../../app/store';
import { confirmUser } from '../../../slices/authSlice';
import { ConfirmUserForm } from './ConfirmUserForm';
import { INVALID_PATTERN } from '../../../constants/formMessages';

interface IDispatchProps {
  confirmUser: (code: string, history: History) => AppThunk;
}

export interface EnhancedConfirmUserFormValues {
  code: string;
}

export interface EnhancedConfirmUserFormProps extends RouteComponentProps {
  code?: string;
  confirmUser: (code: string, history: History) => void;
}

const EnhancedConfirmUserForm = withFormik<
  EnhancedConfirmUserFormProps,
  EnhancedConfirmUserFormValues
>({
  mapPropsToValues: () => ({
    code: '',
  }),
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .matches(/([0-9]){6}/gi, INVALID_PATTERN)
      .length(6, INVALID_PATTERN),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { confirmUser, history } = props;
    const { code } = values;
    confirmUser(code, history);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(ConfirmUserForm);

const EnhancedConfirmUserFormWithRouter = withRouter(EnhancedConfirmUserForm);
export default connect<null, IDispatchProps>(null, { confirmUser })(
  EnhancedConfirmUserFormWithRouter
);
