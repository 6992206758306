import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../app/rootReducer';
import {
  getAllUsers,
  createChatRoom,
  setFilteredAllUsers,
  addToSelectedUsers,
  addMembersToGroup,
  setSelected,
  removeFromSelectedUsers,
  emptySelectedUsers,
  getUserChatRooms,
} from '../../slices/chatRoomSlice';
import { MESSAGING } from '../../constants/routes';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  makeStyles,
  InputAdornment,
  Fab,
} from '@material-ui/core';
import { SearchOutlined, ChevronRight,  Close as AddOutlined } from '@material-ui/icons';
import formStyles from '../../assets/jss/components/formStyles';
import { ContactListItem } from '../../components/Messaging/ContactListItem';
import { InfoListItem } from '../../components/Messaging/InfoListItem';
import { GroupContactListItem } from '../../components/Messaging/GroupContactListItem';
import { CircularProgress, Divider, IconButton } from '@mui/material';
import { GroupMemberListItem } from '../../components/Messaging/GroupMemberListItem';
//import { SearchOutlined,  Close as AddOutlined } from '@material-ui/icons';

const useStyles = makeStyles(formStyles);

interface ViewGroupMembersProps {
  setOpenMembersModal: Dispatch<SetStateAction<boolean>>;

}

export const ViewGroupMembers: React.FC<ViewGroupMembersProps> = ({
  setOpenMembersModal,
}) => {
  const classes = useStyles();
  const {
    chatRoom: {
      allUsers,
      filteredAllUsers,
      chatRoom,
      groupUsers: users,
      selected,
      selectedUsers,
    },
    auth: { graphQlUser: currentUser },
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      auth: state.auth,
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUserChatRooms(currentUser));
  }, []);

  if (users === null) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading...
      </div>
    );
  }
  return (
    <div>
      <Grid
        container
        justify='center'
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent style={{ position: 'relative', flex: 1 }}>
            <Grid
                container
                spacing={3}
                
              >
                  <Grid item xs={10} sm={10} md={10}>

                </Grid>

                <Grid item xs={2} sm={2} md={2}>

                <IconButton
                      style={{ marginLeft: '0.5rem',width:'20%' }}
                      size='medium'
                      onClick={() => setOpenMembersModal(false)}
                    >
                      <AddOutlined style={{ color: 'blue' }} />
                    </IconButton>
                </Grid>
              </Grid>
              <Typography
                variant='h5'
                style={{ margin: '1rem auto', textAlign: 'center' }}
                component='h2'
              >
                Group Members
              </Typography>
              {/* <TextField
                onChange={handleChange}
                value={query}
                style={{
                  width: '100%',
                  background: 'rgba(255,255,255,1)',
                }}
                variant='outlined'
                size='small'
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search'
              /> */}

              <div style={{ maxHeight: '350px', overflow: 'scroll' }}>
                {/* @ts-ignore */}
                {users.length === 0 && (
                  <Typography
                    variant='h6'
                    style={{ margin: '1rem 0rem', textAlign: 'center' }}
                  >
                    No member found
                  </Typography>
                )}
                {/* @ts-ignore */}
                {users.map((user: any) => {
                  return (
                    <GroupMemberListItem
                      contact={user}
                      key={user.id}
                      groupAdmin={chatRoom.groupAdmin}
                      chatRoomId={chatRoom.id}
                    />
                  );
                })}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
