import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { TransitionGroup } from 'react-transition-group';
import * as DAYS from '../../../constants/days';
import { Collapse } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { setDays } from '../../../slices/taskSlice';

interface IEditDaysOfWeekPickerComponentProps {}
const DAYS_ARRAY = [
  {
    key: DAYS.SUNDAY,
    label: 'S',
  },
  {
    key: DAYS.MONDAY,
    label: 'M',
  },
  {
    key: DAYS.TUESDAY,
    label: 'T',
  },
  {
    key: DAYS.WEDNESDAY,
    label: 'W',
  },
  {
    key: DAYS.THURSDAY,
    label: 'T',
  },
  {
    key: DAYS.FRIDAY,
    label: 'F',
  },
  {
    key: DAYS.SATURDAY,
    label: 'S',
  },
];
const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
    '&:first-child': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles((theme) => ({
  root: {
    background: 'white',
    color: 'black',
    '&$selected': {
      color: 'white',
      background: theme.palette.primary.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    '&:hover$selected': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: 'unset',
    fontSize: '0.75rem',
  },
  selected: {},
}))(ToggleButton);

export const EditDaysOfWeekPickerComponent: React.FC<
  IEditDaysOfWeekPickerComponentProps
> = ({}) => {
  const dispatch = useDispatch();
  const { days, isRecurring } = useSelector((state: RootState) => {
    return { days: state.task.days, isRecurring: state.task.isRecurring };
  }, shallowEqual);

  return (
    <TransitionGroup>
      <Collapse>
        {isRecurring && (
          <StyledToggleButtonGroup
            size='small'
            arial-label='Days of the week'
            value={days}
            onChange={(event, value) => {
              dispatch(setDays({ data: value }));
            }}
            style={{ paddingLeft: '0px' }}
          >
            {DAYS_ARRAY.map((day, index) => (
              <StyledToggle key={day.key} value={index} aria-label={day.key}>
                {day.label}
              </StyledToggle>
            ))}
          </StyledToggleButtonGroup>
        )}
      </Collapse>
    </TransitionGroup>
  );
};
