import React, { useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';

import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Container,
  Chip,
  Link,
  Divider,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
  TextareaAutosize,
} from '@material-ui/core';
import viewStudentDetailsStyles from '../../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../app/rootReducer';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById, setIngredient } from '../../../../slices/studentSlice';
import { editStudent } from '../../../../constants/routes';
import { Ingredient } from '../../../../constants/models/Ingredient';
import { EnhancedEditGroceryListFormValues } from './EnhancedEditGroceryListForm';
import { FormikProps, ErrorMessage } from 'formik';
import formStyles from '../../../../assets/jss/components/formStyles';
import { categoryList } from '../../../../constants/category';
import { DisplayFormikState } from '../../../../components/DisplayFormikState';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { UnitPicker } from './UnitPicker';
import { Close } from '@material-ui/icons';
import { addRecipeToS3 } from '../../../../api/recipeRequests';
import { DropzoneDialog } from 'material-ui-dropzone';
import  SIMG  from '../../../../assets/images/sampleimg.png'; 
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Resizer from "react-image-file-resizer";


interface EditGroceryListFormProps {}

const useStyles = makeStyles(viewStudentDetailsStyles);
const useStyles2 = makeStyles(formStyles);

export const EditGroceryListForm: React.FC<
EditGroceryListFormProps &
    FormikProps<EnhancedEditGroceryListFormValues>
> = (props) => {
  const classes = { ...useStyles(), ...useStyles2() };
  const history = useHistory();
  const dispatch = useDispatch();
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const userData = localStorage.getItem('userData');

  useEffect(() => {
    if(userData)
    {
     const userDt = JSON.parse(userData);
     console.log("call function----->"+userDt);
 
     //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('title',userDt.title?userDt.title:'')
             setFieldValue('description',userDt.description?userDt.description:'')
             setFieldValue('groceryImage',userDt.groceryImage?userDt.groceryImage:'')
            
      // }
    }
     
   }, [])


  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  
  const { groceryList } = useSelector((state: RootState) => ({
    groceryList: state.groceryLists.groceryList,
  }));
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  console.warn("values---->"+JSON.stringify(values));

  let renderedAltName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  renderedAltName = values.title;
  renderedAvatar =
    values.groceryImage !== 'no-url' ? values.groceryImage : renderedAvatar;
    const [openModal, setOpenModal] = useState(false);
    return (
      <div>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>
        <div style={{ margin: '0px 0px 32px' }}>
        
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          Edit Grocery List
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {isAddStudent ? 'Enter student details' : ''} */}
          Edit Grocery List
        </Typography>
      </div>
    <form onSubmit={handleSubmit}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        // style={{ padding: '1rem' }}
      >
        <Grid item md={6} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 56, height: 56 }}
                /> */}
                {values.groceryImage && values.groceryImage !== 'no-url'? 
                  <img src={values.groceryImage} style={{ width: 56, height: 56 }}/>:
                  <img src={SIMG} style={{ width: 56, height: 56 }}/>
                }
				
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                Edit Grocery List Picture
                <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
              </Typography>
            </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl
            required
            className={classes.formControl}
            style={{
              width: '100%',
              // paddingRight: '0.4em',
              // padding: '0 2rem',
              position: 'relative',
            }}
          >
            {/* {ingredient !== null && (
              <IconButton
                style={{ position: 'absolute', top: '-33px', right: '18px' }}
                onClick={() => dispatch(setIngredient(null))}
              >
                <Close />
              </IconButton>
            )} */}
            <Typography variant='subtitle1' className={classes.label}>
              Enter Grocery Title <span style={{color:'red'}}>*</span>
            </Typography>
            <TextField
              placeholder='Enter Title'
              type='text'
              name='title'
              variant='outlined'
              size='small'
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.title && touched.title) as boolean}
              helperText={errors.title && touched.title && errors.title}
              InputLabelProps={{
                classes: {
                  root: classes.heading,
                  focused: classes.cssFocused,
                },
              }}
            />
          </FormControl>
        </Grid>
        
        <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                 Description <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='description'
                placeholder='Enter Description'
                name='description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ height:'100px' }}
              error={(errors.description && touched.description) as boolean}
              helperText={errors.description && touched.description && errors.description}
              InputLabelProps={{
                classes: {
                  root: classes.heading,
                  focused: classes.cssFocused,
                },
              }}
            />
             
            </FormControl>
          </Grid>
        <Grid
          item
          md={12}
          xs={12}
          // style={{ padding: '0 130px' }}
        >
          <Button
            className={classes.secondaryButton}
            variant='contained'
            color='primary'
            type='submit'
            // disabled={studentLoading}
            // fullWidth
          >
            {/* {isAddStudent ? 'Add' : 'Edit'} Student */}
            {groceryList ? 'Edit' : 'Add'} Grocery List
          </Button>
        </Grid>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={async (files) => {
            try {
              if (files.length > 0) {
                let file = files[0];
                Resizer.imageFileResizer(
                  file,
                  680,
                  420,
                  "JPEG",
                  100,
                  0,
                  async(uri:any) => {
                    //console.log(uri);
                    console.warn("Form data view--->" + JSON.stringify(uri));
                   let fdata= getImageUrl(uri);
                   //setImg(fdata);
                   const formData = new FormData();
                   formData.append('file', fdata);
                   //dispatch(addRecipeToS3(formData));
                   let urldatanxt = await addRecipeToS3(formData);
                   // setImage(url);
                   // url.push(urldata);
                   //console.log("res data---->"+urldatanxt)
                   //let jurl=JSON.stringify(urldatanxt)
                   setFieldValue('groceryImage', urldatanxt);
                  },
                  "base64",
                  200,
                  200
                );
                // const formData = new FormData();
                // console.warn("Form data view--->"+JSON.stringify(formData));
                // formData.append('file', file);
                //  //dispatch(addRecipeToS3(formData));
                // const url = await addRecipeToS3(formData);
                // // setImage(url);
                // setFieldValue('groceryImage', url);
              }
              setOpenModal(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        {debug ? <DisplayFormikState {...props} /> : ''}
      </Grid>
    </form>
    </div>
  );
};
