import { Question } from './../constants/models/Question';
import { PROGRAM_ROUTE, QUESTION_ROUTE } from './../constants/routes';
import API from './api';

export async function getQuestionById(id: string) {
  try {
    const res = await API.get<{ data: Question; success: true }>(
      `${QUESTION_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getQuestionsForProgram(programId: string) {
  try {
    const res = await API.get<{ data: Question[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${QUESTION_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createQuestion(
  questionText: string,
  answer: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: Question; success: boolean }>(
      `${QUESTION_ROUTE}`,
      {
        questionText,
        answer,
        programId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateQuestion(
  id: string,
  questionText: string,
  answer: string
) {
  try {
    const res = await API.post<{
      data: Question;
      success: boolean;
    }>(`${QUESTION_ROUTE}/${id}/updateQuestion`, {
      questionText,
      answer,
    });
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteQuestion(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${QUESTION_ROUTE}/${id}/deleteQuestion`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
