import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import {
  setChatRoom,
  setChatRoomUser,
  setUser,
} from '../../slices/chatRoomSlice';
import { decryptMessage } from '../../utils/encryption';
import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { CheckOutlined } from '@mui/icons-material';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const SECONDARY = '#0da9e5';

interface GroupContactListItemProps {
  contact: any;
  onClick: () => void;
}

export const GroupContactListItem: React.FC<GroupContactListItemProps> = ({
  contact,
  onClick,
}) => {
  const { selectedUsers } = useSelector((state: RootState) => {
    return {
      selectedUsers: state.chatRoom.selectedUsers,
    };
  }, shallowEqual);
  const user = {
    id: contact.id,
    name: `${contact.name}`,
    email: contact.email,
    about: contact.about,
    imageUrl: contact.imageUrl,
  };
  let renderedImage;

  // if (selectedUsers.find((contact: any) => contact.id === user.id)) {
  renderedImage = (
    // <IconButton>
    <StyledBadge
      invisible={
        selectedUsers.find((contact: any) => contact.id === user.id)
          ? false
          : true
      }
      // style={{ color: "white", backgroundColor: SECONDARY }}
      overlap='circular'
      badgeContent={
        <CheckOutlined style={{ color: 'white', fontSize: '10px' }} />
      }
      color='primary'
      className='avatar'
    >
      <img
        src={
          user.imageUrl
            ? user.imageUrl
            : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
        }
        alt={
          user.imageUrl
            ? user.imageUrl
            : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
        }
        className='avatar'
      />
    </StyledBadge>
    // </IconButton>
  );
  // } else {
  //   renderedImage = (
  //     <img
  //       src={
  //         user.imageUrl
  //           ? user.imageUrl
  //           : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  //       }
  //       alt={
  //         user.imageUrl
  //           ? user.imageUrl
  //           : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  //       }
  //       className='avatar'
  //     />
  //   );
  // }
  return (
    <div
      className='sidebar-contact'
      // to={`/chat/${chatRoom.id}`}
      onClick={onClick}
    >
      <div
        className='sidebar-contact__avatar-wrapper'
        style={{ position: 'relative' }}
      >
        {renderedImage}
      </div>
      <div className='sidebar-contact__content'>
        <div className='sidebar-contact__top-content'>
          <h2 className='sidebar-contact__name'> {user.name} </h2>
          <span className='sidebar-contact__time'>{user.email}</span>
        </div>
      </div>
    </div>
  );
};
