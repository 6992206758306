import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
  setStudents,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_MOBILE_USER,
  ADD_STUDENT,
  ADD_SUPPORT_ADMIN,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import { getOrganisationList, getSearchOrganisationBySuperAdminorList, getSearchSuperAdminListByUser } from '../../../api/programManagerRequests';
import { FiRefreshCcw } from 'react-icons/fi';
import { Autocomplete } from '@mui/material';
import { getSearchOrganisationBySuperAdminUserist } from '../../../api/studentRequests';
import { setLoading } from '../../../slices/chatRoomSlice';
import { setCounselors } from '../../../slices/counselorSlice';
import { getOrganisationById } from '../../../slices/organisationSlice';


interface ISuperAdminStudentProfileProps {}

const useStyles = makeStyles(formStyles);

const SuperAdminStudentProfile: React.FC<ISuperAdminStudentProfileProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { students, selectedDate, role, counselor } = useSelector(
    (state: RootState) => {
      return {
        students: state.student.students,
        selectedDate: state.task.selectedDate,
        role: state.auth.role,
        counselor: state.counselor.counselor,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(setRecipeId(""));
    dispatch(setNotiTime({data:[]}));
  }, []);
console.warn("chk login");

const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');

useEffect(() => {
  if(user_id && authlocal=="4")
  {
    dispatch(getStudentsForProgramSuperadminRefresh(user_id));
  }
  else if(user_id && authlocal=="3")
  {
    dispatch(getChildForParentRefresh(user_id, history));
  }
  else
  {
    dispatch(getStudentsForParent());
  }
  return () => {};
}, []);
useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
      const orgDatanxt = await getOrganisationList();
        if(orgDatanxt)
        {
          setorgarrNxt(orgDatanxt)

        }
      
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);
  
localStorage.setItem('urlRoute', "parent_users");
 

  useEffect(() => {
    dispatch(setSelectedDate(new Date()));
  }, []);
  console.log("student details - > "+students);

  localStorage.setItem('urlRoute', "parent_admins");
  const [orgarr,setorgarr]=useState(students);
  const [orgarr_nxt,setorgarrNxt]=useState([]);

  let renderedStudents_nxt: { label: string; value: string; imageUrl: string }[] =[];

  let autoorgdata: any[];
  if(orgarr_nxt)
  {
              if (orgarr_nxt.length > 0) {
                renderedStudents_nxt = orgarr_nxt
                  .map((org:any) => {
                    return {
                      label: org.name,
                      value: org.id,
                      imageUrl: "",
                    };
                  });
              } else {
                renderedStudents_nxt = [];
              }
  }
  console.log("----->"+renderedStudents_nxt)
  const [cstatus,setCstatus]=useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orgSelect,setorgSelect]=useState("");
  const [orgid,setOrgId]=useState("");

  async function getSearchOrganisation(e:any,nm:string)
  {
    //alert(nm)
        //setorgSelect(nm)
      let orgnm=nm;
      //setIsLoading(true); 
        const orgData = await getSearchOrganisationBySuperAdminUserist(orgnm);
        //dispatch(getSearchOrganisationBySuperAdminUserist(orgnm));

        if(orgData)
        {
          setorgarr(orgData)
          localStorage.setItem('userData',JSON.stringify(orgData));

          dispatch(setStudents({ data: orgData }));

          const orgAdmins = await getSearchOrganisationBySuperAdminorList(orgnm);
          if(orgAdmins)
          {
            dispatch(setCounselors({ data: orgAdmins }));
            //setIsLoading(false); 

          }
        }
      
  }
  console.log("org data-->"+JSON.stringify(orgarr))

  function addSpportAdmin(orgid:any)
  {
    if(orgid==="")
    {
      alert("Please Select Organization First !")
    }
    else
    {
      history.push(ADD_MOBILE_USER(orgid));
    }
  }

  async function getEditUser(id:any)
  {
    // if(orgid==="")
    // {
    //   alert("Please Select Organization First !")
    // }
    // else
    // {
    //   await dispatch(getStudentById(id));
    //   await dispatch(getOrganisationById(orgid));
    //   const orgAdmins = await getSearchOrganisationBySuperAdminorList(orgid);
    //   if(orgAdmins)
    //   {
    //     dispatch(setCounselors({ data: orgAdmins }));
    //     setIsLoading(false); 

    //   }

   // const orgAdmins = await getSearchOrganisationBySuperAdminorList(orgid);
    const orgAdmins = await getSearchSuperAdminListByUser(id);
    if(orgAdmins)
      {
        dispatch(setCounselors({ data: orgAdmins }));
        setIsLoading(false); 

      }

      localStorage.setItem('urlRoute', "edit_user");
      localStorage.setItem('urlId', id);
      history.push(editStudent(id));
   // }
  }
  let renderedStudents;


  if (students.length > 0) 
  {
    renderedStudents =
      role === USER_ROLE.COUNSELOR
        ? students
            .filter((student) =>
              student.studentGroups
                .map((group) => group.name)
                .includes(counselor!.name)
            )
            .map((student, index) => {
              const { name, email, id, diagnoses, studentGroups } = student;
              return (
                <StyledTableRow key={index} style={{width:'100%'}}>
                  {/* <StyledTableCell component='th' scope='row'>
                    {index + 1}
                  </StyledTableCell> */}
                  <StyledTableCell>{name}</StyledTableCell>
                  {/* <StyledTableCell>{email}</StyledTableCell> */}
                  {/* <StyledTableCell>
                    {diagnoses &&
                      diagnoses
                        .map((diagnosis) => {
                          return diagnosis.name;
                        })
                        .toString()}
                  </StyledTableCell> */}
                  <StyledTableCell style={{textOverflow:'ellipsis'}}>
                    {studentGroups &&
                      studentGroups
                        .map((studentGroup) => {
                          return studentGroup.name;
                        })
                        .toString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                    style={{fontSize:"9px"}}
                      variant='outlined'
                      startIcon={<RemoveRedEyeIcon />}
                      onClick={(e) => {
                        // dispatch(getTasksForStudent(id));

                        dispatch(getStudentTasksForDate(id, selectedDate));
                        dispatch(getStudentById(id));
                        history.push(viewStudentTask(id));
                      }}
                    >
                      View User Task
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      style={{fontSize:"9px"}}

                      variant='outlined'
                      startIcon={<EditIcon />}
                      onClick={async () => {
                        await dispatch(getStudentById(id));
                        localStorage.setItem('urlRoute', "edit_user");
                        localStorage.setItem('urlId', id);
                        history.push(editStudent(id));
                      }}
                    >
                      Edit User Profile
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                      startIcon={<LocalGroceryStoreOutlinedIcon />}
                      onClick={(e) => {
                        // dispatch(getTasksForStudent(id));

                        // dispatch(getStudentTasksForDate(id, selectedDate));
                        dispatch(getStudentById(id));
                        history.push(viewStudentGroceryList(id));
                      }}
                    >
                      View Grocery List
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
        : students.map((student, index) => {
            const { name, email, id, diagnoses, studentGroups } = student;
            return (
              <StyledTableRow key={index} style={{width:'100%'}}>
                {/* <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell> */}
                <StyledTableCell style={{textOverflow:'ellipsis'}}>{name}</StyledTableCell>
                {/* <StyledTableCell>{email}</StyledTableCell> */}
                {/* <StyledTableCell>
                  {diagnoses &&
                    diagnoses
                      .map((diagnosis) => {
                        return diagnosis.name;
                      })
                      .toString()}
                </StyledTableCell> */}
                <StyledTableCell style={{textOverflow:'ellipsis'}}>
                  {studentGroups &&
                    studentGroups
                      .map((studentGroup) => {
                        return studentGroup.name;
                      })
                      .toString()}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={(e) => {
                      // dispatch(getTasksForStudent(id));

                      dispatch(getStudentTasksForDate(id, selectedDate));
                      dispatch(getStudentById(id));
                      history.push(viewStudentTask(id));
                    }}
                  >
                    View User Task
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={async () => {
                      // await dispatch(getStudentById(id));
                      // localStorage.setItem('urlRoute', "edit_user");
                      // localStorage.setItem('urlId', id);
                      // history.push(editStudent(id));
                      getEditUser(id)
                    }}
                  >
                    Edit User Profile
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<LocalGroceryStoreOutlinedIcon />}
                    onClick={(e) => {
                      // dispatch(getTasksForStudent(id));

                      // dispatch(getStudentTasksForDate(id, selectedDate));
                      dispatch(getStudentById(id));
                      history.push(viewStudentGroceryList(id));
                    }}
                  >
                    View Grocery List
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          });
  } else if (students.length === 0) {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No user found.
            {/* <Link to={ADD_STUDENT} onClick={closeOnClick} className={classes.link}>
              Add User?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  if(isLoading===false)
    {

        return (
            
            <div style={{maxWidth:'100%'}}>
                            <Typography variant='h6'>
                                {/* Students */}
                                Users
                            </Typography>
                            <br />
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                >
                                <Grid item md={2} xs={12}>
                                        <Button
                                            title='Add User'
                                            className={classes.secondaryButton}
                                              style={{height:'40px',fontSize:'11px',backgroundColor:'#0092BC',color:'white'}}
                                              variant='outlined'
                                              startIcon={<AddIcon />}
                                              onClick={ (e) => {
                                                addSpportAdmin(orgid)
                                              }}
                                              color='primary'

                                            >
                                             Add User
                                          </Button>
                                            
                                </Grid>
                                <Grid item md={4} xs={12}></Grid>
                                <Grid item md={4} xs={12} style={{padding:'5px'}}>
                                    <label><b>Select Organization</b></label>
                                    <Autocomplete
                                        freeSolo
                                        autoComplete
                                        id=''
                                        disableClearable={false}
                                        options={renderedStudents_nxt}
                                        onChange={(e, option:any) => {
                                            if (typeof option !== 'string') {
                                            getSearchOrganisation(e,option.value)
                                            setOrgId(option.value)

                                            }

                                        }}
                                        //value={orgSelect}
                                        renderInput={(params) => (
                                            <TextField {...params} label='' variant='outlined' size='small' placeholder='Select Organization' />
                                        )}
                                        getOptionLabel={(option) =>
                                            typeof option !== 'string' && option.label ? option.label : ''
                                        }
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={12} className='text-center' style={{padding:'5px'}}>
                                        <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.secondaryButton}

                                        onClick={async() => { 
                                        // setOrgDate("")
                                        
                                          window.location.reload()
                                          //   //setorgSelect("")
                                          //   setIsLoading(true)
                                          //   if(user_id)
                                          //  await dispatch(getStudentsForProgramSuperadminRefresh(user_id));
                                          //  setIsLoading(false)

                                        }}
                                        style={{height:'50px',marginTop:'20px'}}
                                        startIcon={<FiRefreshCcw />}
                                        >
                                        Refresh List 
                                        </Button>
                                    </Grid>
                            </Grid>

                            <br />
                            <br />
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                      <Grid item sm={12} xs={12} className='' style={{padding:'5px',overflow:'scroll',overflowX:'scroll',overflowY:'scroll',width:'800px',height:'400px'}}>

                            <TableContainer component={Paper} >
                                <Table aria-label='customized table'>
                                <TableHead>
                                    <TableRow style={{width:'100%'}}>
                                    {/* <StyledTableCell>S.No.</StyledTableCell> */}
                                    <StyledTableCell>Name</StyledTableCell>
                                    {/* <StyledTableCell>Email</StyledTableCell> */}
                                    {/* <StyledTableCell>Diagnoses</StyledTableCell> */}
                                    {/* <StyledTableCell>Counselors</StyledTableCell> */}
                                    <StyledTableCell>Admins</StyledTableCell>
                                    <StyledTableCell>View User Task</StyledTableCell>
                                    <StyledTableCell>Edit User Profile</StyledTableCell>
                                    <StyledTableCell>View Grocery List</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderedStudents}</TableBody>
                                </Table>
                            </TableContainer>
                      </Grid>
                </Grid>
            </div>
        );
    }
    else
    {
        return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
        </div>;
    }
};
export default SuperAdminStudentProfile;
