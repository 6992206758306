import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
const aboutStyles = (theme: Theme) =>
  createStyles({
    section: {
      //background: `linear-gradient(180deg, rgba(255, 254, 252, 0.5) 0%, #F5FAFE 100%)`,
      background: `linear-gradient(180deg, #F5FAFE 100%, rgba(255, 254, 252, 0.5) 0%)`,
      paddingTop: '15vh',
      paddingBottom: theme.spacing(1),
    },

    heading: {
      fontWeight: 700,
      fontFamily: 'sans-serif',
      letterSpacing: 'heading',
      fontStyle: 'italic',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        margin: '0 auto',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '3.0rem',
      },
      textAlign: 'center',
    },
    subHeading: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('md')]: {
        paddingTop: '0.5rem',
        fontSize: '16px',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '0.5rem',
        fontSize: '1.4rem',
      },
      textAlign: 'center',
    },
    description: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('md')]: {
        paddingTop: '0.5rem',
        fontSize: '16px',
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '0.5rem',
        fontSize: '18px',
        paddingRight: '2rem',
        paddingLeft: '2rem',
      },
      textAlign: 'center',
    },
    img: {
      margin: 'auto 0',
      [theme.breakpoints.down('md')]: {
        maxHeight: '500px',
        maxWidth: '300px',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '50vh',
        maxWidth: '50vw',
      },
    },
  });

export default aboutStyles;
