import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Grid,
  IconButton,
  Select,
  Avatar,
  OutlinedInput,
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import formStyles from '../../../assets/jss/components/formStyles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedEditSuperAdminProfileFormValues } from './EnhancedEditSuperAdminProfileForm';
import { useLocation } from 'react-router';
import { ADD_STUDENT, CHANGE_PASSWORD } from '../../../constants/routes';
import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { getCounselorsForProgram } from '../../../slices/counselorSlice';
import { USER_ROLE } from '../../../constants/userRoles';
import { addLibraryImageToS3 } from '../../../api/imageRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import Resizer from "react-image-file-resizer";
import async from 'react-select/dist/declarations/src/async/index';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import { useHistory } from 'react-router-dom';
import { AiFillSetting } from 'react-icons/ai';

interface IEditSuperAdminProfileFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const EditSuperAdminProfileForm: React.FC<
  IEditSuperAdminProfileFormProps & FormikProps<EnhancedEditSuperAdminProfileFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAddStudent = location.pathname === ADD_STUDENT;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const {
    counselorLoading,
    parentLoading,
    programManagerLoading,
    superAdminLoading,
    role,
    diagnoses,
    counselors,
  } = useSelector((state: RootState) => {
    return {
      superAdminLoading: state.superAdmin.loading,
      programManagerLoading: state.programManager.loading,
      counselorLoading: state.counselor.loading,
      parentLoading: state.parent.loading,
      diagnoses: state.diagnosis.diagnoses,
      counselors: state.counselor.counselors,
      role: state.auth.role,
    };
  }, shallowEqual);
  useEffect(() => {
    dispatch(getDiagnosesForProgram());
    dispatch(getCounselorsForProgram());
    return () => {};
  }, [diagnoses.length, counselors.length]);

  let renderedDiagnosesOptions = diagnoses.map((diagnosis) => {
    return {
      value: diagnosis.id,
      label: diagnosis.name,
    };
  });
  let renderedCounselorOptions = counselors.map((counselor) => {
    return {
      value: counselor.id,
      label: counselor.name,
    };
  });
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  const [openModal, setOpenModal] = useState(false);
  let renderedLoading = false;
  let renderedName = '';
  if (role === USER_ROLE.SUPER_ADMIN) {
    renderedLoading = superAdminLoading;
    renderedName = 'Super Admin';
  } else if (role === USER_ROLE.PROGRAM_MANAGER) {
    renderedLoading = programManagerLoading;
    renderedName = 'Program Manager';
  } else if (role === USER_ROLE.COUNSELOR) {
    renderedLoading = counselorLoading;
    renderedName = 'Counselor';
  } else if (role === USER_ROLE.PARENT) {
    renderedLoading = parentLoading;
    renderedName = 'Parent';
  }
  const userData = localStorage.getItem('userData');

   //sg=student;
   useEffect(() => {
    if(userData)
    {
        const userDt = JSON.parse(userData);
        console.log("call function----->"+userDt);
    
       //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('name',userDt.name?userDt.name:'')
             setFieldValue('email',userDt.email?userDt.email:'')
             setFieldValue('address',userDt.address?userDt.address:'')
             setFieldValue('street',userDt.street?userDt.street:'')
             setFieldValue('city',userDt.city?userDt.city:'')
             setFieldValue('state',userDt.state?userDt.state:'')
             setFieldValue('country',userDt.country?userDt.country:'')
             setFieldValue('postalCode',userDt.postalCode?userDt.postalCode:0)
             setFieldValue('about',userDt.about?userDt.about:'')
             setFieldValue('imageUrl',userDt.imageUrl?userDt.imageUrl:'')
            
      // }
    }
     
   }, []);

  console.log("roles--->"+renderedLoading);

  let renderedAltName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  renderedAltName = values.name;
  renderedAvatar =
    values.imageUrl !== 'no-url' ? values.imageUrl : renderedAvatar;


    function getImageUrl(imguri:any)
    {
      let arr=imguri.split(",");
      let mime=arr[0].match(/:(.*?);/)[1];
      let data=arr[1];
  
      let dataStr=atob(data);
      let n=dataStr.length;
      let dataArr=new Uint8Array(n);
      while(n--)
      {
        dataArr[n]=dataStr.charCodeAt(n);
      }
      let file = new File([dataArr],'file.jpg',{type:mime});
      //console.log(file);
      return file;
    }

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          {renderedName} 
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Edit {renderedName} profile
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          Edit {renderedName} details
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 56, height: 56 }}
                /> */}
                {values.imageUrl && values.imageUrl !== 'no-url'? 
                  <img src={values.imageUrl} style={{ width: 56, height: 56 }}/>:
                  <img src={SIMG} style={{ width: 56, height: 56 }}/>
                }
				
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                Change Profile Picture
                <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
              </Typography>
            </div>
          </Grid>
          <Grid item md={2} xs={12}></Grid>
          <Grid item md={4} xs={12}>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button  
                           className={classes.secondaryButton}
                           variant='contained'
                           color='primary'
             
             style={{fontSize:'14px'}}  onClick={ () => {
                      history.push(CHANGE_PASSWORD);
                    }}>Change Password &nbsp;&nbsp;&nbsp;&nbsp;<AiFillSetting /> </Button>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter {renderedName} name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Name'
                type='text'
                name='name'
                variant='outlined'
                size='small'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.name && touched.name) as boolean}
                helperText={errors.name && touched.name && errors.name}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter {renderedName} email <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='emailAddress'
                placeholder='Email'
                type='email'
                name='email'
                variant='outlined'
                size='small'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.email && touched.email) as boolean}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Choose Counselors
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions={renderedCounselorOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'counselors',
                    option.map((option) => option.value)
                  );
                }}
              />

            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Choose Diagnosis
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions={renderedDiagnosesOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'diagnoses',
                    option.map((option) => option.value)
                  );
                }}
              />

            </FormControl>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Address
              </Typography>
              <TextField
                id='address'
                placeholder='Address'
                type='text'
                name='address'
                variant='outlined'
                size='small'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.address && touched.address) as boolean}
                helperText={errors.address && touched.address && errors.address}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                City
              </Typography>
              <TextField
                placeholder='City'
                type='text'
                name='city'
                variant='outlined'
                size='small'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.city && touched.city) as boolean}
                helperText={errors.city && touched.city && errors.city}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  State/Province/Region
                </Typography>
                <TextField
                  id='state'
                  placeholder='State'
                  type='text'
                  name='state'
                  variant='outlined'
                  size='small'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.state && touched.state) as boolean}
                  helperText={errors.state && touched.state && errors.state}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Country
                </Typography>
                <TextField
                  placeholder='Country'
                  type='text'
                  name='country'
                  variant='outlined'
                  size='small'
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.country && touched.country) as boolean}
                  helperText={
                    errors.country && touched.country && errors.country
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Postal Code
                </Typography>
                <TextField
                  placeholder='Postal Code'
                  type='text'
                  name='postalCode'
                  variant='outlined'
                  size='small'
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.postalCode && touched.postalCode) as boolean}
                  helperText={
                    errors.postalCode && touched.postalCode && errors.postalCode
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                About
              </Typography>
              <TextField
                placeholder='About'
                type='text'

                name='about'
                variant='outlined'
                size='small'
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.about && touched.about) as boolean}
                helperText={errors.about && touched.about && errors.about}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            {/* <Typography variant='subtitle2'>
              {false
                ? `The login credentials will be sent to the student's email
              ${values.email}`
                : ''}
            </Typography> */}
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={renderedLoading}
              // fullWidth
            >
              Update Details
            </Button>
          </Grid>
        </Grid>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={openModal}
          onClose={() => setOpenModal(false)}
          // onSave={async (files) => {
          //   try {
          //     if (files.length > 0) {
          //       let file = files[0];
          //       const formData = new FormData();
          //       formData.append('file', file);
          //       // dispatch(addSubTaskImageToS3(formData));
          //       const url = await addLibraryImageToS3(formData);
          //       // setImage(url);
          //       setFieldValue('imageUrl', url);
          //     }
          //     setOpenModal(false);
          //   } catch (err) {
          //     console.log(err);
          //   }
          // }}

          onSave={async (files) => {
            try {
              if (files.length > 0) {
                // for(let i=0;i<files.length;i++)
                // {
                let file = files[0];
                Resizer.imageFileResizer(
                  file,
                  680,
                  420,
                  "JPEG",
                  100,
                  0,
                  async(uri:any) => {
                    console.warn("Form data view--->" + JSON.stringify(uri));
                   let fdata= getImageUrl(uri);
                   const formData = new FormData();
                   formData.append('file', fdata);
                   let urldatanxt = await addRecipeToS3(formData);
                  
                   setFieldValue('imageUrl', urldatanxt);
                  },
                  "base64",
                  200,
                  200
                );
              }
              setOpenModal(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
