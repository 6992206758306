import { createStyles, Theme } from '@material-ui/core';

const allImagesStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
    imageList: {
      width: '100%',
      height: '90vh',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
  });

export default allImagesStyles;
