import React, {useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
} from '@material-ui/core';

import formStyles from '../../assets/jss/components/formStyles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../app/rootReducer';
import { EnhancedAddOrEditRecipeImagesFormValues } from './EnhancedAddOrEditRecipeImagesForm';
import { useHistory, useLocation } from 'react-router';
import { ADD_RECIPE_VIEW, ADD_RECIPE, addRecipeIngredients } from '../../constants/routes';
//import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../components/DisplayFormikState';
import { addRecipeImagesToS3 } from '../../api/recipeRequests';
import { getrecipeTags } from '../../slices/tagSlice';
import { OptionsOrGroups } from 'react-select/dist/declarations/src';
import { Margin } from '@mui/icons-material';
import { useParams, } from 'react-router';


interface IAddOrEditRecipeImagesFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const AddOrEditRecipeImagesForm: React.FC<
  IAddOrEditRecipeImagesFormProps & FormikProps<EnhancedAddOrEditRecipeImagesFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAddStudent = location.pathname === ADD_RECIPE_VIEW;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const { recipeLoading, tags } = useSelector(
    (state: RootState) => {
      return {
        recipeLoading: state.recipe.loading,
        tags: state.tag.tags,
      };
    },
    shallowEqual
  );
  
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  useEffect(() => {
    dispatch(getrecipeTags());
    return () => {};
  }, [tags.length]);
  //let renderedRecipeTags: boolean | OptionsOrGroups<any, any> | undefined=[];
//let renderedRecipeTags;
// if(tags)
// {
  let renderedRecipeTags= tags.map((tag) => {
    return {
      value: tag.id,
      label: tag.type,
    };
  });
//}
   
  let renderType=[];
  renderType=[
    { "label": "Breakfast",
    "value": "Breakfast"
  },
  { "label": "Lunch",
  "value": "Lunch"
  },
  { "label": "Dinner",
    "value": "Dinner"
  }];
  const { recipeId } = useParams<{ recipeId: string | undefined }>();

  const [recipeUrl1,setRecipeUrl1]= useState("");
  const [recipeUrl2,setRecipeUrl2]= useState("");
  const [recipeUrl3,setRecipeUrl3]= useState("");
  const [recipeUrl4,setRecipeUrl4]= useState("");
  const [recipeUrl5,setRecipeUrl5]= useState("");


  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);

  let url:string[];

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          {isAddStudent ? 'Add More Images' : 'Add More Images'}
        </Typography>
       
      </div>
      <form >
        
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={2} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal1(true)}>
                <Avatar
                  src={recipeUrl1}
                  alt={recipeUrl1}
                  style={{ width: 56, height: 56 }}
                />
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                
              </Typography>
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal2(true)}>
                <Avatar
                  src={recipeUrl2}
                  alt={recipeUrl2}
                  style={{ width: 56, height: 56 }}
                />
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                
              </Typography>
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal3(true)}>
                <Avatar
                  src={recipeUrl3}
                  alt={recipeUrl3}
                  style={{ width: 56, height: 56 }}
                />
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                
              </Typography>
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal4(true)}>
                <Avatar
                  src={recipeUrl4}
                  alt={recipeUrl4}
                  style={{ width: 56, height: 56 }}
                />
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                
              </Typography>
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal5(true)}>
                <Avatar
                  src={recipeUrl5}
                  alt={recipeUrl5}
                  style={{ width: 56, height: 56 }}
                />
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                
              </Typography>
            </div>
          </Grid><br/><br/>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={9} xs={12}>

            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                //type='submit'
                disabled={recipeLoading}
                style={{marginTop:'100'}}
                onClick={(e) => {
                 
                  history.push(addRecipeIngredients(recipeId));
                }}
              // fullWidth
              >
                {isAddStudent ? 'Add Images' : 'Add Images'} Recipe
              </Button>
            </Grid>
          </Grid>
        </Grid>
        
        
       
        <DropzoneDialog
          acceptedFiles={['image/*']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={10240000}
          filesLimit={5}
          open={openModal1}
          onClose={() => setOpenModal1(false)}
          onSave={async (files) => {
            try {
              if (files.length > 0) {
                // for(let i=0;i<files.length;i++)
                // {
                  let file = files[0];
                  const formData = new FormData();
                  console.warn("Form data view--->"+JSON.stringify(formData));
                  formData.append('file', file);
                  if(recipeId)
                  {
                    formData.append('recipeId', recipeId);
                  }                   //dispatch(addRecipeToS3(formData));
                  let urldata = await addRecipeImagesToS3(formData);
                  // setImage(url);
                 // url.push(urldata);
                 setRecipeUrl1(urldata);
                 //setFieldValue('recipeUrl', urldata);
               // }
               // setFieldValue('recipeUrl', url);

              }
              setOpenModal1(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        <DropzoneDialog
          acceptedFiles={['image/*']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={10240000}
          filesLimit={5}
          open={openModal2}
          onClose={() => setOpenModal2(false)}
          onSave={async (files) => {
            try {
              if (files.length > 0) {
                // for(let i=0;i<files.length;i++)
                // {
                  let file = files[0];
                  const formData = new FormData();
                  console.warn("Form data view--->"+JSON.stringify(formData));
                  formData.append('file', file);
                  if(recipeId)
                  {
                    formData.append('recipeId', recipeId);
                  }
                   //dispatch(addRecipeToS3(formData));
                  let urldata = await addRecipeImagesToS3(formData);
                  // setImage(url);
                 // url.push(urldata);
                 setRecipeUrl2(urldata);
                 //setFieldValue('recipeUrl', urldata);
               // }
               // setFieldValue('recipeUrl', url);

              }
              setOpenModal2(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={'Cancel'}
        submitButtonText={'Submit'}
        maxFileSize={10240000}
        filesLimit={5}
        open={openModal3}
        onClose={() => setOpenModal3(false)}
        onSave={async (files) => {
          try {
            if (files.length > 0) {
              // for(let i=0;i<files.length;i++)
              // {
                let file = files[0];
                const formData = new FormData();
                console.warn("Form data view--->"+JSON.stringify(formData));
                formData.append('file', file);
                if(recipeId)
                {
                  formData.append('recipeId', recipeId);
                }
                 //dispatch(addRecipeToS3(formData));
                let urldata = await addRecipeImagesToS3(formData);
                // setImage(url);
               // url.push(urldata);
               setRecipeUrl3(urldata);
               //setFieldValue('recipeUrl', urldata);
             // }
             // setFieldValue('recipeUrl', url);

            }
            setOpenModal3(false);
          } catch (err) {
            console.log(err);
          }
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
      <DropzoneDialog
      acceptedFiles={['image/*']}
      cancelButtonText={'Cancel'}
      submitButtonText={'Submit'}
      maxFileSize={10240000}
      filesLimit={5}
      open={openModal4}
      onClose={() => setOpenModal4(false)}
      onSave={async (files) => {
        try {
          if (files.length > 0) {
            // for(let i=0;i<files.length;i++)
            // {
              let file = files[0];
              const formData = new FormData();
              console.warn("Form data view--->"+JSON.stringify(formData));
              formData.append('file', file);
              if(recipeId)
              {
                formData.append('recipeId', recipeId);
              }
               //dispatch(addRecipeToS3(formData));
              let urldata = await addRecipeImagesToS3(formData);
              // setImage(url);
             // url.push(urldata);
             setRecipeUrl4(urldata);
             //setFieldValue('recipeUrl', urldata);
           // }
           // setFieldValue('recipeUrl', url);

          }
          setOpenModal4(false);
        } catch (err) {
          console.log(err);
        }
      }}
      showPreviews={true}
      showFileNamesInPreview={true}
    />
    <DropzoneDialog
    acceptedFiles={['image/*']}
    cancelButtonText={'Cancel'}
    submitButtonText={'Submit'}
    maxFileSize={10240000}
    filesLimit={5}
    open={openModal5}
    onClose={() => setOpenModal5(false)}
    onSave={async (files) => {
      try {
        if (files.length > 0) {
          // for(let i=0;i<files.length;i++)
          // {
            let file = files[0];
            const formData = new FormData();
            console.warn("Form data view--->"+JSON.stringify(formData));
            formData.append('file', file);
            if(recipeId)
            {
              formData.append('recipeId', recipeId);
            }

             //dispatch(addRecipeToS3(formData));
            let urldata = await addRecipeImagesToS3(formData);
            // setImage(url);
           // url.push(urldata);
           setRecipeUrl5(urldata);
           //setFieldValue('recipeUrl', urldata);
         // }
         // setFieldValue('recipeUrl', url);

        }
        setOpenModal5(false);
      } catch (err) {
        console.log(err);
      }
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
  />        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
