import React from 'react';
import { AllImagesView } from './AllImagesView';

interface IAddImageProps {}

const AddImages: React.FC<IAddImageProps> = ({}) => {
  return (
    <div>
      <AllImagesView />
    </div>
  );
};

export default AddImages;
