import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import {
  setChatRoom,
  setChatRoomUser,
  setUser,
} from '../../slices/chatRoomSlice';
import { decryptMessage } from '../../utils/encryption';

interface ContactListItemProps {
  contact: any;
  useLoading?: boolean;
  onClick: () => void;
}

export const ContactListItem: React.FC<ContactListItemProps> = ({
  contact,
  useLoading,
  onClick,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = {
    id: contact.id,
    name: `${contact.name}`,
    email: contact.email,
    about: contact.about,
    imageUrl: contact.imageUrl,
  };
  return (
    <div
      className='sidebar-contact'
      // to={`/chat/${chatRoom.id}`}
      onClick={async () => {
        if (useLoading) {
          setLoading(true);
          await onClick();
          setLoading(false);
        } else {
          onClick();
        }
      }}
    >
      <div className='sidebar-contact__avatar-wrapper'>
        <img
          src={
            user.imageUrl
              ? user.imageUrl
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
          }
          alt={
            user.imageUrl
              ? user.imageUrl
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
          }
          className='avatar'
        />
      </div>
      <div className='sidebar-contact__content'>
        <div className='sidebar-contact__top-content'>
          <h2 className='sidebar-contact__name'> {user.name} </h2>
          <span className='sidebar-contact__time'>
            {loading ? 'Loading...' : user.email}
          </span>
        </div>
      </div>
    </div>
  );
};
