import React, { useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import Box from '@mui/material/Box';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { TransitionGroup } from 'react-transition-group';
import {
  addInstruction,
  removeInstruction,
  toggleIsSpecial,
  addSubTask,
  removeSubTask,
  addSubTaskImageToS3,
  setIndex,
  setInstructions,
  setTasks,
  setSubTasks,
} from '../../../slices/taskSlice';
import { RootState } from '../../../app/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface IDisplayInstructionsProps {}

export const DisplayInstructions: React.FC<
  IDisplayInstructionsProps
> = ({}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const { instructions, subTasks } = useSelector((state: RootState) => {
    return {
      instructions: state.task.instructions,
      subTasks: state.task.subTasks,
    };
  }, shallowEqual);


  //drag ref
const dragItem=React.useRef<any>(null);
const dragOverItem=React.useRef<any>(null);
//handle sort
async function handleSort() {
   //duplicate items
   let _stepItems=[...subTasks]

   //remove and save the drag item content
   const dragItemContent=_stepItems.splice(dragItem.current,1)[0]
 
   //switch the position
   _stepItems.splice(dragOverItem.current,0,dragItemContent)
 
   //reset the position ref
   dragItem.current=null
   dragOverItem.current=null;
 
   //update the actual array
   //setSteparr(_stepItems)
   await dispatch(setSubTasks({data:_stepItems}))
   //getCheckLiast() 
}
// const handleSort=()=>{
 
console.log("drag end"+JSON.stringify(subTasks))
// }

  // drag start
// const onDragEnd=(e:React.DragEvent<HTMLDivElement>,index:number)=>{
//   //console.log("drag end"+index)
//   }
async function getCheckLiast(e:any) {
  e.preventDefault()
 // 

}

  let renderedSubTasks;
  if (subTasks.length > 0) {
    renderedSubTasks = subTasks.map((subTaskItem, index) => {
      return (
        <Collapse key={index}
        style={{cursor:'move'}}
        draggable
         onDragStart={(e)=>dragItem.current=index}
         onDragEnter={(e)=>dragOverItem.current=index}
         onDragEnd={handleSort}
         onDragOver={(e)=>{getCheckLiast(e)}}
        
        >
          <ListItem
            style={{ width: '100%' }}
            secondaryAction={
              <>
                {subTaskItem.completedImageUrl === '' ||   subTaskItem.completedImageUrl === 'no-url' ? (
                  <IconButton
                    edge='end'
                    aria-label='add-image'
                    title='AddImage'
                    onClick={() => {
                      setOpen(true);
                      dispatch(setIndex(index));
                    }}
                    color='primary'
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                ) : (
                  <>
                    {/* <IconButton
                      edge='end'
                      aria-label='image'
                      title='Image'
                      color='primary'
                      onClick={() => {
                        setImageModalOpen(true);
                      }}
                    >
                      <InsertPhotoIcon />
                    </IconButton> */}
                    <IconButton>
                      <a href={subTaskItem.completedImageUrl} target='_blank'>
                        <img
                          src={subTaskItem.completedImageUrl}
                          height='20'
                          width='20'
                        />
                      </a>
                    </IconButton>
                  </>
                )}
                &nbsp; &nbsp;
                <IconButton
                  edge='end'
                  aria-label='delete'
                  title='Delete'
                  onClick={() => {
                    //dispatch(removeInstruction(instruction));
                    dispatch(removeSubTask(subTaskItem));
                  }}
                  color='error'
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={`${index + 1}. ${subTaskItem.name}`} />
          </ListItem>
          <DropzoneDialog
            acceptedFiles={['image/*']}
            cancelButtonText={'Cancel'}
            submitButtonText={'Submit'}
            maxFileSize={10240000}
            filesLimit={1}
            open={open}
            onClose={() => setOpen(false)} 
            onSave={(files) => {
              if (files.length > 0) { 
                let file = files[0];
                
                dispatch(addSubTaskImageToS3(files));
              }
              setOpen(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </Collapse>
      );
    });
  }
  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>{renderedSubTasks}</TransitionGroup>
        </List>
      </Box>
    </div>
  );
};
