export const HOME = '/';
export const USER_LIST = '/user-list';
export const ALERT_ROUTE = '/alert';
export const ABOUT_US = '/about-us';
export const CONTACT_US = '/contact-us';
export const FOR_SCHOOLS = '/for-schools';
export const FOR_STUDENTS = '/for-students';
export const FOR_FAMILIES = '/for-families';
export const FOR_SCHOOOLS = '/for-schools';
export const FOR_IPSE_PROGRAMS = 'for-ipse-programs';
export const PRIVACY_POLICY = '/privacy-policy';

export const BASE_ROUTE = '/api/v1';
export const DASHBOARD_ROUTE = '/dashboard';
export const AUTH_ROUTE = '/auth';
export const SUPER_ADMIN_ROUTE = "/super-admin";
export const COUNSELOR_ROUTE = '/counselor';
export const DIAGNOSIS_ROUTE = '/diagnosis';
export const DOCUMENT_ROUTE = '/document';
export const RECIPE_ROUTE = '/recipe';
export const RECIPE_INGREDIENT_ROUTE = '/recipe-ingredients';

export const EVENT_ROUTE = '/event';
export const IMAGE_ROUTE = '/image';
export const DAY_REPORT_ROUTE = '/day-report';
export const LIBRARY_ITEM_ROUTE = '/library-item';
export const PARENT_ROUTE = '/parent';
export const PROGRAM_MANAGER_ROUTE = '/program-manager';
export const PROGRAM_ROUTE = '/program';
export const QUESTION_ROUTE = '/question';
export const RECURRING_TASK_ROUTE = '/recurring-task';
export const STUDENT_GROUP_ROUTE = '/student-group';
export const STUDENT_ROUTE = '/student';
export const SUBTASK_ROUTE = '/subtask';
export const TASK_ROUTE = '/task';
export const TASK_TYPE_ROUTE = '/task-type';
export const RECIPE_TAG_ROUTE = '/recipe-tag';
export const VIDEO_ROUTE = '/video';
export const INGREDIENT_ROUTE = '/ingredient';
export const STEP_ROUTE = '/step';
export const GROCERY_LIST_ROUTE = '/grocery-list';

// export const GROCERY_LIST_ROUTE = '/grocery-list';

// dashboards
export const COUNSELOR_DASHBOARD = `${COUNSELOR_ROUTE}${DASHBOARD_ROUTE}`;
export const PROGRAM_MANAGER_DASHBOARD = `${PROGRAM_MANAGER_ROUTE}${DASHBOARD_ROUTE}`;
export const PARENT_DASHBOARD = `${PARENT_ROUTE}${DASHBOARD_ROUTE}`;
export const SUPER_ADMIN_DASHBOARD =`${SUPER_ADMIN_ROUTE}${DASHBOARD_ROUTE}`;
//ids
export const USER_ID = ':userId';
export const DIAGNOSIS_ID = ':diagnosisId';
export const LIBRARY_ITEM_ID = ':libraryItemId';
export const PROGRAM_ID = ':programId';
export const TASK_ID = ':taskId';
export const TASK_TYPE_ID = ':taskTypeId';
export const STUDENT_ID = ':studentId';
export const RECIPE_ID = ':recipeId';
export const INGREDIENT_ID = ':ingredientId';
export const GROCERY_ID = ':groceryId';
export const STEP_ID = ':stepId';
export const COUNSELOR_ID = ':couselorId';
export const PROGRAM_MANAGER_ID = ':programManagerId';
export const ORGANISATION_ID = ':organisationId';
export const PARENT_ID = ':parentId';

//auth routes
export const LOGIN = `${AUTH_ROUTE}/login`;
export const REGISTER = `${AUTH_ROUTE}/register`;
export const REGISTER_ORGANISATION = `${AUTH_ROUTE}/register-organisation`;
export const CONFIRM_USER = `${AUTH_ROUTE}/confirm-user`;
export const LOGOUT = `${AUTH_ROUTE}/login`;
export const GET_ME = `${AUTH_ROUTE}/me`;
export const UPDATE_PASSWORD = `${AUTH_ROUTE}/update-password`;
export const FORGOT_PASSWORD = `${AUTH_ROUTE}/forgot-password`;

export const resetPasssword = (resetToken = ':resetToken') => {
  return `${AUTH_ROUTE}/${resetToken}/reset-password`;
};

export const MANAGE_ACCOUNT = `${AUTH_ROUTE}/manage-account`;

// Parent Routes
export const PARENT_ADD_CHILD = `${PARENT_ROUTE}/add-child`;
export const EDIT_PARENT = `${PARENT_DASHBOARD}/edit-parent-profile`;
export const CHANGE_PASSWORD = `${PARENT_ROUTE}/change-password`;
export const EDIT_SUPER_ADMIN = `${SUPER_ADMIN_DASHBOARD}/edit-profile`;
export const ENQUIRY_LIST = `${SUPER_ADMIN_DASHBOARD}/organisation-list`;
export const PROGRAM_MANAGER_LIST = `${SUPER_ADMIN_DASHBOARD}/program-manager-list`;
export const ADD_ORGANISATION = `${SUPER_ADMIN_DASHBOARD}/add-organisation`;

export const PARENT_STUDENT_PROFILES = `${PARENT_ROUTE}${STUDENT_ROUTE}/profiles`;
export const SUPERADMIN_STUDENT_PROFILES = `${SUPER_ADMIN_ROUTE}${STUDENT_ROUTE}/profiles`;
export const SUPERADMIN_PARENT_PROFILES = `${SUPER_ADMIN_ROUTE}${PARENT_ROUTE}/profiles`;
export const EDIT_PROGRAM_MANAGER_BY_SUPERADMIN = `${SUPER_ADMIN_ROUTE}/edit-program-manager`;
export const EDIT_PARENT_BY_SUPERADMIN = `${SUPER_ADMIN_ROUTE}/edit-parent`;
export const COUNSELORS_STUDENT_PROFILES = `${COUNSELOR_ROUTE}${STUDENT_ROUTE}/profiles`;
//export const PARENT_ADD_STUDENT = `${PARENT_ROUTE}${STUDENT_ROUTE}/add-student`;

export const EDIT_COUNSELOR = `${COUNSELOR_DASHBOARD}/edit-profile`;
// Program Manager Routes
export const PROGRAM_MANAGER_INTAKE = `${PROGRAM_MANAGER_ROUTE}/intake`;
export const EDIT_PROGRAM_MANAGER = `${PROGRAM_MANAGER_ROUTE}/edit-profile`;

// Counselor routes
export const COUNSELOR_PROFILES = `${PROGRAM_MANAGER_ROUTE}${COUNSELOR_ROUTE}/profiles`;
export const PARENT_COUNSELOR_PROFILES = `${PARENT_ROUTE}/counselor-profiles`;
export const SUPERADMIN_COUNSELOR_PROFILES = `${SUPER_ADMIN_ROUTE}/counselor-profiles`;
export const ADD_COUNSELOR = `${PROGRAM_MANAGER_ROUTE}/add-counselor`;
//export const EDIT_COUNSELOR_PROFILE = `${PROGRAM_MANAGER_ROUTE}${COUNSELOR_ROUTE}/profile/edit`;
export const COUNSELOR_STUDENT_PROFILES = `${COUNSELOR_DASHBOARD}${STUDENT_ROUTE}/profiles`;
export const EDIT_COUNSELOR_PROFILE = (counselorId = COUNSELOR_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${COUNSELOR_ROUTE}/profile/${counselorId}/edit`;
};
export const UPDATE_ORGANISATION_TO_PROGRAM = (programMangerId = PROGRAM_MANAGER_ID) => {
  return `${SUPER_ADMIN_ROUTE}/update-organisation/${programMangerId}`;
};
export const VIEW_ORGANISATION_DETAILS = (organisationId = ORGANISATION_ID) => {
  return `${SUPER_ADMIN_ROUTE}/view-organisation-details/${organisationId}`;
};
export const VIEW_PARENT_DETAILS = (parentId = PARENT_ID) => {
  return `${SUPER_ADMIN_ROUTE}/view-parent-details/${parentId}`;
};
export const ADD_SUPPORT_ADMIN = (organisationId = ORGANISATION_ID) => {
  return `${SUPER_ADMIN_ROUTE}/add-admin/${organisationId}`;
};
export const ADD_MOBILE_USER = (organisationId = ORGANISATION_ID) => {
  return `${SUPER_ADMIN_ROUTE}/add-user/${organisationId}`;
};

export const EDIT_PARENTS_COUNSELOR_PROFILE = (counselorId = COUNSELOR_ID) => {
  return `${PARENT_ROUTE}/profile/${counselorId}/edit`;
};

// Student
export const STUDENT_PROFILES = `${PROGRAM_MANAGER_ROUTE}${STUDENT_ROUTE}/profiles`;
export const ADD_STUDENT = `${PROGRAM_MANAGER_ROUTE}${STUDENT_ROUTE}/add-student`;
export const viewStudentTask = (studentId = STUDENT_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${STUDENT_ROUTE}/${studentId}/view-tasks`;
};
export const viewParentStudentTask = (studentId = STUDENT_ID) => {
  return `${PARENT_ROUTE}${STUDENT_ROUTE}/${studentId}/view-tasks`;
};

export const viewStudentGroceryList = (studentId = STUDENT_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${STUDENT_ROUTE}/${studentId}/view-grocery-list`;
};

export const addStudentGroceryList = (studentId = STUDENT_ID) => {
  return `${STUDENT_ROUTE}/${studentId}/add-grocery-list`;
};

export const editGroceryListById = (groceryId = GROCERY_ID) => {
  return `${GROCERY_LIST_ROUTE}/${groceryId}/edit-grocery`;
};
export const editStudent = (studentId = STUDENT_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${STUDENT_ROUTE}/${studentId}/edit`;
};

export const favorite_recipe_by_user = (studentId = STUDENT_ID) => {
  return `${RECIPE_ROUTE}/${studentId}/get-favorite-recipes`;
};
// Event Routes
export const ADD_EVENT = `${EVENT_ROUTE}/add-event`;
export const VIEW_CALENDAR = `${EVENT_ROUTE}/calendar`;

//Diagnosis routes
export const ADD_DIAGNOSIS = `${PROGRAM_MANAGER_ROUTE}${DIAGNOSIS_ROUTE}/view`;
// Task Type routes
export const ADD_TASK_TYPE = `${PROGRAM_MANAGER_ROUTE}${TASK_TYPE_ROUTE}/view`;
export const ADD_RECIPE_TAG = `${RECIPE_TAG_ROUTE}/view`;
// Counselor Routes
export const COUNSELOR_INTAKE = `${COUNSELOR_ROUTE}/intake`;

// Task Routes
export const ADD_TASK= (studentId = STUDENT_ID) => {
  return `${TASK_ROUTE}/${studentId}/add-task`;
};
export const ADD_TASK_WITH_USER = `${TASK_ROUTE}/add-task`;

//export const ADD_TASK = `${TASK_ROUTE}/add-task`;
//export const EDIT_TASK = `${TASK_ROUTE}/edit-task`;
export const EDIT_TASK = (taskId = TASK_ID) => {
  return `${TASK_ROUTE}/${taskId}/edit-task`;
};
// Add Questions
export const ADD_QUESTIONS = `${LIBRARY_ITEM_ROUTE}/add-questions`;

// Add Images
export const ADD_IMAGES = `${LIBRARY_ITEM_ROUTE}/add-images`;

// Add Videos
export const ADD_VIDEOS = `${LIBRARY_ITEM_ROUTE}/add-videos`;

// Add Documents
export const ADD_DOCUMENTS = `${LIBRARY_ITEM_ROUTE}/add-documents`;
export const ADD_RECIPE = `${LIBRARY_ITEM_ROUTE}/list-recipe`;
export const ADD_RECIPE_BY_USER = (studentId = STUDENT_ID) => {
  return `${RECIPE_ROUTE}/${studentId}/list-recipe`;
};
export const MY_RECIPES= `${RECIPE_ROUTE}/my-recipes`;

export const ADD_ALL_RECIPE_BY_USER = (studentId = STUDENT_ID) => {
  return `${RECIPE_ROUTE}/${studentId}/list-all-recipe`;
};
export const ADD_RECIPE_VIEW = `${LIBRARY_ITEM_ROUTE}/add-recipe`;
//export const ADD_RECIPE_INGREDIENT = `${LIBRARY_ITEM_ROUTE}/add-recipe-ingredient`;
export const ADD_RECIPE_INGREDIENT_VIEW = `${LIBRARY_ITEM_ROUTE}/view-recipe-ingredient`;
export const ADD_RECIPE_STEP_VIEW = `${LIBRARY_ITEM_ROUTE}/view-recipe-step`;
//export const VIEW_RECIPE = `${LIBRARY_ITEM_ROUTE}/view-recipe`;
//export const VIEW_INGRIDIANS_LIST = `${LIBRARY_ITEM_ROUTE}/view-ingridians-list`;
export const addRecipeImages = (recipeId = RECIPE_ID) => {
  return `${RECIPE_ROUTE}/${recipeId}/add-recipe-images`;
};
export const addRecipeIngredients = (recipeId = RECIPE_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${RECIPE_ROUTE}/${recipeId}/add-recipe-ingredient`;
};
export const addRecipeSteps = (recipeId = RECIPE_ID) => {
  return `${STEP_ROUTE}/${recipeId}/add-recipe-step`;
};
export const viewRecipe = (recipeId = RECIPE_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${RECIPE_ROUTE}/${recipeId}/view-recipe`;
};

export const viewIngridians = (recipeId = RECIPE_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${RECIPE_ROUTE}/${recipeId}/view-ingridians-list`;
};
export const viewNutrintion = (recipeId = RECIPE_ID) => {
  return `${RECIPE_ROUTE}/${recipeId}/view-nutrition`;
};
export const editNutrintion = (recipeId = RECIPE_ID) => {
  return `${RECIPE_ROUTE}/${recipeId}/edit-nutrition`;
};
export const viewSteps = (recipeId = RECIPE_ID) => {
  return `${STEP_ROUTE}/${recipeId}/view-steps`;
};

export const editRecipe = (recipeId = RECIPE_ID) => {
  return `${PROGRAM_MANAGER_ROUTE}${RECIPE_ROUTE}/${recipeId}/edit`;
};

export const viewGroceryDetails = (groceryId = GROCERY_ID) => {
  return `${GROCERY_LIST_ROUTE}/${groceryId}/viewGroceryDetails`;
};

export const viewRecipeDetails = (recipeId = RECIPE_ID) => {
  return `${RECIPE_ROUTE}/${recipeId}/viewRecipeDetails`;
};

export const editRecipeIngredient = (ingredientId = INGREDIENT_ID) => {
  return `${RECIPE_INGREDIENT_ROUTE}/${ingredientId}/edit`;
};
export const editRecipeStep = (stepId = STEP_ID) => {
  return `${RECIPE_ROUTE}/${stepId}/edit`;
};
export const MESSAGING = '/chat';
export const CHAT_ROOM = `${MESSAGING}/:id`;
