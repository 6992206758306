import React, { useEffect } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    InputAdornment,
    TextField,
    MenuItem,
    Grid,
    Avatar,
    Select,
    OutlinedInput,
    IconButton,
    Typography,
    makeStyles,
    Backdrop,
    Container,
    Button
  } from '@material-ui/core';
import GRAPHIC from '../../assets/images/stacey.png';
import GRAPHIC_3 from '../../assets/images/screenshots/5.png';
import aboutStyles from '../../assets/jss/components/aboutStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../app/muiTheme';
import { RootState } from '../../app/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllUserList } from '../../slices/studentSlice';
import { StyledTableCell } from '../../components/StyledTableCell';
import { StyledTableRow } from '../../components/StyledTableRow';
import NoImageIcon from '@mui/icons-material/HideImage';
import { DateTime } from 'luxon';

interface IUserListProps {}

const useStyles = makeStyles(aboutStyles);

const UserList: React.FC<IUserListProps> = ({}) => {
    const dispatch = useDispatch();

    const { students } =
    useSelector((state: RootState) => {
      return {
        students: state.student.students,
        
      };
    }, shallowEqual);

    useEffect(() => {
        dispatch(getAllUserList());
        return () => {};
      }, []);
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let renderedStudents;
  if (students.length > 0) 
  {

      
    renderedStudents =students
        ? students.map((recipes:any, index:any) => {
              const { name, email, id, role, createdAt, programEnrolled, user} = recipes;
              let rtype="";
              if(role===0)
              {
                rtype="Program Manager";
              }
              else if(role===1)
              {
                rtype="Counselor";
              }
              else if(role===3)
              {
                rtype="Parent";
              }
             else
              {
                rtype="User";
              }

              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component='th' scope='row'>
                    {index + 1}
                  </StyledTableCell>
                  
                  <StyledTableCell>{name}</StyledTableCell>
                  <StyledTableCell>{email}</StyledTableCell>
                  <StyledTableCell>{rtype}</StyledTableCell>
                  {programEnrolled?
                    <StyledTableCell>{programEnrolled.programType}</StyledTableCell>
                    :
                    <StyledTableCell></StyledTableCell>

                }
                 {user?
                    <StyledTableCell style={{fontSize:'11px'}}>Name : -{user.name}<br/>Email : -{user.email}</StyledTableCell>
                    :
                    <StyledTableCell></StyledTableCell>

                }
                <StyledTableCell>
                {DateTime.fromJSDate(new Date(createdAt)).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                    </StyledTableCell>
                </StyledTableRow>
              );
            }):""
       
  } 
  
  return (
    <div>
     
      <Container>
      <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
            <Grid item xs={10} md={10} >

            </Grid>
            <Grid item xs={2} md={2} >
            <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        dispatch(getAllUserList());
                    }}
                >
                    {/* Add Student */}
                   Refresh Page
                </Button> 
            </Grid>
            
        </Grid><hr/>
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
            
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
          <TableContainer component={Paper}>
                <Table aria-label='customized table'>
                <TableHead>
                    <TableRow style={{width:"100%"}}>
                    <StyledTableCell style={{width:"5%"}}>Sr.No.</StyledTableCell>
                    <StyledTableCell style={{width:"10%"}}>Name</StyledTableCell>
                    <StyledTableCell style={{width:"10%"}}>Email</StyledTableCell>
                    <StyledTableCell style={{width:"25%"}}>User Type</StyledTableCell>
                    <StyledTableCell style={{width:"10%"}}>Created Type</StyledTableCell>
                    <StyledTableCell style={{width:"30%"}}>Creator Details</StyledTableCell>
                    <StyledTableCell style={{width:"10%"}}>Time</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderedStudents}</TableBody>
                </Table>
            </TableContainer>
          </Grid>
         
         
          
        </Grid>
      </Container>
    </div>
  );
};
export default UserList;
