import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
let theme = createTheme({
  palette: {
    primary: {
      light: '#C7F8FF',
      main: '#0092BC',
      dark: '#348CBA',
    },
    secondary: {
      light: '#ACA9BB',
      main: '#606060',
      dark: '#1E2022',
    },
  },
  typography: {
    fontFamily: ['Helvetica', 'Arial'].join(','),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

export default theme;
