// import { GroceryList } from './../constants/models/GroceryList';
import { GroceryList } from '../constants/models/GroceryList';
import { User } from '../constants/models/User';

import {
  STUDENT_ROUTE,
  PROGRAM_ROUTE,
  COUNSELOR_ROUTE,
  GROCERY_LIST_ROUTE
} from './../constants/routes';
import API from './api';



export const getStudentGroceryList = async (id: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/${id}/grocery-list`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const createStudentGroceryList = async (
  programId: string,
  studentId: string
) => {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/${studentId}/grocery-list`,
      {
        programId,
        studentId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export async function getGroceryList(id: string) {
  try {
    const res = await API.get<{ data: GroceryList; success: boolean }>(
      `${GROCERY_LIST_ROUTE}/${id}/getGroceryListByIdNext`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getGroceryListIngredients(id: string) {
  try {
    const res = await API.get<{ data: GroceryList; success: boolean }>(
      `${GROCERY_LIST_ROUTE}/${id}/getGroceryListById`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createGroceryList(
  title: string,
    description: string,
    groceryImage: string,
    studentId:string
) {
  try {
    const res = await API.post<{ data: GroceryList; success: boolean }>(
      `${GROCERY_LIST_ROUTE}`,
      {
        title,
        description,
        groceryImage,
        studentId,
        programId:""
       
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteGroceryList(id: string) {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${GROCERY_LIST_ROUTE}/deleteGroceryList`,
      {
        groceryListId:id,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateGroceryListById(
  id: string,
    title: string,
    description: string,
    groceryImage: string,
) {
  try {
    const res = await API.post<{ data: GroceryList; success: boolean }>(
      `${GROCERY_LIST_ROUTE}/${id}/updateGroceryList`,
      {
        id,
          title,
          description,
          groceryImage,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}