import { withFormik } from 'formik';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { createRecipeTagType } from '../../../slices/recipeTagTypeSlice';
import { AddRecipeTagForm } from './AddRecipeTagForm';
import { IMAGE_REQUIRED, NAME_REQUIRED, TAG_REQUIRED } from '../../../constants/formMessages';

interface IDispatchProps {
  createRecipeTagType: (type: string,recipetypeUrl:string) => AppThunk;
}

export interface EnhancedAddRecipeTagFormValues {
  type: string;
  recipetypeUrl:string;
}

export interface EnhancedAddRecipeTagFormProps extends RouteComponentProps {
  recipetypeUrl?: string;
  type?: string;
  createRecipeTagType: (type: string,recipetypeUrl:string) => void;
}

const EnhancedAddRecipeTagForm = withFormik<
  EnhancedAddRecipeTagFormProps,
  EnhancedAddRecipeTagFormValues
>({
  mapPropsToValues: (props) => ({
    type: props.type ? props.type : '',
    recipetypeUrl: props.recipetypeUrl ? props.recipetypeUrl : '',

  }),
  validationSchema: Yup.object().shape({
    recipetypeUrl: Yup.string().required(),
    type: Yup.string().required(TAG_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createRecipeTagType } = props;
    const { type, recipetypeUrl } = values;
    resetForm({});
    createRecipeTagType(type,recipetypeUrl);
    setSubmitting(false);
  },
  enableReinitialize: true,
  displayName: 'BasicForm',
})(AddRecipeTagForm);

const EnhancedAddRecipeTagFormWithRouter = withRouter(EnhancedAddRecipeTagForm);

export default connect<null, IDispatchProps>(null, { createRecipeTagType })(
  EnhancedAddRecipeTagFormWithRouter
);
