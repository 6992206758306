import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { EnhancedAdditionalTaskDetailsFormValues } from './EnhancedAdditionalTaskDetailsForm';
import Dialog from '@material-ui/core/Dialog';
import {
  Grid,
  Container,
  CssBaseline,
  makeStyles,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import Avatar from '@material-ui/core/Avatar';
//import DateTimePicker from '@mui/lab/DateTimePicker';

import InputAdornment from '@mui/material/InputAdornment';
//import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SearchIcon from '@mui/icons-material/Search';
import AddLocationIcon from '@mui/icons-material/AddLocation';
//import TimePicker from '@mui/lab/TimePicker';

import { DisplayFormikState } from '../../../components/DisplayFormikState';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DisplayNotiTimes } from './DisplayNotiTimes';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  addStudentId,
  setDays,
  setIsRecurring,
  setTaskStep,
  setDaysRadio,
  addNotiTime,
  setNotiTime,
  setRecipeId
} from '../../../slices/taskSlice';
import { DatePicker } from '@mui/lab';
import { DaysOfWeekPickerComponent } from './DaysOfWeekPickerComponent';
import { DisplayStudents } from './DisplayStudents';
import { getStudentsForProgram } from '../../../slices/studentSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AddStudentsToTask } from './AddStudentsToTask';
import { AddRecipeToTask } from './AddRecipeToTask';
import { DisplayAddedRecipe } from '../EditTask/DisplayAddedRecipe';
import { AddRecipeToTaskModal } from './AddRecipeToTaskModal';
import { getRecipesForTask } from '../../../slices/recipeSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import NoImageIcon from '@mui/icons-material/HideImage';
import store, { AppThunk } from '../../../app/store';
import { AiOutlinePlusCircle } from 'react-icons/ai';

//import { DisplayNotiTimes } from './DisplayNotiTimes';


const useStyles = makeStyles(formStyles);



interface IAdditionalTaskDetailsFormProps {}

export const AdditionalTaskDetailsForm: React.FC<
  IAdditionalTaskDetailsFormProps &
    FormikProps<EnhancedAdditionalTaskDetailsFormValues>
> = (props) => {
  const [custome_noti, setNotiTime] = useState<string>('');
  let debug = false;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rrecipeTitle, setrrecipeTitle]= useState("");
  const [rtypeOfRecipe, setrtypeOfRecipe] = useState("");
  const [rTagType, setrTagType] = useState("");

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const { taskLoading, students, studentIds, isRecurring, daysRadio,notiTime, recipes, recipeId, recipe, rtags } =
    useSelector((state: RootState) => {
      return {
        taskLoading: state.task.loading,
        students: state.student.students,
        studentIds: state.task.studentIds,
        isRecurring: state.task.isRecurring,
        daysRadio: state.task.daysRadio,
        notiTime: state.task.notiTime,
        recipes: state.recipe.recipes,
        recipeId: state.task.recipeId,
        recipe:state.recipe.recipe,
        rtags: state.tag.tags,

      };
    }, shallowEqual);
  const [value, setValue] = React.useState(daysRadio.toString());
  const [autocompleteValue, setAutocompleteValue] = React.useState<{
    label: string;
    value: string;
    imageUrl: string;
  }>({
    label: '',
    value: '',
    imageUrl: '',
  });
  
  console.warn("student ids------->"+studentIds[0])

  const handleChangeRadio = (event: any) => {
    let value = event.target.value;
    dispatch(setDaysRadio(value.toString()));
    setValue(value);
    if (value === '0') {
      dispatch(setIsRecurring(false));
    } else if (value === '1') {
      dispatch(setIsRecurring(true));
      dispatch(setDays({ data: [0, 1, 2, 3, 4, 5, 6] }));
    } else if (value === '2') {
      dispatch(setIsRecurring(true));
      let date = new Date();
      let day = date.getDay();
      dispatch(setDays({ data: [day] }));
    } else {
      dispatch(setIsRecurring(true));
      dispatch(setDays({ data: [] }));
    }
  };

  // useEffect(() => {
  //   if(studentIds[0])
  //   {
  //     dispatch(addStudentId(studentIds[0]));
  //   }
  //   return () => {};
  // }, [studentIds[0]]);//students.length, studentIds.length

  let renderedStudents: { label: string; value: string; imageUrl: string }[] =
    [];
  let stdcnt=0;
  if (students.length > 0) {
    renderedStudents = students.map((student) => {
      return {
        label: student.name,
        value: student.id,
        imageUrl: student.imageUrl,
      };
    });

  } else {
    renderedStudents = [];
  }
  let renderTime=[];
  renderTime=[
    { "label": "0 minutes",
    "value": "0"
  },
  { "label": "1 minutes",
  "value": "1"
  },
  { "label": "5 minutes",
    "value": "5"
  },
  { "label": "10 minutes",
  "value": "10"
  },
  { "label": "15 minutes",
  "value": "15"
  },
  { "label": "20 minutes",
  "value": "20"
  },
  { "label": "25 minutes",
  "value": "25"
  },
  { "label": "30 minutes",
  "value": "30"
  },
  { "label": "40 minutes",
  "value": "40"
  },
  { "label": "50 minutes",
  "value": "50"
  },
  { "label": "60 minutes",
  "value": "60"
  }
  ];
  const [valueRecipe, setValueRecipe] = React.useState<string>('');

    let renderedRecipes: { label: string; value: string; }[] =
    [];
  if (recipes.length > 0) {
    renderedRecipes = recipes
      .filter((recipe) => {
        return !recipeId.includes(recipe.id);
      })
      .map((recipe) => {
        return {
          label: recipe.recipeTitle,
          value: recipe.id,
          imageUrl: recipe.recipeUrl,

        };
      });
  } else {
    renderedRecipes = [];
  }
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  if(studentIds.length > 0)
  {
    stdcnt++;
  }
  
  let renderedRecipeTags= rtags.map((tag) => {
    return {
      value: tag.id,
      label: tag.type,
    };
  });
  // if (recipe) {
  //   setOpenModal(false);
  // }
  const handleClose = () => {
    setOpenModal(false);

    //(setIngredient(null));
  };
  // const getOpenModel = (e:any) =>{
  //  // $('#largeModal').modal('show');

  // }
  //console.warn("studis----->"+studentIds.length+"   /  "+stdcnt);
  let renderType=[];
  renderType=[
    { "label": "Other",
    "value": "Other"
  },
    { "label": "Breakfast",
    "value": "Breakfast"
  },
  { "label": "Lunch",
  "value": "Lunch"
  },
  { "label": "Dinner",
    "value": "Dinner"
  }];
  function apicall()
  {
    //console.warn("rrecipeTitle-->"+rrecipeTitle+"<br/>rtypeOfRecipe-->"+rtypeOfRecipe);
    
      dispatch(getRecipesForTask(rrecipeTitle,rtypeOfRecipe,rTagType));
      renderType=[
        { "label": "Other",
        "value": "Other"
      },
        { "label": "Breakfast",
        "value": "Breakfast"
      },
      { "label": "Lunch",
      "value": "Lunch"
      },
      { "label": "Dinner",
        "value": "Dinner"
      }];
      setrtypeOfRecipe("")
   // console.warn("rrdata-->"+rdataa);

  }
  

  let renderedRecipeArr;
  if (recipes.length > 0) {

      
    renderedRecipeArr =
     recipes.map((recipe, index) => {
            const { id, recipeTitle, recipeDescription, recipeUrl, tags, userId} = recipe;

            return (
              <StyledTableRow key={index}>
                <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                          {recipeUrl !== '' ? (
                              <a href={recipeUrl} target='_blank'>
                                <img
                                  src={recipeUrl}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                    </StyledTableCell>
                <StyledTableCell>{recipeTitle}</StyledTableCell>
                <StyledTableCell>{recipeDescription}</StyledTableCell>
               
                
              
               
                  <StyledTableCell>

                    <Button
                    title='View Nutritions'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<i className="fa fa-cutlery"></i>}
                      onClick={async () => {
                       await dispatch(setRecipeId(id));
                        setFieldValue('recipeId', id);
                        setOpenModal(false);
                      }}
                    >
                      Select 
                    </Button>
                  </StyledTableCell>
                  
              </StyledTableRow>
            );
          });
  } else if (recipes.length === 0) {
    renderedRecipeArr = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row' style={{width:"100%"}}>
          <Typography variant='subtitle2' className={classes.label}>
            No Recipe found.
            
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedRecipeArr = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  function functionGetRecipe()
  {
    let rtitle="";
    let rtype="";
    let rtag="";
      dispatch(getRecipesForTask(rtitle,rtype,rtag));
        
  }
  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Add Task
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>
            <Grid container={true} style={{ paddingRight: '1em' }}>
              <FormControl required className={classes.formControl}>
                <Typography variant='subtitle1' className={classes.label}>
                  Time
                </Typography>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item md={5} xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    
                      <TimePicker
                        
                        label=''
                        value={values.start}
                        onChange={(value) => {
                          setFieldValue('start', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: '200px', display: 'inline' }}
                            size='small'
                            variant='outlined'
                            autoFocus
                            onFocus={function(e) {
                              var val = e.target.value;
                              e.target.value = '';
                              e.target.value = val;
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  to
                  <Grid item md={5} xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label=''
                        value={values.end}
                        onChange={(value) => {
                          setFieldValue('end', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: '200px', display: 'inline' }}
                            {...params}
                            size='small'
                            variant='outlined'
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label=''
                  inputFormat='MM/dd/yyyy'
                  value={new Date(values.date)}
                  onChange={(value) => {
                    setFieldValue('date', value);
                  }}
                  disabled={isRecurring}
                  //minDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: '100%' }}
                      size='small'
                      variant='outlined'
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid><br/><br/>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={12} xs={12}>
              {/* <FormControl required className={classes.formControl}>
                <Typography variant='subtitle1' className={classes.label}>
                  Recipes
                </Typography>
                <AsyncSelect
                  cacheOptions
                  // @ts-ignore
                  defaultOptions={renderedTaskTypes}
                  // @ts-ignore
                  // options={renderedTaskTypes}
                  styles={customStyles}
                  // value={values.taskTypeId}
                  value={renderedTaskTypes.find(
                    (recipe) => recipe.value === values.recipeId
                  )}
                  // defaultInputValue={values.recipeId}
                  //value={ta}
                  onChange={(option) => {
                    if (option) {
                      // @ts-ignore
                      setFieldValue('recipeId', option.value);
                    }
                  }}
                /> 
              </FormControl> */}
              <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label} style={{marginTop:'30px'}}>
                {/* Add Students */}
                Add Recipe  &nbsp; <Button
                  className={classes.secondaryButton}
                  variant='contained'
                  color='primary'
                  style={{height:'50px',width:'50px'}}
                  onClick={(e) => {
                    setOpenModal(true)
                    functionGetRecipe()
                  }}
                >
                  {/* <i className='fa fa-plus-circle' ></i> */}
                  <AiOutlinePlusCircle style={{height:'25px',width:'25px'}}/>
                </Button>
                
                {/* <Button  onClick={(e) => {setOpenModal(true);}} className='btn btn-xs btn-primary' style={{height:'50px',width:'50px'}}> <i className='fa fa-plus-circle' ></i></Button> */}

              </Typography>
                {/* <Autocomplete
                  freeSolo
                  autoComplete
                  id=''
                  placeholder='Jane Doe'
                  disableClearable
                  options={renderedRecipes}
                  onChange={(e, option) => {
                    if (typeof option !== 'string') {
                      dispatch(setRecipeId(option.value));
                      setValueRecipe(option.label);
                      setFieldValue('recipeId', option.value);
                    }
                  }}
                  value={valueRecipe}
                  renderInput={(params) => (
                    <TextField {...params} label='' variant='outlined' size='small' />
                  )}
                
                /> */}
              <DisplayAddedRecipe />
              {/* <AddRecipeToTaskModal openModal={openModal} setOpenModal={setOpenModal} /> */}

            </FormControl>
            </Grid>
          </Grid>
          <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >

            <Grid item md={12} xs={12}>
              <FormControl required className={classes.formControl}>
                <Typography variant='subtitle1' className={classes.label}>
                  Select Notification Time
                </Typography>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={renderTime}
                  styles={customStyles}
                  //value={ta}
                  onChange={(option) => {
                    if (option) {
                      dispatch(
                        addNotiTime({
                          notification_time: option.value,
                          time_period: '',
                        })
                      );
                      //setNotiTime(option.value);

                    }
                  }}
                />
              </FormControl>
            </Grid>

            {/* <Grid item md={12}>
            <FormControl
              required
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
               Set Notification Time
              </Typography>
              <Grid container={true}>
                <TextField
                  type='text'
                  size='small'
                  placeholder='Eg. 30'
                  name='name'
                  variant='outlined'
                  value={custome_noti}
                  onChange={(e) => {
                    setNotiTime(e.target.value);
                  }}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          color='primary'
                          onClick={(e) => {
                            //   dispatch(addInstruction(instruction));
                            dispatch(
                              addNotiTime({
                                notification_time: custome_noti,
                                time_period: '',
                              })
                            );

                            setNotiTime('');
                          }}
                          disabled={custome_noti === ''}
                        >
                          <AddCircleOutlineIcon color='primary' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (custome_noti !== '') {
                        //dispatch(addInstruction(instruction));
                        dispatch(
                          addNotiTime({
                            notification_time: custome_noti,
                            time_period: '',
                          })
                        );
                        setNotiTime('');
                      }
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                  helperText={'Press enter to add an time'}
                />
              </Grid>
            </FormControl>
          </Grid> */}
          </Grid>

        <DisplayNotiTimes />
          <Grid item md={12} xs={12}>
            <FormControl component='fieldset'>
              <Typography variant='subtitle1' className={classes.label}>
                Reminder
              </Typography>
              <RadioGroup
                row
                aria-label='gender'
                name='gender1'
                value={value}
                onChange={handleChangeRadio}
                color='primary'
              >
                <FormControlLabel
                  value='0'
                  control={<Radio color='primary' />}
                  label='Never'
                />
                <FormControlLabel
                  value='1'
                  control={<Radio color='primary' />}
                  label='Every day'
                />
                <FormControlLabel
                  value='2'
                  control={<Radio color='primary' />}
                  label='Every week'
                />
                <FormControlLabel
                  value='3'
                  control={<Radio color='primary' />}
                  label='Custom'
                />
              </RadioGroup>
              <DaysOfWeekPickerComponent />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Location
              </Typography>
              <TextField
                label=''
                type='text'
                placeholder='Eg. Townhall'
                name='taskLocation'
                variant='outlined'
                size='small'
                value={values.taskLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.taskLocation && touched.taskLocation) as boolean}
                helperText={
                  errors.taskLocation &&
                  touched.taskLocation &&
                  errors.taskLocation
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AddLocationIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                {/* Add Students */}
                Add Users <span style={{color:'red'}}>*</span>
              </Typography>
              <AddStudentsToTask />
              <Button
                variant='text'
                style={{
                  color: '#0092BC',
                  width: '20%',
                  justifyContent: 'flex-start',
                }}
                onClick={(e) => {
                  students.forEach((student) => {
                    dispatch(addStudentId(student.id));
                  });
                }}
              >
                {' '}
                Add all?{' '}
              </Button>
              <DisplayStudents />
            </FormControl>
          </Grid>
          
          <Grid item md={12} xs={12}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item md={3} xs={6}>
                <Button
                  className={classes.secondaryButton}
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    dispatch(setTaskStep(0));
                  }}
                >
                  Previous
                </Button>
              </Grid>

              <Grid item md={3} xs={6}>
                <Button
                  className={classes.secondaryButton}
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={studentIds.length === 0 || taskLoading}
                  // fullWidth
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
      
      <Dialog
      fullScreen
              open={openModal}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              style={{padding: '1rem', width: '100%'}}
              
            >
              <div className='row' style={{ padding: '1rem', width: '100%' }}>
                <div className='col-sm-12'>
                  <h5>Select Recipe</h5>
                </div>
              </div>
              <div className='row' style={{ padding: '1rem', width: '100%' }}>
                <div className='col-sm-12'>
                  {/* <Autocomplete
                    freeSolo
                    autoComplete
                    id=''
                    placeholder='Jane Doe'
                    disableClearable
                    options={renderedRecipes}
                    style={{width:'500px'}}
                    onChange={(e, option) => {
                      if (typeof option !== 'string') {
                        dispatch(setRecipeId(option.value));
                        setValueRecipe(option.label);
                        setFieldValue('recipeId', option.value);
                        setOpenModal(false);

                      }
                    }}
                    value={valueRecipe}
                    renderInput={(params) => (
                      <TextField {...params} label='' variant='outlined' size='small' placeholder='Search Cook Book...'/>
                    )}

                  /> */}
                </div>
                <div className='col-sm-3'>
                  <Typography variant='subtitle1' className={classes.label}>
                    Enter Recipe Name
                  </Typography>
                  <TextField
                    placeholder='Title'
                    type='text'
                    name='recipeTitle'
                    variant='outlined'
                    size='small'
                    style={{width:"100%"}}
                    value={rrecipeTitle}
                    onChange={(e) => setrrecipeTitle(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.heading,
                        focused: classes.cssFocused,
                      },
                    }}
                  />
                </div>
                  <div className='col-sm-3'>
                    <FormControl required className={classes.formControl}>
                      <Typography className={classes.label}>
                        Recipe Type
                      </Typography>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={renderType}
                        styles={customStyles}
                        defaultInputValue={rtypeOfRecipe}
                        onChange={(option) => {
                          if (option) {
                            setrtypeOfRecipe(option.value);
                          }
                        }}

                      />
                    </FormControl>
                  </div>
                    <div className='col-sm-3'>
                      <Typography variant='subtitle1' className={classes.label}>
                        Tags
                      </Typography>
                      <AsyncSelect
                        
                        cacheOptions
                        defaultOptions={renderedRecipeTags}
                        defaultInputValue={rTagType}
                        styles={customStyles}
                        //value={ta}

                        onChange={(option) => {
                          if (option) {
                            setrTagType(option.value);
                          }
                        }}
                       
                      />
                    </div>
                    
                    <div className='col-sm-3'>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => apicall()}
                        style={{ "marginTop": "30px" }}
                        startIcon={<SearchIcon />}
                      >
                        {/* Add Student */}
                        SEARCH
                      </Button>
                    </div>
              </div><hr/>
              <div className='row' style={{ padding: '1rem', width: '100%',overflow:"scroll",overflowX:"hidden",overflowY:"scroll",height:"400px" }}>
                <br />
                <TableContainer component={Paper}>
                  <Table aria-label='customized table'>
                    <TableHead>
                      <TableRow style={{ width: "100%" }}>
                        <StyledTableCell style={{ width: "5%" }}>S.No.</StyledTableCell>
                        <StyledTableCell style={{ width: "15%" }}>Image</StyledTableCell>
                        <StyledTableCell style={{ width: "20%" }}>Title</StyledTableCell>
                        <StyledTableCell style={{ width: "50%" }}>Description</StyledTableCell>
                        <StyledTableCell style={{ width: "10%" }}>Action</StyledTableCell>
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>{renderedRecipeArr}</TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className='row' style={{ padding: '1rem', width: '100%' }}>
              <div className='col-sm-9'>

              </div>
                <div className='col-sm-2 text-right'>
                <Button
                  className={classes.secondaryButton}
                  variant='contained'
                  color='primary'
                  onClick={(e) => {setOpenModal(false)}}
                >
                  Close
                </Button>
                </div>
              </div>
            </Dialog>
            <br/><br/><br/><br/><br/><br/>

    </div>
  );
};
