import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, IconButton, List } from '@material-ui/core';
import { RootState } from '../../../app/rootReducer';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeStudentId } from '../../../slices/taskSlice';
import { TransitionGroup } from 'react-transition-group';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { getRecipeById } from '../../../slices/recipeSlice';
interface IDisplayAddedRecipeProps {}

export const DisplayAddedRecipe: React.FC<IDisplayAddedRecipeProps> = ({}) => {
  const dispatch = useDispatch();

  const { recipeId,recipe } = useSelector((state: RootState) => {
    return {
        recipeId: state.task.recipeId,
        recipe:state.recipe.recipe
    };
  }, shallowEqual);

  let renderedStudentsLists;
  useEffect(() => {
    dispatch(getRecipeById(recipeId));
    return () => {};
  }, [recipeId]);

  if (recipe) {
  
    renderedStudentsLists = (
        //const { name, email, id, imageUrl } = recipe;
        
          <Collapse key={recipe.id}>
            <ListItem style={{ width: '100%' }}>
              <ListItemAvatar>
                <Avatar alt={recipe.recipeTitle} src={recipe.recipeUrl} style={{ width: '100px', height:'100px' }}/>
              </ListItemAvatar>
              <ListItemText style={{ marginLeft:'50px'}} primary={recipe.recipeTitle} secondary={recipe.recipeDescription} />
              
            </ListItem>
          </Collapse>
    )
  }
  else
  {
    renderedStudentsLists = ( <></>);
  }

  return (
    <div style={{ width: '80%' }}>
      <List>
        <TransitionGroup>{renderedStudentsLists}</TransitionGroup>
      </List>
    </div>
  );
};
