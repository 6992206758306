import { PARENT_STUDENT_PROFILES, STUDENT_PROFILES } from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task } from '../constants/models/Task';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { actionButton, API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { History } from 'history';

import * as REQUESTS from '../api/taskRequests';
import * as RECURRING_REQUESTS from '../api/recurringTaskRequests';
import { addLibraryImageToS3 } from '../api/imageRequests';
import _ from 'lodash';
import { AnyIfEmpty } from 'react-redux';
import { recipeComplete } from './recipeSlice';
import { SubTask } from '../constants/models/SubTask';
import Resizer from "react-image-file-resizer";

export interface SubTaskItem {
  name: string;
  completedImageUrl: string;
}
export interface NotiTimeItem {
  notification_time: string;
  time_period: string;
}

export interface taskState {
  loading: boolean;
  error: string | null;
  taskId: string;
  task: Task | null;
  tasks: Task[];
  name: string;
  start: Date | null;
  end: Date | null;
  fileName: string;
  attachment: boolean;
  isSpecial: boolean;
  isRecurring: boolean;
  taskTypeId: string;
  recipeId: string;
  students: string[];
  instructions: string[];
  notiTime: any[];
  studentIds: string[];
  taskStep: number;
  days: any[];
  taskLocation: string;
  imageUrl: string;
  studentId: string;
  isDeleteModalOpen: boolean;
  openEditModal: boolean;
  imageBinary: string;
  subTasks: any[];
  //  SubTaskItem[];
  index: number;
  daysRadio: string;
  selectedDate: Date;
}

export const initialState: taskState = {
  loading: false,
  error: null,
  taskId: '',
  task: null,
  tasks: [],
  name: '',
  start: null,
  end: null,
  fileName: '',
  attachment: false,
  isSpecial: false,
  isRecurring: false,
  taskTypeId: '',
  recipeId: '',
  students: [],
  instructions: [],
  notiTime: [],

  studentIds: [],
  taskStep: 0,
  days: [],
  taskLocation: '',
  imageUrl: '',
  studentId: '',
  isDeleteModalOpen: false,
  imageBinary: '',
  subTasks: [],
  index: 0,
  openEditModal: false,
  daysRadio: '0',
  selectedDate: new Date(),
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    taskStart(state) {
      state.loading = true;
      state.error = null;
    },
    taskLongStart(state) {
      state.loading = true;
      state.error = null;
    },
    setTaskId(state, action: PayloadAction<string>) {
      state.taskId = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setStart(state, action: PayloadAction<Date>) {
      state.start = action.payload;
    },
    setEnd(state, action: PayloadAction<Date>) {
      state.end = action.payload;
    },
    setImageBinary(state, action: PayloadAction<string>) {
      state.imageBinary = action.payload;
    },
    setFileName(state, action: PayloadAction<string>) {
      state.fileName = action.payload;
    },
    setAddedAttachment(state, action: PayloadAction<boolean>) {
      state.attachment = action.payload;
    },
    setIsSpecial(state, action: PayloadAction<boolean>) {
      state.isSpecial = action.payload;
    },
    toggleIsSpecial(state) {
      state.isSpecial = !state.isSpecial;
    },
    setIsRecurring(state, action: PayloadAction<boolean>) {
      state.isRecurring = action.payload;
    },
    setTaskTypeId(state, action: PayloadAction<string>) {
      state.taskTypeId = action.payload;
    },
    setTaskStep(state, action: PayloadAction<number>) {
      state.taskStep = action.payload;
    },
   
    addInstruction(state, action: PayloadAction<string>) {
      if (!state.instructions.includes(action.payload)) {
        state.instructions = [...state.instructions, action.payload];
      }
    },
   
    removeInstruction(state, action: PayloadAction<string>) {
      //const index = state.selectedPhotos.indexOf(action.payload);
      const index = state.instructions.findIndex((instruction) => {
        return instruction === action.payload;
      });

      let updatedArray = state.instructions;

      if (index !== -1) {
        updatedArray.splice(index, 1);
      }

      state.instructions = [...updatedArray];
    },
    setStudentIds(state, action: PayloadAction<string[]>) {
      state.studentIds = action.payload;
    },
    addStudentId(state, action: PayloadAction<string>) {
      if (!state.studentIds.includes(action.payload)) {
        state.studentIds = [...state.studentIds, action.payload];
      }
    },
    removeStudentId(state, action: PayloadAction<string>) {
      const index = state.studentIds.findIndex((studentId) => {
        return studentId === action.payload;
      });

      let updatedArray = state.studentIds;

      if (index !== -1) {
        updatedArray.splice(index, 1);
      }

      state.studentIds = [...updatedArray];
    },
    setStudents(state, action: PayloadAction<{ data: string[] }>) {
      const { data } = action.payload;
      state.students = data.map((studentId) => studentId);
    },
    setInstructions(state, action: PayloadAction<{ data: string[] }>) {
      const { data } = action.payload;
      state.instructions = data.map((instruction) => instruction);
    },
    setRecipeId(state, action: PayloadAction<string>) {
      state.recipeId = action.payload;
    },
    setTaskLocation(state, action: PayloadAction<string>) {
      state.taskLocation = action.payload;
    },
    setDays(state, action: PayloadAction<{ data: any[] }>) {
      const { data } = action.payload;
      state.days = data.map((day) => day);
    },
    addDay(state, action: any) {
      if (!state.days.includes(action.payload)) {
        state.days = [...state.days, action.payload];
      }
    },
    removeDay(state, action: any) {
      const index = state.days.findIndex((day: any) => {
        return day === action.payload;
      });

      let updatedArray = state.days;

      if (index !== -1) {
        updatedArray.splice(index, 1);
      }

      state.days = [...updatedArray];
    },
    setTask(state, action: PayloadAction<Task | null>) {
      state.task = action.payload;
    },
    setTasks(state, action: PayloadAction<{ data: Task[] }>) {
      const { data } = action.payload;
      state.tasks = data.map((task) => task);
    },
    setStudentId(state, action: PayloadAction<string>) {
      state.studentId = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string>) {
      state.imageUrl = action.payload;
    },
    setIsDeleteModalOpen(state, action: PayloadAction<boolean>) {
      state.isDeleteModalOpen = action.payload;
    },
    taskFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    setSubTasks(
      state,
      action: PayloadAction<{ data: SubTask[] }>
      // PayloadAction<{ data: SubTaskItem[] }>
    ) {
      const { data } = action.payload;
      state.subTasks = data.map((subTask: any) => subTask);
    },
    setNotiTime(
      state,
      action: PayloadAction<{ data: string[] }>
      // PayloadAction<{ data: SubTaskItem[] }>
    ) {
      const { data } = action.payload;
      state.notiTime = data.map((custome_noti: string) => custome_noti);
    },
    addNotiTime(state, action: PayloadAction<NotiTimeItem>) {
      if (!_.some(state.notiTime, { notification_time: action.payload.notification_time })) {
        state.notiTime = [...state.notiTime, action.payload];
      }
    },
    removeNotiTime(state, action: PayloadAction<NotiTimeItem>) {
      const index = state.notiTime.findIndex((custome_noti) => {
        return custome_noti.notification_time === action.payload.notification_time;
      });
      let updatedNotiTimeArray = state.notiTime;
      if (index !== -1) {
        updatedNotiTimeArray.splice(index, 1);
      }
      state.notiTime = [...updatedNotiTimeArray];
    },
    updateNotiTime(
      state,
      action: PayloadAction<{ index: number; notification_time: string }>
    ) {
      let { index, notification_time } = action.payload;

      let updatedNotiTimeArray = state.notiTime;

      updatedNotiTimeArray[index].notification_time = notification_time;

      state.notiTime = [...updatedNotiTimeArray];
    },
   
    addSubTask(state, action: PayloadAction<SubTaskItem>) {
      if (!_.some(state.subTasks, { name: action.payload.name })) {
        state.subTasks = [...state.subTasks, action.payload];
      }
    },
   
    removeSubTask(state, action: PayloadAction<SubTaskItem>) {
      const index = state.subTasks.findIndex((subTask) => {
        return subTask.name === action.payload.name;
      });
      let updatedSubTasksArray = state.subTasks;
      if (index !== -1) {
        updatedSubTasksArray.splice(index, 1);
      }
      state.subTasks = [...updatedSubTasksArray];
    },
    updateSubTask(
      state,
      action: PayloadAction<{ index: number; completedImageUrl: string }>
    ) {
      // const index = state.subTasks.findIndex((subTask) => {
      //   return subTask.name === action.payload.name;
      // });
      let { index, completedImageUrl } = action.payload;

      let updatedSubTasksArray = state.subTasks;

      updatedSubTasksArray[index].completedImageUrl = completedImageUrl;

      state.subTasks = [...updatedSubTasksArray];
    },
    setIndex(state, action: PayloadAction<number>) {
      state.index = action.payload;
    },
    setOpenEditModal(state, action) {
      state.openEditModal = action.payload;
    },
    setDaysRadio(state, action) {
      state.daysRadio = action.payload;
    },
    setSelectedDate(state, action: PayloadAction<Date>) {
      state.selectedDate = action.payload;
    },
    taskLongComplete(state) {
      state.loading = false;
    },
    taskComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  taskStart,
  taskLongStart,
  setTaskId,
  setName,
  setStart,
  setEnd,
  setImageBinary,
  setFileName,
  setAddedAttachment,
  setIsSpecial,
  toggleIsSpecial,
  setIsRecurring,
  setTaskTypeId,
  setRecipeId,
  setTaskStep,
  addInstruction,
  removeInstruction,
  setStudentIds,
  addStudentId,
  removeStudentId,
  setStudents,
  setInstructions,
  setTaskLocation,
  setDays,
  addDay,
  removeDay,
  setTask,
  setTasks,
  setStudentId,
  setImageUrl,
  setIsDeleteModalOpen,
  addNotiTime,
  removeNotiTime,
  setNotiTime,
  updateNotiTime,
  addSubTask,
  removeSubTask,
  setSubTasks,
  updateSubTask,
  setIndex,
  setOpenEditModal,
  setDaysRadio,
  taskLongComplete,
  taskComplete,
  taskFailure,
  setSelectedDate,
} = taskSlice.actions;

export default taskSlice.reducer;

export const getTaskById =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskStart());
      const task = await REQUESTS.getTaskById(id);
      dispatch(setTask(task));
      
      dispatch(taskComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(taskFailure(error));
      dispatch(setErrorMsg(error));
    }
  };

export const getTasksForStudent =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskStart());
      let tasks = await REQUESTS.getTasksForStudent(id);
      tasks = tasks.reverse();
      dispatch(setTasks({ data: tasks }));
      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getStudentTasksForDate =
  (id: string, date: Date): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(taskStart());
      const { morningTasks, afternoonTasks, eveningTasks, nightTasks } =
        await REQUESTS.getStudentTasksForDate(
          id,
          date,
          new Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      //console.log('morningTasks', morningTasks.length);
      dispatch(
        setTasks({
          data: [
            ...morningTasks,
            ...afternoonTasks,
            ...eveningTasks,
            ...nightTasks,
          ],
        })
      );

      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
      }
    }
  };

export const addTaskImageToS3 = (): AppThunk => async (dispatch) => {
  try {
    dispatch(taskStart());
    const { attachment, imageBinary } = store.getState().task;
    if (attachment && imageBinary !== '') {
      const url = await REQUESTS.addTaskImageToS3(imageBinary);
      dispatch(setImageUrl(url));
    }
    dispatch(taskComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(taskFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const addImageToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskStart());
      let data = await addLibraryImageToS3(formData);
      dispatch(setImageUrl(data));
      dispatch(setAddedAttachment(true));
      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      } 
    }
  };

export const addSubTaskImageToS3 =
  (files:any[]): AppThunk =>
  async (dispatch, getState) => { 
    try {
      dispatch(taskStart());
      let file = files[0];

      Resizer.imageFileResizer(
        file,
        680,
        460,
        "JPEG",
        100,
        0,
        async(uri:any) => {
          let fdata= getImageUrl(uri);
          //setImg(fdata);
          const formData = new FormData();
          formData.append('file', fdata);
              let { task } = getState();
              console.log(formData);
              let completedImageUrl = await addLibraryImageToS3(formData);
              dispatch(updateSubTask({ index: task.index, completedImageUrl }));
          },
          "base64",
          200,
          200
      );
      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createTasksForStudents =
  (history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskLongStart());

      let { username } = await Auth.currentUserInfo();
      const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      let program_de_id="";
      const {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        instructions,
        subTasks,
        days,
        taskTypeId,
        recipeId,
        studentIds,
        notiTime,
      } = store.getState().task;

      const { superAdmin } = store.getState().superAdmin;

      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    

      if (program_de_id && start && end) {
        // await dispatch(
        //   createTask(
        //     name,
        //     imageUrl,
        //     taskLocation,
        //     start,
        //     end,
        //     isSpecial,
        //     isRecurring,
        //     instructions,
        //     days,
        //     taskTypeId,
        //     username,
        //     studentIds,
        //     program.id
        //   )
        // );
        dispatch(
          createTaskWithSubTaskImages(
            name,
            imageUrl,
            taskLocation,
            start,
            end,
            isSpecial,
            isRecurring,
            subTasks,
            days,
            taskTypeId,
            recipeId,
            username,
            studentIds,
            notiTime,
            program_de_id
          )
        );
      }
      dispatch(resetTaskState());
      if(parent?.id)
      {
        history.push(PARENT_STUDENT_PROFILES);
      }
      else
      {
        history.push(STUDENT_PROFILES);

      }
      dispatch(taskLongComplete());
      dispatch(recipeComplete());

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createTask =
  (
    name: string,
    imageUrl: string,
    taskLocation: string,
    start: Date,
    end: Date,
    isSpecial: boolean,
    isRecurring: boolean,
    instructions: string[],
    days: number[],
    taskTypeId: string,
    recipeId:string,
    counselorId: string,
    studentIds: string[],
    programId: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskStart());
      const success = await REQUESTS.createTask(
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        instructions,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
        programId
      );
      dispatch(setSuccessMsg('Task created!'));
      dispatch(taskComplete());
      dispatch(recipeComplete());

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const createTaskWithSubTaskImages =
  (
    name: string,
    imageUrl: string,
    taskLocation: string,
    start: Date,
    end: Date,
    isSpecial: boolean,
    isRecurring: boolean,
    subTasks: SubTaskItem[],
    days: number[],
    taskTypeId: string,
    recipeId: string,
    counselorId: string,
    studentIds: string[],
    notiTime: string[],
    programId: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(taskStart());
      const success = await REQUESTS.createTaskWithSubTaskImages(
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        subTasks,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
        notiTime,
        programId
      );
      dispatch(setSuccessMsg('Task created!'));
      dispatch(taskComplete());
      dispatch(recipeComplete());

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateTaskForStudent =
  (history: any): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(taskStart());
      let store = getState();
      let {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        days,
        taskTypeId,
        recipeId,
        subTasks,
        notiTime,
        task,
        selectedDate,
        studentId,
      } = store.task;

      if (task && start && end) {
        let updatedTask = await REQUESTS.updateTask(
          task?.id,
          name,
          imageUrl,
          taskLocation,
          start,
          end,
          isSpecial,
          isRecurring,
          subTasks,
          notiTime,
          days,
          taskTypeId,
          recipeId,
        );
        dispatch(getTaskById(updatedTask.id));
        dispatch(getStudentTasksForDate(studentId, selectedDate));
        dispatch(resetTaskState());
        dispatch(setSuccessMsg('Task updated!'));
        
        history.goBack();

        //navigation.navigate(ALL_TASKS_SCREEN);
        dispatch(taskComplete());
        dispatch(recipeComplete());

      }
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
      }
    }
  };

export const updateRecurringTaskForStudent =
  (history: any): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(taskStart());

      let store = getState();
      let {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        days,
        taskTypeId,
        subTasks,
        task,
        notiTime,
        selectedDate,
        studentId,
        recipeId,

      } = store.task;

      if (task && start && end) {
        let updatedTask = await RECURRING_REQUESTS.updateRecurringTask(
          task.id,
          name,
          imageUrl,
          taskLocation,
          start,
          end,
          isSpecial,
          isRecurring,
          subTasks,
          days,
          notiTime,
          taskTypeId,
          recipeId
        );
        dispatch(getTaskById(updatedTask.id));
        dispatch(getStudentTasksForDate(studentId, selectedDate));
        dispatch(setOpenEditModal(false));
        dispatch(resetTaskState());
        dispatch(setSuccessMsg('Task updated!'));
        history.goBack();
        dispatch(taskComplete());
        dispatch(recipeComplete());

      }
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        // dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteTask =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const { studentId, selectedDate } = store.getState().task;
      dispatch(taskStart());
      await REQUESTS.deleteTask(id);
      dispatch(getStudentTasksForDate(studentId, selectedDate));
      dispatch(setIsDeleteModalOpen(false));
      dispatch(resetTaskState());
      dispatch(setSuccessMsg('Task deleted!'));
      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteRecurringTask =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const { studentId, selectedDate } = store.getState().task;
      dispatch(taskStart());
      await RECURRING_REQUESTS.deleteRecurringTask(id);
      dispatch(getStudentTasksForDate(studentId, selectedDate));
      dispatch(setIsDeleteModalOpen(false));
      dispatch(resetTaskState());
      dispatch(setSuccessMsg('Task deleted!'));
      dispatch(taskComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(taskFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const resetTaskState = (): AppThunk => (dispatch) => {
  try {
    dispatch(setTaskId(''));
    dispatch(setTask(null));
    dispatch(setName(''));
    dispatch(setRecipeId(''));
    dispatch(setFileName(''));
    dispatch(setAddedAttachment(false));
    dispatch(setIsSpecial(false));
    dispatch(setIsRecurring(false));
    dispatch(setTaskTypeId(''));
    dispatch(setInstructions({ data: [] }));
    dispatch(setSubTasks({ data: [] }));
    dispatch(setStudentIds([]));
    dispatch(setNotiTime({ data: [] }));
    dispatch(setTaskStep(0));
    dispatch(setDays({ data: [] }));
    dispatch(setTaskLocation(''));
    dispatch(setImageUrl(''));
    dispatch(setImageBinary(''));
    dispatch(setDaysRadio('0'));
    dispatch(setOpenEditModal(false));
  } catch (err: any) {}
};

function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }