import { User } from '../constants/models/User';
import { COUNSELOR_ROUTE, PROGRAM_MANAGER_ROUTE, PROGRAM_ROUTE, STUDENT_ROUTE } from './../constants/routes';
import API from './api';

export async function getCounselorById(id: string) {
  try {
    const res = await API.get<{ data: User; success: boolean }>(
      `${COUNSELOR_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getCounselorsForProgram(programId: string) {
  try {
    const res = await API.get<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${COUNSELOR_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getCounselorsForParent(programId: string) {
  try {
    const res = await API.get<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${COUNSELOR_ROUTE}/getConselorByParent`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getCounselorsForParentPanel(programId: string) {
  try {
    const res = await API.get<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${COUNSELOR_ROUTE}/getConselorByParentPanel`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getCounselorsForOrganisationProgram(programId: string) {
  try {
    const res = await API.get<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${COUNSELOR_ROUTE}/getConselorByOrgainsation`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createCounselor(
  
  name: string,
  email: string,
  schoolName: string,
  password: string
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${COUNSELOR_ROUTE}`,
      {
       
        name,
        email,
        schoolName,
        password
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createCounselorAsProgramManger(
  name: string,
  email: string,
  schoolName: string,
  referenceCode: string,
  address: string,
  street: string,
  state: string,
  city: string,
  country: string,
  postalCode: number,
  about: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${COUNSELOR_ROUTE}/register`,
      {
        name,
        email,
        schoolName,
        referenceCode,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        programId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function createCounselorBySuperAdmin(
  name: string,
  email: string,
  schoolName: string,
  referenceCode: string,
  address: string,
  street: string,
  state: string,
  city: string,
  country: string,
  postalCode: number,
  about: string,
  programId: string
) {
  try {
    let orgid;
    const userData = localStorage.getItem('userData');
    if(userData)
    {

     const userDt = JSON.parse(userData);
     orgid=userDt.id;
    }
    const res = await API.post<{ data: User; success: boolean }>(
      `${COUNSELOR_ROUTE}/createCounselorBySuperAdmin`,
      {
        name,
        email,
        schoolName,
        referenceCode,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        programId,
        organisationId:orgid,

      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateCounselor(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  // about: string,
  imageUrl: string | undefined
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${COUNSELOR_ROUTE}/${id}/updateCounselor`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        // about,
        imageUrl: imageUrl ?? 'no-url',
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

//get organisation
export async function getOrganisationByName(
  name: string,
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getOrganisation`,
      {
        name,
     
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createUserBySuperAdmin(
  name: string,
  email: string,
  schoolName: string,
  referenceCode: string,
  address: string,
  street: string,
  state: string,
  city: string,
  country: string,
  postalCode: number,
  about: string,
  programId: string
) {
  try {
    let orgid;
    const userData = localStorage.getItem('userData');
    if(userData)
    {

     const userDt = JSON.parse(userData);
     orgid=userDt.id;
    }
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/createUserBySuperAdmin`,
      {
        name,
        email,
        schoolName,
        referenceCode,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        programId,
        organisationId:orgid,

      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}