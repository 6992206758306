import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import {
  ADD_COUNSELOR,
  ADD_ORGANISATION,
  EDIT_COUNSELOR_PROFILE,
  EDIT_PARENTS_COUNSELOR_PROFILE,
  EDIT_PROGRAM_MANAGER_BY_SUPERADMIN,
  UPDATE_ORGANISATION_TO_PROGRAM,
} from '../../../constants/routes';
import {
  getCounselorById,
  getCounselorsForOrganisationProgramRefresh,
  getCounselorsForProgram,
  getCounselorsForProgramRefresh,
  getCounselorsForProgramRefreshParent,
  setCounselor,
} from '../../../slices/counselorSlice';
import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { approvedOrganisation, changeProgramStatus, getOrganisationList, getProgramManagerList, getSearchOrganisationBySuperAdmin } from '../../../api/programManagerRequests';
import { TiTick } from 'react-icons/ti';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import { getProgramManagerByIdRefresh } from '../../../slices/programManagerSlice';
import { setRole } from '../../../slices/authSlice';

interface IProgramManagerListProps {}

const useStyles = makeStyles(formStyles);

const ProgramManagerList: React.FC<IProgramManagerListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { counselors } = useSelector((state: RootState) => {
    return {
      counselors: state.counselor.counselors,
    };
  }, shallowEqual);


  localStorage.setItem('urlRoute', "parent_admins");

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const [orgarr,setorgarr]=useState([]);
  const [orgarr_nxt,setorgarrNxt]=useState([]);
 
  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
      const orgDatanxt = await getOrganisationList();
        if(orgDatanxt)
        {
          setorgarrNxt(orgDatanxt)

        }
      const orgData = await getProgramManagerList();
      if(orgData)
      {
        setorgarr(orgData)
      }
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);
  
  async function getApproveOrganisation(id:any)
  {
    //console.log("chk id-->"+id)

    const orgData_nxt = await approvedOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
          if(orgData)
          {
            setorgarrNxt(orgData)
        
            
          }
      }
      
  }
  let renderedStudents: { label: string; value: string; imageUrl: string }[] =[];

  let autoorgdata: any[];
  if(orgarr_nxt)
  {
              if (orgarr_nxt.length > 0) {
                renderedStudents = orgarr_nxt
                  .map((org:any) => {
                    return {
                      label: org.name,
                      value: org.id,
                      imageUrl: "",
                    };
                  });
              } else {
                renderedStudents = [];
              }
  }
  console.log("----->"+renderedStudents)
  const [cstatus,setCstatus]=useState("");
  async function getInactiveProgramManager(e:any,cnt:any,id:any) {
    setCstatus(e.target.value)

    let c1=window.confirm("Do you want to change status !");
    if(c1)
    {
      //alert(e.target.value)
      let orstatus=e.target.value;
      const orgData = await changeProgramStatus(id,orstatus);
      if(orgData)
      {
        const orgData = await getProgramManagerList();
        if(orgData)
        {
          setorgarr(orgData)
        }
        //window.location.reload()
      }
    }
  }
  const [orgSelect,setorgSelect]=useState("");
  async function getSearchOrganisation(e:any,nm:string)
  {
    //alert(nm)
        setorgSelect(nm)
      let orgnm=nm;
        const orgData = await getSearchOrganisationBySuperAdmin(orgnm);
        if(orgData)
        {
          setorgarr(orgData)
        }
  
      
  }

  async function getOrganisationUpdateToProgram(id:any)
  {
    history.push(UPDATE_ORGANISATION_TO_PROGRAM(id))
  }
  console.log("org data-->"+JSON.stringify(orgarr))
  let renderedCounselors;
  if (orgarr.length > 0) {
    renderedCounselors = orgarr.map((counselor, index) => {
      const { name,
        email, 
        phoneno,
        schoolName: schoolName, 
        tellus,
        is_program,
        status,
        organisationId,
        id } = counselor;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          {/* <StyledTableCell>{schoolName?schoolName:<button className='btn btn-xs btn-warning' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Approve"}
              onClick={ async() => {
                
                await dispatch(getProgramManagerByIdRefresh(id))
                getOrganisationUpdateToProgram(id)
              //   history.push(EDIT_PROGRAM_MANAGER_BY_SUPERADMIN)
              }}
              >Add Organisation</button>}</StyledTableCell> */}
               <StyledTableCell>{schoolName?schoolName:""}</StyledTableCell>
               {/* <StyledTableCell>{status}</StyledTableCell> */}
          <StyledTableCell>
                <select value={status} className='form-control' style={{padding:'10px',fontSize:'12px'}} onChange={(e)=>getInactiveProgramManager(e,index,id)}>
                    <option value={"Active"} selected={status==="Active"?true:false}>Active </option>
                    <option value={"Inactive"} selected={status==="Inactive"?true:false}>Inactive </option>
                    <option value={"Delete"} selected={status==="Delete"?true:false}>Delete </option>
                  </select>
              </StyledTableCell>
          <StyledTableCell>
           
              <button className='btn btn-xs btn-warning' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Approve"}
              onClick={ async() => {

                await dispatch(getProgramManagerByIdRefresh(id))
                setRole(0)
                localStorage.setItem('req_from',"super_admin");
                history.push(EDIT_PROGRAM_MANAGER_BY_SUPERADMIN)
              }}
              ><AiOutlineEdit /></button>
            
            {/* <IconButton
              onClick={async () => {
                await dispatch(getCounselorById(id));
                if(authlocal==="3")
                {
                  history.push(EDIT_PARENTS_COUNSELOR_PROFILE(id));
                }
                else
                {
                  history.push(EDIT_COUNSELOR_PROFILE(id));
                }
              }}
            >
              <EditIcon />
            </IconButton> */}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (counselors.length === 0) { 
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Program Manager found.
            <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Program Manager?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  
  
  return (
    <div>
      <Typography variant='h6'>Program Manager</Typography>
      <br />
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
         
            <Grid item md={6} xs={12}>

            </Grid>
            <Grid item md={4} xs={6} style={{padding:'5px'}}>
              <label><b>Select Organization</b></label>
              <Autocomplete
                  freeSolo
                  autoComplete
                  id=''
                  disableClearable={false}
                  options={renderedStudents}
                  onChange={(e, option:any) => {
                    if (typeof option !== 'string') {
                      getSearchOrganisation(e,option.value)
                    }

                  }}
                  //value={orgSelect}
                  renderInput={(params) => (
                    <TextField {...params} label='' variant='outlined' size='small' placeholder='Select Organization' value={orgSelect}/>
                  )}
                  getOptionLabel={(option) =>
                    typeof option !== 'string' && option.label ? option.label : ''
                  }
                />
              </Grid>
            {/* <Grid item md={2} xs={12} style={{padding:'5px'}}>
                <select className='form-control' value={orgSelect} style={{padding:'10px'}} onChange={(e)=>getSearchOrganisation(e)}>
                  <option value={""}>- Select Organisation -</option>
                  {
                      orgarr_nxt.map((item, index)=>{
                        const {name}=item
                          return(
                          <option key={index} value={name}>{name}</option>
                          )
                      })
                  }
                </select>
              </Grid> */}

             
              <Grid item md={2} xs={4} className='text-center' style={{padding:'5px'}}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={async() => { 
                    window.location.reload()
                   // setOrgDate("")
                    // const orgData = await getProgramManagerList();
                    // if(orgData)
                    // {
                    //   setorgarr(orgData)
                    //   setorgSelect("")
                    //   const orgDatan = await getOrganisationList();
                    //   if(orgDatan)
                    //   {
                    //     setorgarrNxt(orgDatan)
                    

                    //   }

                    // }
                  }}
                  style={{height:'50px',marginTop:'20px'}}
                  startIcon={<FiRefreshCw />}
                >
                  Refresh List 
                </Button>
              </Grid> 
        </Grid>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push(ADD_ORGANISATION);
        }}
        startIcon={<AddIcon />}
      >
        Add Organisation
      </Button> */}
      <br />
      <br />
      <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                      <Grid item sm={12} xs={12} className='' style={{padding:'5px',overflow:'scroll',overflowX:'scroll',overflowY:'scroll',width:'800px',height:'400px'}}>

                            <TableContainer component={Paper}>
                              <Table aria-label='customized table'>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>S.No.</StyledTableCell>
                                    {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
                                    <StyledTableCell>Program Manager Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Organization Name</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>{renderedCounselors}</TableBody>
                              </Table>
                            </TableContainer>
                    </Grid>
              </Grid>
    </div>
  );
};

export default ProgramManagerList;
