import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedLoginFormValues } from './EnhancedLoginForm';
import { RootState } from '../../../app/rootReducer';
import { FORGOT_PASSWORD, REGISTER } from '../../../constants/routes';

interface IStudentRegisterFormProps {}

const useStyles = makeStyles(formStyles);

export const LoginForm: React.FC<
  IStudentRegisterFormProps & FormikProps<EnhancedLoginFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  const { authLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          LOGIN
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Welcome Back
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          Login to manage your account
        </Typography>
      </div>

      <form onSubmit={handleLoginSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Email
          </Typography>
          <TextField
            id='emailAddress'
            placeholder='Enter your email'
            type='email'
            name='email'
            variant='outlined'
            size='small'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.email && touched.email) as boolean}
            helperText={errors.email && touched.email && errors.email}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>

        <br />
        <FormControl required className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='subtitle1' className={classes.label}>
                Password
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2' className={classes.label}>
                <Link to={FORGOT_PASSWORD} className={classes.link}>
                  Forgot your password?
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <TextField
            id='password'
            placeholder='Enter your password'
            type='password'
            name='password'
            variant='outlined'
            size='small'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.password && touched.password) as boolean}
            helperText={errors.password && touched.password && errors.password}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='subtitle2'>
                Don't have an account yet?{' '}
                <Link to={REGISTER} className={classes.link}>
                  Sign up here.
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={authLoading}
                // fullWidth
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};
