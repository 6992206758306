import { Ingredient } from './../constants/models/Ingredient';
import { GroceryList } from './../constants/models/GroceryList';
import { STUDENT_PROFILES } from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './../constants/models/User';
import * as REQUESTS from '../api/studentRequests';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { History } from 'history';
import * as REPORT_REQUESTS from '../api/reportRequests';
import * as INGREDIENT_REQUESTS from '../api/ingredientRequests';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { getGroceryListIngredients } from './grocerySlice';

export interface studentState {
  loading: boolean;
  error: string | null;
  student: User | null;
  reportUrl: string | null;
  students: User[];
  groceryList: GroceryList | null;
  ingredient: Ingredient | null;
}

export const initialState: studentState = {
  loading: false,
  error: null,
  student: null,
  students: [],
  reportUrl: null,
  groceryList: null,
  ingredient: null,
};

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    studentStart(state) {
      state.loading = true;
      state.error = null;
    },
    setIngredient(state, action: PayloadAction<Ingredient | null>) {
      state.ingredient = action.payload;
    },
    setGroceryList(state, action: PayloadAction<GroceryList | null>) {
      state.groceryList = action.payload;
    },
    setReportUrl(state, action: PayloadAction<string>) {
      state.reportUrl = action.payload;
    },
    setStudent(state, action: PayloadAction<User | null>) {
      state.student = action.payload;
    },
    setStudents(state, action: PayloadAction<{ data: User[] }>) {
      const { data } = action.payload;
      state.students = data.map((student) => student);
    },
    studentFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    studentComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  studentStart,
  setStudent,
  setStudents,
  studentComplete,
  studentFailure,
  setReportUrl,
  setGroceryList,
  setIngredient,
} = studentSlice.actions;

export default studentSlice.reducer;

export const getStudentById =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const student = await REQUESTS.getStudentBydId(studentId);
      dispatch(setStudent(student));
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createStudentAsProgramManager =
  (
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    diagnoses: string[],
    counselors: string[],
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      console.log("check user ---->"+program+"/"+parent);
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
        // let password="";
        // const userauth =  await Auth.signUp({
        //   username: email,
        //   password,
        //   attributes: {
        //     given_name: name,
        //     family_name: name,
        //     address,
        //     email,
        //     'custom:schoolName': "schoolName",
        //     'custom:userRole': "2",
        //   },
        // });
        
        // if(userauth)
        // {
          const student = await REQUESTS.createStudentAsProgramManager(
            name,
            email,
            address,
            street,
            city,
            state,
            country,
            postalCode,
            about,
            program_de_id,
            diagnoses,
            counselors
          );
          dispatch(setSuccessMsg(`User added successfully !`));
        //}
       
      }
      history.push(STUDENT_PROFILES);

      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

// export const updateStudent =
//   (
//     id: string | undefined,
//     name: string,
//     email: string,
//     address: string,
//     street: string,
//     city: string,
//     state: string,
//     country: string,
//     postalCode: number,
//     about: string,
//     imageUrl: string | undefined,
//     diagnoses: string[],
//     counselors: string[],
//     history: History
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(studentStart());
//       //const { program } = store.getState().program;
//       const { program } = store.getState().program;
//       const { parent } = store.getState().parent;
//       let program_de_id="";
//       if(parent?.id)
//       {
//         program_de_id=parent.id;
//       }
//       else if(program?.id)
//       {
//         program_de_id=program.id;
//       }
    

//       if (program_de_id && id !== undefined) {
//         const student = await REQUESTS.updateStudent(
//           id,
//           name,
//           email,
//           address,
//           street,
//           city,
//           state,
//           country,
//           postalCode,
//           about,
//           imageUrl,
//           diagnoses,
//           counselors,
//         );
//         dispatch(setSuccessMsg(`Student updated!`));
//       }
//       history.push(STUDENT_PROFILES);

//       dispatch(studentComplete());
//     } catch (err: any) {
//       if (err.response) {
//         const { error } = err.response.data;
//         dispatch(studentFailure(error));
//         dispatch(setErrorMsg(error));
//       }
//     }
//   };

export const getStudentsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(studentStart());
    // const { program } = store.getState().program;

    // if (program && program.id) {
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const students = await REQUESTS.getStudentsForProgram(programId);
      dispatch(setStudents({ data: students }));
    }
    dispatch(studentComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(studentFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getStudentsForParent = (): AppThunk => async (dispatch) => {
  try {
    dispatch(studentStart());
    const { parent } = store.getState().parent;

    if (parent && parent.id) {
      let parentId = parent.id;
      const students = await REQUESTS.getStudentsForParent(parentId);
      dispatch(setStudents({ data: students }));
    }
    dispatch(studentComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(studentFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getReportForStudent =
  (date: Date, studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
        let programId =program_de_id;// program.id;
        const data = await REPORT_REQUESTS.getReport(
          date,
          programId,
          studentId,
          new Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        console.log(data);
        dispatch(setReportUrl(data.s3Url));
        const a = document.createElement('a');
        a.href = data.s3Url;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getIngidiansListForStudent =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const data = await REQUESTS.getStudentGroceryList(studentId);
        if (data.length > 0) {
          console.log(data);
          dispatch(setGroceryList(data[0]));
        } else {
          const newData = await REQUESTS.createStudentGroceryList(
            programId,
            studentId
          );
          console.log(newData);
          dispatch(setGroceryList(newData));
        }

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createIngredient =
  (
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.createIngredient(
          name,
          category,
          unit,
          price,
          quantity,
          isCompleted,
          groceryListId
        );
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getIngidiansListForStudent(student.id));
        // }
          dispatch(getGroceryListIngredients(groceryListId,history));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getIngredient =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await INGREDIENT_REQUESTS.getIngredientById(id);
        console.log(data);
        dispatch(setIngredient(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateIngredient =
  (
    id: string,
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;

        const data = await INGREDIENT_REQUESTS.updateIngredientById(
          id,
          name,
          category,
          unit,
          price,

          quantity,
          isCompleted,
          groceryListId
        );
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getIngidiansListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryListId,history));

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteIngredient =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.deleteIngredientById(id);
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getIngidiansListForStudent(student.id));
        // }
        dispatch(setSuccessMsg(`Ingredient deleted!`));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const markIngredientAsComplete =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.markIngredientAsComplete(id);
        console.log(data);
        dispatch(setIngredient(null));
        if (student != null) {
          dispatch(getIngidiansListForStudent(student.id));
        }
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const markIngredientAsIncomplete =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.markIngredientAsIncomplete(id);
        console.log(data);
        dispatch(setIngredient(null));
        if (student != null) {
          dispatch(getIngidiansListForStudent(student.id));
        }

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
