export const NAME_REQUIRED = 'Please enter name';
export const TAG_REQUIRED = 'Tag is required';
export const IMAGE_REQUIRED = 'Image is required';
export const TITLE_REQUIRED = 'Title is required';
export const DESCRIPTION_REQUIRED = 'Description is required';
export const RECIPE_TYPE_REQUIRED = 'Recipe type is required';
export const PREPARATION_TIME_REQUIRED = 'Preparation time is required';
export const TASK_TYPE_REQUIRED = 'Task Type is required';
export const SCHOOL_NAME_REQUIRED = 'School name is required';
export const EMAIL_NOT_LONG_ENOUGH = 'Email not long enough';
export const EMAIL_REQUIRED = 'Please enter valid email';
export const EMAIL_INVALID = 'Invalid email address';
export const PASSWORD_REQUIRED = 'Password is required';
export const ORGANISATION_REQUIRED = 'Organisation is required';
export const NEW_PASSWORD_REQUIRED = `New ${PASSWORD_REQUIRED}`;
export const CURRENT_PASSWORD_REQUIRED = `Current ${PASSWORD_REQUIRED}`;
export const CONFIRM_PASSWORD_REQUIRED = `Confrim ${PASSWORD_REQUIRED}`;
export const PASSWORD_TOO_SHORT =
  'Password is too short - should be 6 characters minimum.';
export const PASSWORDS_MUST_MATCH = 'Passwords must match';
export const INVALID_PATTERN = 'Please enter a 6 digit code with numbers only!';
export const REFERENCE_CODE_REQUIRED = 'Reference code is required';
export const ADDRESS_REQUIRED = 'Address is required';
export const STREET_REQUIRED = 'Street is required';
export const CITY_REQUIRED = 'City is required';
export const COUNTRY_REQUIRED = 'Country is required';
export const POSTAL_CODE_REQUIRED = 'Postal code is required';
export const ABOUT_REQUIRED = 'About is required';
export const INVALID_MIN_DATE = "You can't create an event this early";
export const QUESTION_REQUIRED = 'Please enter a question!';
export const QUESTION_TOO_SHORT =
  'There must be atleast 10 characters in a question!';
export const QUESTION_TOO_LONG =
  'A question can have a maximum of 250 characters!';
export const ANSWER_IS_REQUIRED = 'Answer is required!';
export const IMAGE_DESCRIPTION_IS_REQUIRED = 'Image description is required';
export const Quantity_IS_REQUIRED = 'Quantity is required';
export const Unit_IS_REQUIRED = 'Unit is required';
