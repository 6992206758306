import { Document } from './../constants/models/Document';
import { DOCUMENT_ROUTE, PROGRAM_ROUTE } from './../constants/routes';

import API from './api';

export async function getDocumentById(id: string) {
  try {
    const res = await API.get<{ data: Document; success: boolean }>(
      `${DOCUMENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getDocumentsForProgram(programId: string) {
  try {
    const res = await API.get<{ data: Document[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${DOCUMENT_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createDocument(
  name: string,
  documentUrl: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: Document; success: boolean }>(
      `${DOCUMENT_ROUTE}`,
      { name, documentUrl, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateDocument(
  id: string,
  name: string,
  documentUrl: string
) {
  try {
    const res = await API.post<{ data: Document; success: boolean }>(
      `${DOCUMENT_ROUTE}/${id}/updateDocument`,
      {
        name,
        documentUrl,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteDocument(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${DOCUMENT_ROUTE}/${id}/deleteDocument`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addDocumentToS3(formData: FormData) {
  try {
    const res = await API.post<{ data: string; success: boolean }>(
      `${DOCUMENT_ROUTE}/add-document`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
