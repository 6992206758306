import WineBarIcon from '@mui/icons-material/WineBar';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import SanitizerIcon from '@mui/icons-material/FastfoodOutlined';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import CoffeeIcon from '@mui/icons-material/Coffee';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LaptopIcon from '@mui/icons-material/Laptop';
import SetMealIcon from '@mui/icons-material/SetMeal';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SausageIcon from '../assets/images/icons/meat-on-bone-svgrepo-com.svg'; 
import FaceIcon from '@mui/icons-material/Face';
import { TbMeat } from 'react-icons/tb';
import { GiHeartPlus } from 'react-icons/gi';
import { MdPets } from 'react-icons/md';
import { RxCrumpledPaper } from 'react-icons/rx';
import { SiIfood } from 'react-icons/si';
import { MdOutlineOtherHouses } from 'react-icons/md';
import { GiCannedFish } from 'react-icons/gi';

export interface Category {
  name: string;
  icon: any;
  color: string;
}

export const categoryList: Category[] = [
  {
    name: 'Alcoholic drinks',
    icon: WineBarIcon,
    color: 'red',
  },
  {
    name: 'Baby care',
    icon: ChildFriendlyIcon,
    color: 'purple',
  },
  {
    name: 'Bread & Bakery',
    icon: BreakfastDiningOutlinedIcon,
    color: 'brown',
  },
  {
    name: 'Canned foods',
    icon: GiCannedFish,
    color: 'lightBrown',
  },
  {
    name: 'Clothes',
    icon: CheckroomIcon,
    color: 'green',
  },
  {
    name: 'Coffee and Tea (Take out chocolates)',
    icon: CoffeeIcon,
    color: 'coffee',
  },
  {
    name: 'Dairy',
    icon: LocalPizzaIcon,
    color: 'gold',
  },
  {
    name: 'Drinks',
    icon: LocalDrinkIcon,
    color: 'lightBlue',
  },
  {
    name: 'Electronic & Appliances',
    icon: LaptopIcon,
    color: 'silver',
  },
  {
    name: 'Fish & Seafood',
    icon: SetMealIcon,
    color: 'orange',
  },
  {
    name: 'Frozen products',
    icon: AcUnitIcon,
    color: 'lightBlue',
  },
  {
    name: 'Fruits & vegetables',
    icon: RestaurantIcon,
    color: 'red',
  },
  {
    name: 'Grains, Pasta',
    icon: DinnerDiningIcon,
    color: 'wheat',
  },
  {
    name: 'Health & Beauty',
    icon: GiHeartPlus,
    color: 'red',
  },
  {
    name: 'Meat & Poultry',
    icon: TbMeat,
    color: 'red',
  },
  {
    name: 'Pet Food',
    icon: MdPets,
    color: 'green',
  },
  {
    name: 'Paper Products',
    icon: RxCrumpledPaper,
    color: 'black',
  }, 
  {
    name: 'Snacks',
    icon: SiIfood,
    color: 'blue',
  },
  {
    name: 'Other',
    icon: MdOutlineOtherHouses,
    color: 'skyblue',
  },
  
];
