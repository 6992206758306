import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../app/rootReducer';
import {
  getAllUsers,
  createChatRoom,
  setFilteredAllUsers,
  addToSelectedUsers,
  addMembersToGroup,
  setSelected,
  removeFromSelectedUsers,
  emptySelectedUsers,
  getUserChatRooms,
} from '../../slices/chatRoomSlice';
import { MESSAGING } from '../../constants/routes';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  makeStyles,
  InputAdornment,
  Fab,
  IconButton,

} from '@material-ui/core';
import { SearchOutlined, ChevronRight,  Close as AddOutlined, } from '@material-ui/icons';
import formStyles from '../../assets/jss/components/formStyles';
import { ContactListItem } from '../../components/Messaging/ContactListItem';
import { InfoListItem } from '../../components/Messaging/InfoListItem';
import { GroupContactListItem } from '../../components/Messaging/GroupContactListItem';
import { CircularProgress, Divider } from '@mui/material';
import { ContactSelectedListItem } from '../../components/Messaging/ContactSelectedListItem';

const useStyles = makeStyles(formStyles);

interface SelectContactsForGroupProps {
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  setOpenGroupModal: Dispatch<SetStateAction<boolean>>;
  setOpenAddGroupModal?: Dispatch<SetStateAction<boolean>>;
  filterOn?: boolean;
}

export const SelectContactsForGroup: React.FC<SelectContactsForGroupProps> = ({
  setOpenModal,
  setOpenAddGroupModal,
  setOpenGroupModal,
  filterOn = false,
}) => {
  const classes = useStyles();
  const {
    chatRoom: {
      allUsers,
      filteredAllUsers,
      chatRoom,
      groupUsers: users,
      selected,
      selectedUsers,
    },
    auth: { graphQlUser: currentUser },
    programUsers
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      auth: state.auth,
      programUsers: state.student.students

    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUserChatRooms(currentUser));
  }, []);
  useEffect(() => {
    dispatch(
      setFilteredAllUsers({
        query,
        isGroup: filterOn,
        users: filterOn ? users : [],
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      setFilteredAllUsers({
        query,
        isGroup: filterOn,
        users: filterOn ? users : [],
      })
    );
  }, [query]);
  if (
    allUsers === null ||
    filteredAllUsers === null ||
    filteredAllUsers === undefined
  ) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading...
      </div>
    );
  }
  let filtuser: string[]=[];
  if(programUsers)
  {
    for(let j=0;j<programUsers.length;j++)
    {
      filtuser.push(programUsers[j].id);
    
    }
  }
  console.log("filtuser-->"+filtuser);

  return (
    <div>
      <Grid
        container
        justify='center'
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent style={{ position: 'relative', flex: 1,height:'550px' }}>
            <Grid
                container
                spacing={3}
                
              >
                  <Grid item xs={10} sm={10} md={10}>

                </Grid>

                <Grid item xs={2} sm={2} md={2}>

                <IconButton
                      style={{ marginLeft: '0.5rem',width:'20%' }}
                      size='medium'
                      onClick={() => setOpenGroupModal(false)}
                    >
                      <AddOutlined style={{ color: 'blue' }} />
                    </IconButton>
                </Grid>
              </Grid>
              <Typography
                variant='h5'
                style={{ margin: '1rem auto', textAlign: 'center' }}
                component='h2'
              >
                Select Contacts For Group
              </Typography>
              <TextField
                onChange={handleChange}
                value={query}
                style={{
                  width: '100%',
                  background: 'rgba(255,255,255,1)',
                }}
                variant='outlined'
                size='small'
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search'
              />
              {selected && selectedUsers.length > 0 && (
                <>
                  <div
                    style={{
                      paddingTop: '10px',
                      display: 'flex',
                      overflow: 'scroll',
                      // justifyContent: 'flex-start',
                      // borderWidth: 5,
                      // alignItems: 'flex-start',
                      height: '100px',
                      flexDirection: 'row',
                    }}
                  >
                    {selectedUsers.map((contact: any, i: number) => (
                      <ContactSelectedListItem contact={contact} key={i} />
                    ))}
                  </div>
                  <Divider />
                </>
              )}
              <div style={{ maxHeight: '350px', overflow: 'scroll' }}>
                {/* @ts-ignore */}
                {allUsers
                  // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id).length ===
                  0 && (
                  <Typography
                    variant='h6'
                    style={{ margin: '1rem 0rem', textAlign: 'center' }}
                  >
                    No contacts found
                  </Typography>
                )}

                {/* @ts-ignore */}
                {filteredAllUsers
                  // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id).length ===
                  0 && (
                  <Typography
                    variant='h6'
                    style={{ margin: '1rem 0rem', textAlign: 'center' }}
                  >
                    No contact found
                  </Typography>
                )}
                {/* @ts-ignore */}

                {filterOn
                  ? filteredAllUsers
                      // @ts-ignore
                      ?.filter((user: any) => user.id !== currentUser.id)
                      .filter((u1: any) => {
                        //  @ts-ignore
                        return !users.some((u2) => {
                          return u1.id === u2.id;
                        });
                      })
                      .map((user: any) => {
                        if(filtuser.find(usr => usr === user.id))
                        {
                            return (
                              <GroupContactListItem
                                contact={user}
                                key={user.id}
                                onClick={async () => {
                                  if (!selected) {
                                    dispatch(setSelected(true));
                                    dispatch(addToSelectedUsers(user));
                                  } else if (
                                    // @ts-ignore
                                    !selectedUsers.find(
                                      (contact: any) => contact.id === user.id
                                    )
                                  ) {
                                    dispatch(setSelected(true));
                                    dispatch(addToSelectedUsers(user));
                                  } else {
                                    dispatch(removeFromSelectedUsers(user));
                                  }
                                }}
                              />
                            );
                        }
                      })
                  : filteredAllUsers
                      // @ts-ignore
                      ?.filter((user: any) => user.id !== currentUser.id)
                      .map((user: any) => {
                        if(filtuser.find(usr => usr === user.id))
                        {
                              return (
                                <GroupContactListItem
                                  contact={user}
                                  key={user.id}
                                  onClick={async () => {
                                    if (!selected) {
                                      dispatch(setSelected(true));
                                      dispatch(addToSelectedUsers(user));
                                    } else if (
                                      // @ts-ignore
                                      !selectedUsers.find(
                                        (contact: any) => contact.id === user.id
                                      )
                                    ) {
                                      dispatch(setSelected(true));
                                      dispatch(addToSelectedUsers(user));
                                    } else {
                                      dispatch(removeFromSelectedUsers(user));
                                    }
                                  }}
                                />
                              );
                        }
                      })}
              </div>
              {selected && selectedUsers.length > 0 && (
                <Fab
                  size='medium'
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                  color='primary'
                  onClick={async () => {
                    if (filterOn) {
                      setLoading(true);
                      await dispatch(
                        addMembersToGroup(
                          selectedUsers,
                          // @ts-ignore
                          chatRoom.id,
                          currentUser
                        )
                      );
                      setLoading(false);
                      setOpenGroupModal(false);
                      history.push(MESSAGING);
                    } else if (!filterOn && setOpenAddGroupModal) {
                      setOpenGroupModal(false);
                      setOpenAddGroupModal(true);
                    }
                  }}
                  aria-label='next'
                >
                  {loading ? (
                    <CircularProgress style={{ color: 'white' }} />
                  ) : (
                    <ChevronRight />
                  )}
                </Fab>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
