import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_STUDENT } from '../../../constants/routes';
import { getStudentById } from '../../../slices/studentSlice';
import EnhancedEditParentProfileForm from './EnhancedEditParentProfileForm';
import { getParentByIdRefresh } from '../../../slices/parentSlice';

interface IEditParentProfileProps {}

const EditParentProfile: React.FC<IEditParentProfileProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    counselorLoading,
    parentLoading,
    programManagerLoading,
    role,
    diagnoses,
    counselors,
    programManager,
    counselor
  } = useSelector((state: RootState) => {
    return {
      programManagerLoading: state.programManager.loading,
      counselorLoading: state.counselor.loading,
      parentLoading: state.parent.loading,
      diagnoses: state.diagnosis.diagnoses,
      counselors: state.counselor.counselors,
      role: state.auth.role,
      programManager:state.programManager.programManager,
      counselor: state.counselor.counselor,

    };
  }, shallowEqual);

  const user_id = localStorage.getItem('user_id');
  const userRole = localStorage.getItem('userRole');

  useEffect(() => {
    async function fetchMyAPI() {

        if(user_id)
        {
            await  dispatch(getParentByIdRefresh(user_id));
        }
        else
        {
          
        }
}
  
fetchMyAPI()
  return () => {};
}, []);


  let renderedForm;
  // if (!isAddStudent && student) {
  //   const {
  //     name,
  //     email,
  //     schoolName,
  //     referenceCode,
  //     address,
  //     street,
  //     city,
  //     country,
  //     postalCode,
  //     about,
  //   } = student;
  //   renderedForm = (
  //     <EnhancedEditStudentForm
  //       name={name}
  //       email={email}
  //       address={address}
  //       street={street}
  //       city={city}
  //       country={country}
  //       postalCode={postalCode}
  //       about={about}
  //     />
  //   );
  // } else {
  renderedForm = <EnhancedEditParentProfileForm />;
  // }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditParentProfile;
