import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import AddIcon from '@mui/icons-material/Add';
import {
  ImageList,
  makeStyles,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import {
  getVideosForProgram,
  deleteVideo,
  setVideoUrl,
  createVideo,
  getVideosForProgramRefresh,
} from '../../../slices/videoSlice';
import DeleteIcon from '@material-ui/icons/Delete';
import EnhancedAddVideoForm from './EnhancedAddVideoForm';
import allImageStyles from '../../../assets/jss/components/allImagesStyles';

interface IAllVideosViewProps {}

const useStyles = makeStyles(allImageStyles);

//const useStyles = makeStyles(allVideoStyles);

export const AllVideosView: React.FC<IAllVideosViewProps> = ({}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { videoLoading, videos, video } = useSelector((state: RootState) => {
    return {
      video: state.video.video,
      videos: state.video.videos,
      videoLoading: state.video.loading,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(authlocal && user_id)
    {
      dispatch(getVideosForProgramRefresh(user_id));

    }
    else
    {
      dispatch(getVideosForProgram());
    }
    return () => {};
  }, [videos.length]);

  let renderedVideos;
  if (videoLoading) {
    renderedVideos = <div>Loading...</div>;
  }
  if (videos.length > 0) {
    renderedVideos = videos.map((video) => {
      const { id, videoUrl, description } = video;
      return (
        <ImageListItem key={id}>
          {/* <img src={imageUrl} alt={description} />
           */}
          <iframe
            src={videoUrl}
            style={{ width: '100%', height: '100%' }}
            title={description}
          />
          <ImageListItemBar
            title={description}
            actionIcon={
              <IconButton
                aria-label={`info about ${description}`}
                className={classes.icon}
                onClick={(e: any) => {
                  dispatch(deleteVideo(id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      );
    });
  }

  // const classes = useStyles();
  return (
    <div>
      <Typography variant="h6">Library Videos</Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(setVideoUrl(''));
          dispatch(createVideo('New Video', 'no-url'));
        }}
        startIcon={<AddIcon />}
      >
        Add Video
      </Button>
      <br />
      <br />
      {videos.length > 0 && (
        <div className={classes.root}>
          <ImageList rowHeight={180} className={classes.imageList} cols={3}>
            {renderedVideos}
          </ImageList>
        </div>
      )}
      {video ? <EnhancedAddVideoForm video={video} /> : ''}
    </div>
  );
};
