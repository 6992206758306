import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import store, { AppThunk } from '../../../app/store';
import { setIsModalOpen, updateQuestion } from '../../../slices/questionSlice';
import {
  QUESTION_TOO_SHORT,
  QUESTION_REQUIRED,
  QUESTION_TOO_LONG,
  ANSWER_IS_REQUIRED,
} from '../../../constants//formMessages';
import { QuestionEditorForm } from './QuestionEditorForm';
import { Question } from '../../../constants/models/Question';

export interface EnhancedQuestionEditorFormValues {
  id: string;
  questionText: string;
  answer: string;
}

export interface IDispatchProps {
  setIsModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateQuestion: (
    questionId: string,
    questionText: string,
    answer: string
  ) => AppThunk;
}

export interface EnhancedQuestionEditorFormProps {
  question: Question;
  setIsModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateQuestion: (
    questionId: string,
    questionText: string,
    answer: string
  ) => void;
}

const EnhancedQuestionEditorForm = withFormik<
  EnhancedQuestionEditorFormProps,
  EnhancedQuestionEditorFormValues
>({
  mapPropsToValues: (props) => ({
    id: props.question.id,
    questionText: props.question.questionText,
    answer: props.question.answer,
  }),
  validationSchema: Yup.object().shape({
    questionText: Yup.string()
      .min(10, QUESTION_TOO_SHORT)
      .max(250, QUESTION_TOO_LONG)
      .required(QUESTION_REQUIRED),
    answer: Yup.string().required(ANSWER_IS_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { updateQuestion, setIsModalOpen } = props;
    const { id, questionText, answer } = values;
    updateQuestion(id, questionText, answer);
    setIsModalOpen(false);
    setSubmitting(false);
  },
  enableReinitialize: true,
})(QuestionEditorForm);

export default connect<null, IDispatchProps>(null, {
  setIsModalOpen,
  updateQuestion,
})(EnhancedQuestionEditorForm);
