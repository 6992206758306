import store, { AppThunk } from '../app/store';
import { viewNutrintion,ADD_RECIPE, ADD_RECIPE_VIEW, addRecipeSteps } from './../constants/routes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/recipeNutritionRequest';
//import { recipe } from '../constants/models/recipe';
import { RecipeNutrition } from '../constants/models/RecipeNutrition';
import { History } from 'history';
import { useParams } from 'react-router';


export interface recipeNutritionState {
  loading: boolean;
  error: string | null;
  recipeNutrition: RecipeNutrition | null;
  recipeNutritions: RecipeNutrition[];
  fats: string;
  isrecipeNutritionModalOpen: boolean;
}

export const initialState: recipeNutritionState = {
  loading: false,
  error: null,
  recipeNutrition: null,
  recipeNutritions: [],
  fats: '',
  isrecipeNutritionModalOpen: false,
};

const recipeNutritionSlice = createSlice({
  name: 'recipeNutrition',
  initialState,
  reducers: {
    recipeNutritionStart(state) {
      state.loading = true;
      state.error = null;
    },
    setrecipeNutrition(state, action: PayloadAction<RecipeNutrition | null>) {
      state.recipeNutrition = action.payload;
    },
    setrecipeNutritions(state, action: PayloadAction<{ data: RecipeNutrition[] }>) {
      const { data } = action.payload;
      state.recipeNutritions = data.map((recipeNutrition) => recipeNutrition);
      console.warn("state-->"+state.recipeNutritions);
    },
    setIsrecipeNutritionModalOpen(state, action: PayloadAction<boolean>) {
      state.isrecipeNutritionModalOpen = action.payload;
    },
    setrecipefats(state, action: PayloadAction<string>) {
      state.fats = action.payload;
    },
    recipeNutritionFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    recipeNutritionComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  recipeNutritionStart,
  setrecipeNutrition,
  setrecipeNutritions,
  setIsrecipeNutritionModalOpen,
  setrecipefats,
  recipeNutritionFailure,
  recipeNutritionComplete,
} = recipeNutritionSlice.actions;

export default recipeNutritionSlice.reducer;

export const getrecipeNutritionById =
  (recipeNutritionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeNutritionStart());
      const recipeNutrition = await REQUESTS.getrecipeNutritionById(recipeNutritionId);
      dispatch(setrecipeNutrition(recipeNutrition));
      dispatch(recipeNutritionComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeNutritionFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getrecipeNutritionByIdRefresh =
  (recipeNutritionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeNutritionStart());
      const recipeNutrition = await REQUESTS.getrecipeNutritionById(recipeNutritionId);
      dispatch(setrecipeNutrition(recipeNutrition));
      dispatch(recipeNutritionComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeNutritionFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getrecipeNutritionsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeNutritionStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      console.log("program id ---> "+program_de_id);
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const recipeNutritions = await REQUESTS.getrecipeNutritionsForProgram(programId);
      dispatch(setrecipeNutritions({ data: recipeNutritions }));
      
    }
    dispatch(recipeNutritionComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeNutritionFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getNutritionListForStudent =
  (recipeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeNutritionStart());
     
      const recipeNutritions = await REQUESTS.getParentNutritionList(
        recipeId
      );
      console.warn(recipeNutritions);
      dispatch(setrecipeNutritions({ data: recipeNutritions }));

      dispatch(recipeNutritionComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeNutritionFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const createrecipeNutrition =
  ( servings: string,
    caloriesPerServing: string,
    totalCarbs: string,
    protein: string,
    totalFat: string,
    totalSugar: string,
    sodium: string,
    recipeId:string,
    history: History
   ): AppThunk =>
  async (dispatch) => {
    // const { recipeId } = useParams<{ recipeId: string | undefined }>();
    try {
      dispatch(recipeNutritionStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      
      // let recipe_de_id="";
      //   if(recipeId)
      //   {
      //     recipe_de_id=recipeId;
      //   }
       
        //if (recipe_de_id) {
        //let recipeId = recipe_de_id;//program.id;
        const recipeNutrition = await REQUESTS.createrecipeNutrition(
          servings,
          caloriesPerServing,
          totalCarbs,
          protein,
          totalFat,
          totalSugar,
          sodium,
          recipeId
        );
        
        //dispatch(setrecipeNutrition(recipeNutrition));
        localStorage.setItem('userData', JSON.stringify(recipeNutrition));

        dispatch(setSuccessMsg(`Nutrition updated successfully..!`));
      //}
      //dispatch(getrecipeNutritionsForProgram());
      dispatch(setrecipefats(''));
      dispatch(recipeNutritionComplete());

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeNutritionFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updaterecipeNutrition =
  (recipeNutritionId: string, name: string, recipefats: string,history: History
    ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeNutritionStart());
      await REQUESTS.updaterecipeNutrition(recipeNutritionId, name, recipefats);
      dispatch(getrecipeNutritionsForProgram());
      dispatch(recipeNutritionComplete());
      history.push(ADD_RECIPE);

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeNutritionFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };



  