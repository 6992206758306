import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { CreateProgramForm } from './CreateProgramForm';
import { createProgram } from '../../../slices/programSlice';

interface IDispatchProps {
  createProgram: (
    schoolName: string,
    numberOfStudents: number,
    programType: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedCreateProgramFormValues {
  schoolName: string;
  numberOfStudents: number;
  programType: string;
}

export interface EnhancedCreateProgramFormProps extends RouteComponentProps {
  schoolName?: string;
  numberOfStudents?: number;
  programType?: string;
  createProgram: (
    schoolName: string,
    numberOfStudents: number,
    programType: string,
    history: History
  ) => void;
}

const EnhancedCreateProgramForm = withFormik<
  EnhancedCreateProgramFormProps,
  EnhancedCreateProgramFormValues
>({
  mapPropsToValues: (props) => ({
    schoolName: props.schoolName ? props.schoolName : '',
    numberOfStudents: props.numberOfStudents ? props.numberOfStudents : 0,
    programType: props.programType ? props.programType : '',
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, createProgram } = props;
    const { schoolName, numberOfStudents, programType } = values;
    createProgram(schoolName, numberOfStudents, programType, history);

    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(CreateProgramForm);

const EnhancedCreateProgramFormWithRouter = withRouter(
  EnhancedCreateProgramForm
);

export default connect<null, IDispatchProps>(null, { createProgram })(
  EnhancedCreateProgramFormWithRouter
);
