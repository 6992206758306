
import store, { AppThunk } from '../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../constants/models/User';
import { History } from 'history';

import * as REQUESTS from '../api/recipeRequests';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { updateDetailsForGraphQlUser } from './authSlice';
import { Tag } from '../constants/models/Tag';

export interface tagState {
  loading: boolean;
  error: string | null;
  tag: Tag | null;
  tags: Tag[];
}

export const initialState: tagState = {
  loading: false,
  error: null,
  tag: null,
  tags: [],
};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    tagStart(state) {
      state.loading = true;
      state.error = null;
    },
    setTag(state, action: PayloadAction<Tag | null>) {
      state.tag = action.payload;
    },
    setTags(state, action: PayloadAction<{ data: Tag[] }>) {
      const { data } = action.payload;
      state.tags = data.map((tag) => tag);
    },
    tagFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    tagComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  tagStart,
  setTag,
  setTags,
  tagComplete,
  tagFailure,
} = tagSlice.actions;

export default tagSlice.reducer;

// export const gettagById =
//   (tagId: string): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(tagStart());
//       const tag = await REQUESTS.gettagById(tagId);
//       dispatch(settag(tag));
//       dispatch(tagComplete());
//     } catch (error: any) {
//       if (error.response) {
//         const { data, status } = error.response;
//         dispatch(tagFailure(data));
//       }
//     }
//   };

// export const createtag =
//   (id: string, name: string, email: string, schoolName: string): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(tagStart());
//       const tag = await REQUESTS.createtag(
//         id,
//         name,
//         email,
//         schoolName
//       );
//       dispatch(settag(tag));
//       dispatch(tagComplete());
//     } catch (error: any) {
//       if (error.response) {
//         const { data, status } = error.response;
//         dispatch(tagFailure(data));
//       }
//     }
//   };

// export const updatetag =
//   (
//     id: string | undefined,
//     name: string,
//     email: string,
//     address: string,
//     street: string,
//     city: string,
//     state: string,
//     country: string,
//     postalCode: number,
//     // about: string,
//     imageUrl: string | undefined,
//     history: History,
//     userProgramRoute: boolean = false
//   ): AppThunk =>
//   async (dispatch, getStore) => {
//     try {
//       dispatch(tagStart());

//       const tag = await REQUESTS.updatetag(
//         id!,
//         name,
//         email,
//         address,
//         street,
//         city,
//         state,
//         country,
//         postalCode,
//         // about,
//         imageUrl
//       );
//       dispatch(setSuccessMsg(`Profile updated!`));
//       dispatch(gettagById(id!));
//       const { role } = getStore().auth;
//       // if (role === USER_ROLE.tag) {
//       const { graphQlUser } = getStore().auth;
//       dispatch(
//         updateDetailsForGraphQlUser(
//           id!,
//           email,
//           name,
//           imageUrl ?? graphQlUser.imageUrl,
//           email,
//           graphQlUser.about
//         )
//       );
//       // }
//       if (userProgramRoute) {
//         history.push(tag_PROFILES);
//       } else {
//         history.push(tag_DASHBOARD);
//       }

//       dispatch(tagComplete());
//     } catch (err: any) {
//       if (err.response) {
//         const { error } = err.response.data;
//         // dispatch(studentFailure(error));
//         dispatch(setErrorMsg(error));
//         dispatch(tagFailure(error));
//       }
//     }
//   };

export const getrecipeTags = (): AppThunk => async (dispatch) => {
    try {
      dispatch(tagStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const tags = await REQUESTS.getRecipeTags(programId);
        dispatch(setTags({ data: tags }));
      }
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getrecipeTagsRefresh = (program_pass_id:string): AppThunk => async (dispatch) => {
    try {
      dispatch(tagStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const tags = await REQUESTS.getRecipeTags(programId);
        dispatch(setTags({ data: tags }));
      }
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(setErrorMsg(error));
      }
    }
  };