import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { AppBar, Typography, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import LOGO from '../../assets/images/Nflyte right side.png';

import navbarStyles from '../../assets/jss/components/navbarStyles';
import { useDispatch } from 'react-redux';
import ElevationScroll from './ElevationScroll';
import { navbarRoutes } from './navbarRoutes';
import { HOME } from '../../constants/routes';

const useStyles = makeStyles(navbarStyles);

interface INavbarProps {}

export const Navbar: React.FC<INavbarProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  let path = location.pathname;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [navOpen, setNavOpen] = useState(false);
  const [linkDelay, setLinkDelay] = useState(0);

  const BREAKPOINT = 950;

  const updateDimensions = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    if (window.innerWidth < BREAKPOINT) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  const closeOnClick = () => {
    if (window.innerWidth < BREAKPOINT) setNavOpen(false);
  };

  useEffect(() => {
    updateDimensions();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (navOpen) {
      setTimeout(() => {
        setLinkDelay(1);
      }, 300);
    } else {
      setLinkDelay(0);
    }
  }, [navOpen]);
  function redirectUrl()
  {
    window.open('https://zfrmz.com/qlbsb83rXm8hWJFf1Lyr','_blank');
  }
  function redirectHome()
  {
    window.open('https://nflyte.com/','_blank');
  }
  function redirectAboute()
  {
    window.open('https://nflyte.com/about/','_blank');
  }
  function redirectSolutions()
  {
    window.open('https://nflyte.com/for-families/','_blank');
  }
  function redirectResources()
  {
    window.open('https://nflyte.com/who-is-it-for/','_blank');
  }
  return (
    <>
      <div className="nav">
        <ElevationScroll {...props} style={{ border: '5px solid black' }}>
          <AppBar
            color="inherit"
            position="fixed"
            className={classes.navbar}
            style={
              screenSize.width < BREAKPOINT && navOpen
                ? { minHeight: '100vh' }
                : {
                    //border: '5px solid black',
                  }
            }
          >
            <div
              className={classes.nav}
              style={{
                flexDirection: screenSize.width < BREAKPOINT ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // alignItems: "flex-start",
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="h6" className={classes.title}>
                <Link to={HOME} onClick={closeOnClick}>
                  <img className={classes.logo} src={LOGO} alt="Nflyte" style={{height:'70px',width:'120px'}}/>
                  </Link>
                  {/* {screenSize.width > 500 && (
                    <Link to={HOME} onClick={closeOnClick}>
                      <span className={classes.n}>N</span>
                      <span className={classes.flyte}>Flyte</span>
                    </Link>
                  )} */}
                </Typography>
                {screenSize.width < BREAKPOINT && (
                  <p
                    className={classes.toggle}
                    onClick={() => {
                      setNavOpen(!navOpen);
                    }}
                  >
                    {navOpen ? <CloseIcon /> : <MenuIcon />}
                  </p>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection:
                    screenSize.width < BREAKPOINT ? 'column' : 'row',
                }}
              >
                {/* {navOpen && (
                  <>
                    {navbarRoutes.map(({ id, route, text }) => (
                      <React.Fragment key={id}>
                        <Link
                          style={
                            screenSize.width < BREAKPOINT
                              ? {
                                  fontSize: '1.2rem',
                                  padding: '0.5rem',
                                  textAlign: 'center',
                                  display: linkDelay ? 'flex' : 'none',
                                }
                              : {}
                          }
                          className={
                            path == route
                              ? classes.navlink_active
                              : classes.navlink
                          }
                          color="inherit"
                          to={route}
                          onClick={closeOnClick}
                        >
                          {text}
                        </Link>
                      </React.Fragment>
                    ))}
                  </>
                )} */}
                <React.Fragment>
                  <Link
                  style={screenSize.width < BREAKPOINT
                    ? {
                      fontSize: '1.2rem',
                      padding: '0.5rem',
                      textAlign: 'center',
                      display: linkDelay ? 'flex' : 'none',
                    }
                    : {}}
                  onClick={redirectHome} to={undefined}       
                  className={classes.navlink}           
                  >
                      Home
                  </Link>
                  <Link
                  style={screenSize.width < BREAKPOINT
                    ? {
                      fontSize: '1.2rem',
                      padding: '0.5rem',
                      textAlign: 'center',
                      display: linkDelay ? 'flex' : 'none',
                    }
                    : {}}
                  onClick={redirectAboute} to={undefined}       
                  className={classes.navlink}           
                  >
                      About
                  </Link>
                  <Link
                  style={screenSize.width < BREAKPOINT
                    ? {
                      fontSize: '1.2rem',
                      padding: '0.5rem',
                      textAlign: 'center',
                      display: linkDelay ? 'flex' : 'none',
                    }
                    : {}}
                  onClick={redirectSolutions} to={undefined}       
                  className={classes.navlink}           
                  >
                      Solutions
                  </Link>
                  <Link
                  style={screenSize.width < BREAKPOINT
                    ? {
                      fontSize: '1.2rem',
                      padding: '0.5rem',
                      textAlign: 'center',
                      display: linkDelay ? 'flex' : 'none',
                    }
                    : {}}
                  onClick={redirectResources} to={undefined}       
                  className={classes.navlink}           
                  >
                      Resources
                  </Link>
                  <Link
                  style={screenSize.width < BREAKPOINT
                    ? {
                      fontSize: '1.2rem',
                      padding: '0.5rem',
                      textAlign: 'center',
                      display: linkDelay ? 'flex' : 'none',
                    }
                    : {}}
                  onClick={redirectUrl} to={undefined}       
                  className={classes.navlink}           
                  >
                      Support
                  </Link>
                </React.Fragment>
              </div>
            </div>
          </AppBar>
        </ElevationScroll>
      </div>
    </>
  );
};
