import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { ADD_RECIPE_VIEW } from '../../constants/routes';
import { getRecipeById, setRecipe } from '../../slices/recipeSlice';
import { getrecipeTagsRefresh,setTags } from '../../slices/tagSlice';
import EnhancedAddOrEditRecipeForm from './EnhancedAddOrEditRecipeForm';
import * as REQUESTS from '../../api/recipeRequests';

interface IAddRecipesProps {}

const AddRecipes: React.FC<IAddRecipesProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { recipeId } = useParams<{ recipeId: string | undefined }>();
  const { recipe, recipeLoading } = useSelector((state: RootState) => {
    return {
      recipe: state.recipe.recipe,
      recipeLoading: state.recipe.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_VIEW ? true : false;

  const authlocal = localStorage.getItem('userRole');
 const user_id = localStorage.getItem('user_id');
 const urlRoute = localStorage.getItem('urlRoute');
 const urlId = localStorage.getItem('urlId');
 const userData = localStorage.getItem('userData');

  useEffect(() => {

    async function fetchMyAPI() {
      dispatch(setRecipe(null))
    if(user_id)
    {
      //await dispatch(getrecipeTagsRefresh(user_id));
      const tags = await REQUESTS.getRecipeTags(user_id);
        dispatch(setTags({ data: tags }));
     
    }
    // else
    // {
      // if (!isAddStudent && recipeId) {
      //   dispatch(getRecipeById(recipeId));
      // }
    //}
      
  }
  
  fetchMyAPI()
    return () => {};
  }, [isAddStudent, recipeId, dispatch]);
  let renderedForm;
  if (!isAddStudent && recipe) {
    const {
        recipeTitle,
        recipeUrl,
        recipeDescription,
        TypeOfRecipe, 
        tags,
        preparationTime,
        userId,
    } = recipe;
    renderedForm = (
      <EnhancedAddOrEditRecipeForm
      recipeTitle={recipeTitle}
      recipeUrl={recipeUrl}
      recipeDescription={recipeDescription}
      TypeOfRecipe={TypeOfRecipe}
      tags={tags}
      preparationTime={preparationTime}
      //userId={userId}
      
      />
    );
  } else {
    renderedForm = <EnhancedAddOrEditRecipeForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default AddRecipes;
