/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      id
      name
      imageUrl
      email
      about
      username
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
          user {
            id
          }
          chatRoom {
            id
            isGroup
            groupName
            groupAdminId
            groupAdmin {
              id
              email
            }
            createdAt
            updatedAt
            groupDescription
            groupImageUri
            lastMessage {
              id
              content
              isMedia
              mediaType
              fileName
              fileType
              user {
                id
                name
                username
                imageUrl
                email
                about
              }
              createdAt
            }
            chatRoomUsers {
              items {
                id
                userId
                chatRoomId

                user {
                  id
                  username
                  name
                  imageUrl
                  email
                  about
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        imageUrl
        username
        email

        about

        chatRoomUser {
          items {
            id
            userId
            chatRoomId
            createdAt
            updatedAt
            chatRoom {
              id
              isGroup
              groupName
              groupDescription
              groupImageUri
              groupAdminId
              groupAdmin {
                id
                email
              }
              lastMessage {
                id
                content
                isMedia
                mediaType
                fileName
                fileType
                user {
                  id
                  name
                  username
                  imageUrl
                  email
                  about
                }
                createdAt
              }
              chatRoomUsers {
                items {
                  user {
                    id
                    name
                    imageUrl
                    username
                    email
                    about
                  }
                }
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getChatRoomUser = /* GraphQL */ `
  query GetChatRoomUser($id: ID!) {
    getChatRoomUser(id: $id) {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        username
        email
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          email
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          isMedia
          mediaType
          length
          fileName
          fileType
          chatRoomId
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const listChatRoomUsers = /* GraphQL */ `
  query ListChatRoomUsers(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        chatRoomId

        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          groupAdminId
          groupAdmin {
            id
            email
          }
          lastMessageId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      groupAdmin {
        id
        email
      }
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
          chatRoom {
            isGroup
            id
            groupAdminId
            groupAdmin {
              id
              email
            }
            chatRoomUsers {
              items {
                user {
                  id
                  name
                  imageUrl
                  email
                  username
                  about
                }
              }
            }
          }
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }

        chatRoomUsers {
          items {
            id
            userId
            chatRoomId
            createdAt
            updatedAt
            chatRoom {
              isGroup
              id
              groupAdminId
              groupAdmin {
                id
                name
                imageUrl
                email
                username
                about
                createdAt
                updatedAt
              }
              chatRoomUsers {
                items {
                  user {
                    id
                    name
                    imageUrl
                    email
                    about
                  }
                }
              }
            }
          }
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByChatRoom = /* GraphQL */ `
  query MessagesByChatRoom(
    $chatRoomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatRoom(
      chatRoomId: $chatRoomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          lastMessage {
            id
            createdAt
            content
            userId
            chatRoomId
            isMedia
            mediaType
            length
            fileName
            fileType
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
