import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStepListForStudent,
    deleterecipeStep,
    getrecipeStepById,
    setrecipeSteps
} from '../../../slices/recipeStepsSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import { getChangePositionAPi } from '../../../api/recipeRequests';

import {
  addRecipeSteps,
  editRecipeStep
} from '../../../constants/routes';

import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
//import { USER_ROLE } from '../../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';
import { useParams } from 'react-router';


interface IStepsListProps {}

const useStyles = makeStyles(formStyles);

export const StepsList: React.FC<IStepsListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { recipeId } = useParams<{ recipeId: string }>();

  const { recipeSteps } = useSelector(
    (state: RootState) => {
      return {
        recipeSteps: state.recipeStep.recipeSteps,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(getStepListForStudent(recipeId));
    localStorage.setItem('urlRoute', "recipe_step_list");
    localStorage.setItem('urlId', recipeId);
    return () => {};
  }, []);//recipeSteps.length
  console.warn("list----->"+recipeSteps);
  let arrpos: number[]=[];
  for(let i=1;i<=recipeSteps.length;i++)
  {
    arrpos.push(i);
  }
  const [selectcount,setSelectCount]=useState(0);
  let renderedRecipeTags= arrpos.map((tag) => {
    return {
      value: tag,
      label: tag,
    };
  });

async function getChangePosition(pos:any,id:any)
{
  //console.log("chk id-->"+id)
  
  setSelectCount(pos);
  console.log("chk counts--->"+pos);
  const orgData_nxt = await getChangePositionAPi(id,pos);
    if(orgData_nxt)
    {
     
      dispatch(getStepListForStudent(recipeId));

    }
}

//drag ref
const dragItem=React.useRef<any>(null);
const dragOverItem=React.useRef<any>(null);
//handle sort
async function handleSort() {
   //duplicate items
   let _stepItems=[...recipeSteps]

   //remove and save the drag item content
   const dragItemContent=_stepItems.splice(dragItem.current,1)[0]
 
   //switch the position
   _stepItems.splice(dragOverItem.current,0,dragItemContent)
 
   //reset the position ref
   dragItem.current=null
   dragOverItem.current=null;
 
   //update the actual array
   //setSteparr(_stepItems)
   await dispatch(setrecipeSteps({data:_stepItems}))
   //getCheckLiast() 
}
// const handleSort=()=>{
 

// }

  // drag start
// const onDragEnd=(e:React.DragEvent<HTMLDivElement>,index:number)=>{
//   //console.log("drag end"+index)
//   }
async function getCheckLiast(e:any) {
  e.preventDefault()
 // 

}
const [rlist,setRlist]=useState([])
if(recipeSteps.length > 0)
{
  //for(let )
}
  let renderedStudents;
  if (recipeSteps.length > 0) {
    renderedStudents =
    recipeSteps.map((recipeStep, index) => {
            const { id, stepTitle, stepUrl, stepDescription, recipeId, step_position} = recipeStep;
            return (
              <StyledTableRow key={index} 
              style={{cursor:'move'}}
              draggable
               onDragStart={(e)=>dragItem.current=index}
               onDragEnter={(e)=>dragOverItem.current=index}
               onDragEnd={handleSort}
               onDragOver={(e)=>{getCheckLiast(e)}}
               
              >
                <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                          {stepUrl !== '' ? (
                              <a href={stepUrl} target='_blank'>
                                <img
                                  src={stepUrl}
                                  width="30" height="30"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                    </StyledTableCell>
                <StyledTableCell>{stepTitle}</StyledTableCell>
                <StyledTableCell>{stepDescription}</StyledTableCell>
                {/* <StyledTableCell>
                  <select className='form-control' onChange={(e)=>{getChangePosition(e.target.value,id)}} > 
                    <option value="Select Position"> -- Select Position -- </option>
                    {renderedRecipeTags.map((fruit) => <option value={fruit.value} selected={step_position===fruit.value?true:false}>{fruit.label}</option>)}
                  </select>
                 
                </StyledTableCell> */}
                
                <StyledTableCell>
                <IconButton
                    onClick={async (e) => {
                      await dispatch(getrecipeStepById(id));
                      history.push(editRecipeStep(id));
                    }} 
                  >
                    <EditIcon style={{ color: 'red' }} />
                    </IconButton>
                    <IconButton
                    onClick={async (e) => {
                      await dispatch(deleterecipeStep(id,recipeId,history));
                    }}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                </StyledTableCell>
                
              </StyledTableRow>
            );
          });
  } else if (recipeSteps.length === 0) {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Steps found.
           
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={2} xs={12}>
            {/* <Button
              variant='contained'
              color='primary'
              onClick={() => {
                  history.push(addRecipeSteps(recipeId));
              }}
              startIcon={<AddIcon style={{ alignItems: 'right' }}/>}
            >
              Add Step
            </Button> */}
            </Grid>
        </Grid>

      {/* <Typography variant='h6'>
        Recipes
      </Typography> */}
      <br />
     
     
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead> 
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              {/* <StyledTableCell>Change Position</StyledTableCell> */}
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedStudents}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default StepsList;