import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  createImage,
  deleteImage,
  getImagesForProgram,
  getImagesForProgramRefresh,
  setImageUrl,
} from '../../../slices/imageSlice';
import { Theme, createStyles, makeStyles, Typography } from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import allImageStyles from '../../../assets/jss/components/allImagesStyles';
import { Button } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import EnhancedAddImageForm from './EnhancedAddImageForm';
import { getQuestionsForProgramRefresh } from '../../../slices/questionSlice';

interface IAllImagesViewProps {}

const useStyles = makeStyles(allImageStyles);

export const AllImagesView: React.FC<IAllImagesViewProps> = ({}) => {
  const dispatch = useDispatch();
  const { imageLoading, images, image } = useSelector((state: RootState) => {
    return {
      image: state.image.image,
      images: state.image.images,
      imageLoading: state.image.loading,
    };
  }, shallowEqual);
  const classes = useStyles();

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  useEffect(() => {
    if(authlocal && user_id)
    {
      dispatch(getImagesForProgramRefresh(user_id));

    }
    else
    {
      dispatch(getImagesForProgram());
    }
    return () => {};
  }, []);
  let renderedImages;
  if (imageLoading && images.length === 0) {
    renderedImages = <div>Loading...</div>;
  }
  if (images.length > 0) {
    renderedImages = images.map((image) => {
      const { id, imageUrl, description } = image;
      return (
        <ImageListItem key={id}>
          <a href={imageUrl} target='_blank' download={false}>

            <img src={imageUrl} alt={description} />
            </a>
          <ImageListItemBar
            title={description}
            actionIcon={
              <IconButton
                aria-label={`info about ${description}`}
                className={classes.icon}
                onClick={(e) => {
                  dispatch(deleteImage(id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          />
         
        </ImageListItem>
      );
    });
  } else {
    renderedImages = <></>;
  }

  return (
    <div>
      <Typography variant="h6">Library Images</Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(setImageUrl(''));
          dispatch(createImage('', 'no-url'));
        }}
        startIcon={<AddIcon />}
      >
        Add Image
      </Button>
      <br />
      <br />
      {images.length > 0 && (

        <div className={classes.root}>
       
          <ImageList rowHeight={180} className={classes.imageList} cols={3}>
            {renderedImages}
          </ImageList>
          
        </div>
      )}
      {image ? <EnhancedAddImageForm image={image} /> : ''}
    </div>
  );
};
