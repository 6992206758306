import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { Grid, makeStyles } from '@material-ui/core';
import EnhancedAdditionalTaskDetailsForm from './EnhancedAdditionalTaskDetailsForm';
import EnhancedAddTaskDetailsForm from './EnhancedAddTaskDetailsForm';
import useMediaQuery from '@mui/material/useMediaQuery';

import GRAPHIC from '../../../assets/images/onboarding.svg';
import { resetTaskState } from '../../../slices/taskSlice';
import theme from '../../../app/muiTheme';
import { setRecipe, setRecipes } from '../../../slices/recipeSlice';
import { getTaskTypesForProgram, getTaskTypesForProgramRefresh, setTaskTypes } from '../../../slices/taskTypeSlice';
import * as REQUESTS from '../../../api/taskTypeRequests';


interface IAddTaskProps {}

const AddTask: React.FC<IAddTaskProps> = ({}) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { taskLoading, name, taskTypeId, start, end, taskLocation, step } =
    useSelector((state: RootState) => {
      return {
        taskLoading: state.task.loading,
        name: state.task.name,
        taskTypeId: state.task.taskTypeId,
        start: state.task.start,
        end: state.task.end,
        taskLocation: state.task.taskLocation,
        step: state.task.taskStep,
      };
    }, shallowEqual);
  useEffect(() => {
    return () => {};
  }, [step]);

  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');
  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
      dispatch(getTaskTypesForProgramRefresh(user_id));

      // const taskTypes = await REQUESTS.getTaskTypesForProgram(user_id);
      // dispatch(setTaskTypes({ data: taskTypes }));
    }
    else
    {
      dispatch(getTaskTypesForProgram());
    }
  }
    fetchMyAPI()
return () => {};
}, []);//recipes.length

  useEffect(() => {
    dispatch(setRecipe(null));
    dispatch(setRecipes({data:[]}));
    dispatch(resetTaskState());
  }, []);

  let renderedForm;
  switch (step) {
    case 0:
      renderedForm = (
        <EnhancedAddTaskDetailsForm name={name} taskTypeId={taskTypeId} />
      );
      break;
    case 1:
      renderedForm = (
        <EnhancedAdditionalTaskDetailsForm taskLocation={taskLocation} />
      );
      break;
  }
  return (
    <div>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            {renderedForm}
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ paddingTop: '10vh', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default AddTask;
