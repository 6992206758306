import { User } from '../constants/models/User';
import { PARENT_ROUTE, PROGRAM_ROUTE, STUDENT_ROUTE } from '../constants/routes';
import API from './api';

export async function getParentById(id: string) {
  try {
    const res = await API.get<{ data: User; success: boolean }>(
      `${PARENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getParentsList() {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PARENT_ROUTE}/getParentList`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function createParent(id:string,name: string, email: string, password: string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PARENT_ROUTE}`,
      {
        id,
        name,
        email,
        password
       
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getUserData(email: string, password: string) {
  try {
    const res = await API.post<{ data: string[]; success: boolean }>(
      `${PARENT_ROUTE}/getUserData`,
      {
        email,
        password
       
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getChildForParent(parentId: string) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${parentId}${STUDENT_ROUTE}/getStudentsForParentPanel`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export const getParentIngridiansList = async (id: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${PARENT_ROUTE}/${id}/ingridians-list`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};


export async function updateParent(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  // about: string,
  imageUrl: string | undefined
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PARENT_ROUTE}/${id}/updateParent`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        // about,
        imageUrl: imageUrl ?? 'no-url',
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getUserUpdateUserguid(
  id: string,
) { 
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PARENT_ROUTE}/getUserUpdateUserguid`,
      {
        id,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function sendMailToParent(name: string, email: string, password: string, subject: string, otpcode:number) {
  try {
    const res = await API.post<{ data: string; success: boolean }>(
      `${PARENT_ROUTE}/sendWelcomeMail`,
      {
        
        name,
        email,
        password,
        subject,
        otpcode
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateNewPassword(email: string, password: string,check_nxt: string) {
  try {
    let check;
    if(check_nxt==="forgot")
    {
      check=false;
    }
    else{
      check=true;
    }
    const res = await API.post<{ data: string; success: boolean }>(
      `${PARENT_ROUTE}/updateNewPasswrod`,
      {
        
        email,
        password,
        check
       
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function updateParentBySuperAdmin(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  // about: string,
  imageUrl: string | undefined
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PARENT_ROUTE}/${id}`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        // about,
        imageUrl: imageUrl ?? 'no-url',
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}