import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_STUDENT } from '../../../constants/routes';
import { getStudentById, getStudentByIdRefresh, setStudent } from '../../../slices/studentSlice';
import EnhancedEditStudentForm from './EnhancedEditStudentForm';
import EnhancedEditStudentFormValues from './EnhancedEditStudentForm';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as REQUESTS from '../../../api/studentRequests';


interface IEditStudentProps {}

const EditStudent: React.FC<IEditStudentProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { studentId } = useParams<{ studentId: string | undefined }>();
  const { student, studentLoading } = useSelector((state: RootState) => {
    return {
      student: state.student.student,
      studentLoading: state.student.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_STUDENT ? true : false;

  //console.log("check stud profile data--->"+JSON.stringify(student));
  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const urlRoute = localStorage.getItem('urlRoute');
  const urlId = localStorage.getItem('urlId');
  const userData = localStorage.getItem('userData');



  
    useEffect(() => {
      async function fetchMyAPI() {
        if(!student)
      {
        
        if (studentId) {
          localStorage.setItem('urlRoute', "user_tasks");
          localStorage.setItem('urlId', studentId);
          if(userData)
          {
            //setStudent(JSON.stringify(userData));
          }
         //await dispatch(getStudentByIdRefresh(studentId));
         const student_nxt = await REQUESTS.getStudentBydId(studentId);
         dispatch(setStudent(student_nxt));
          
        }
        else
        {
          if(urlId)
          {
           await dispatch(getStudentById(urlId));
          }
        }
      }
       else
      {
        

        if(urlId)
        {
          await dispatch(getStudentById(urlId));
        }

      }
     }
  
      fetchMyAPI()
      
      
      return () => {};
    }, []);
 
    if(student)
    {
      console.warn("grc title"+JSON.stringify(student));
      localStorage.setItem('userData', JSON.stringify(student));
    }

  console.warn("userdata-->"+student);

  let renderedForm;
  if (student) {
    localStorage.setItem('userData', JSON.stringify(student));

    const {
      name,
      email,
      schoolName,
      referenceCode,
      address,
      street,
      city,
      country,
      postalCode,
      about,
      diagnoses,
      studentGroups,

    } = student;
    console.warn("students------------------->"+name);

    renderedForm = (
      <EnhancedEditStudentForm
        name={name?name:''}
        email={email?email:''}
        address={address?address:''}
        street={street?street:''}
        city={city?city:''}
        country={country?country:''}
        postalCode={postalCode?postalCode:0}
        about={about?about:''}
        diagnoses={diagnoses?diagnoses:[]}
        counselors={studentGroups?studentGroups:[]}

      />
    );
  } else {
  renderedForm = <EnhancedEditStudentForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditStudent;
