import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { EditRecipeStepForm } from './EditRecipeStepForm';
import { updaterecipeStep } from '../../../slices/recipeStepsSlice';
import { useParams } from 'react-router';
import { RecipeStep } from '../../../constants/models/RecipeStep';
import { RootState } from '../../../app/rootReducer';


interface IDispatchProps {
    
  updaterecipeStep: (
    id: string | undefined,
    stepTitle: string,
    stepUrl: string,
    stepDescription: string,
    recipeId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedEditRecipeStepFormValues {
  stepTitle: string;
  stepUrl: string;
  stepDescription: string;
  recipeId: string;
}

export interface EnhancedEditRecipeStepFormProps extends RouteComponentProps {
  stepTitle?: string;
  stepUrl?: string;
  stepDescription?: string;
  recipeId?: string;
  recipeStep?:RecipeStep;
  updaterecipeStep: (
    id: string | undefined,
    stepTitle: string,
    stepUrl: string,
    stepDescription: string,
      recipeId: string,
      history: History
  ) => void;
}

const EnhancedEditRecipeStepForm = withFormik<
EnhancedEditRecipeStepFormProps,
EnhancedEditRecipeStepFormValues
>({
  mapPropsToValues: (props) => ({
    stepTitle: props.stepTitle ? props.stepTitle : '',
    stepUrl: props.stepUrl ? props.stepUrl : '',
    stepDescription: props.stepDescription ? props.stepDescription : '',
    recipeId: props.recipeId ? props.recipeId : '',
   
  }),
  validationSchema: Yup.object().shape({
    stepTitle: Yup.string().required(NAME_REQUIRED),
    stepUrl: Yup.string().notRequired(),
    stepDescription: Yup.string().required(DESCRIPTION_REQUIRED),
    recipeId: Yup.string().notRequired(),
    
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, updaterecipeStep, recipeStep } = props;
    const {
      stepTitle,
      stepUrl,
      stepDescription,
      recipeId,
    } = values;
    resetForm({}); 
    updaterecipeStep(
      recipeStep?.id,
      stepTitle,
      stepUrl,
      stepDescription,
        recipeId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditRecipeStepForm);

const EnhancedEditRecipeStepFormWithRouter = withRouter(
  EnhancedEditRecipeStepForm
);

const mapStateToProps = (state: RootState) => ({
  recipeStep: state.recipeStep.recipeStep,
});
export default connect(mapStateToProps, {
  updaterecipeStep,
  // @ts-ignore
})(EnhancedEditRecipeStepFormWithRouter);


