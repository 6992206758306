import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  setRecipeId,
  setStart,
  setEnd,
  setTaskLocation,
  createTasksForStudents,
} from '../../../slices/taskSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AdditionalTaskDetailsForm } from './AdditionalTaskDetailsForm';

interface IDispatchProps {
  setStart: ActionCreatorWithPayload<Date, string>;
  setEnd: ActionCreatorWithPayload<Date, string>;
  setTaskLocation: ActionCreatorWithPayload<string, string>;
  setRecipeId: ActionCreatorWithPayload<string, string>;
  createTasksForStudents: (history: History) => AppThunk;
}

export interface EnhancedAdditionalTaskDetailsFormValues {
  start: Date;
  end: Date;
  date: Date;
  taskLocation: string;
  recipeId: string;
  notiTime: string[];
}

export interface EnhancedAdditionalTaskDetailsFormProps
  extends RouteComponentProps {
  start?: Date;
  end?: Date;
  date?: Date;
  taskLocation?: string;
  recipeId?: string;
  notiTime?: string[];
  setStart: ActionCreatorWithPayload<Date, string>;
  setEnd: ActionCreatorWithPayload<Date, string>;
  setTaskLocation: ActionCreatorWithPayload<string, string>;
  setRecipeId: ActionCreatorWithPayload<string, string>;
  createTasksForStudents: (history: History) => void;
}

const EnhancedAdditionalTaskDetailsForm = withFormik<
  EnhancedAdditionalTaskDetailsFormProps,
  EnhancedAdditionalTaskDetailsFormValues
>({
  mapPropsToValues: (props) => ({
    start: props.start ? new Date(props.start) : new Date(),
    end: props.end
      ? new Date(props.end)
      : new Date(new Date().setHours(new Date().getHours() + 1)),
    date: props.date ? new Date(props.date) : new Date(),
    taskLocation: props.taskLocation ? props.taskLocation : '',
    recipeId: props.recipeId ? props.recipeId : '',
    notiTime: props.notiTime ? props.notiTime : [],
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const {
      setStart,
      setEnd,
      setTaskLocation,
      setRecipeId,
      createTasksForStudents,
      history,
    } = props;
    const { start, end, date, taskLocation, recipeId} = values;
    start.setDate(date.getDate());
    start.setFullYear(date.getFullYear());
    start.setMonth(date.getMonth());
    end.setDate(date.getDate());
    end.setFullYear(date.getFullYear());
    end.setMonth(date.getMonth());
    setStart(start);
    setEnd(end);
    setTaskLocation(taskLocation);
    setRecipeId(recipeId);
    createTasksForStudents(history);

    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AdditionalTaskDetailsForm);

const EnhancedAdditionalTaskDetailsFormWithRouter = withRouter(
  EnhancedAdditionalTaskDetailsForm
);
export default connect<null, IDispatchProps>(null, {
  setStart,
  setEnd,
  setTaskLocation,
  setRecipeId,
  createTasksForStudents,
})(EnhancedAdditionalTaskDetailsFormWithRouter);
