import store, { AppThunk } from '../app/store';
import { addRecipeImages,addRecipeIngredients, ADD_RECIPE } from './../constants/routes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/recipeRequests';
//import { recipe } from '../constants/models/recipe';
import { Recipe } from '../constants/models/Recipe';
import { History } from 'history';
import { USER_ROLE } from '../constants/userRoles';

export interface recipeState {
  loading: boolean;
  error: string | null;
  recipe: Recipe | null;
  recipes: Recipe[];
  recipeUrl: string[];
  tags: string[];
  total:number;
  page:number;
  size:number;
  isrecipeModalOpen: boolean;
}

export const initialState: recipeState = {
  loading: false,
  error: null,
  recipe: null,
  recipes: [],
  recipeUrl: [],
  tags: [],
  total:0,
  page:0,
  size:0,
  isrecipeModalOpen: false,
};

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    recipeStart(state) {
      state.loading = true;
      state.error = null;
    },
    setRecipe(state, action: PayloadAction<Recipe | null>) {
      state.recipe = action.payload;
    },
    setRecipes(state, action: PayloadAction<{ data: Recipe[] }>) {
      const { data } = action.payload;
      state.recipes = data.map((recipe) => recipe);
    },
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
    },
    setTags(state, action: PayloadAction<{ data: string[] }>) {
      const { data } = action.payload;
      state.tags = data.map((tag) => tag);
    },
    setIsRecipeModalOpen(state, action: PayloadAction<boolean>) {
      state.isrecipeModalOpen = action.payload;
    },
    setRecipeUrl(state, action: PayloadAction<{ data: string[] }>) {
      const { data } = action.payload;
      state.recipeUrl = data.map((recipeUrl) => recipeUrl);

    },
    recipeFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    recipeComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  recipeStart,
  setRecipe,
  setRecipes,
  setTotal,
  setPage,
  setSize,
  setTags,
  setIsRecipeModalOpen,
  setRecipeUrl,
  recipeFailure,
  recipeComplete,
} = recipeSlice.actions;

export default recipeSlice.reducer;

export const getFavoriteRecipesForUser =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id;
      // }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
      const data = await REQUESTS.getFavoriteRecipesForUser(studentId);
      dispatch(setRecipes({ data: data }));
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };


  export const getFavoriteRecipesForUserRefresh =
  (studentId: string,program_pass_id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      let program_de_id=program_pass_id;
     
      const data = await REQUESTS.getFavoriteRecipesForUser(studentId);
      dispatch(setRecipes({ data: data }));
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getRecipeByIdRefresh =
  (recipeId: string,program_pass_id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      let program_de_id=program_pass_id;
     
      const recipe = await REQUESTS.getRecipeById(recipeId,program_de_id);
      dispatch(setRecipe(recipe));
      //localStorage.setItem('userData', JSON.stringify(recipe));

      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getRecipeById =
  (recipeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      const recipe = await REQUESTS.getRecipeById(recipeId,program_de_id);
      dispatch(setRecipe(recipe));
     // window.localStorage.setItem('userData',JSON.stringify(recipe))
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getRecipesAll = (): AppThunk => async (dispatch) => {
    try {
      dispatch(recipeStart());
     
        const recipes = await REQUESTS.getRecipesAll();
        dispatch(setRecipes({ data: recipes }));
        
      
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  
  export const getRecipesBySearch = (
    recipeTitle: string,
    TypeOfRecipe: string,
    page: number,
    size: number,
  ): AppThunk => async (dispatch) => {
    try {

      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      //   else if(program?.id)
      //   {
      //     program_de_id=program.id;
      //   }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
       // console.log("program id ---> "+program_de_id);
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const recipes = await REQUESTS.getRecipesBySearch(programId,recipeTitle,TypeOfRecipe,page,size);
        console.warn("page count first-------->"+JSON.stringify(recipes))

        dispatch(setRecipes({ data: recipes.data.data }));
        dispatch(setTotal(recipes.data.total));
        dispatch(setPage(recipes.data.page));
        dispatch(setSize(recipes.data.size));

      }
      dispatch(recipeComplete()); 
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getRecipesForProgram = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,
  studentId:string,

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
    // const { program } = store.getState().program;
    // const { parent } = store.getState().parent;
    // const { counselor } = store.getState().counselor;
    const { role } = store.getState().auth;
    // const { programManager } = store.getState().programManager;
    // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id;
      // }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }

      //console.warn("program id ---> "+programManager?.role);

      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size,studentId);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      dispatch(setRecipes({ data: recipes.data.data }));
      dispatch(setTotal(recipes.data.total));
      dispatch(setPage(recipes.data.page)); 
      dispatch(setSize(recipes.data.size));
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForProgramRefresh = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,
  studentId:string,
  program_passId:string,

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    const { counselor } = store.getState().counselor;
    const { role } = store.getState().auth;
    const { programManager } = store.getState().programManager;
    let program_de_id=program_passId;
      
      //console.warn("program id ---> "+programManager?.role);

      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size,studentId);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      dispatch(setRecipes({ data: recipes.data.data }));
      dispatch(setTotal(recipes.data.total));
      dispatch(setPage(recipes.data.page)); 
      dispatch(setSize(recipes.data.size));
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForProgramWithoutUser = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
    // const { program } = store.getState().program;
    // const { parent } = store.getState().parent;
    // const { counselor } = store.getState().counselor;
    const { role } = store.getState().auth;
    // const { programManager } = store.getState().programManager;
    // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id;
      // }
      
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
      //console.warn("program id ---> "+programManager?.role);

      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForProgramWithoutUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      if(recipes)  
      {
        dispatch(setRecipes({ data: recipes.data.data }));
        dispatch(setTotal(recipes.data.total));
        dispatch(setPage(recipes.data.page)); 
        dispatch(setSize(recipes.data.size));
      }
     
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForProgramWithoutUserRefresh = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,
  programId:string

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
   
      //console.warn("program id ---> "+programManager?.role);
    let program_de_id=programId;
      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForProgramWithoutUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      if(recipes)  
      {
        dispatch(setRecipes({ data: recipes.data.data }));
        dispatch(setTotal(recipes.data.total));
        dispatch(setPage(recipes.data.page)); 
        dispatch(setSize(recipes.data.size));
      }
     
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForWebUser = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
    // const { program } = store.getState().program;
    // const { parent } = store.getState().parent;
    // const { counselor } = store.getState().counselor;
    const { role } = store.getState().auth;
    // const { programManager } = store.getState().programManager;
    // const { superAdmin } = store.getState().superAdmin;

    let program_de_id="";
    // if(superAdmin?.id)
    // {
    //   program_de_id=superAdmin.id;
    // } 
    // else if(parent?.id)
    // {
    //     program_de_id=parent.id;
    //   }
    //   else if(programManager?.id)
    //   {
    //     program_de_id=programManager.id;
    //   }
    //   else if(counselor?.id)
    //   {
    //     program_de_id=counselor.id;
    //   }
    //   else if(program?.id)
    //   {
    //     program_de_id=program.id;
    //   }
    const user_id = localStorage.getItem('user_id');
    if(user_id)
    {
      program_de_id=user_id;
    }

      //console.warn("program id ---> "+programManager?.role);

      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      if(recipes)  
      {
        dispatch(setRecipes({ data: recipes.data.data }));
        dispatch(setTotal(recipes.data.total));
        dispatch(setPage(recipes.data.page)); 
        dispatch(setSize(recipes.data.size));
      }
     
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForWebUserRefresh = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  page: number,
  size: number,
  program_pass_id: string,
  

  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    const { counselor } = store.getState().counselor;
    const { role } = store.getState().auth;
    const { programManager } = store.getState().programManager;
    let program_de_id=program_pass_id;
     

      //console.warn("program id ---> "+programManager?.role);

      let recipes;
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      // if(role===USER_ROLE.PROGRAM_MANAGER)
      // {
         recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else if(role===USER_ROLE.PARENT)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }else if(role===USER_ROLE.COUNSELOR)
      // {
      //    recipes = await REQUESTS.getRecipesForWebUser(programId,rrecipeTitle,rtypeOfRecipe,page,size);

      // }
      // else
      // {
      //    recipes = await REQUESTS.getRecipesForProgram(programId,rrecipeTitle,rtypeOfRecipe,page,size);
      // }
        //dispatch(setRecipes({ data: recipes }));
      if(recipes)  
      {
        dispatch(setRecipes({ data: recipes.data.data }));
        dispatch(setTotal(recipes.data.total));
        dispatch(setPage(recipes.data.page)); 
        dispatch(setSize(recipes.data.size));
      }
     
      
    }
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getRecipesForTask = (
  rrecipeTitle:string,
  rtypeOfRecipe:string,
  recipeTagType:string,
 
  ): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStart());
  
      let recipes;
     
         recipes = await REQUESTS.getRecipesForTask(rrecipeTitle,rtypeOfRecipe,recipeTagType);

      dispatch(setRecipes({ data: recipes.data.data }));
    
    dispatch(recipeComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createRecipe =
  ( 
    recipeUrl:string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    userId:string,
    history: History
   ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;
      // console.warn("recipe tags--------->"+tags)

       let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //     program_de_id=parent.id;
      //   }
      //   else if(counselor?.id)
      //   {
      //     program_de_id=counselor.id;
      //   }
      //   else if(programManager?.id)
      //   {
      //     program_de_id=programManager.id;
      //   }
      //   else if(program?.id)
      //   {
      //     program_de_id=program.id;
      //   }
      const user_id = localStorage.getItem('user_id');
      console.log("chk user-->"+user_id)
      if(user_id)
      {
        program_de_id=user_id;
      }
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const recipe = await REQUESTS.createRecipe(
           recipeTitle,
          recipeUrl,
          recipeDescription,
          TypeOfRecipe,
          tags,
          preparationTime,
          programId
        );
       // dispatch(setRecipe(recipe));
        dispatch(setSuccessMsg(`Recipe added successfully..!`));
        dispatch(getRecipeById(recipe.id));
          
      }
      //dispatch(getRecipesForProgram());
      //dispatch(setRecipeUrl([]);
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updaterecipe =
  (
    id: string,
    recipeUrl: string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    programId: string,
    history: History
): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //     program_de_id=parent.id;
      //   }
      //   else if(counselor?.id)
      //   {
      //     program_de_id=counselor.id;
      //   }
      //   else if(programManager?.id)
      //   {
      //     program_de_id=programManager.id;
      //   }
      //   else if(program?.id)
      //   {
      //     program_de_id=program.id;
      //   }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
      console.warn("recipe tags--------->"+tags)
        if (program_de_id) {
          let programId = program_de_id;//program.id;
         let rdata= await REQUESTS.updateRecipe(
            id,
            recipeUrl,
            recipeTitle,
            recipeDescription,
            TypeOfRecipe,
            preparationTime,
            programId,
            tags,
          );
          if(rdata){
            window.localStorage.setItem('userData',JSON.stringify(rdata))
          }

          //dispatch(setRecipe(rdata));
          dispatch(setSuccessMsg(`Recipe updated successfully..!`));
          dispatch(getRecipeById(id));

         // history.push(ADD_RECIPE);
        }
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteRecipe =
  (id: string,rrecipeTitle:string,rtypeOfRecipe:string, page: number,size: number): AppThunk =>
  async (dispatch) => {
    try {
      let studid="";
      dispatch(recipeStart());
      await REQUESTS.deleteRecipe(id);
      dispatch(getRecipesForWebUser(rrecipeTitle,rtypeOfRecipe,page,size));
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const addRecipeToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      console.warn(formData);

      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //     program_de_id=parent.id;
      //   }
      //   else if(counselor?.id)
      //   {
      //     program_de_id=counselor.id;
      //   }
      //   else if(programManager?.id)
      //   {
      //     program_de_id=programManager.id;
      //   }
      //   else if(program?.id)
      //   {
      //     program_de_id=program.id;
      //   }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) 
        {
          formData.append('programId', program_de_id);
          let data = await REQUESTS.addRecipeToS3(formData);
            
          //dispatch(setRecipeUrl(data));
        } 
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const addRecipeImagesToS3 =
  (formData: FormData,recipeId:string,files:any[]): AppThunk =>
  async (dispatch) => {
    try {
      console.warn(formData);

      dispatch(recipeStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //     program_de_id=parent.id;
      //   }
      //   else if(counselor?.id)
      //   {
      //     program_de_id=counselor.id;
      //   }
      //   else if(programManager?.id)
      //   {
      //     program_de_id=programManager.id;
      //   }
      //   else if(program?.id)
      //   {
      //     program_de_id=program.id;
      //   }
      const user_id = localStorage.getItem('user_id');
      if(user_id)
      {
        program_de_id=user_id;
      }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) 
        {
          formData.append('programId', program_de_id);
          //let data = await REQUESTS.addRecipeImagesToS3(formData);
          let data = await REQUESTS.addRecipeImagesToS3(formData);
            
        } 
        dispatch(getRecipeById(recipeId));

      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  
  export const deleteRecipeImage =
  (id: string,recipeId:string): AppThunk =>
  async (dispatch) => {
    try {
      let studid="";
      dispatch(recipeStart());
      await REQUESTS.deleteRecipeImage(id,recipeId);
      dispatch(getRecipeById(recipeId));
      dispatch(recipeComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };