import React, { useState } from 'react';
import { Send } from '@material-ui/icons';
import {
  RootStateOrAny,
  useSelector,
  useDispatch,
  shallowEqual,
} from 'react-redux';
import { sendMessage } from '../../slices/chatRoomSlice';
import formStyles from '../../assets/jss/components/formStyles';
import { RootState } from '../../app/rootReducer';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(formStyles);
interface ChatInputProps {
  chatRoomId: string;
  user: any;
  disabled: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  chatRoomId,
  user,
  disabled,
}) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  
  const {
    auth: { graphQlUser: currentUser }, 
  } = useSelector((state: RootStateOrAny) => {
    return {
      auth: state.auth,
    };
  }, shallowEqual);

  const { inputUser,chkChatRoomUsers } = useSelector(
    (state: RootState) => {
      return {
        inputUser: state.chatRoom.user,
        chkChatRoomUsers:state.chatRoom.groupUsers
      };
    },
    shallowEqual
  );

  const dispatch = useDispatch();
//  let chkChatusers;
//  if(chkChatRoomUsers)
//  {
//   chkChatusers.push(chkChatRoomUsers);
//  }

// chkChatusers.push(chkChatRoomUsers);

  const onMessageSend = () => {
    // if (Platform.OS === 'android')
    //   // @ts-ignore
    //   emojiViewAndroid.current.collapse();
    // if (Platform.OS === 'ios')
    //   // @ts-ignore
    //   emojiViewIos.current.collapse();
    if (text.trim().length !== 0) {
      dispatch(
        sendMessage(chkChatRoomUsers, inputUser, currentUser, chatRoomId, text.trim(), {
          isMedia: false,
          mediaType: null,
          length: null,
          // isLink: isValidUrl(text.trim()),
        })
      );
      setText('');
      // }
    }
    // dispatch(setIsReply(false));
    // dispatch(setReplyMessage(null));
    setText('');
  };
  const detectEnterPress = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onMessageSend();
    }
  };
  return (
    <div className='chat__input-wrapper'>
      {/* <input
        className="chat__input"
        placeholder="Type a message"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={detectEnterPress}
      /> */}
      <TextField
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={detectEnterPress}
        style={{
          width: '100%',
          background: 'rgba(255,255,255,1)',
          borderRadius: '8px',
        }}
        // inputProps={{
        // style:{

        // }
        // }}
        variant='outlined'
        size='small'
        InputLabelProps={{
          classes: {
            root: classes.heading,
            focused: classes.cssFocused,
          },
        }}
        disabled={disabled}
        placeholder='Type a message'
      />

      {/* <button aria-label="Send message" onClick={onMessageSend}> */}
      {/* <Icon id="send" className="chat__input-icon" />
       */}
      <Send
        className='chat__input-icon'
        onClick={disabled ? () => {} : onMessageSend}
        style={{ color: disabled ? 'gray' : '#2C7AA4' }}
      />
      {/* </button> */}
    </div>
  );
};

export default ChatInput;
