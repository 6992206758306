import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createEvent } from '../../../slices/eventSlice';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { USER_ROLE } from '../../../constants/userRoles';
import { EVENT_TYPE } from '../../../constants/eventType';
import { INVALID_MIN_DATE } from '../../../constants/formMessages';
import { AddOrEditEventForm } from './AddOrEditEventForm';

interface IDispatchProps {
  createEvent: (
    name: string,
    description: string,
    eventType: EVENT_TYPE,
    start: Date,
    end: Date,
    history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditEventFormValues {
  name: string;
  description: string;
  eventType: EVENT_TYPE;
  start: Date;
  end: Date;
}

export interface EnhancedAddOrEventFormProps extends RouteComponentProps {
  name?: string;
  description?: string;
  eventType?: EVENT_TYPE;
  start?: Date;
  end?: Date;
  createEvent: (
    name: string,
    description: string,
    eventType: EVENT_TYPE,
    start: Date,
    end: Date,
    history: History
  ) => void;
}
const getNextHalfHour = (date: Date): Date => {
  let minutes = date.getMinutes();
  let nextHalfHour;
  if (minutes >= 30) {
    nextHalfHour = +date + (60 - minutes) * 60 * 1000;
  } else {
    nextHalfHour = +date + (30 - minutes) * 60 * 1000;
  }
  return new Date(nextHalfHour);
};

const EnhancedAddOrEditEventForm = withFormik<
  EnhancedAddOrEventFormProps,
  EnhancedAddOrEditEventFormValues
>({
  mapPropsToValues: (props) => ({
    name: props.name ? props.name : '',
    description: props.description ? props.description : '',
    eventType: props.eventType ? props.eventType : EVENT_TYPE.RESIDENCE_EVENT,
    start: props.start ? props.start : new Date(),
    end: props.end ? props.end : new Date(),
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    //start: Yup.date().min(getNextHalfHour(new Date()), INVALID_MIN_DATE),
    //end: Yup.date().min(getNextHalfHour(new Date()), INVALID_MIN_DATE),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, createEvent } = props;
    const { name, description, eventType, start, end } = values;
    createEvent(name, description, eventType, start, end, history);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditEventForm);

const EnhancedAddOrEditEventFormWithRouter = withRouter(
  EnhancedAddOrEditEventForm
);

export default connect<null, IDispatchProps>(null, { createEvent })(
  EnhancedAddOrEditEventFormWithRouter
);
