import React, { ReactNode } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../app/rootReducer';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface ILoadingProps {
  children: ReactNode;
}
export const Loading: React.FC<ILoadingProps> = ({ children }) => {
  const classes = useStyles();
  const { authLoading, authLongLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
      authLongLoading: state.auth.longLoading,
    };
  }, shallowEqual);
  return (
    <>
      <Backdrop className={classes.backdrop} open={authLoading}>
        <Box position='relative' display='inline-flex'>
          <CircularProgress size={100} />
        </Box>
      </Backdrop>
      {children}
    </>
  );
};
