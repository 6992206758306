import { VIEW_CALENDAR } from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg } from './alertSlice';
import * as REQUESTS from '../api/eventRequests';
import { History } from 'history';
import { EVENT_TYPE } from '../constants/eventType';
import { USER_ROLE } from '../constants/userRoles';
import { EventModel } from '../constants/models/EventModel';

export interface eventState {
  loading: boolean;
  error: string | null;
  event: EventModel | null;
  events: EventModel[];
  staffEvents: EventModel[];
  residentEvents: EventModel[];
}

export const initialState: eventState = {
  loading: false,
  error: null,
  event: null,
  events: [],
  staffEvents: [],
  residentEvents: [],
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    eventStart(state) {
      state.loading = true;
      state.error = null;
    },
    setEvent(state, action: PayloadAction<EventModel | null>) {
      state.event = action.payload;
    },
    setEvents(state, action: PayloadAction<{ data: EventModel[] }>) {
      const { data } = action.payload;
      state.events = data.map((event) => event);
    },
    setStaffEvents(state, action: PayloadAction<{ data: EventModel[] }>) {
      const { data } = action.payload;
      state.staffEvents = data.map((event) => event);
    },
    setResidentEvents(state, action: PayloadAction<{ data: EventModel[] }>) {
      const { data } = action.payload;
      state.residentEvents = data.map((event) => event);
    },

    eventFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    eventComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  eventStart,
  setEvent,
  setEvents,
  setStaffEvents,
  setResidentEvents,
  eventFailure,
  eventComplete,
} = eventSlice.actions;

export default eventSlice.reducer;

export const getEventById =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(eventStart());
      const event = await REQUESTS.getEventById(eventId);
      dispatch(setEvent(event));
      dispatch(eventComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(eventFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getEventsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(eventStart());
    const { program } = store.getState().program;
    if (program && program.id) {
      const events = await REQUESTS.getEventsForProgram(program.id);
      dispatch(setEvents({ data: events }));
    }
    dispatch(eventComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(eventFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createEvent =
  (
    title: string,
    description: string,
    eventType: EVENT_TYPE,
    start: Date,
    end: Date,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(eventStart());
      const { program } = store.getState().program;
      const { role } = store.getState().auth;

      let userId = '';

      if (program && program.id) {
        if (
          role === USER_ROLE.PROGRAM_MANAGER &&
          store.getState().programManager.programManager
        ) {
          userId = store.getState().programManager.programManager!.id;
        } else if (
          role === USER_ROLE.COUNSELOR &&
          store.getState().counselor.counselor
        ) {
          userId = store.getState().counselor.counselor!.id;
        }
        const event = await REQUESTS.createEvent(
          title,
          description,
          eventType,
          start,
          end,
          userId,
          program.id
        );
        dispatch(setEvent(event));
      }
      history.push(VIEW_CALENDAR);
      dispatch(eventComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(eventFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteEvent =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(eventStart());
      await REQUESTS.deleteEvent(id);
      dispatch(eventComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(eventFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getEventsForResidents = (): AppThunk => async (dispatch) => {
  try {
    dispatch(eventStart());
    const { program } = store.getState().program;
    if (program && program.id) {
      const events = await REQUESTS.getEventsForResidents(program.id);
      dispatch(setResidentEvents({ data: events }));
    }
    dispatch(eventComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(eventFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getEventsForStaff = (): AppThunk => async (dispatch) => {
  try {
    dispatch(eventStart());
    const { program } = store.getState().program;
    if (program && program.id) {
      const events = await REQUESTS.getEventsForStaff(program.id);
      dispatch(setStaffEvents({ data: events }));
    }
    dispatch(eventComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(eventFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
