import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import viewStudentDetailsStyles from '../../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById } from '../../../../slices/studentSlice';
import { editGroceryListById, editStudent, viewStudentGroceryList } from '../../../../constants/routes';
import { Ingredient } from '../../../../constants/models/Ingredient';
import EnhancedEditGroceryListForm from './EnhancedEditGroceryListForm';
import IngidiansList from '../../../IngridiansList/IngidiansList';
import { getGroceryList, getGroceryListRefresh, setGroceryList } from '../../../../slices/grocerySlice';

interface IEditGroceryListProps {}

const EditGroceryList: React.FC<IEditGroceryListProps> = ({}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

  const { groceryId } = useParams<{ groceryId: string | undefined }>();
  const { groceryList } = useSelector((state: RootState) => {
    return {
      groceryList: state.groceryLists.groceryList,
    };
  }, shallowEqual);

  if (groceryId) {
    localStorage.setItem('urlRoute', "edit_grocery");
    localStorage.setItem('urlId', groceryId);
  }
  const isAddStudent = location.pathname === viewStudentGroceryList ? true : false;
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');
  
  useEffect(() => {
    if(user_id)
    {
      if (groceryId) {
        dispatch(getGroceryListRefresh(user_id,groceryId,history));
      }
    }
    else
    {
      if (!isAddStudent && groceryId) {
        dispatch(getGroceryList(groceryId,history));
      }
    }
    
    return () => {};
  }, []);//[isAddStudent, groceryId, dispatch]

  
  // if(user_id)
  //   {
  //     dispatch(setGroceryList(groceryList));
  //   }
  if(groceryList)
  {
    console.warn("grc title"+JSON.stringify(groceryList));
    localStorage.setItem('userData', JSON.stringify(groceryList));
  }
  
  let renderedForm;
  // if (!isAddStudent && groceryList) {
   
  

  //   const {
  //     title,
  //     description,
  //       groceryImage,
  //   } = groceryList;
  //   renderedForm = (
  //     <EnhancedEditGroceryListForm 
  //     title={title}
  //     description={description}
  //     groceryImage={groceryImage}      
      
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedEditGroceryListForm />;
 // }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
        {/* <IngidiansList /> */}

      </Container>
    </div>
  );
};
export default EditGroceryList;
