import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { CalendarComponent } from '../../../components/CalendarComponent';
import {
  getEventsForResidents,
  getEventsForStaff,
} from '../../../slices/eventSlice';
interface IViewCalendarProps {}

const ViewCalendar: React.FC<IViewCalendarProps> = ({}) => {
  const [value, setValue] = useState('one');
  const dispatch = useDispatch();

  const { eventLoading, staffEvents, residentEvents } = useSelector(
    (state: RootState) => {
      return {
        eventLoading: state.event.loading,
        staffEvents: state.event.staffEvents,
        residentEvents: state.event.residentEvents,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEventsForStaff());
    dispatch(getEventsForResidents());

    return () => {};
  }, [staffEvents.length, residentEvents.length]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="calendar"
        >
          <Tab value="one" label="Staff Calendar" />
        </Tabs>
        <TabPanel value="one">
          <CalendarComponent events={staffEvents} />
        </TabPanel>
        {/* <TabPanel value="two">
          <CalendarComponent events={residentEvents} />
        </TabPanel> */}
      </TabContext>
    </Box>
  );
};
//<Tab value="two" label="Residence Calendar" />

export default ViewCalendar;
