import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  Quantity_IS_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
  Unit_IS_REQUIRED,
} from '../../constants/formMessages';
import { AddOrEditRecipeIngredientForm } from './AddOrEditRecipeIngredientForm';
import { createrecipeIngredient } from '../../slices/recipeingredientSlice';
import { useParams } from 'react-router';


interface IDispatchProps {
    
    createrecipeIngredient: (
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditRecipeIngredientFormValues {
    recipeingredientUrl: string;
    ingredientTitle: string;
    quantity: string;
    unit: string,
    unitType: string,
    recipeId: string;
}

export interface EnhancedAddOrEditRecipeIngredientFormProps extends RouteComponentProps {
    recipeingredientUrl?: string;
    ingredientTitle?: string;
    quantity?: string;
    unit?: string;
    unitType?: string;
    recipeId?: string;
    createrecipeIngredient: (
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId: string,
     history: History
  ) => void;
}

const EnhancedAddOrEditRecipeIngredientForm = withFormik<
  EnhancedAddOrEditRecipeIngredientFormProps,
  EnhancedAddOrEditRecipeIngredientFormValues
>({
  mapPropsToValues: (props) => ({
    recipeingredientUrl: props.recipeingredientUrl ? props.recipeingredientUrl : '',
    ingredientTitle: props.ingredientTitle ? props.ingredientTitle : '',
    quantity: props.quantity ? props.quantity : '',
    unit: props.unit ? props.unit : '',
    unitType: props.unitType ? props.unitType : '',
    recipeId: props.recipeId ? props.recipeId : '',
   
  }),
  validationSchema: Yup.object().shape({
    ingredientTitle: Yup.string().required(NAME_REQUIRED),
    recipeingredientUrl: Yup.string().notRequired(),
    quantity: Yup.string().required(Quantity_IS_REQUIRED),
    unit: Yup.string().required(Unit_IS_REQUIRED),
    unitType: Yup.string().notRequired(),
    recipeId: Yup.string().notRequired(),
    // city: Yup.string().notRequired(),
    // country: Yup.string().notRequired(),
    // postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createrecipeIngredient } = props;
    const {
        ingredientTitle,
        recipeingredientUrl,
        quantity,
        unit,
        unitType,
        recipeId
    } = values;
    resetForm({});

    createrecipeIngredient(
        ingredientTitle,
        recipeingredientUrl,
        quantity,
        unit,
        unitType,
        recipeId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditRecipeIngredientForm);

const EnhancedAddOrEditRecipeIngredientFormWithRouter = withRouter(
    EnhancedAddOrEditRecipeIngredientForm
);

export default connect<null, IDispatchProps>(null, {
    createrecipeIngredient,
})(EnhancedAddOrEditRecipeIngredientFormWithRouter);
