import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_RECIPE_STEP_VIEW } from '../../../constants/routes';
import { getrecipeStepById } from '../../../slices/recipeStepsSlice';
import EnhancedAddOrEditRecipeStepForm from './EnhancedAddOrEditRecipeStepForm';
import StepsList from './StepsList';

interface IAddRecipesProps {}

const AddRecipeStep: React.FC<IAddRecipesProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { recipeStepId } = useParams<{ recipeStepId: string | undefined }>();
  const { recipeStep } = useSelector((state: RootState) => {
    return {
      recipeStep: state.recipeStep.recipeStep,
      recipeStepLoading: state.recipeStep.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_STEP_VIEW ? true : false;
  useEffect(() => {
    if (!isAddStudent && recipeStepId) {
      dispatch(getrecipeStepById(recipeStepId));
    }
    return () => {};
  }, [isAddStudent, recipeStepId, dispatch]);
  let renderedForm;
  // if (!isAddStudent && recipeStep) {
  //   const {
  //     stepTitle,
  //     stepDescription,
  //     stepUrl,
  //       recipeId,
  //   } = recipeStep;
  //   renderedForm = (
  //     <EnhancedAddOrEditRecipeStepForm
  //     stepTitle={stepTitle}
  //     stepDescription={stepDescription}
  //     stepUrl={stepUrl}
  //     recipeId={recipeId}
      
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedAddOrEditRecipeStepForm />;
 // }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
        <StepsList />
      </Container>
    </div>
  );
};
export default AddRecipeStep;
