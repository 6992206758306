import { Question } from './../constants/models/Question';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg } from './alertSlice';
import * as REQUESTS from '../api/questionRequests';

export interface questionState {
  loading: boolean;
  error: string | null;
  question: Question | null;
  questions: Question[];
  isModalOpen: boolean;
}

export const initialState: questionState = {
  loading: false,
  error: null,
  question: null,
  questions: [],
  isModalOpen: false,
};

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    questionStart(state) {
      state.loading = true;
      state.error = null;
    },
    setQuestion(state, action: PayloadAction<Question | null>) {
      state.question = action.payload;
    },
    setQuestions(state, action: PayloadAction<{ data: Question[] }>) {
      const { data } = action.payload;
      state.questions = data.map((question) => question);
    },
    setIsModalOpen(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
    },
    questionFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    questionComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  questionStart,
  setQuestion,
  setQuestions,
  setIsModalOpen,
  questionFailure,
  questionComplete,
} = questionSlice.actions;

export default questionSlice.reducer;
export const getQuestionById =
  (questionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionStart());
      const data = await REQUESTS.getQuestionById(questionId);
      dispatch(setQuestion(data));
      dispatch(questionComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(questionFailure(data));
      }
    }
  };

export const getQuestionsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(questionStart());
    // const { program } = store.getState().program;
    //   const { parent } = store.getState().parent;
    //   const { counselor } = store.getState().counselor;
    //   const { programManager } = store.getState().programManager;
    // console.log("check auth-->"+JSON.stringify(parent)); 
    // const { superAdmin } = store.getState().superAdmin;

    const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

      let program_de_id=user_id;
    // if(superAdmin?.id)
    // {
    //   program_de_id=superAdmin.id;
    // } 
    // else if(parent?.id)
    // {
    //     program_de_id=parent.id;
    //   }
    //   else if(counselor?.id)
    //   {
    //     program_de_id=counselor.id;
    //   }
    //   else if(programManager?.id)
    //   {
    //     program_de_id=programManager.id;
    //   }
    //   else if(program?.id)
    //   {
    //     program_de_id=program.id; 
    //   }
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const data = await REQUESTS.getQuestionsForProgram(programId);
      dispatch(setQuestions({ data }));
    }
    dispatch(questionComplete());
  } catch (error: any) {
    if (error.response) {
      const { data, status } = error.response;
      dispatch(questionFailure(data));
    }
  }
};
export const getQuestionsForProgramRefresh = (user_id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(questionStart());
    
       let program_de_id=user_id; 
      
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const data = await REQUESTS.getQuestionsForProgram(programId);
      dispatch(setQuestions({ data }));
    }
    dispatch(questionComplete());
  } catch (error: any) {
    if (error.response) {
      const { data, status } = error.response;
      dispatch(questionFailure(data));
    }
  }
};
export const createQuestion =
  (questionText: string, answer: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;
      const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

      let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id; 
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await REQUESTS.createQuestion(
          questionText,
          answer,
          programId
        );
        dispatch(setQuestion(data));
        dispatch(setIsModalOpen(true));
      }
      dispatch(questionComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(questionFailure(data));
      }
    }
  };

export const updateQuestion =
  (questionId: string, questionText: string, answer: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionStart());
      await REQUESTS.updateQuestion(questionId, questionText, answer);
      dispatch(getQuestionsForProgram());
      dispatch(questionComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(questionFailure(data));
      }
    }
  };

export const deleteQuestion =
  (questionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionStart());
      await REQUESTS.deleteQuestion(questionId);
      dispatch(getQuestionsForProgram());
      dispatch(questionComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(questionFailure(data));
      }
    }
  };
