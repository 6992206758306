import store, { AppThunk } from "./../app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as REQUESTS from "../api/imageRequests";
import { setErrorMsg } from "./alertSlice";
import { addLibraryImageToS3 } from "../api/imageRequests";
import Resizer from "react-image-file-resizer";
import { setImageUrl } from "./taskSlice";

export interface commonState {
  loading: boolean;
  error: string | null;
  commonUrl: string;
  iscommonModalOpen: boolean;
}

export const initialState: commonState = {
  loading: false,
  error: null,
  commonUrl: "",
  iscommonModalOpen: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    commonStart(state) {
      state.loading = true;
      state.error = null;
    },
    setIscommonModalOpen(state, action: PayloadAction<boolean>) {
      state.iscommonModalOpen = action.payload;
    },
    setcommonUrl(state, action: PayloadAction<string>) {
      state.commonUrl = action.payload;
    },
    commonFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : "some error";
    },
    commonComplete(state) {
      state.loading = false;
    },
  },
});

export const
{
  commonStart,
  setIscommonModalOpen,
  setcommonUrl,
  commonFailure,
  commonComplete,
} = commonSlice.actions;

export default commonSlice.reducer;
function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }

export const addImageToS3 =
  (formData: FormData,files:any[]): AppThunk =>
  async (dispatch) => {
    try {
        let file = files[0];
        console.log("get file-->"+file);
        dispatch(commonStart());
        Resizer.imageFileResizer(
        file,
        680,
        460,
        "JPEG",
        100,
        0,
        async(uri:any) => {
          //console.log(uri);
         // console.warn("Form data view--->" + JSON.stringify(uri));
         let fdata= getImageUrl(uri);
         //setImg(fdata);
         const formData = new FormData();
         formData.append('file', fdata);

            let data = await addLibraryImageToS3(formData);
            dispatch(setImageUrl(data));
            dispatch(setcommonUrl(data));
           
            },
            "base64",
            200,
            200
        );

       
      
      dispatch(commonComplete());
      
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(commonFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
