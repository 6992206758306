import { Program } from './../constants/models/Program';
import {
  PROGRAM_MANAGER_ROUTE,
  PROGRAM_ROUTE,
  COUNSELOR_ROUTE,
  SUPER_ADMIN_ROUTE
} from './../constants/routes';
import { User } from '../constants/models/User';
import API from './api';

export async function getSuperAdminById(id: string) {
  try {
    const res = await API.get<{ data: User; success: boolean }>(
      `${SUPER_ADMIN_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createSuperAdmin(
  id:string,
  name: string,
  email: string,
  schoolName: string,
  password: string,
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${SUPER_ADMIN_ROUTE}`,
      {
        id,
        name,
        email,
        schoolName,
        password
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramForSuperAdmin(superAdminId: string) {
  try {
    const res = await API.get<{ data: Program[]; success: boolean }>(
      `${SUPER_ADMIN_ROUTE}/${superAdminId}${PROGRAM_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramForCounselor(counselorId: string) {
  try {
    const res = await API.get<{ data: Program[]; success: boolean }>(
      `${COUNSELOR_ROUTE}/${counselorId}${PROGRAM_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateSuperAdmin(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  // about: string,
  imageUrl: string | undefined
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${SUPER_ADMIN_ROUTE}/${id}/updateSuperAdmin`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        // about,
        imageUrl: imageUrl ?? 'no-url',
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
