import React, { useEffect } from 'react';

import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EnhancedAddOrEditCounselorBySuperAdminForm from './EnhancedAddOrEditCounselorBySuperAdminForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_COUNSELOR } from '../../../constants/routes';
import { getCounselorById } from '../../../slices/counselorSlice';
interface IAddOrEditCounselorBySuperAdminProps {}

const AddOrEditCounselorBySuperAdmin: React.FC<IAddOrEditCounselorBySuperAdminProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { counselorId } = useParams<{ counselorId: string | undefined }>();
  const { counselor, counselorLoading } = useSelector((state: RootState) => {
    return {
      counselor: state.counselor.counselor,
      counselorLoading: state.counselor.loading,
    };
  }, shallowEqual);

  const isAddCounselor = location.pathname === ADD_COUNSELOR ? true : false;

  useEffect(() => {
    if (!isAddCounselor && counselorId) {
      dispatch(getCounselorById(counselorId));
    }
    return () => {};
  }, [isAddCounselor, counselorId, dispatch]);
  let renderedForm;
  if (!isAddCounselor && counselor) {
    const {
      name,
      email,
      schoolName,
      referenceCode,
      address,
      street,
      city,
      country,
      postalCode,
      about,
    } = counselor;
    renderedForm = (
      <EnhancedAddOrEditCounselorBySuperAdminForm
        name={name}
        email={email}
        schoolName={schoolName}
        referenceCode={referenceCode}
        address={address}
        street={street}
        city={city}
        country={country}
        postalCode={postalCode}
        about={about}
      />
    );
  } else if (isAddCounselor) {
    renderedForm = <EnhancedAddOrEditCounselorBySuperAdminForm />;
  } else if (counselorLoading) {
    renderedForm = <EnhancedAddOrEditCounselorBySuperAdminForm />;
  } else {
    renderedForm = <EnhancedAddOrEditCounselorBySuperAdminForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default AddOrEditCounselorBySuperAdmin;
{
  /* <IconButton
        color="primary"
        onClick={(e) => {
          history.goBack();
        }}
      >
        <ArrowBackIosIcon fontSize="small" />
      </IconButton> */
}
