import React from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import GRAPHIC from '../../../assets/images/login_graphic.svg';
import EnhancedAddRecipeTagForm from './EnhancedAddRecipeTagForm';
import { DisplayRecipeTag } from './DisplayRecipeTag';
import theme from '../../../app/muiTheme';

interface IAddRecipeTagProps {}

const AddRecipeTag: React.FC<IAddRecipeTagProps> = ({}) => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div style={{ paddingTop: '1vh' }}>
      <Container style={{ padding: '0.4em', height: '80vh' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <EnhancedAddRecipeTagForm />
            <DisplayRecipeTag />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {matches&&
              <div style={{ padding: '1em', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            } */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddRecipeTag;
