import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  InputAdornment,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { FormikProps } from 'formik';
import { EnhancedConfirmUserFormValues } from './EnhancedConfirmUserForm';
import formStyles from '../../../assets/jss/components/formStyles';
import { RootState } from '../../../app/rootReducer';
import { HOME } from '../../../constants/routes';
import { authStart, resendConfirmationCode } from '../../../slices/authSlice';
import { sendMailToParent } from '../../../slices/parentSlice';

interface IConfirmUserFormProps {}

//@ts-ignore
const useStyles = makeStyles(formStyles);

export const ConfirmUserForm: React.FC<
  IConfirmUserFormProps & FormikProps<EnhancedConfirmUserFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const [isDisabled, setDisabled] = useState(false);
  const [codeerr, setCodeerr] = useState("");
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;

  const { authLoading,tempCode,email,name, subject } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
      tempCode: state.auth.tempCode,
      email: state.auth.email,
      name: state.auth.name,
      subject: state.auth.subject
    };
  }, shallowEqual);
  
function checkCode()
{
  console.log("tpc----->"+tempCode)


    if(tempCode)
    {
      let valc=values.code;
      let valc2=tempCode.toString();
      console.log("temp--->"+values.code)
      if(valc2 === valc)
      {
       
          setDisabled(false);
          setCodeerr("");
      
      }
      else
      {
        
       
        setDisabled(true);
        setCodeerr("Invalid code provided, please request code again")

      }
    }
      
}
  return (
    <div>
      <div style={{ margin: '0px 0px 22px' }}>
        <Typography variant='body1' className={classes.loginText}>
          Sign Up
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Confirm Account
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          We have sent a 6-digit code by email, enter it below to confirm your
          account.
        </Typography>
        <br />
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter verification code <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            type='text'
            placeholder='123456'
            name='code'
            size='small'
            variant='outlined'
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={checkCode}
            error={(errors.code && touched.code) as boolean}
            helperText={errors.code && touched.code && errors.code}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
             <Grid item md={12} xs={12}>
                <span style={{color:'red',fontWeight:'700'}}>{codeerr}</span>
             </Grid>
            <Grid item md={9} xs={12}>
              Didn't receive a code?{' '}
              <Link
                to={HOME}
                className={classes.link}
                onClick={(e) => {
                  e.preventDefault();
                  let otpcode=Math.floor(100000 + Math.random() * 900000);
                  dispatch(sendMailToParent(name,email,"",subject,otpcode));
                }}
              >
                Resend it
              </Link>
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={isDisabled}
                fullWidth
                id='btn_confirm'
              >
                Confirm Account
              </Button>
              <br />
            </Grid>
          </Grid>
        </FormControl>

        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
