import React, { useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { Provider as AlertProvider } from 'react-alert';
import { Alerts } from '../components/Alerts/Alerts';
import { AlertTemplate } from '../components/Alerts/AlertTemplate';
import * as ROUTES from '../constants/routes';
import Theme from './theme';
import MUITheme from './muiTheme';
import config from '../config/config';
import store from './store';
import { DashboardMenu } from '../components/DashboardMenu/DashboardMenu';
import Home from '../features/Home/Home';
import CounselorRoute from '../components/Routes/CounselorRoute';
import { Navbar } from '../components/Navbar/Navbar';
import '../assets/css/index.css';

//Pages
import Login from '../features/Auth/Login/Login';
import Register from '../features/Auth/Register/Register';
import ConfirmUser from '../features/Auth/ConfirmUser/ConfirmUser';
import ProgramManagerRoute from '../components/Routes/ProgramManagerRoute';
import CreateProgram from '../features/ProgramManager/CreateProgram/CreateProgram';
import ProgramManagerDashboard from '../features/ProgramManager/ProgramManagerDashboard/ProgramManagerDashboard';
import AddOrEditCounselor from '../features/ProgramManager/AddOrEditCounselor/AddOrEditCounselor';
import ForgotPassword from '../features/Auth/ForgotPassword/ForgotPassword';
import CounselorDashboard from '../features/Counselor/CounselorDashboard/CounselorDashboard';
import CounselorProfiles from '../features/ProgramManager/CounselorProfiles/CounselorProfiles';
import AddDiagnosis from '../features/ProgramManager/AddDiagnosis/AddDiagnosis';
import AddTaskType from '../features/ProgramManager/AddTaskType/AddTaskType';
import AddOrEditStudent from '../features/ProgramManager/AddorEditStudent/AddOrEditStudent';

import AddOrEditEvent from '../features/Counselor/AddOrEditEvent/AddOrEditEvent';
import ViewCalendar from '../features/Counselor/ViewCalendar/ViewCalendar';
import AddTask from '../features/Counselor/AddTask/AddTask';
import AddQuestions from '../features/Library/Question/AddQuestions';
import AboutUs from '../features/AboutUs/AboutUs';
import ForFamilies from '../features/ForFamilies/ForFamilies';
import ForStudents from '../features/ForStudents/ForStudents';
import ForSchools from '../features/ForSchools/ForSchools';

import StudentProfiles from '../features/ProgramManager/StudentProfiles/StudentProfiles';
import ParentStudentProfiles from '../features/ProgramManager/StudentProfiles/ParentStudentProfiles';

import ViewStudentTasks from '../features/ProgramManager/ViewStudentTasks/ViewStudentTasks';
import { Loading } from '../components/Loading';
// @ts-ignore
import awsConfig from '../aws-exports';

import { ChatList } from '../features/Messaging/ChatList';
import AddImages from '../features/Library/Image/AddImages';
import AddVideos from '../features/Library/Video/AddVideos';
import AddDocuments from '../features/Library/Document/AddDocuments';
import EditTask from '../features/Counselor/EditTask/EditTask';
import EditStudent from '../features/ProgramManager/EditStudent/EditStudent';
//import { AddImage } from '../features/Library/Image/AddImage';
import { GroceryList } from '../features/GroceryList/GroceryList';
import EditProfile from '../features/ProgramManager/EditProfile/EditProfile';
import ParentRoute from '../components/Routes/ParentRoute';
import EditCounselorProfile from '../features/ProgramManager/CounselorProfiles/EditCounselorProfile/EditCounselorProfile';
import { PrivacyPolicy } from '../features/PrivacyPolicy/PrivacyPolicy';
import ParentDashboard from '../features/Parent/ParentDashboard/ParentDashboard';
import ListRecipe from '../features/Recipe/ListRecipe';
import AddRecipes from '../features/Recipe/AddRecipes';
import { IngidiansList } from '../features/IngridiansList/IngidiansList';
import AddRecipeIngredient from '../features/IngridiansList/AddRecipeIngredient';
import AddRecipeImages from '../features/Recipe/AddRecipeImages';
import { StepsList } from '../features/Recipe/steps/StepsList';
import AddRecipeStep from '../features/Recipe/steps/AddRecipeStep';
import AddRecipeNutrition from '../features/Recipe/nutritions/AddRecipeNutrition';
import AddRecipeTag from '../features/Recipe/AddRecipeTag/AddRecipeTag';
import EditRecipes from '../features/Recipe/EditRecipe/EditRecipes';
import ListNutrition from '../features/Recipe/nutritions/ListNutrition';
import EditRecipeIngredient from '../features/Recipe/EditIngedient/EditRecipeIngredient';
import EditRecipeStep from '../features/Recipe/EditStep/EditRecipeStep';
import ViewRecipeDetails from '../features/Recipe/viewRecipeDetails';
import headerStyles from '../assets/jss/components/headerStyles';
import { makeStyles } from '@material-ui/core/styles';
import ListRecipeAll from '../features/Recipe/ListRecipeAll';
import ListFavoriteRecipes from '../features/Recipe/ListFavoriteRecipes';
import { PreGroceryList } from '../features/GroceryList/Grocery/PreGroceryList';
import AddOrEditGroceryItem from '../features/GroceryList/Grocery/AddOrEditGrocery/EditGroceryList';
import AddGroceryList from '../features/GroceryList/Grocery/AddGroceryList/AddGroceryList';
import ListRecipeWithoutUser from '../features/Recipe/ListRecipeWithoutUser';
import SuperAdminRoute from '../components/Routes/SuperAdminRoute';
import SuperAdminDashboard from '../features/SuperAdmin/SuperAdminDashboard';
import UserList from '../features/UserListForMail/UserList';
import MyRecipes from '../features/Recipe/MyRecipes';
import EditParentProfile from '../features/Parent/EditParentProfile/EditParentProfile';
import ChangePassword from '../features/Auth/ConfirmUser/ChangePassword';
import EditSuperAdminProfile from '../features/SuperAdmin/EditSuperAdminProfile/EditSuperAdminProfile';
import SignUpOrganisation from '../features/Auth/Register/SignUpOrganisation';
import RegisterOrganisation from '../features/Auth/RegisterOrganisation/RegisterOrganisation';
import EnquiryList from '../features/SuperAdmin/Organisations/EnquiryList';
import OrganisationBySuperAdmin from '../features/Auth/RegisterOrganisation/OrganisationBySuperAdmin';
import ProgramManagerList from '../features/SuperAdmin/ProgramManagers/ProgramManagerList';
import SuperAdminCounselorProfile from '../features/ProgramManager/CounselorProfiles/SuperAdminCounselorProfile';
import SuperAdminStudentProfile from '../features/ProgramManager/StudentProfiles/SuperAdminStudentProfile';
import EditProgramManager from '../features/SuperAdmin/ProgramManagers/EditProgramManager';
import AddOrganisationToProgram from '../features/SuperAdmin/ProgramManagers/AddOrganisationToProgram';
import ViewOrganisationDetails from '../features/SuperAdmin/Organisations/ViewOrganisationDetails';
import AddOrEditCounselorBySuperAdmin from '../features/SuperAdmin/SupportAdmins/AddOrEditCounselorBySuperAdmin';
import AddOrEditStudentBySuperAdmin from '../features/SuperAdmin/MobileUsers/AddOrEditStudentBySuperAdmin';
import ParentList from '../features/SuperAdmin/Parent/ParentList';
import EditParent from '../features/SuperAdmin/Parent/EditParent';
import ViewParentDetails from '../features/SuperAdmin/Parent/ViewParentDetails';
import EditProfileProgram from '../features/SuperAdmin/ProgramManagers/EditProgramManager/EditProfileProgram';


interface AppProps {}

Amplify.configure(
  // {
  // Auth: {
  //   region: config.cognito.REGION,
  //   userPoolId: config.cognito.USER_POOL_ID,
  //   userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  // },
  // }
  awsConfig
);

const App: React.FC<AppProps> = () => {
  const useStyles = makeStyles(headerStyles);
  const classes = useStyles();

  console.log(config);
  const authlocal = localStorage.getItem('userRole');


  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate}>
        <MUIThemeProvider theme={MUITheme}>
          <ThemeProvider theme={Theme}>
            <div className='App'>
              <Router>
                <Loading>
                  <Alerts />
                  <Switch>
                    <DashboardMenu>
                      <Route exact path={ROUTES.USER_LIST}>
                        <UserList />
                      </Route>
                      <Route exact path={ROUTES.HOME}>
                        <Login />
                      </Route>
                      <Route exact path={ROUTES.ABOUT_US}>
                        <AboutUs />
                      </Route>
                      <Route exact path={ROUTES.LOGIN}>
                        <Login />
                      </Route>
                      <Route exact path={ROUTES.REGISTER}>
                        <Register />
                      </Route>
                      <Route exact path={ROUTES.REGISTER_ORGANISATION}>
                        <RegisterOrganisation />
                      </Route>
                      <Route exact path={ROUTES.CONFIRM_USER}>
                        <ConfirmUser />
                      </Route>
                      <Route exact path={ROUTES.FORGOT_PASSWORD}>
                        <ForgotPassword />
                      </Route>
                     
                      <ProgramManagerRoute
                        exact
                        path={ROUTES.PROGRAM_MANAGER_INTAKE}
                      >
                        <CreateProgram />
                      </ProgramManagerRoute>
                      <ProgramManagerRoute
                        exact
                        path={ROUTES.EDIT_PROGRAM_MANAGER}
                      >
                        <EditProfile />
                      </ProgramManagerRoute>
                      <ProgramManagerRoute
                        exact
                        path={ROUTES.PROGRAM_MANAGER_DASHBOARD}
                      >
                        <ProgramManagerDashboard />
                      </ProgramManagerRoute>

                      <ProgramManagerRoute
                        exact
                        path={ROUTES.COUNSELOR_PROFILES}
                      >
                        <CounselorProfiles />
                      </ProgramManagerRoute>
                      <ProgramManagerRoute
                        exact
                        path={ROUTES.EDIT_COUNSELOR_PROFILE()}
                      >
                        <EditCounselorProfile />
                      </ProgramManagerRoute>
                      <ProgramManagerRoute exact path={ROUTES.STUDENT_PROFILES}>
                        <StudentProfiles />
                     
                      </ProgramManagerRoute>
                      <CounselorRoute
                        exact
                        path={ROUTES.COUNSELORS_STUDENT_PROFILES}
                      >
                        <StudentProfiles />
                      </CounselorRoute>
                      <CounselorRoute
                        exact
                        path={ROUTES.COUNSELOR_DASHBOARD}
                      >
                        <CounselorDashboard />
                      </CounselorRoute>
                      <ProgramManagerRoute exact path={ROUTES.EDIT_COUNSELOR}>
                        <EditProfile />
                      </ProgramManagerRoute>
                      <ParentRoute
                        exact
                        path={ROUTES.EDIT_PARENTS_COUNSELOR_PROFILE()}
                      >
                        <EditCounselorProfile />
                      </ParentRoute>
                      <ParentRoute exact path={ROUTES.EDIT_PARENT}>
                        <EditParentProfile />
                      </ParentRoute>
                      <ParentRoute exact path={ROUTES.PARENT_STUDENT_PROFILES}>
                      <ParentStudentProfiles />
                      </ParentRoute> 
                      <ParentRoute exact path={ROUTES.PARENT_DASHBOARD}>
                        <ParentDashboard />
                      </ParentRoute>
                      <ParentRoute exact path={ROUTES.MY_RECIPES}>
                        <MyRecipes />
                      </ParentRoute>
                      <ParentRoute
                        exact
                        path={ROUTES.PARENT_COUNSELOR_PROFILES}
                      >
                        <CounselorProfiles />
                      </ParentRoute>
                      <SuperAdminRoute exact path={ROUTES.SUPERADMIN_STUDENT_PROFILES}>
                        <SuperAdminStudentProfile />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.SUPERADMIN_PARENT_PROFILES}>
                        <ParentList />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.EDIT_PROGRAM_MANAGER_BY_SUPERADMIN}>
                        {/* <EditProgramManager /> */}
                        <EditProfileProgram />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.EDIT_PARENT_BY_SUPERADMIN}>
                        <EditParent />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.UPDATE_ORGANISATION_TO_PROGRAM()}>
                        <AddOrganisationToProgram />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.VIEW_ORGANISATION_DETAILS()}>
                        <ViewOrganisationDetails />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.VIEW_PARENT_DETAILS()}>
                        <ViewParentDetails />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.ADD_SUPPORT_ADMIN()}>
                        <AddOrEditCounselorBySuperAdmin />
                      </SuperAdminRoute> 
                      <SuperAdminRoute exact path={ROUTES.ADD_MOBILE_USER()}>
                        <AddOrEditStudentBySuperAdmin />
                      </SuperAdminRoute> 
                      <SuperAdminRoute
                        exact
                        path={ROUTES.SUPERADMIN_COUNSELOR_PROFILES}
                      >
                        <SuperAdminCounselorProfile />
                        
                      </SuperAdminRoute>
                      <SuperAdminRoute
                        exact
                        path={ROUTES.SUPER_ADMIN_DASHBOARD}
                      >
                        <SuperAdminDashboard />
                        
                      </SuperAdminRoute>
                      <SuperAdminRoute
                        exact
                        path={ROUTES.ENQUIRY_LIST}
                      >
                        <EnquiryList />
                        
                      </SuperAdminRoute>
                      <SuperAdminRoute
                        exact
                        path={ROUTES.PROGRAM_MANAGER_LIST}
                      >
                        <ProgramManagerList />
                        
                      </SuperAdminRoute>
                      <SuperAdminRoute
                        exact
                        path={ROUTES.ADD_ORGANISATION}
                      >
                        <OrganisationBySuperAdmin />
                        
                      </SuperAdminRoute>
                      <SuperAdminRoute
                        exact
                        path={ROUTES.EDIT_SUPER_ADMIN}
                      >
                        <EditSuperAdminProfile />
                        
                      </SuperAdminRoute>
                      <Route exact path={ROUTES.CHANGE_PASSWORD}>
                        <ChangePassword />
                      </Route>
                      <Route exact path={ROUTES.ADD_COUNSELOR}>
                        <AddOrEditCounselor />
                      </Route>
                      <Route exact path={ROUTES.ADD_STUDENT}>
                        <AddOrEditStudent />
                      </Route>
                      <Route exact path={ROUTES.ADD_DIAGNOSIS}>
                        <AddDiagnosis />
                      </Route>
                      <Route exact path={ROUTES.ADD_TASK_TYPE}>
                        <AddTaskType />
                      </Route>
                      <Route exact path={ROUTES.ADD_RECIPE_TAG}  >
                        <AddRecipeTag />
                      </Route>
                      <Route exact path={ROUTES.ADD_EVENT}>
                        <AddOrEditEvent />
                      </Route>
                      <Route exact path={ROUTES.VIEW_CALENDAR}>
                        <ViewCalendar />
                      </Route>
                      <Route exact path={ROUTES.ADD_TASK_WITH_USER}>
                        <AddTask />
                      </Route>
                      <Route exact path={ROUTES.ADD_TASK()}>
                        <AddTask />
                      </Route>
                      <Route exact path={ROUTES.EDIT_TASK()}>
                        <EditTask />
                      </Route>
                      <Route exact path={ROUTES.ADD_QUESTIONS}>
                        <AddQuestions />
                      </Route>
                      <Route exact path={ROUTES.ADD_IMAGES}>
                        <AddImages />
                      </Route>
                      <Route exact path={ROUTES.ADD_VIDEOS}>
                        <AddVideos />
                      </Route>
                      <Route exact path={ROUTES.viewStudentTask()}>
                        <ViewStudentTasks />
                      </Route>
                      <Route exact path={ROUTES.editStudent()}>
                        <EditStudent />
                      </Route>
                      <Route exact path={ROUTES.viewStudentGroceryList()}>
                        <PreGroceryList />
                      </Route>
                      <Route exact path={ROUTES.viewIngridians()}>
                        <IngidiansList/>
                      </Route>
                      <Route exact path={ROUTES.viewSteps()}>
                        <StepsList/>
                      </Route>
                      <Route exact path={ROUTES.viewNutrintion()}>
                        <ListNutrition />
                      </Route>
                      <Route exact path={ROUTES.editNutrintion()}>
                        <AddRecipeNutrition />
                      </Route>
                      <Route exact path={ROUTES.addRecipeSteps()}>
                        <AddRecipeStep/>
                      </Route>
                      <Route exact path={ROUTES.ADD_DOCUMENTS}>
                        <AddDocuments />
                      </Route>
                      <Route exact path={ROUTES.ADD_RECIPE} >
                        <ListRecipeWithoutUser />
                      </Route>
                      <Route exact path={ROUTES.ADD_RECIPE_BY_USER()} >
                        <ListRecipe />
                      </Route>
                      <Route exact path={ROUTES.ADD_ALL_RECIPE_BY_USER()} >
                        <ListRecipeAll />
                      </Route>
                      <Route exact path={ROUTES.favorite_recipe_by_user()} >
                        <ListFavoriteRecipes />
                      </Route>
                      <Route exact path={ROUTES.editRecipe()}>
                        <EditRecipes />
                      </Route>
                      <Route exact path={ROUTES.addStudentGroceryList()}>
                        <AddGroceryList />
                      </Route>
                      <Route exact path={ROUTES.viewGroceryDetails()}>
                        <GroceryList />
                      </Route>
                      <Route exact path={ROUTES.viewRecipeDetails()}>
                        <ViewRecipeDetails />
                      </Route>
                      <Route exact path={ROUTES.editRecipeIngredient()}>
                        <EditRecipeIngredient />
                      </Route>
                      <Route exact path={ROUTES.editRecipeStep()}>
                        <EditRecipeStep />
                      </Route>
                      <Route exact path={ROUTES.editGroceryListById()}>
                        <AddOrEditGroceryItem />
                      </Route>
                      <Route exact path={ROUTES.ADD_RECIPE_VIEW}>
                        <AddRecipes />
                      </Route>
                      <Route exact path={ROUTES.addRecipeIngredients()}>
                        <AddRecipeIngredient /> 
                      </Route>
                      <Route exact path={ROUTES.addRecipeImages()}>
                        <AddRecipeImages/>
                      </Route>
                      <Route exact path={ROUTES.MESSAGING}>
                        <ChatList />
                      </Route>
                      <Route exact path={ROUTES.FOR_FAMILIES}>
                        <ForFamilies />
                      </Route>
                      <Route exact path={ROUTES.FOR_STUDENTS}>
                        <ForStudents />
                      </Route>
                      <Route
                        exact
                        path={ROUTES.FOR_SCHOOLS}
                        // path={ROUTES.FOR_IPSE_PROGRAMS}
                      >
                        <ForSchools />
                      </Route>
                      <Route exact path={ROUTES.PRIVACY_POLICY}>
                        <PrivacyPolicy />
                      </Route>
                    </DashboardMenu>
                  </Switch>
                </Loading>
              </Router>
            </div>
          </ThemeProvider>
        </MUIThemeProvider>
      </AlertProvider>
    </Provider>
  );
};
export default App;
