import React from 'react';
import {
  Grid,
  Container,
  CssBaseline,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Typical from 'react-typical';
import aboutStyles from '../../assets/jss/components/aboutStyles';

import GRAPHIC from '../../assets/images/for-family.png';
import GRAPHIC_1 from '../../assets/images/screenshots/4.png';
import GRAPHIC_2 from '../../assets/images/screenshots/3.png';

const useStyles = makeStyles(aboutStyles);

interface IForFamiliesProps {}

const ForFamilies: React.FC<IForFamiliesProps> = ({}) => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Typography className={classes.heading}>For Families</Typography>
      {/* <Typography className={classes.subHeading}>
        <Typical
          wrapper="b"
          steps={['Looking for something for your child?', 5000]}
        />
      </Typography> */}
      <br />
      <br />
      <br />
      <br />
      <Container>
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <img src={GRAPHIC} className={classes.img} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description}>
              <em>
                Independence doesn’t have to be done alone. As parents of
                children with developmental/intellectual disabilities, we want
                them to launch and thrive but understand that they will need
                help along the way. NFlyte gives families visibility into how
                their child is progressing through their day. View their daily
                calendar, completed daily living activities, self-reported mood
                and uploaded photos of completed tasks. NFlyte helps families
                stay connected and have confidence that independence is
                possible.
              </em>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: '2vh', textAlign: 'center' }}
          >
            <img src={GRAPHIC_2} className={classes.img} />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: '2vh', textAlign: 'center' }}
          >
            <img src={GRAPHIC_1} className={classes.img} />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default ForFamilies;
