import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { AddOrEditStudentForm } from './AddOrEditStudentForm';
import { createStudentAsProgramManager } from '../../../slices/studentSlice';

interface IDispatchProps {
  createStudentAsProgramManager: (
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    diagnoses: any[],
    counselors: any[],
    history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditStudentFormValues {
  name: string;
  email: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
  about: string;
  diagnoses: any[];
  counselors: any[];
}

export interface EnhancedAddOrEditStudentFormProps extends RouteComponentProps {
  name?: string;
  email?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: number;
  about?: string;
  diagnoses?: any[];
  counselors?: any[];

  createStudentAsProgramManager: (
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    diagnoses: any[],
    counselors: any[],
    history: History
  ) => void;
}

const EnhancedAddOrEditStudentForm = withFormik<
  EnhancedAddOrEditStudentFormProps,
  EnhancedAddOrEditStudentFormValues
>({
  mapPropsToValues: (props) => ({
    name: props.name ? props.name : '',
    email: props.email ? props.email : '',
    address: props.address ? props.address : '',
    street: props.street ? props.street : '',
    city: props.city ? props.city : '',
    state: props.state ? props.state : '',
    country: props.country ? props.country : '',
    postalCode: props.postalCode ? props.postalCode : 0,
    about: props.about ? props.about : '',
    diagnoses: [],
    counselors: [],
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
    address: Yup.string().notRequired(),
    street: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    postalCode: Yup.number().notRequired(),
    about: Yup.string().notRequired(),
    diagnoses: Yup.array().of(Yup.string()),
    counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, createStudentAsProgramManager } = props;
    const {
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      about,
      diagnoses,
      counselors,
    } = values;
    createStudentAsProgramManager(
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      about,
      diagnoses,
      counselors,
      history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditStudentForm);

const EnhancedAddOrEditStudentFormWithRouter = withRouter(
  EnhancedAddOrEditStudentForm
);

export default connect<null, IDispatchProps>(null, {
  createStudentAsProgramManager,
})(EnhancedAddOrEditStudentFormWithRouter);
