import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
  TITLE_REQUIRED,
} from '../../../../constants/formMessages';
import { EditGroceryListForm } from './EditGroceryListForm';
import { updateGroceryListById } from '../../../../slices/grocerySlice';
import { useParams } from 'react-router';
import { GroceryList } from '../../../../constants/models/GroceryList';
import { RootState } from '../../../../app/rootReducer';


interface IDispatchProps {
    
  updateGroceryListById: (
    id: string | undefined,
    title: string,
    description: string,
    groceryImage: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedEditGroceryListFormValues {
  title: string;
  description: string;
  groceryImage: string;
}

export interface EnhancedEditGroceryListFormProps extends RouteComponentProps {
  title?: string;
  description?: string;
  groceryImage?: string;
  groceryList?:GroceryList;
  updateGroceryListById: (
    id: string | undefined,
    title: string,
    description: string,
    groceryImage: string,
      history: History
  ) => void;
}

const EnhancedEditGroceryListForm = withFormik<
EnhancedEditGroceryListFormProps,
EnhancedEditGroceryListFormValues
>({
  mapPropsToValues: (props) => ({
    title: props.title ? props.title : '',
    description: props.description ? props.description : '',
    groceryImage: props.groceryImage ? props.groceryImage : '',
   
  }),
  validationSchema: Yup.object().shape({
    title: Yup.string().required(TITLE_REQUIRED),
    description: Yup.string().required(DESCRIPTION_REQUIRED),
    groceryImage: Yup.string().notRequired(),
    
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, updateGroceryListById, groceryList } = props;
    const {
      title,
      description,
      groceryImage,
    } = values;
    resetForm({}); 
    updateGroceryListById(
      groceryList?.id,
      title,
      description,
      groceryImage,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditGroceryListForm);

const EnhancedEditGroceryListFormWithRouter = withRouter(
  EnhancedEditGroceryListForm
);

const mapStateToProps = (state: RootState) => ({
  groceryList: state.groceryLists.groceryList,
});
export default connect(mapStateToProps, {
  updateGroceryListById,
  // @ts-ignore
})(EnhancedEditGroceryListFormWithRouter); 


