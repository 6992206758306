import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { deleteIngredient, setIngredient } from '../../slices/studentSlice';
import theme from '../../app/theme';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AddOrEditGroceryItem } from './AddOrEditGroceryItem/AddOrEditGroceryItem';

interface IEditIngredientModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

export const EditIngredientModal: React.FC<IEditIngredientModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal(false);
    dispatch(setIngredient(null));
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <AddOrEditGroceryItem handleClose={handleClose} />
      </Dialog>
    </div>
  );
};
