import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import viewStudentDetailsStyles from '../../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById } from '../../../../slices/studentSlice';
import { addStudentGroceryList, editGroceryListById, editStudent, viewStudentGroceryList } from '../../../../constants/routes';
import { Ingredient } from '../../../../constants/models/Ingredient';
import EnhancedAddGroceryListForm from './EnhancedAddGroceryListForm';
import IngidiansList from '../../../IngridiansList/IngidiansList';
import { getGroceryList } from '../../../../slices/grocerySlice';

interface IAddGroceryListProps {}

const AddGroceryList: React.FC<IAddGroceryListProps> = ({}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

  const { groceryId } = useParams<{ groceryId: string | undefined }>();
  const { groceryList } = useSelector((state: RootState) => {
    return {
      groceryList: state.groceryLists.groceryList,
    };
  }, shallowEqual);
  const { studentId } = useParams<{ studentId: string }>();

  const isAddStudent = location.pathname === addStudentGroceryList ? true : false;

  useEffect(() => {
    if (!isAddStudent && groceryId) {
      dispatch(getGroceryList(groceryId,history));
    }
    return () => {};
  }, [isAddStudent, groceryId, dispatch]);

  let renderedForm;
  if (!isAddStudent && groceryList) {
    console.warn("grc title"+JSON.stringify(groceryList));

    const {
      title,
      description,
        groceryImage,
    } = groceryList;
    renderedForm = (
      <EnhancedAddGroceryListForm 
      studentId={studentId}
      
      />
    );
  } else {
    renderedForm = <EnhancedAddGroceryListForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        
        {renderedForm}
        {/* <IngidiansList /> */}

      </Container>
    </div>
  );
};
export default AddGroceryList;
