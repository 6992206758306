import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { AddOrEditRecipeNutritionForm } from './AddOrEditRecipeNutritionForm';
import { createrecipeNutrition } from '../../../slices/recipeNutritionSlice';
import { useParams } from 'react-router';
import { RootState } from '../../../app/rootReducer';
import { RecipeNutrition } from '../../../constants/models/RecipeNutrition';



interface IDispatchProps {
    
  createrecipeNutrition: (
    servings: string,
      caloriesPerServing: string,
      totalCarbs: string,
      protein: string,
      totalFat: string,
      totalSugar: string,
      sodium: string,
      recipeId: string,
      history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditRecipeNutritionFormValues {
  servings: string;
  caloriesPerServing: string;
  totalCarbs: string;
  protein: string;
  totalFat: string;
  totalSugar: string;
  sodium: string;
    recipeId: string;
}

export interface EnhancedAddOrEditRecipeNutritionFormProps extends RouteComponentProps {
  servings?: string;
  caloriesPerServing?: string;
  totalCarbs?: string;
  protein?: string;
  totalFat?: string;
  totalSugar?: string;
  sodium?: string;
    recipeId?: string;
    recipeNutrition?: RecipeNutrition;
    createrecipeNutrition: (
      servings: string,
      caloriesPerServing: string,
      totalCarbs: string,
      protein: string,
      totalFat: string,
      totalSugar: string,
      sodium: string,
    recipeId: string,
     history: History
  ) => void;
}

const EnhancedAddOrEditRecipeNutritionForm = withFormik<
EnhancedAddOrEditRecipeNutritionFormProps,
EnhancedAddOrEditRecipeNutritionFormValues
>({
  mapPropsToValues: (props) => ({
    servings: props.servings ? props.servings : '',
    caloriesPerServing: props.caloriesPerServing ? props.caloriesPerServing : '',
    totalCarbs: props.totalCarbs ? props.totalCarbs : '',
    protein: props.protein ? props.protein : '',
    totalFat: props.totalFat ? props.totalFat : '',
    totalSugar: props.totalSugar ? props.totalSugar : '',
    sodium: props.sodium ? props.sodium : '',
    recipeId: props.recipeId ? props.recipeId : '',
   
  }),
  validationSchema: Yup.object().shape({
   // servings: Yup.string().required(NAME_REQUIRED),
    servings: Yup.string().notRequired(),
    caloriesPerServing: Yup.string().notRequired(),
    totalCarbs: Yup.string().notRequired(),
    protein: Yup.string().notRequired(),
    totalFat: Yup.string().notRequired(),
    totalSugar: Yup.string().notRequired(),
    sodium: Yup.string().notRequired(),
    recipeId: Yup.string().notRequired(),
   
    // city: Yup.string().notRequired(),
    // country: Yup.string().notRequired(),
    // postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, createrecipeNutrition, recipeNutrition } = props;
    const {
      servings,
      caloriesPerServing,
      totalCarbs,
      protein,
      totalFat,
      totalSugar,
      sodium,
        recipeId
    } = values;
    createrecipeNutrition(
      servings,
      caloriesPerServing,
      totalCarbs,
      protein,
      totalFat,
      totalSugar,
      sodium,
        recipeId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditRecipeNutritionForm);

const EnhancedAddOrEditRecipeNutritionFormWithRouter = withRouter(
  EnhancedAddOrEditRecipeNutritionForm
);

const mapStateToProps = (state: RootState) => ({
  recipeNutrition: state.recipeNutrition.recipeNutrition,
});
export default connect(mapStateToProps, {
  createrecipeNutrition,
  // @ts-ignore
})(EnhancedAddOrEditRecipeNutritionFormWithRouter);


