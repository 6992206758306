import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  INVALID_PATTERN,
  PASSWORDS_MUST_MATCH,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_SHORT,
} from '../../../constants/formMessages';

import { forgotPasswordSubmit } from '../../../slices/authSlice';
import { ForgotPasswordForm } from './ForgotPasswordForm';

interface IDispatchProps {
  forgotPasswordSubmit: (
    code: string,
    newPassword: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedForgotPasswordFormValues {
  code: string;
  password: string;
  passwordConfirmation: string;
}

export interface EnhancedForgotPasswordFormProps extends RouteComponentProps {
  code?: string;
  password?: string;
  paswordConfirmation?: string;
  forgotPasswordSubmit: (
    code: string,
    newPassword: string,
    history: History
  ) => void;
}

const EnhancedForgotPasswordForm = withFormik<
  EnhancedForgotPasswordFormProps,
  EnhancedForgotPasswordFormValues
>({
  mapPropsToValues: (props) => ({
    code: '',
    password: '',
    passwordConfirmation: '',
  }),
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .matches(/([0-9]){6}/gi, INVALID_PATTERN)
      .length(6, INVALID_PATTERN),
    password: Yup.string()
      .required(PASSWORD_REQUIRED)
      .min(6, PASSWORD_TOO_SHORT),
    passwordConfirmation: Yup.string()
      .required(CONFIRM_PASSWORD_REQUIRED)
      .oneOf([Yup.ref('password')], PASSWORDS_MUST_MATCH),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, forgotPasswordSubmit } = props;
    const { code, password } = values;
    forgotPasswordSubmit(code, password, history);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(ForgotPasswordForm);

const EnhancedForgotPasswordFormWithRouter = withRouter(
  EnhancedForgotPasswordForm
);

export default connect<null, IDispatchProps>(null, { forgotPasswordSubmit })(
  EnhancedForgotPasswordFormWithRouter
);
