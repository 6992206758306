import React, { ReactNode, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@material-ui/core';
import { Navigator } from './Navigator';
import { Header } from './Header';
import dashboardMenuStyles from '../../assets/jss/components/dashboardMenuStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setErrorMsg } from '../../slices/alertSlice';
import { useLocation } from 'react-router-dom';
import {
  ABOUT_US,
  CONFIRM_USER,
  CONTACT_US,
  FORGOT_PASSWORD,
  FOR_SCHOOOLS,
  FOR_FAMILIES,
  FOR_STUDENTS,
  HOME,
  LOGIN,
  REGISTER,
  PRIVACY_POLICY,
} from '../../constants/routes';
import { RootState } from '../../app/rootReducer';
import { Navbar } from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import theme from '../../app/muiTheme';

const useStyles = makeStyles(dashboardMenuStyles);

export interface IAdminMenuProps {
  children: ReactNode;
}

export const DashboardMenu: React.FC<IAdminMenuProps> = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  let path = location.pathname;
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { role } = useSelector((state: RootState) => {
    return {
      role: state.auth.role,
    };
  }, shallowEqual);

  useEffect(() => {
    return () => {};
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let pathsToExclude: string[] = [
    HOME,
    LOGIN,
    REGISTER,
    ABOUT_US,
    CONTACT_US,
    CONFIRM_USER,
    FORGOT_PASSWORD,
    FOR_SCHOOOLS,
    FOR_FAMILIES,
    FOR_STUDENTS,
    PRIVACY_POLICY,
  ];
  if (pathsToExclude.includes(path)) {
    return (
      <>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={12}>
            <Navbar />
          </Grid>
          <Grid item xs={12} md={12}>
            {children}
          </Grid>
          
          <Grid item xs={12} md={12}>
            {/* <Footer /> */}
          </Grid>
        </Grid>
      </>
    );
  }
  //<Footer />

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        {matches ? (
          <Navigator PaperProps={{ style: { width: 256 } }} />
        ) : (
          <Navigator
            PaperProps={{ style: { width: 256 } }}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>{children}</main>
      </div>
    </div>
  );
};
