import React from 'react';
import { FormikProps } from 'formik';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  Typography,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
//import DateTimePicker from '@mui/lab/DateTimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedAddOrEditEventFormValues } from './EnhancedAddOrEditEventForm';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { EVENT_TYPE } from '../../../constants/eventType';
import { DisplayFormikState } from '../../../components/DisplayFormikState';

interface IAddOrEditEventFormProps {}

const useStyles = makeStyles(formStyles);

export const AddOrEditEventForm: React.FC<
  IAddOrEditEventFormProps & FormikProps<EnhancedAddOrEditEventFormValues>
> = (props) => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const debug = false;
  const { eventLoading } = useSelector((state: RootState) => {
    return {
      eventLoading: state.event.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant="body1" className={classes.loginText}>
          Add Event
        </Typography>
        <Typography variant="h4" className={classes.welcomeBackText}>
          Create events
        </Typography>
        <Typography variant="body1" className={classes.loginText}>
          Staff events for all the counselors & resident events for everyone.
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%', paddingRight: '0.4em' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Title{' '}
          </Typography>
          <TextField
            placeholder="Eg. Townhall"
            type="text"
            name="name"
            variant="outlined"
            size="small"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.name && touched.name) as boolean}
            helperText={errors.name && touched.name && errors.name}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl required className={classes.formControl}>
          <Typography variant="subtitle1" className={classes.label}>
            Description{' '}
          </Typography>
          <TextField
            placeholder="Eg. This is a sample description"
            type="text"
            name="description"
            variant="outlined"
            size="small"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.description && touched.description) as boolean}
            helperText={
              errors.description && touched.description && errors.description
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant="subtitle1" className={classes.label}>
                  Start Time
                </Typography>
                <DateTimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={values.start}
                  onChange={(value) => {
                    setFieldValue('start', value);
                  }}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  End Time
                </Typography>
                <DateTimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={values.end}
                  onChange={(value) => {
                    setFieldValue('end', value);
                  }}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <FormControl required className={classes.formControl}>
          <Typography variant="subtitle1" className={classes.label}>
            Choose event type
          </Typography>
          <Select
            labelId="select-role"
            value={values.eventType}
            onChange={handleChange('eventType')}
            variant="outlined"
          >
            <MenuItem value={EVENT_TYPE.STAFF_EVENT}>Staff Event</MenuItem>
          </Select>
        </FormControl>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={eventLoading}
              // fullWidth
            >
              Create Event
            </Button>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
