import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { Grid, Container, CssBaseline, makeStyles, IconButton } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

import formStyles from '../../../assets/jss/components/formStyles';
import EnhancedRegisterOrganisationForm from './EnhancedRegisterOrganisationForm';

import GRAPHIC from '../../../assets/images/onboarding.svg';
import EnhancedConfirmUserForm from '../ConfirmUser/EnhancedConfirmUserForm';
import theme from '../../../app/muiTheme';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(formStyles);

interface IRegisterOrganisationProps {}

const RegisterOrganisation: React.FC<IRegisterOrganisationProps> = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { step } = useSelector((state: RootState) => {
    return {
      step: state.auth.step,
    };
  }, shallowEqual);
  let renderedForm;

  switch (step) {
    case 0:
      renderedForm = <EnhancedRegisterOrganisationForm />;
      break;
    case 1:
      renderedForm = <EnhancedConfirmUserForm />;
      break;
    default:
      renderedForm = <EnhancedRegisterOrganisationForm />;
  }
  const socialMedia = [
    {
      icon: TwitterIcon,
      link: 'https://twitter.com/NFlytePlan',
    },
    {
      icon: LinkedInIcon,
      link: 'www.linkedin.com/in/stacey-ledbetter-596843a',
    },
    {
      icon: FacebookIcon,
      link: 'https://www.facebook.com/NFlyteLife',
    },
  ];
  return (
    <div style={{ paddingTop: '20vh', background: '#F5FAFE' }}>
      <Container
        style={{ paddingLeft: '0.4em', paddingRight: '0.4em', height: '80vh' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <div style={{ padding: '1em' }}>{renderedForm}</div>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ paddingTop: '10vh', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}{' '}
          </Grid>
        </Grid>
        <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={6} xs={12} style={{textAlign:'center'}}>
              <p>&copy; {new Date().getFullYear()} NFlyte All rights reserved</p>
            </Grid>
            <Grid item md={6} xs={12} style={{textAlign:'center'}}>
                <div><b> Follow us on</b>  {socialMedia.map((account) => (
                <IconButton
                  href={account.link}
                  target="_blank"
                  style={{ color: 'black' }}
                >
                  <account.icon />
                </IconButton>
              ))}</div>
            </Grid>
        </Grid>  <br/><br/>
      </Container>
    </div>
  );
};

export default RegisterOrganisation;
