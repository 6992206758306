import { AppThunk } from './../app/store';
import {
  deleteChatRoom,
  deleteChatRoomUser,
  deleteMessage,
  updateChatRoom,
  updateChatRoomUser,
  updateMessage as changeMessage,
  // @ts-ignore
} from '../graphql/mutations';
import { decryptMessage, encryptMessage } from './../utils/encryption';
import {
  getChatRoom,
  getChatRoomUser,
  getUser,
  listChatRooms,
  listUsers,
  messagesByChatRoom,
  // @ts-ignore
} from '../graphql/queries';
import { createSlice } from '@reduxjs/toolkit';

import { API, graphqlOperation } from 'aws-amplify';

import {
  createChatRoom as addChatRoom,
  createChatRoomUser,
  createMessage,
} from '../graphql/mutations';
import { History } from 'history';
import { CHAT_ROOM, MESSAGING } from '../constants/routes';
import { setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/studentRequests';

// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { CHAT_SCREEN } from '../constants/routes';

const initialState = {
  allUsers: <null | any[]>null,
  filteredAllUsers: <null | any[]>null,
  allChatRooms: <null | any[]>null,
  filteredAllChatRooms: <null | any[]>null,
  chatRoomMessages: <any[]>[],
  loading: <boolean>false,
  lastMessage: <null | any>null,
  user: <null | any>null,
  chatRoom: <null | any>null,
  chatRoomUser: <null | any>null,
  chatRoomUsers: <null | any[]>null,
  groupUsers: <null | any[]>null,
  selected: <boolean>false,
  selectedUsers: <any>[],
};

const chatRoomSlice = createSlice({
  name: 'chatRoom',
  initialState,
  reducers: {
    setAllUsers(state, action) {
      state.allUsers = action.payload;
      state.filteredAllUsers = action.payload;
    },
    setGroupUsers(state, action) {
      state.groupUsers = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    addToSelectedUsers(state, action) {
      state.selectedUsers = [...state.selectedUsers, action.payload];
    },
    removeFromSelectedUsers(state, action) {
      state.selectedUsers =
        state.selectedUsers.length === 1
          ? []
          : state.selectedUsers.filter(
              (contact: any) => contact.id !== action.payload.id
            );
      state.selected =
        state.selectedUsers.length === 0 ? false : state.selected;
    },
    emptySelectedUsers(state) {
      state.selectedUsers = [];
      state.selected = false;
    },
    setFilteredAllUsers(state, action) {
      state.filteredAllUsers =
        state.filteredAllUsers !== null &&
        state.allUsers !== null &&
        state.allUsers !== undefined
          ? action.payload.isGroup
            ? state.allUsers
                .filter((u1: any) => {
                  return !action.payload.users.some((u2: any) => {
                    return u1.id === u2.id;
                  });
                })
                .filter((user: any) => {
                  const regex = new RegExp(`${action.payload.query}`, 'gi');
                  return user.name.match(regex) || user.email.match(regex);
                })
            : state.allUsers.filter((user: any) => {
                const regex = new RegExp(`${action.payload.query}`, 'gi');
                return user.name.match(regex) || user.email.match(regex);
              })
          : state.allUsers;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setChatRoom(state, action) {
      state.chatRoom = action.payload;
    },
    setChatRoomUser(state, action) {
      state.chatRoomUser = action.payload;
    },
    setChatRoomUsers(state, action) {
      state.chatRoomUsers = action.payload;
    },
    setAllChatRooms(state, action) {
      state.allChatRooms = action.payload;
      state.filteredAllChatRooms = action.payload;
      state.loading = false;
    },
    setFilteredAllChatRooms(state, action) {
      state.filteredAllChatRooms =
        state.filteredAllChatRooms !== null &&
        state.allChatRooms !== null &&
        state.allChatRooms !== undefined
          ? // @ts-ignore
            state.allChatRooms.filter(({ chatRoom }) => {
              // build up a regular expression from text as well as set second param as gi, which means globally insensitive
              const regex = new RegExp(`${action.payload.data}`, 'gi');
              const user =
                chatRoom.chatRoomUsers.items[1] === undefined
                  ? action.payload.currentUser
                  : chatRoom.chatRoomUsers.items[0].user.id !==
                    action.payload.currentUser.id
                  ? // @ts-ignore
                    chatRoom.chatRoomUsers.items[0].user
                  : // @ts-ignore
                    chatRoom.chatRoomUsers.items[1].user;
              return chatRoom.isGroup
                ? chatRoom.groupName.match(regex)
                : user.name.match(regex) || user.email.match(regex);

              // find(user => user.name.match(regex));
            })
          : state.allChatRooms;
      state.loading = false;
    },

    setLastMessage(state, action) {
      state.lastMessage = action.payload;
      state.loading = false;
    },
    setChatRoomMessages(state, action) {
      state.chatRoomMessages = action.payload;
      state.loading = false;
    },
    updateChatRoomMessages(state, action) {
      state.chatRoomMessages = [...state.chatRoomMessages, action.payload];
    },

    setLoading(state) {
      state.loading = !state.loading;
    },
  },
});

export const {
  setAllChatRooms,
  setAllUsers,
  setFilteredAllUsers,
  setChatRoomMessages,
  setFilteredAllChatRooms,
  setLastMessage,
  setLoading,
  updateChatRoomMessages,
  setUser,
  setChatRoom,
  setChatRoomUser,
  addToSelectedUsers,
  removeFromSelectedUsers,
  emptySelectedUsers,
  setSelected,
  setGroupUsers,
  setChatRoomUsers,
} = chatRoomSlice.actions;

export default chatRoomSlice.reducer;

// thunks

export const getAllUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading());
    const allUsers = await localStorage.getItem('allUsers');
    if (allUsers) {
      dispatch(setAllUsers(JSON.parse(allUsers)));
    }
    const {
      // @ts-ignore
      data: {
        listUsers: { items },
      },
    } = await API.graphql(graphqlOperation(listUsers));
    await localStorage.setItem('allUsers', JSON.stringify(items));
    dispatch(setAllUsers(items));
    dispatch(setLoading());
  } catch (error) {
    console.log(error);
  }
};
export const getUserChatRooms =
  (user: any): AppThunk =>
  async (dispatch) => {
    try {
      const chatRooms = await localStorage.getItem('allChatRooms');
      if (chatRooms) {
        dispatch(setAllChatRooms(JSON.parse(chatRooms)));
      }
      console.log('CHATROOM', user);
      const {
        // @ts-ignore
        data: {
          getUser: {
            chatRoomUser: { items },
          },
        },
      } = await API.graphql(graphqlOperation(getUser, { email: user.email }));
      const foundChatRooms = items.sort((a: any, b: any) => {
        return (
          // @ts-ignore
          new Date(b.chatRoom?.lastMessage?.createdAt) -
          // @ts-ignore
          new Date(a.chatRoom?.lastMessage?.createdAt)
        );
      });
      dispatch(setAllChatRooms(foundChatRooms));
      await localStorage.setItem(
        'allChatRooms',
        JSON.stringify(foundChatRooms)
      );
    } catch (err) {
      console.log(err);
    }
  };

export const createChatRoom =
  (user: any, currentUser: any, history: History): AppThunk =>
  async (dispatch) => {
    try {
      console.log("users-------->"+JSON.stringify(user));

      const {
        // @ts-ignore
        data: {
          listChatRooms: { items },
        },
      } = await API.graphql(graphqlOperation(listChatRooms, {}));
      console.log(user, currentUser);
      const newItems = items.filter((item: any) => item.isGroup === false);
      let found = false;
      let chatRoom;
      for (let item of newItems) {
        const userItems = item.chatRoomUsers.items;

        for (let userItem of userItems) {
          console.log("new item---->"+JSON.stringify(userItem.chatRoom.chatRoomUsers.items));
          console.log("user item---->"+JSON.stringify(user.email));
          console.log("current user---->"+JSON.stringify(currentUser.email));

          const chatRoomUsers_next = userItem.chatRoom.chatRoomUsers.items;
          console.log("new item next---->"+JSON.stringify(chatRoomUsers_next));

          // if (
          //   chatRoomUsers.find((u: any) => u.user.email === user.email) &&
          //   chatRoomUsers.find((u: any) => u.user.email === currentUser.email)
          // )
          // if (
          //   chatRoomUsers.user.email === user.email 
          // )

          // if(chatRoomUsers_next.includes(user.email))
          // {
            if (
              chatRoomUsers_next.find((u: any) => u.user.email === user.email) &&
              chatRoomUsers_next.find((u: any) => u.user.email === currentUser.email)
            )
              {
                found = true;
                chatRoom = userItem.chatRoom;
              }
          //}
              
        }
      }

      if (found) {
        console.log('ChatRoom already exist');
        dispatch(getUserChatRooms(currentUser));
        console.log(chatRoom);
        dispatch(setUser(user));
        dispatch(setChatRoom(chatRoom));
        dispatch(setChatRoomUser(chatRoom.chatRoomUsers.items[0]));
        //  TODO: navigate to chat screen
        // navigation.navigate(CHAT_SCREEN, {
        //   user: user,
        //   // @ts-ignore
        //   id: chatRoom.id,
        //   isGroup: chatRoom.isGroup,
        //   chatRoomUser: chatRoom.chatRoomUsers.items[0],
        // });
        return;
      } else {
        // dispatch(setLoading());
        // create a new chat room
        // add selected user and authenticated user to that chat room
        // @ts-ignore
        const { data } = await API.graphql(
          graphqlOperation(addChatRoom, {
            input: { isGroup: false },
          })
        );
        if (!data) {
          console.log('Failed to add chat room');
          return;
        }
        const { createChatRoom } = data;
        // create new chat room user with selected user
        await API.graphql(
          graphqlOperation(createChatRoomUser, {
            input: {
              userId: user.email,
              chatRoomId: createChatRoom.id,
            },
          })
        );
        // create new chat room user with me
        await API.graphql(
          graphqlOperation(createChatRoomUser, {
            input: {
              userId: currentUser.email,
              chatRoomId: createChatRoom.id,
            },
          })
        );
        dispatch(getUserChatRooms(currentUser));
        dispatch(setUser(user));
        dispatch(setChatRoom(createChatRoom));
        dispatch(setChatRoomUser(createChatRoom.chatRoomUsers.items[0]));
        //  TODO: navigate to chat screen
        // navigation.navigate(CHAT_SCREEN, {
        //   user: user,
        //   // @ts-ignore
        //   id: createChatRoom.id,
        //   isGroup: createChatRoom.isGroup,
        //   chatRoomUser: createChatRoom.chatRoomUsers.items[0],
        // });
      }
    } catch (err) {
      console.log(err);
    }
  };

export const createGroupChatRoom =
  (
    users: any,
    currentUser: any,
    groupName?: string,
    groupDescription?: string,
    groupImageUri?: string,
    history?: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(setLoading());
      // create a new chat room
      // add selected user and authenticated user to that chat room
      // @ts-ignore
      const { data } = await API.graphql(
        graphqlOperation(addChatRoom, {
          input: {
            isGroup: true,
            groupName,
            groupAdminId: currentUser.email,
            groupDescription,
            groupImageUri,
          },
        })
      );
      if (!data) {
        console.log('Failed to add chat room');
        return;
      }
      const { createChatRoom } = data;
      // create new chat room user with selected user
      await API.graphql(
        graphqlOperation(createChatRoomUser, {
          input: {
            userId: currentUser.email,
            chatRoomId: createChatRoom.id,
          },
        })
      );
      for (let user of users) {
        await API.graphql(
          graphqlOperation(createChatRoomUser, {
            input: {
              userId: user.email,
              chatRoomId: createChatRoom.id,
            },
          })
        );
      }
      dispatch(getUserChatRooms(currentUser));
      dispatch(setSuccessMsg('Group created successfully!'));
      // TODO: CLOSE MODAL
      history?.push(MESSAGING);
    } catch (err) {
      console.log(err);
    }
  };

export const addMembersToGroup =
  (users: any, chatRoomId: string, currentUser: any): AppThunk =>
  async (dispatch) => {
    try {
      const {
        // @ts-ignore
        data: {
          getChatRoom: {
            chatRoomUsers: { items },
          },
        },
      } = await API.graphql(graphqlOperation(getChatRoom, { id: chatRoomId }));

      for (let user of users) {
        const found = items.find((item: any) => item.userId === user.email);
        if (!found) {
          await API.graphql(
            graphqlOperation(createChatRoomUser, {
              input: {
                userId: user.email,
                chatRoomId: chatRoomId,
              },
            })
          );
        }
      }
      dispatch(setSelected(false));

      dispatch(emptySelectedUsers());

      dispatch(getUserChatRooms(currentUser));
      dispatch(setChatRoom(null));
      dispatch(setUser(null));
      dispatch(setChatRoomUser(null));
      dispatch(setChatRoomUsers(null));
      dispatch(setGroupUsers(null));
      dispatch(setSuccessMsg('Members added successfully!'));
    } catch (err) {
      console.log(err);
    }
  };

export const exitGroup =
  (
    chatRoomUser: any,
    chatRoomUsers: any,
    chatRoom: any,
    currentUser: any,
    chatRoomMessages: any,
    history?: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (chatRoom.isGroup) {
        if (chatRoomUser.user.id === chatRoom.groupAdmin.id) {
          const deletedUser = await API.graphql(
            graphqlOperation(deleteChatRoomUser, {
              input: { id: chatRoomUser.id },
            })
          );
          const leftChatRoomUsers = chatRoomUsers.filter(
            (item: any) => item.id !== chatRoomUser.id
          );
          for (let leftChatRoomUser of leftChatRoomUsers) {
            const deletedChatRoomUser = await API.graphql(
              graphqlOperation(deleteChatRoomUser, {
                input: { id: leftChatRoomUser.id },
              })
            );
          }
          for (let message of chatRoomMessages) {
            const deletedMessage = await API.graphql(
              graphqlOperation(deleteMessage, {
                input: { id: message.id },
              })
            );
          }
          const deletedChatRoom = await API.graphql(
            graphqlOperation(deleteChatRoom, {
              input: { id: chatRoom.id },
            })
          );
        } else {
          const deletedChatRoomUser = await API.graphql(
            graphqlOperation(deleteChatRoomUser, {
              input: { id: chatRoomUser.id },
            })
          );
        }
      }
      // TODO: Close Modal
      dispatch(getUserChatRooms(currentUser));
      dispatch(setChatRoom(null));
      dispatch(setUser(null));
      dispatch(setChatRoomUser(null));
      dispatch(setChatRoomUsers(null));
      dispatch(setGroupUsers(null));

      dispatch(setSuccessMsg('Left group successfully!'));
      history?.push(MESSAGING);
      // navigation.navigate(CHAT_LIST_SCREEN);
      // navigation.navigate(ROOT);
    } catch (err) {
      console.log(err);
    }
  };

export const sendMessage =
  (chkChatRoomUsers:any, inputUser:any, userData: any, chatRoomId: string, message: any, mediaOptions: any): AppThunk =>
  async (dispatch) => {
    try {
      console.warn("message user------>"+JSON.stringify(chkChatRoomUsers));
      let inputUser2=inputUser;
      let message2=message;
      let grpUser2=chkChatRoomUsers; 
      let fromuser=userData.name;
      const {
        // @ts-ignore
        data: { createMessage: createdMessage },
      } = await API.graphql(
        graphqlOperation(createMessage, {
          input: {
            content: encryptMessage(message),
            userId: userData.email,
            chatRoomId,
            isMedia: mediaOptions.isMedia,
            mediaType: mediaOptions.mediaType,
            length: mediaOptions.length,
            fileName: mediaOptions.fileName,
            fileType: mediaOptions.fileType,
          },
        })
      );
      //dispatch(getChatRoomMessages(chatRoomId, user, currentUser));

      await API.graphql(
        graphqlOperation(updateChatRoom, {
          input: {
            id: chatRoomId,
            lastMessageId: createdMessage.id,
          },
        })
      );

      await API.graphql(
        graphqlOperation(updateChatRoom, {
          input: {
            id: chatRoomId,
            lastMessageId: createdMessage.id,
          },
        })
      );
      const counselors = await REQUESTS.createMessageNotification(
        inputUser2,
        message2,       
        grpUser2,
        fromuser,

      );
      console.log(createdMessage);
      dispatch(setLastMessage(createdMessage));
    } catch (err) {
      console.log(err);
    }
  };

export const getChatRoomMessages =
  (chatRoomId: string, user: any, currentUser: any): AppThunk =>
  async (dispatch) => {
    try {
      console.warn(currentUser);

      // const {
      //   // @ts-ignore
      //   data: {
      //     getChatRoom: {
      //       messages: { items },
      //     },
      //   },
      // } = await API.graphql(graphqlOperation(getChatRoom, { id: chatRoomId }));

      const messages = await localStorage.getItem(chatRoomId);
      if (messages) {
        dispatch(setChatRoomMessages(JSON.parse(messages)));
      }
      console.log(messages);
      // if (user && currentUser) {
      //   if (
      //     user.blockedContacts.find(
      //       (contact: any) => contact.phone === currentUser.phone
      //     )
      //   ) {
      //     return;
      //   }
      // }

      const {
        // @ts-ignore
        data: {
          messagesByChatRoom: { items },
        },
      } = await API.graphql(
        graphqlOperation(messagesByChatRoom, {
          chatRoomId: chatRoomId,
          sortDirection: 'ASC',
        })
      );
      await localStorage.setItem(chatRoomId, JSON.stringify(items));
      dispatch(setChatRoomMessages(items));
      // console.log(items);
    } catch (err) {
      console.log(err);
    }
  };

export const updateMessage =
  (chatRoomId: string, message: string): AppThunk =>
  async (dispatch) => {
    try {
      console.log('subscribing....');
      dispatch(updateChatRoomMessages(message));
      await API.graphql(
        graphqlOperation(updateChatRoom, {
          input: {
            id: chatRoomId,
            // @ts-ignore
            lastMessageId: message.id,
          },
        })
      );
      // console.log(items);
    } catch (err) {
      console.log(err);
    }
  };

export const updateChatRoomMessage =
  (chatRoomId: string, message: any, user: any): AppThunk =>
  async (dispatch) => {
    try {
      console.log('subscribing2...');

      await API.graphql(
        graphqlOperation(updateChatRoom, {
          input: {
            id: chatRoomId,
            lastMessageId: message.id,
          },
        })
      );
      dispatch(getUserChatRooms(user));
      // console.log(items);
    } catch (err) {
      console.log(err);
    }
  };
