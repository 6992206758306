import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory,useParams } from 'react-router-dom';
import {
    getRecipeById,
    getRecipesForProgram,
} from '../../../slices/recipeSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  viewNutrintion,
  editNutrintion
} from '../../../constants/routes';
import { getrecipeNutritionById } from '../../../slices/recipeNutritionSlice';

import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';

interface IListNutritionProps {}

const useStyles = makeStyles(formStyles);

export const ListNutrition: React.FC<IListNutritionProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { recipeId } = useParams<{ recipeId: string | undefined }>();

  const { recipeNutrition,recipeNutritionLoading } = useSelector((state: RootState) => {
    return {
      recipeNutrition: state.recipeNutrition.recipeNutrition,
      recipeNutritionLoading: state.recipeNutrition.loading,
    };
  }, shallowEqual);

  useEffect(() => {
    if (recipeId) {
    dispatch(getrecipeNutritionById(recipeId));
    }
    console.log();
    return () => {};
  }, [recipeId, dispatch]);
 
// useEffect(() => {
//   dispatch(getRecipesForStudent());
//   return () => {};
// }, [students.length]);
  
//   useEffect(() => {
//     dispatch(setSelectedDate(new Date()));
//   }, []);

  let renderedStudents;
  if (recipeNutrition)
  {
    const { recipeId, servings, caloriesPerServing, totalCarbs, protein, totalFat, totalSugar, sodium } = recipeNutrition;

        renderedStudents =  (
          
              
                <StyledTableRow >          
                  <StyledTableCell>{servings}</StyledTableCell>
                  <StyledTableCell>{caloriesPerServing}</StyledTableCell>
                  <StyledTableCell>{totalCarbs}</StyledTableCell>
                  <StyledTableCell>{protein}</StyledTableCell>
                  <StyledTableCell>{totalFat}</StyledTableCell>
                  <StyledTableCell>{totalSugar}</StyledTableCell>
                  <StyledTableCell>{sodium}</StyledTableCell>

                  
                  <StyledTableCell>
                    <Button
                      variant='outlined'
                      startIcon={<EditIcon />}
                      onClick={async () => {
                        history.push(editNutrintion(recipeId));
                      }}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                 
                  
                 
                </StyledTableRow>
              );
            
      
  }
 else 
 {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Nutrition found.
            
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <div>
      <Typography variant='h6'>
        {/* Students */}
        Nutrition Facts
      </Typography>
      <br />
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
              <Button
                  color='primary'
                  startIcon={
                    <ArrowBackIosIcon
                      fontSize='small'
                      style={{ paddingRight: '0px' }}
                    />
                  }
                  onClick={(e) => {
                    history.go(-1);
                  }}
                  size='small'
                >
                  Back
                </Button>
          </Grid>
          <Grid item md={3} xs={12}>

          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(editNutrintion(recipeId));
            }}
            startIcon={<AddIcon />}
          >
            {/* Add Student */}
            Add OR Edit Nutrition Facts
          </Button>
          </Grid>
      </Grid>
      <br /> 
      <br />
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Servings</StyledTableCell>
              <StyledTableCell>CaloriesPerServing</StyledTableCell>
              <StyledTableCell>TotalCarbs</StyledTableCell>
              <StyledTableCell>Protein</StyledTableCell>
              <StyledTableCell>TotalFat</StyledTableCell>
              <StyledTableCell>TotalSugar</StyledTableCell>
              <StyledTableCell>Sodium</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>{renderedStudents}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default ListNutrition