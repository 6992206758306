import { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LibraryItem } from './../constants/models/LibraryItem';

export interface libraryItemState {
  loading: boolean;
  error: string | null;
  libraryItem: LibraryItem | null;
  libraryItems: LibraryItem[];
}

export const initialState: libraryItemState = {
  loading: false,
  error: null,
  libraryItem: null,
  libraryItems: [],
};

const libraryItemSlice = createSlice({
  name: 'libraryItem',
  initialState,
  reducers: {
    libraryItemStart(state) {
      state.loading = true;
      state.error = null;
    },
    setLibraryItem(state, action: PayloadAction<LibraryItem | null>) {
      state.libraryItem = action.payload;
    },
    setLibraryItems(state, action: PayloadAction<{ data: LibraryItem[] }>) {
      const { data } = action.payload;
      state.libraryItems = data.map((libraryItem) => libraryItem);
    },
    libraryItemFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    libraryItemComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  libraryItemStart,
  setLibraryItem,
  setLibraryItems,
  libraryItemFailure,
  libraryItemComplete,
} = libraryItemSlice.actions;

export default libraryItemSlice.reducer;
