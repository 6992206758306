import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg } from './alertSlice';
import * as REQUESTS from '../api/documentRequests';
import { Document } from '../constants/models/Document';

export interface documentState {
  loading: boolean;
  error: string | null;
  document: Document | null;
  documents: Document[];
  documentUrl: string;
  isDocumentModalOpen: boolean;
}

export const initialState: documentState = {
  loading: false,
  error: null,
  document: null,
  documents: [],
  documentUrl: '',
  isDocumentModalOpen: false,
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    documentStart(state) {
      state.loading = true;
      state.error = null;
    },
    setDocument(state, action: PayloadAction<Document | null>) {
      state.document = action.payload;
    },
    setDocuments(state, action: PayloadAction<{ data: Document[] }>) {
      const { data } = action.payload;
      state.documents = data.map((document) => document);
    },
    setIsDocumentModalOpen(state, action: PayloadAction<boolean>) {
      state.isDocumentModalOpen = action.payload;
    },
    setDocumentUrl(state, action: PayloadAction<string>) {
      state.documentUrl = action.payload;
    },
    documentFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    documentComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  documentStart,
  setDocument,
  setDocuments,
  setIsDocumentModalOpen,
  setDocumentUrl,
  documentFailure,
  documentComplete,
} = documentSlice.actions;

export default documentSlice.reducer;

export const getDocumentById =
  (documentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(documentStart());
      const document = await REQUESTS.getDocumentById(documentId);
      dispatch(setDocument(document));
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getDocumentsForProgramRefresh = (user_id: string): AppThunk => async (dispatch) => {
    try {
      dispatch(documentStart());
      
  
         let program_de_id=user_id;
        
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const videos = await REQUESTS.getDocumentsForProgram(programId);
        dispatch(setDocuments({ data: videos }));
      }
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getDocumentsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(documentStart());
    // const { program } = store.getState().program;
    //   const { parent } = store.getState().parent;
    //   const { counselor } = store.getState().counselor;
    //   const { programManager } = store.getState().programManager;

    //   const { superAdmin } = store.getState().superAdmin;

    const authlocal = localStorage.getItem('userRole');
    const user_id = localStorage.getItem('user_id');
  
        let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id; 
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const videos = await REQUESTS.getDocumentsForProgram(programId);
      dispatch(setDocuments({ data: videos }));
    }
    dispatch(documentComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(documentFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createDocument =
  (name: string, documentUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(documentStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

      let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id; 
      // }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const document = await REQUESTS.createDocument(
          name,
          documentUrl,
          programId
        );
        dispatch(setDocument(document));
      }
      dispatch(getDocumentsForProgram());
      dispatch(setDocumentUrl(''));
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateDocument =
  (documentId: string, name: string, documentUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(documentStart());
      await REQUESTS.updateDocument(documentId, name, documentUrl);
      dispatch(getDocumentsForProgram());
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteDocument =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(documentStart());
      await REQUESTS.deleteDocument(id);
      dispatch(getDocumentsForProgram());
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const addDocumentToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(documentStart());
      let data = await REQUESTS.addDocumentToS3(formData);
      dispatch(setDocumentUrl(data));
      dispatch(documentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(documentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
