import { Ingredient } from './../constants/models/Ingredient';
import { GroceryList } from './../constants/models/GroceryList';
import { PARENT_STUDENT_PROFILES, STUDENT_PROFILES } from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './../constants/models/User';
import * as REQUESTS from '../api/studentRequests';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { History } from 'history';
import * as REPORT_REQUESTS from '../api/reportRequests';
import * as INGREDIENT_REQUESTS from '../api/ingredientRequests';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { getGroceryListIngredients,setGroceryLists } from './grocerySlice';
import { useHistory } from 'react-router';
import { USER_ROLE } from '../constants/userRoles';




export interface studentState {
  loading: boolean;
  error: string | null;
  student: User | null;
  reportUrl: string | null;
  students: User[];
  groceryList: GroceryList | null;
  ingredient: Ingredient | null;
}

export const initialState: studentState = {
  loading: false,
  error: null,
  student: null,
  students: [],
  reportUrl: null,
  groceryList: null,
  ingredient: null,
};

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    studentStart(state) {
      state.loading = true;
      state.error = null;
    },
    setIngredient(state, action: PayloadAction<Ingredient | null>) {
      state.ingredient = action.payload;
    },
    setGroceryList(state, action: PayloadAction<GroceryList | null>) {
      state.groceryList = action.payload;
    },
    setReportUrl(state, action: PayloadAction<string>) {
      state.reportUrl = action.payload;
    },
    setStudent(state, action: PayloadAction<User | null>) {
      state.student = action.payload;
    },
    setStudents(state, action: PayloadAction<{ data: User[] }>) {
      const { data } = action.payload;
      state.students = data.map((student) => student);
    },
    studentFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    studentComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  studentStart,
  setStudent,
  setStudents,
  studentComplete,
  studentFailure,
  setReportUrl,
  setGroceryList,
  setIngredient,
} = studentSlice.actions;

export default studentSlice.reducer;

export const getStudentById =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const student = await REQUESTS.getStudentBydId(studentId);
      dispatch(setStudent(student));
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getStudentByIdRefresh =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const student = await REQUESTS.getStudentBydId(studentId);
      dispatch(setStudent(student));
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createStudentAsProgramManager =
  (
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    diagnoses: string[],
    counselors: string[],
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
        
        program_de_id=user_id;
      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
      

      if (program_de_id) {
        // let password="";
        // const userauth =  await Auth.signUp({
        //   username: email,
        //   password,
        //   attributes: {
        //     given_name: name,
        //     family_name: name,
        //     address,
        //     email,
        //     'custom:schoolName': "schoolName",
        //     'custom:userRole': "2",
        //   },
        // });
        
        // if(userauth)
        // {
          let text = email;
      email = text.toLowerCase();

      console.log("chk email-->"+email);
          const student = await REQUESTS.createStudentAsProgramManager(
            name,
            email,
            address,
            street,
            city,
            state,
            country,
            postalCode,
            about,
            program_de_id,
            diagnoses,
            counselors
          );
          dispatch(setSuccessMsg(`User added successfully !`));
        //}
       
      }
      if(parent?.id)
      {
        history.push(PARENT_STUDENT_PROFILES);
      }
      else
      {
        history.push(STUDENT_PROFILES);

      }
      
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateStudent =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    imageUrl: string | undefined,
    diagnoses: string[],
    counselors: string[],
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      let text = email;
            email = text.toLowerCase();
      //const { program } = store.getState().program;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;
      console.log("parent data"+JSON.stringify(parent))
      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }

      if (program_de_id && id !== undefined) {
        const student = await REQUESTS.updateStudent(
          id,
          name,
          email,
          address,
          street,
          city,
          state,
          country,
          postalCode,
          about,
          imageUrl,
          diagnoses,
          counselors,
          program_de_id
        );
        dispatch(setSuccessMsg(`User updated!`));
      }
      if(parent?.id)
      {
        history.push(PARENT_STUDENT_PROFILES);
      }
      else
      {
        history.push(STUDENT_PROFILES);

      }

      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getStudentsForProgramCounselorRefresh = (user_id: string): AppThunk => async (dispatch) => {
    try {
      dispatch(studentStart());

        let program_de_id=user_id; 
        
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        
          const students = await REQUESTS.getStudentsForCounselor(programId);
          if(students.length>0)
          {
            dispatch(setStudents({ data: students }));
          }
          else
          {
            dispatch(setStudents({ data: [] }));

          }
      
        dispatch(setGroceryList(null));
        dispatch(setGroceryLists({ data: [] }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  export const getStudentsByOrganisation = (program_paas_id:string): AppThunk => async (dispatch) => {
    try {
      dispatch(studentStart());
      
        let program_de_id=program_paas_id;
       
        if (program_de_id) {
        let programId = program_de_id;//program.id;
       
          const students = await REQUESTS.getStudentsByOrganisation(programId);
          dispatch(setStudents({ data: students }));

        dispatch(setGroceryList(null));
        dispatch(setGroceryLists({ data: [] }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  export const getStudentsForProgramRefresh = (program_paas_id:string): AppThunk => async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;
  
      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        const { counselor } = store.getState().counselor;
        const { programManager } = store.getState().programManager;
  
        let program_de_id=program_paas_id;
        // if(parent?.id)
        // {
        //   program_de_id=parent.id;
        // }
        // else if(counselor?.id)
        // {
        //   program_de_id=counselor.id;
        // }
        // else if(programManager?.id)
        // {
        //   program_de_id=programManager.id;
        // }
        // else if(program?.id)
        // {
        //   program_de_id=program.id;
        // }
        
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
       
          const students = await REQUESTS.getStudentsForParent(programId);
          dispatch(setStudents({ data: students }));

        dispatch(setGroceryList(null));
        dispatch(setGroceryLists({ data: [] }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  export const getStudentsForProgramSuperadminRefresh = (program_paas_id:string): AppThunk => async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;
  
      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        const { counselor } = store.getState().counselor;
        const { programManager } = store.getState().programManager;
  
        let program_de_id=program_paas_id;
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(counselor?.id)
        {
          program_de_id=counselor.id;
        }
        else if(programManager?.id)
        {
          program_de_id=programManager.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
        
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
       
          const students = await REQUESTS.getStudentsForProgram(programId);
          dispatch(setStudents({ data: students }));

        dispatch(setGroceryList(null));
        dispatch(setGroceryLists({ data: [] }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  
export const getStudentsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(studentStart());
    // const { program } = store.getState().program;

    // if (program && program.id) {
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      if(counselor?.id)
      {
        const students = await REQUESTS.getStudentsForCounselor(programId);
        //dispatch(setStudents({ data: students }));
        console.log("student data--->"+students);
        if(students.length>0)
          {
            dispatch(setStudents({ data: students }));
          }
          else
          {
            dispatch(setStudents({ data: [] }));

          }
      }
      else if(programManager?.id)
      {
        const students = await REQUESTS.getStudentsForParent(programManager.id);
        dispatch(setStudents({ data: students }));
      }
      else
      {
        const students = await REQUESTS.getStudentsForProgram(programId);
        dispatch(setStudents({ data: students }));

      }
      dispatch(setGroceryList(null));
      dispatch(setGroceryLists({ data: [] }));
    }
    dispatch(studentComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(studentFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getStudentsForParent = (): AppThunk => async (dispatch) => {
  try {
    dispatch(studentStart());
    const { parent } = store.getState().parent;
    console.log("parent users---->"+parent);

    if (parent && parent.id) {
      let parentId = parent.id;
      const students = await REQUESTS.getStudentsForParent(parentId);

      dispatch(setStudents({ data: students }));
    }
    dispatch(studentComplete());
    dispatch(setGroceryList(null));
      dispatch(setGroceryLists({ data: [] }));
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(studentFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getReportForStudent =
  (date: Date, studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
      if (program_de_id) {
        let programId =program_de_id;// program.id;
        const data = await REPORT_REQUESTS.getReport(
          date,
          programId,
          studentId,
          new Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        console.log(data);
        dispatch(setReportUrl(data.s3Url));
        const a = document.createElement('a');
        a.href = data.s3Url;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getGroceryListForStudent =
  (studentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        const { counselor } = store.getState().counselor;
        const { programManager } = store.getState().programManager;

        const { superAdmin } = store.getState().superAdmin;

        const authlocal = localStorage.getItem('userRole');
        const user_id = localStorage.getItem('user_id');
      
        
         
        let program_de_id="";
        if(user_id)
        {
           program_de_id=user_id;
  
        }
        else
        {
          if(superAdmin && authlocal=="4")
          {
            program_de_id=superAdmin.id;
          }
          if(parent && authlocal=="3")
          {
            program_de_id=parent.id;
          }
          if(counselor && authlocal=="1")
          {
            program_de_id=counselor.id;
          }
          if(programManager && authlocal=="0")
          {
            program_de_id=programManager.id;
          }
          else 
          {
            if(program?.id){
              program_de_id=program.id;
            }
          }
        }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const data = await REQUESTS.getStudentGroceryList(studentId);
        if (data.length > 0) {
          console.log(data);
          dispatch(setGroceryList(data[0]));
        } else {
          const newData = await REQUESTS.createStudentGroceryList(
            programId,
            studentId
          );
          console.log(newData);
          if(newData)
          {
            const data2 = await REQUESTS.getStudentGroceryList(studentId);
            if (data2.length > 0) {
              console.log(data2);
              dispatch(setGroceryList(data2[0]));
            }
          }
          //dispatch(setGroceryList(newData));
        }

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const createIngredient =
  (
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
     // const history=useHistory();

     const { superAdmin } = store.getState().superAdmin;

     const authlocal = localStorage.getItem('userRole');
     const user_id = localStorage.getItem('user_id');
   
     
      
     let program_de_id="";
     if(user_id)
     {
        program_de_id=user_id;

     }
     else
     {
       if(superAdmin && authlocal=="4")
       {
         program_de_id=superAdmin.id;
       }
       if(parent && authlocal=="3")
       {
         program_de_id=parent.id;
       }
       if(counselor && authlocal=="1")
       {
         program_de_id=counselor.id;
       }
       if(programManager && authlocal=="0")
       {
         program_de_id=programManager.id;
       }
       else 
       {
         if(program?.id){
           program_de_id=program.id;
         }
       }
     }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.createIngredient(
          name,
          category,
          unit,
          price,
          quantity,
          isCompleted,
          groceryListId
        );
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryListId,history));

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getIngredient =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const data = await INGREDIENT_REQUESTS.getIngredientById(id);
        console.log(data);
        dispatch(setIngredient(data));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateIngredient =
  (
    id: string,
    name: string,
    category: string,
    unit: string,
    quantity: number,
    price: number,
    groceryListId: string,
    isCompleted: boolean,
    history:History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
     // const history=useHistory();

     const { superAdmin } = store.getState().superAdmin;

     const authlocal = localStorage.getItem('userRole');
     const user_id = localStorage.getItem('user_id');
   
     
      
     let program_de_id="";
     if(user_id)
     {
        program_de_id=user_id;

     }
     else
     {
       if(superAdmin && authlocal=="4")
       {
         program_de_id=superAdmin.id;
       }
       if(parent && authlocal=="3")
       {
         program_de_id=parent.id;
       }
       if(counselor && authlocal=="1")
       {
         program_de_id=counselor.id;
       }
       if(programManager && authlocal=="0")
       {
         program_de_id=programManager.id;
       }
       else 
       {
         if(program?.id){
           program_de_id=program.id;
         }
       }
     }
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;

        const data = await INGREDIENT_REQUESTS.updateIngredientById(
          id,
          name,
          category,
          unit,
          price,

          quantity,
          isCompleted,
          groceryListId
        );
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryListId,history));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteIngredient =
  (id: string,groceryId:string,history:History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.deleteIngredientById(id);
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryId,history));

        dispatch(setSuccessMsg(`Ingredient deleted!`));
        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const markIngredientAsComplete =
  (id: string,groceryId: string,history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.markIngredientAsComplete(id);
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryId,history));

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const markIngredientAsIncomplete =
(id: string,groceryId: string,history: History): AppThunk =>
async (dispatch) => {
    try {
      dispatch(studentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }
    
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const { student } = store.getState().student;
        const data = await INGREDIENT_REQUESTS.markIngredientAsIncomplete(id);
        console.log(data);
        dispatch(setIngredient(null));
        // if (student != null) {
        //   dispatch(getGroceryListForStudent(student.id));
        // }
        dispatch(getGroceryListIngredients(groceryId,history));

        // dispatch(setStudents({ data: students }));
      }
      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getAllUserList = (): AppThunk => async (dispatch) => {
    try {
      dispatch(studentStart());
     
        const students = await REQUESTS.getStudentsForEmail();
        dispatch(setStudents({ data: students }));
      
      dispatch(studentComplete());
      dispatch(setGroceryList(null));
        dispatch(setGroceryLists({ data: [] }));
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getSearchOrganisationBySuperAdminUserist =
  (counselorId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      const students = await REQUESTS.getSearchOrganisationBySuperAdminUserist(counselorId);
     
      dispatch(setStudents({ data: students }));
     
      dispatch(studentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
      }
    }
  };


  export const createStudentBySuperAdmin=
  (
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    diagnoses: string[],
    counselors: string[],
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(studentStart());
      let text = email;
            email = text.toLowerCase();
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
      const { organisation } = store.getState().organisation;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(user_id)
      {
         program_de_id=user_id;

      }
      else
      {
        if(superAdmin && authlocal=="4")
        {
          program_de_id=superAdmin.id;
        }
        if(parent && authlocal=="3")
        {
          program_de_id=parent.id;
        }
        if(counselor && authlocal=="1")
        {
          program_de_id=counselor.id;
        }
        if(programManager && authlocal=="0")
        {
          program_de_id=programManager.id;
        }
        else 
        {
          if(program?.id){
            program_de_id=program.id;
          }
        }
      }

      let orgid="";
      if(organisation)
      {
        orgid=organisation.id;
      }
      if (program_de_id) {
        // let password="";
        // const userauth =  await Auth.signUp({
        //   username: email,
        //   password,
        //   attributes: {
        //     given_name: name,
        //     family_name: name,
        //     address,
        //     email,
        //     'custom:schoolName': "schoolName",
        //     'custom:userRole': "2",
        //   },
        // });
        
        // if(userauth)
        // {
          const student = await REQUESTS.createStudentBySuperAdmin(
            name,
            email,
            address,
            street,
            city,
            state,
            country,
            postalCode,
            about,
            program_de_id,
            diagnoses,
            counselors,
            orgid
          );
          dispatch(setSuccessMsg(`User added successfully !`));
        //}
       
      }
      if(parent?.id)
      {
        history.push(PARENT_STUDENT_PROFILES);
      }
      else
      {
        history.push(STUDENT_PROFILES);

      }

      dispatch(studentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };