import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import store, { AppThunk } from '../../../app/store';
import { LibraryImage } from '../../../constants/models/LibraryImage';
import { AddImageForm } from './AddImageForm';
import { updateImage, setIsImageModalOpen } from '../../../slices/imageSlice';
import { IMAGE_DESCRIPTION_IS_REQUIRED } from '../../../constants/formMessages';

export interface EnhancedAddImageFormValues {
  id: string;
  description: string;
  imageUrl: string;
}

export interface IDispatchProps {
  setIsImageModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateImage: (
    imageId: string,
    description: string,
    imageUrl: string
  ) => AppThunk;
}

export interface EnhancedAddImageFormProps {
  image: LibraryImage;
  setIsImageModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateImage: (imageId: string, description: string, imageUrl: string) => void;
}

const EnhancedAddImageForm = withFormik<
  EnhancedAddImageFormProps,
  EnhancedAddImageFormValues
>({
  mapPropsToValues: (props) => ({
    id: props.image.id,
    description: props.image.description,
    imageUrl: props.image.imageUrl,
  }),
  validationSchema: Yup.object().shape({
    description: Yup.string().required(IMAGE_DESCRIPTION_IS_REQUIRED),
    //imageUrl: Yup.string().required(),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { updateImage, setIsImageModalOpen } = props;
    const { id, description, imageUrl } = values;
    updateImage(id, description, imageUrl);
    setIsImageModalOpen(false);
    setSubmitting(false);
  },
  enableReinitialize: true,
})(AddImageForm);

export default connect<null, IDispatchProps>(null, {
  updateImage,
  setIsImageModalOpen,
})(EnhancedAddImageForm);
