import { PROGRAM_MANAGER_DASHBOARD } from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Program } from '../constants/models/Program';
import { History } from 'history';
import * as REQUESTS from '../api/programRequests';
import { Auth } from 'aws-amplify';
import { getProgramForProgramManager } from './programManagerSlice';
export interface programState {
  loading: boolean;
  error: string | null;
  program: Program | null;
}

export const initialState: programState = {
  loading: false,
  error: null,
  program: null,
};

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    programStart(state) {
      state.loading = true;
      state.error = null;
    },
    setProgram(state, action: PayloadAction<Program | null>) {
      state.program = action.payload;
    },
    programFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    programComplete(state) {
      state.loading = false;
    },
  },
});

export const { programStart, setProgram, programComplete, programFailure } =
  programSlice.actions;

export default programSlice.reducer;

export const createProgram =
  (
    schoolName: string,
    numberOfStudents: number,
    programType: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programStart());
      const { programManager } = store.getState().programManager;
      if (programManager) {
        const program = await REQUESTS.createProgram(
          schoolName,
          numberOfStudents,
          programType,
          programManager.id
        );
        dispatch(setProgram(program));
        history.push(PROGRAM_MANAGER_DASHBOARD);
      }
      dispatch(programComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.resonse;
        dispatch(programFailure(data));
      }
    }
  };
