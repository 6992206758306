import { withFormik } from 'formik';
import { connect } from 'react-redux';
import store, { AppThunk } from '../../../app/store';
import * as Yup from 'yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { Video } from '../../../constants/models/Video';
import { setIsVideoModalOpen, updateVideo } from '../../../slices/videoSlice';
import { AddVideoForm } from './AddVideoForm';

export interface EnhancedAddVideoFormValues {
  id: string;
  description: string;
  videoUrl: string;
}

export interface IDispatchProps {
  setIsVideoModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateVideo: (
    videoId: string,
    description: string,
    videoUrl: string
  ) => AppThunk;
}

export interface EnhancedAddVideoFormProps {
  video: Video;
  setIsVideoModalOpen: ActionCreatorWithPayload<boolean, string>;
  updateVideo: (videoId: string, description: string, videoUrl: string) => void;
}

const EnhancedAddVideoForm = withFormik<
  EnhancedAddVideoFormProps,
  EnhancedAddVideoFormValues
>({
  mapPropsToValues: (props) => ({
    id: props.video.id,
    description: props.video.description,
    videoUrl: props.video.videoUrl,
  }),
  validationSchema: Yup.object().shape({
    description: Yup.string().required(),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { updateVideo, setIsVideoModalOpen } = props;
    const { id, description, videoUrl } = values;
    updateVideo(id, description, videoUrl);
    setIsVideoModalOpen(false);
    setSubmitting(false);
  },
})(AddVideoForm);

export default connect<null, IDispatchProps>(null, {
  updateVideo,
  setIsVideoModalOpen,
})(EnhancedAddVideoForm);
