import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_SHORT,
} from '../../../constants/formMessages';

import { forgotPassword } from '../../../slices/authSlice';

import { EnterEmailForm } from './EnterEmailForm';

interface IDispatchProps {
  forgotPassword: (email: string) => AppThunk;
}

export interface EnhancedEnterEmailFormValues {
  email: string;
}

export interface EnhancedEnterEmailFormProps extends RouteComponentProps {
  email?: string;
  forgotPassword: (email: string) => void;
}

const EnhancedEnterEmailForm = withFormik<
  EnhancedEnterEmailFormProps,
  EnhancedEnterEmailFormValues
>({
  mapPropsToValues: (props) => ({
    email: props.email ? props.email : '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { forgotPassword, history } = props;
    const { email } = values;
    forgotPassword(email);
    setSubmitting(false);
  },
  displayName: 'Basic Form',
})(EnterEmailForm);

const EnhancedEmailFormWithRouter = withRouter(EnhancedEnterEmailForm);

export default connect<null, IDispatchProps>(null, { forgotPassword })(
  EnhancedEmailFormWithRouter
);
