import axios from 'axios';

const PRODUCTION = true;

export const HOST = PRODUCTION
  // ? 'https://nflytebackend.com'
  // : 'http://localhost:5001';

  ? 'https://gathering.inamh.in/nflyte-backend-main'
  : 'http://localhost:5000';

  // ? 'http://localhost:5000'
  // : 'http://localhost:5000';

export const BASE_URL = `${HOST}/api/v1`;

const API = axios.create({ baseURL: BASE_URL });

export default API;
