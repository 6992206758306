import React from 'react';
import { AllImagesView } from '../Image/AllImagesView';
import { AllVideosView } from '../Video/AllVideosView';
import { AllQuestionsView } from './AllQuestionsView';

interface IAddQuestionsProps {}

const AddQuestions: React.FC<IAddQuestionsProps> = ({}) => {
  return (
    <div>
      {/* <AllImagesView /> */}
      {/* <AllVideosView /> */}
      <AllQuestionsView />
    </div>
  );
};
//<AllVideosView />;

export default AddQuestions;
