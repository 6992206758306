import React, {useState,useEffect } from 'react';
import { RecipeIngredient } from '../constants/models/RecipeIngredient';
import { RECIPE_INGREDIENT_ROUTE, RECIPE_ROUTE, IMAGE_ROUTE } from '../constants/routes';
import { useParams } from 'react-router';

import API from './api';
import { Ingredient } from '../constants/models/Ingredient';
// const [rrecipeId,setRecipeId]=useState("");
// const { recipeId } = useParams<{ recipeId: string | undefined }>();
// let rcheck: string | number | readonly string[] | undefined;
// if(recipeId)
// {
//   rcheck=recipeId;
//   setRecipeId(rcheck);

// }
export async function getrecipeIngredientById(id: string) {
  try {
    const res = await API.get<{ data: RecipeIngredient; success: boolean }>(
      `${RECIPE_INGREDIENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getrecipeIngredientsForProgram(programId: string) {
  try {
    console.log("program id-->"+programId);
    const res = await API.get<{ data: RecipeIngredient[]; success: boolean }>(
      `${RECIPE_ROUTE}/${programId}${RECIPE_INGREDIENT_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createrecipeIngredient(
  ingredientTitle: string,
  recipeingredientUrl: string,
  quantity: string,
  unit: string,
  unitType: string,
  recipeId: string
) {

  try {

   
    const res = await API.post<{ data: RecipeIngredient; success: boolean }>(
      `${RECIPE_INGREDIENT_ROUTE}/create-recipeIngredient`,
      {
        ingredientTitle,
        recipeingredientUrl,
        quantity,
        unit,
        unitType,
        recipeId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updaterecipeIngredient(
  id: string | undefined,
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId: string,
) {
  try {
    const res = await API.post<{ data: RecipeIngredient; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/updateIgredient`,
      {
        id,
        recipeingredientUrl,
        ingredientTitle,
        quantity,
        unit,
        unitType,
        recipeId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleterecipeIngredient(id: string,recipeId: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECIPE_INGREDIENT_ROUTE}/${id}/deleteIngredient`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addrecipeIngredientToS3(formData: FormData) {
  try {
    console.warn("form data ----->"+JSON.stringify(formData));
    //console.warn("form data program id----->"+JSON.stringify(formData.programId));

    const res = await API.post<{ data: string; success: boolean }>(
      `${IMAGE_ROUTE}/add-image`,
      //
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export const getParentIngridiansList = async (recipeId: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${RECIPE_ROUTE}/${recipeId}/ingridians-list`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export async function updateIngredientPositionFromWeb(
  stepPostision: RecipeIngredient[],
  
) {
  try {
    const res = await API.post<{ data: RecipeIngredient[]; success: boolean }>(
      `${RECIPE_ROUTE}/updateIngredientPositionFromWeb`,
      {
        ingerdient_position:stepPostision
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}