import { CameraAltOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import API from '../../api/api';
import { addLibraryImageToS3 } from '../../api/imageRequests';
const SECONDARY = '#0da9e5';

interface GroupPhotoProps {
  image: string | null;
  setImage: Dispatch<SetStateAction<string | null>>;
  externalLoading: boolean;
  setExternalLoading: Dispatch<SetStateAction<boolean>>;
}

export const GroupPhoto: React.FC<GroupPhotoProps> = ({
  image,
  setImage,
  externalLoading,
  setExternalLoading,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // margin: '15px 15px',
        marginLeft: '0.6rem',
      }}
    >
      <div style={{ borderRadius: '65px' }} onClick={() => setOpenModal(true)}>
        <div
          className='sidebar-contact__avatar-wrapper'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EEEEEE',
              cursor: 'pointer',
            }}
            className='avatar'
          >
            {!loading ? (
              image ? (
                <img
                  src={image}
                  className='avatar'
                  // style={{
                  //   width: '50px',
                  //   height: '50px',
                  //   borderRadius: '65px',
                  // }}
                />
              ) : (
                <CameraAltOutlined style={{ color: 'white' }} />
              )
            ) : (
              // @ts-ignore
              <CircularProgress style={{ color: SECONDARY }} />
            )}
          </div>

          {/* <img
            src={
              user.imageUrl
                ? user.imageUrl
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            alt={
              user.imageUrl
                ? user.imageUrl
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            className="avatar"
          /> */}
        </div>
      </div>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={'Cancel'}
        submitButtonText={'Submit'}
        maxFileSize={10240000}
        filesLimit={1}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={async (files) => {
          try {
            if (files.length > 0) {
              let file = files[0];
              const formData = new FormData();
              formData.append('file', file);
              // dispatch(addSubTaskImageToS3(formData));
              const url = await addLibraryImageToS3(formData);
              setImage(url);
            }
            setOpenModal(false);
          } catch (err) {
            console.log(err);
          }
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  );
};
