import {
  PROGRAM_ROUTE,
  STUDENT_ROUTE,
  COUNSELOR_ROUTE,
} from './../constants/routes';
import { TASK_ROUTE } from '../constants/routes';
import { Task } from './../constants/models/Task';
import API from './api';
import { SubTaskItem } from '../slices/taskSlice';

export async function getTaskById(id: string) {
  try {
    const res = await API.get<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/getTaskById`
    );
    return res.data.data;
    //return res.notiTime.notiTime;
  } catch (err) {
    throw err;
  }
}

export async function getTasksForStudent(studentId: string) {
  try {
    const res = await API.get<{ data: Task[]; success: boolean }>(
      `${STUDENT_ROUTE}/${studentId}${TASK_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getStudentTasksForDate(
  id: string,
  date: Date,
  timeZone: string
) {
  try {
    const res: any = await API.get(`${STUDENT_ROUTE}/${id}/tasks-date`, {
      params: {
        date: date,
        timeZone: timeZone,
      },
    });
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addTaskImageToS3(imageBinary: string) {
  try {
    const res = await API.post<{ data: string; success: boolean }>(
      `${TASK_ROUTE}/add-image`,
      {
        imageBinary,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createTask(
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  instructions: string[],
  days: number[],
  taskTypeId: string,
  recipeId: string,
  counselorId: string,
  studentIds: string[],
  programId: string
) {
  try {
    const res = await API.post<{ data: Task[]; success: boolean }>(
      `${TASK_ROUTE}`,
      {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        instructions,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
        programId,
      } 
    );
    return res.data.success;
  } catch (err) {
    throw err;
  }
}

export async function createTaskWithSubTaskImages(
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  subTasks: SubTaskItem[],
  days: number[],
  taskTypeId: string,
  recipeId: string,
  counselorId: string,
  studentIds: string[],
  notiTime: string[],
  programId: string
) {
  try {
    const res = await API.post<{ data: Task[]; success: boolean }>(
      `${TASK_ROUTE}/with-images`,
      {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        subTasks,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
        notiTime,
        programId,
      }
    );
    return res.data.success;
  } catch (err) {
    throw err;
  }
}

export async function updateTask(
  id: string,
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  subTasks: SubTaskItem[],
  notiTime: string[],
  days: number[],
  taskTypeId: string,
  recipeId: string

) {
  try {
    const res = await API.post<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/updateTask`,
      {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        subTasks,
        notiTime,
        days,
        taskTypeId,
        recipeId,

      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteTask(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${TASK_ROUTE}/${id}/deleteTask`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function completeTask(id: string, completedImageUrl: string) {
  try {
    const res = await API.post<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/complete`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
