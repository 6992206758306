import {
  ChevronLeftOutlined,
  Search,
  SearchOutlined,
  AddCircleOutlineOutlined as AddOutlined,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import formStyles from '../../assets/jss/components/formStyles';
import {
  getUserChatRooms,
  setFilteredAllChatRooms,
  updateChatRoomMessage,
  emptySelectedUsers,
} from '../../slices/chatRoomSlice';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateMessage, onUpdateChatRoom } from '../../graphql/subscriptions';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  makeStyles,
  Typography,
  Fab,
  TextField,
  Modal,
  Button,
  IconButton,
} from '@material-ui/core';
import { ChatListItem } from './ChatListItem';
import { Add as AddIcon } from '@material-ui/icons';
import { SelectContact } from '../../features/Messaging/SelectContact';
import { SelectContactsForGroup } from '../../features/Messaging/SelectContactsForGroup';
import { AddGroup } from '../../features/Messaging/AddGroup';
// import { TextField } from "@mui/material";

const useStyles = makeStyles(formStyles);

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = ({}) => {
  const classes = useStyles();
  const { allChatRooms, filteredAllChatRooms, user, lastMessage } = useSelector(
    (state: RootState) => {
      return {
        allChatRooms: state.chatRoom.allChatRooms,
        user: state.auth.graphQlUser,
        lastMessage: state.chatRoom.lastMessage,
        filteredAllChatRooms: state.chatRoom.filteredAllChatRooms,
      };
    },
    shallowEqual
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [openAddGroupModal, setOpenAddGroupModal] = useState<boolean>(false);
  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };
  React.useEffect(() => {
    dispatch(getUserChatRooms(user));
  }, []);
  React.useEffect(() => {
    dispatch(getUserChatRooms(user));
  }, [lastMessage]);

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateMessage)
      // @ts-ignore
    ).subscribe({
      next: (data: any) => {
        const newMessage = data.value.data.onCreateMessage;
        //   console.log('egiuegf', newMessage);
        dispatch(
          updateChatRoomMessage(newMessage.chatRoomId, newMessage, user)
        );
        dispatch(getUserChatRooms(user));
      },
    });
    return () => subscription.unsubscribe();
  });
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateChatRoom)
      // @ts-ignore
    ).subscribe({
      next: (data: any) => {
        const newMessage = data.value.data.onUpdateChatRoom;
        //   console.log('egiuegf', newMessage);
        // dispatch(updateChatRoomMessage(newMessage.chatRoomId, newMessage, user));
        dispatch(getUserChatRooms(user));
      },
    });
    return () => subscription.unsubscribe();
  });
  useEffect(() => {
    dispatch(setFilteredAllChatRooms({ data: query, currentUser: user }));
  }, [query]);
  return (
    <aside className='sidebar'>
      <div
        style={{
          padding: '1rem',
          height: '70px',
          background: '#2C7AA4',
          display: 'flex',
        }}
        // className="search-wrapper"
        // style={{ padding: "1rem", borderRadius: "20px" }}
      >
        {/* <FormControl style={{ width: "100%" }}> */}
        <TextField
          onChange={handleChange}
          value={query}
          style={{
            width: '100%',
            background: 'rgba(255,255,255,1)',
            borderRadius: '8px',
          }}
          // inputProps={{
          // style:{

          // }
          // }}
          variant='outlined'
          size='small'
          InputLabelProps={{
            classes: {
              root: classes.heading,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          placeholder='Search'
        />
        {/* </FormControl> */}
        {/* <div className="search-icons">
          <Search />
 
        </div>
        <input
          onChange={handleChange}
          value={query}
          className="search"
          placeholder="Search or start a new chat"
        /> */}
        <IconButton
          style={{ marginLeft: '0.5rem' }}
          size='medium'
          onClick={() => setOpenModal(true)}
        >
          <AddOutlined style={{ color: 'white' }} />
        </IconButton>
      </div>
      {allChatRooms === null ||
      filteredAllChatRooms === null ||
      filteredAllChatRooms === undefined ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className='sidebar__contacts'>
          {/* @ts-ignore */}
          {allChatRooms.length === 0 && filteredAllChatRooms.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant='h6'
                style={{ margin: '1rem 0rem', textAlign: 'center' }}
              >
                No chats yet
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => setOpenModal(true)}
              >
                Start Chatting
              </Button>
            </div>
          )}

          {/* @ts-ignore */}
          {filteredAllChatRooms.length === 0 && allChatRooms.length !== 0 && (
            <Typography
              variant='h6'
              style={{ margin: '1rem 0rem', textAlign: 'center' }}
            >
              No chats found
            </Typography>
          )}
          {/* @ts-ignore */}
          {filteredAllChatRooms.map((contact: any, index: any) => (
            <ChatListItem key={index} {...contact} index={index} />
          ))}

          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            style={{ border: 'none' }}
            disableAutoFocus={true}
          >
            <div
              style={{
                marginTop: '5rem',
                outline: 'none',
                margin: '5rem auto',
              }}
            >
              <SelectContact
                setOpenModal={setOpenModal}
                setOpenGroupModal={setOpenGroupModal}
                setOpenAddGroupModal={setOpenAddGroupModal}
              />
            </div>
          </Modal>
          <Modal
            open={openGroupModal}
            onClose={() => {
              setOpenGroupModal(false);
              dispatch(emptySelectedUsers());
            }}
            style={{ border: 'none' }}
            disableAutoFocus={true}
          >
            <div
              style={{
                marginTop: '5rem',
                outline: 'none',
                margin: '5rem auto',
              }}
            >
              <SelectContactsForGroup
                setOpenModal={setOpenModal}
                setOpenGroupModal={setOpenGroupModal}
                setOpenAddGroupModal={setOpenAddGroupModal}
              />
            </div>
          </Modal>
          <Modal
            open={openAddGroupModal}
            onClose={() => {
              setOpenAddGroupModal(false);
              dispatch(emptySelectedUsers());
            }}
            style={{ border: 'none' }}
            disableAutoFocus={true}
          >
            <div
              style={{
                marginTop: '5rem',
                outline: 'none',
                margin: '5rem auto',
              }}
            >
              <AddGroup
                setOpenModal={setOpenModal}
                setOpenGroupModal={setOpenGroupModal}
                setOpenAddGroupModal={setOpenAddGroupModal}
              />
            </div>
          </Modal>
        </div>
      )}
    </aside>
  );
};
