import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  RootStateOrAny,
  useSelector,
  shallowEqual,
  useDispatch,
} from 'react-redux';

import { decryptMessage } from '../../utils/encryption';
const SECONDARY = '#0da9e5';
interface ChatMessageProps {
  message: any;
  name: string | null;
  isGroup: boolean | null;
  lastMessage: any;
  nextMessage: any;
  chattingWith: any;
  index: number;
  myRef: any;
}
export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  name,
  isGroup,
  lastMessage,
  nextMessage,
  chattingWith,
  index,
  myRef,
}) => {
  const { user, content, createdAt, id } = message;

  const {
    auth: { graphQlUser: currentUser },
  } = useSelector((state: RootStateOrAny) => {
    return {
      auth: state.auth,
    };
  }, shallowEqual);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openAudioModal, setOpenAudioModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let imagePreviewUrl, location, url;

  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {lastMessage === undefined ? (
        <div
          style={{
            margin: '5px 0px',
            display: 'flex',
            justifySelf: 'center',
            // width: "50%",
            justifyContent: 'center',
            alignSelf: 'center',
            // height: 30,
            // width: 0,
            padding: '5px',

            borderRadius: '4px',
            backgroundColor: 'rgba(0, 146, 188, 1.0)',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {moment(new Date(createdAt)).format('MMMM D, YYYY')}
          </div>
        </div>
      ) : moment(new Date(createdAt)).format('DD') ===
        moment(new Date(lastMessage.createdAt)).format('DD') ? (
        <></>
      ) : (
        <div
          style={{
            margin: '5px 0',
            display: 'flex',
            justifySelf: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            // height: 30,
            // width: 0,
            padding: '5px',

            borderRadius: '4px',
            backgroundColor: 'rgba(0, 146, 188, 1.0)',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {moment(new Date(createdAt)).format('MMMM D, YYYY')}
          </div>
        </div>
      )}

      <div
        style={
          {
            // width: "100%",
            // backgroundColor: "white",
          }
        }
      >
        <div
          style={{
            padding: '6px',
            opacity: 1,
            display: 'flex',
            width: '100%',
            justifyContent:
              user.id === currentUser.id ? 'flex-end' : 'flex-start',
            // marginRight: user.id === currentUser.id ? "7px" : "85%",
            // marginLeft: user.id === currentUser.id ? "85%" : "4px",
            flexDirection: 'column',
          }}
        >
          {user.id !== currentUser.id && isGroup ? (
            lastMessage !== undefined &&
            lastMessage.user.id === message.user.id &&
            moment(new Date(message.createdAt)).format('DD') ===
              moment(new Date(lastMessage.createdAt)).format('DD') ? (
              <></>
            ) : (
              <div
                style={{
                  marginBottom: '5px',
                  color: '#2C7AA4',
                  fontWeight: 500,
                  fontSize: '12px',
                  // fontFamily: "montserrat-semi-bold-italic",
                }}
              >
                {name}
              </div>
            )
          ) : (
            <></>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              // marginRight: user.id === currentUser.id ? "7px" : "80px",
              // marginLeft: user.id === currentUser.id ? "80px" : "4px",

              alignSelf: user.id === currentUser.id ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                // alignItems: 'center',
                alignItems: 'flex-end',
                maxWidth: '100%',
                flexDirection:
                  user.id === currentUser.id ? 'row-reverse' : 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  maxWidth: '80%',
                  flexDirection:
                    user.id === currentUser.id ? 'row-reverse' : 'row',
                }}
              >
                <img
                  src={
                    user.id === currentUser.id
                      ? currentUser.imageUrl
                      : user.imageUrl
                  }
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '20px',
                    marginRight: user.id === currentUser.id ? '0px' : '5px',
                    marginLeft: user.id === currentUser.id ? '5px' : '0px',
                    alignSelf: 'flex-end',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '6px',
                    borderBottomRightRadius:
                      user.id === currentUser.id ? '0px' : '6px',
                    borderBottomLeftRadius:
                      user.id === currentUser.id ? '6px' : '0px',

                    // maxWidth: '90%',
                    // borderTopLeftRadius: 0,
                    // @ts-ignore
                    padding: '6px',
                    alignItems: 'center',
                    flexDirection: 'row',
                    backgroundColor:
                      user.id === currentUser.id ? '#F8F8F8' : '#2C7AA4',
                  }}
                >
                  <div
                    style={{
                      color: user.id === currentUser.id ? 'black' : 'white',
                      fontSize: '14px',
                      //  fontWeight: "500",
                    }}
                  >
                    {decryptMessage(content)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: '10px',
                  margin: '0px 10px',
                  // marginTop: '8px',
                  marginTop: '20px',
                  fontSize: '10px',
                  color: 'rgb(210,210,210)',
                  // fontWeight: "500",
                }}
              >
                {moment(createdAt).format('h:mm A')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
