import React from 'react';
import {
  Grid,
  Container,
  CssBaseline,
  makeStyles,
  Typography,
} from '@material-ui/core';
import GRAPHIC from '../../assets/images/stacey.png';
import GRAPHIC_3 from '../../assets/images/screenshots/5.png';
import aboutStyles from '../../assets/jss/components/aboutStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../app/muiTheme';

interface IAboutUsProps {}

const useStyles = makeStyles(aboutStyles);

const AboutUs: React.FC<IAboutUsProps> = ({}) => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let part_1 =
    'The concept of NFlyte was born out of my own personal need for an application to help my teenage daughter as she navigates the path towards independence as a young adult with autism. I scoured the internet for a solution for neurodiverse adults/adolescents and was frustrated to be limited to technology for young children. I needed a technology to help my daughter stay on task independently while giving me visibility into her progress without being overbearing in her life. Connected but independent.';
  return (
    <section className={classes.section}>
      <Typography className={classes.heading}>About Us</Typography>
      {/* <Typography className={classes.subHeading}>
        <Typical wrapper="b" steps={['A word from our CEO, Stacey', 5000]} />
      </Typography> */}

      <Container>
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <img src={GRAPHIC} className={classes.img} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description}>
              <em>
                The concept of NFlyte was born out of my own personal need for
                an application to help my teenage daughter as she navigates the
                path towards independence as a young adult with autism. I
                scoured the internet for a solution for neurodiverse
                adults/adolescents and was frustrated to be limited to
                technology for young children. I needed a technology to help my
                daughter stay on task independently while giving me visibility
                into her progress without being overbearing in her life.
                Connected but independent.
              </em>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <Typography className={classes.description}>
              <em>
                As parents, it can feel overwhelming to launch them into the
                world without our constant guidance. The long-term vision of
                NFlyte is to become a multi-purpose platform for not only visual
                schedules, but money management, online practical life lessons,
                an online community and interconnectivity with other smart home
                devices. I have embarked on this new journey with the hope that
                others may also benefit from a tool to launch young adults with
                autism or other developmental disabilities into an independent
                life.
              </em>
              <figcaption>
                <strong>Stacey Ledbetter, Founder</strong>
              </figcaption>
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && <img src={GRAPHIC_3} className={classes.img} />}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default AboutUs;
