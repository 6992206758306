import React from 'react';
import { AllDocumentsView } from './AllDocumentsView';

interface IAddDocumentsProps {}

const AddDocuments: React.FC<IAddDocumentsProps> = ({}) => {
  return (
    <div>
      <AllDocumentsView />
    </div>
  );
};
export default AddDocuments;
