import React from 'react';
import {
  Grid,
  Container,
  CssBaseline,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Typical from 'react-typical';
import aboutStyles from '../../assets/jss/components/aboutStyles';

import GRAPHIC from '../../assets/images/for-students.png';
import GRAPHIC_1 from '../../assets/images/screenshots/4.png';
import GRAPHIC_2 from '../../assets/images/screenshots/3.png';

const useStyles = makeStyles(aboutStyles);

interface IForStudentsProps {}
const ForStudents: React.FC<IForStudentsProps> = ({}) => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Typography className={classes.heading}>For Students</Typography>
      {/* <Typography className={classes.subHeading}>
        <Typical
          wrapper="b"
          steps={['How do we help students, let us find out?', 5000]}
        />
      </Typography> */}
      <br />
      <br />
      <br />
      <br />
      <Container>
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <img src={GRAPHIC} className={classes.img} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description}>
              <em>
                NFlyte is built specifically for students in post-secondary
                inclusive programs. Designed under the guidance of neurodiverse
                adults and their parents, the app gives students a single view
                of their daily living tasks and classes in the My Day view.
                Customize the reminders with your own photos or videos to help
                keep you on track. College can be both exciting and
                overwhelming. A visual schedule can give you the confidence that
                you are on track for your day.
              </em>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: '2vh', textAlign: 'center' }}
          >
            <img src={GRAPHIC_2} className={classes.img} />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: '2vh', textAlign: 'center' }}
          >
            <img src={GRAPHIC_1} className={classes.img} />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default ForStudents;
