import { withFormik } from 'formik';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { createTaskType } from '../../../slices/taskTypeSlice';
import { AddTaskTypeForm } from './AddTaskTypeForm';
import { NAME_REQUIRED } from '../../../constants/formMessages';

interface IDispatchProps {
  createTaskType: (name: string) => AppThunk;
}

export interface EnhancedAddTaskTypeFormValues {
  name: string;
}

export interface EnhancedAddTaskTypeFormProps extends RouteComponentProps {
  name?: string;
  createTaskType: (name: string) => void;
}

const EnhancedAddTaskTypeForm = withFormik<
  EnhancedAddTaskTypeFormProps,
  EnhancedAddTaskTypeFormValues
>({
  mapPropsToValues: (props) => ({
    name: props.name ? props.name : '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createTaskType } = props;
    const { name } = values;
    resetForm({});
    createTaskType(name);
    setSubmitting(false);
  },
  enableReinitialize: true,
  displayName: 'BasicForm',
})(AddTaskTypeForm);

const EnhancedAddTaskTypeFormWithRouter = withRouter(EnhancedAddTaskTypeForm);

export default connect<null, IDispatchProps>(null, { createTaskType })(
  EnhancedAddTaskTypeFormWithRouter
);
