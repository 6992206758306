import { USER_ROLE } from './../constants/userRoles';
import store, { AppThunk } from '../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organisation } from '../constants/models/Organisation';
import { History } from 'history';

import * as REQUESTS from '../api/programManagerRequests';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { updateDetailsForGraphQlUser } from './authSlice';

export interface organisationState {
  loading: boolean;
  error: string | null;
  organisation: Organisation | null;
  organisations: Organisation[];
}

export const initialState: organisationState = {
  loading: false,
  error: null,
  organisation: null,
  organisations: [],
};

const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    organisationStart(state) {
      state.loading = true;
      state.error = null;
    },
    setorganisation(state, action: PayloadAction<Organisation | null>) {
      state.organisation = action.payload;
    },
    setorganisations(state, action: PayloadAction<{ data: Organisation[] }>) {
      const { data } = action.payload;
      state.organisations = data.map((organisation) => organisation);
    },
    organisationFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    organisationComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  organisationStart,
  setorganisation,
  setorganisations,
  organisationComplete,
  organisationFailure,
} = organisationSlice.actions;

export default organisationSlice.reducer;

export const getOrganisationById =
  (programMangerId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(organisationStart());
      const organisation = await REQUESTS.getOrganisationById(
        programMangerId
      );
      dispatch(setorganisation(organisation));
      dispatch(organisationComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(organisationFailure(data));
      }
    }
  };