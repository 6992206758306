import { Diagnosis } from './../constants/models/Diagnosis';
import { DIAGNOSIS_ROUTE, PROGRAM_ROUTE } from './../constants/routes';
import API from './api';

export async function getDiagnosisById(id: string) {
  try {
    const res = await API.get<{ data: Diagnosis; success: boolean }>(
      `${DIAGNOSIS_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getDiagnosesForProgram(programId: string) {
  try {
    const res = await API.get<{ data: Diagnosis[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${DIAGNOSIS_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createDiagnosis(name: string, programId: string) {
  try {
    const res = await API.post<{ data: Diagnosis; success: boolean }>(
      `${DIAGNOSIS_ROUTE}`,
      { name, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateDiagnosis(
  id: string,
  name: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: Diagnosis; success: boolean }>(
      `${DIAGNOSIS_ROUTE}/${id}/updateDiagnosis`,
      { name, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteDiagnosis(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${DIAGNOSIS_ROUTE}/${id}/deleteDiagnosis`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
