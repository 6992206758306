import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import {
  ADD_COUNSELOR,
  ADD_ORGANISATION,
  EDIT_COUNSELOR_PROFILE,
  EDIT_PARENT,
  EDIT_PARENTS_COUNSELOR_PROFILE,
  EDIT_PARENT_BY_SUPERADMIN,
  EDIT_PROGRAM_MANAGER_BY_SUPERADMIN,
  UPDATE_ORGANISATION_TO_PROGRAM,
  VIEW_PARENT_DETAILS,
} from '../../../constants/routes';
import {
  getCounselorById,
  getCounselorsForOrganisationProgramRefresh,
  getCounselorsForProgram,
  getCounselorsForProgramRefresh,
  getCounselorsForProgramRefreshParent,
  setCounselor,
} from '../../../slices/counselorSlice';
import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { approvedOrganisation, changeProgramStatus, getOrganisationList, getSearchOrganisationBySuperAdmin, searchParentByName } from '../../../api/programManagerRequests';
import { TiTick } from 'react-icons/ti';
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';

import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import { getProgramManagerByIdRefresh } from '../../../slices/programManagerSlice';
import { getParentByIdRefresh, getParentsList, setParentLists } from '../../../slices/parentSlice';

interface IParentListProps {}

const useStyles = makeStyles(formStyles);

const ParentList: React.FC<IParentListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { parents } = useSelector((state: RootState) => {
    return {
      parents: state.parent.parents,
    };
  }, shallowEqual);


  localStorage.setItem('urlRoute', "parent_admins");

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const [orgarr,setorgarr]=useState([]);
  const [orgarr_nxt,setorgarrNxt]=useState([]);
  const [parentnm,setParentNm]=useState("");
 
  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
       await dispatch(getParentsList());
        
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);
  
  async function getApproveOrganisation(id:any)
  {
    //console.log("chk id-->"+id)

    const orgData_nxt = await approvedOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
          if(orgData)
          {
            setorgarrNxt(orgData)
        
            
          }
      }
      
  }
  let renderedStudents: { label: string; value: string; imageUrl: string }[] =[];

  let autoorgdata: any[];
  if(orgarr_nxt)
  {
              if (orgarr_nxt.length > 0) {
                renderedStudents = orgarr_nxt
                  .map((org:any) => {
                    return {
                      label: org.name,
                      value: org.id,
                      imageUrl: "",
                    };
                  });
              } else {
                renderedStudents = [];
              }
  }
  console.log("----->"+renderedStudents)
  const [cstatus,setCstatus]=useState("");
  async function getInactiveProgramManager(e:any,cnt:any,id:any) {
    setCstatus(e.target.value)

    let c1=window.confirm("Do you want to change status !");
    if(c1)
    {
      //alert(e.target.value)
      let orstatus=e.target.value;
      const orgData = await changeProgramStatus(id,orstatus);
      
      
    }
  }
  const [orgSelect,setorgSelect]=useState("");
  async function getSearchOrganisation(e:any)
  {
    //alert(nm)
        setorgSelect(e.target.value)
      let orgnm=e.target.value;
        const orgData = await getSearchOrganisationBySuperAdmin(orgnm);
        if(orgData)
        {
          setorgarr(orgData)
        }
  }

  async function getOrganisationUpdateToProgram(id:any)
  {
    history.push(UPDATE_ORGANISATION_TO_PROGRAM(id))
  }

  async function getSearchParentByName()
  {
    
      const orgData = await searchParentByName(parentnm);
      if(orgData)
      {
       dispatch(setParentLists({data:orgData}));
      }
  }
  console.log("org data-->"+JSON.stringify(orgarr))
  let renderedCounselors;
  if (parents && parents.length > 0) {
    renderedCounselors = parents.map((parent, index) => {
      const { name,
        email, 
        schoolName: schoolName, 
        organisationId,
        status,
        id } = parent;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          {/* <StyledTableCell>{schoolName?schoolName:<button className='btn btn-xs btn-warning' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Approve"}
              onClick={ async() => {
                
                await dispatch(getProgramManagerByIdRefresh(id))
                getOrganisationUpdateToProgram(id)
              //   history.push(EDIT_PROGRAM_MANAGER_BY_SUPERADMIN)
              }}
              >Add Organisation</button>}</StyledTableCell> */}
               <StyledTableCell>{status}</StyledTableCell>
          {/* <StyledTableCell>
                <select value={status} className='form-control' style={{padding:'10px'}} onChange={(e)=>getInactiveProgramManager(e,index,id)}>
                    <option value={"Active"} selected={status==="Active"?true:false}>Active </option>
                    <option value={"Inactive"} selected={status==="Inactive"?true:false}>Inactive </option>
                    <option value={"Delete"} selected={status==="Delete"?true:false}>Delete </option>
                  </select>
              </StyledTableCell> */}
          <StyledTableCell style={{textAlign:'center'}}>
           
              {/* <button className='btn btn-xs btn-warning' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Approve"}
              onClick={ async() => {

                await dispatch(getProgramManagerByIdRefresh(id))
                if(authlocal==="3")
                {
                  history.push(EDIT_PARENTS_COUNSELOR_PROFILE(id));
                }
                else
                {
                  history.push(EDIT_COUNSELOR_PROFILE(id));
                }
              }}
              ><AiOutlineEdit /></button> */}
            
            <IconButton
              onClick={async () => {
                await dispatch(getParentByIdRefresh(id))
               
                  history.push(EDIT_PARENT_BY_SUPERADMIN);
                
               
              }}
            >
              <EditIcon />
            </IconButton> |
            <IconButton
                  onClick={async () => {
                    await dispatch(getParentByIdRefresh(id))
                    history.push(VIEW_PARENT_DETAILS(id))
                  }}
                >
                  <AiOutlineEye />
                </IconButton> 
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (parents && parents.length === 0) { 
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Parent found.
            {/* <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Program Manager?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  

  
  return (
    <div>
      <Typography variant='h6'>Parent</Typography>
      <br />
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
         
            <Grid item md={6} xs={12}>

            </Grid>
            <Grid item md={3} xs={6} style={{padding:'5px'}}>
              <label><b>Enter Parent Name</b></label>
                <input type='text' placeholder='Enter Parent Name' value={parentnm} onChange={(e) => {setParentNm(e.target.value)}} className='form-control'/>
              </Grid>
           

             
              <Grid item md={3} xs={6} className='text-center' style={{padding:'5px'}}>
                {/* <Button
                  variant='contained'
                  color='primary'
                  onClick={async() => { 
                   // setOrgDate("")
                    // const orgData = await getParentList();
                    // if(orgData)
                    // {
                    //   setorgarr(orgData)
                    //   setorgSelect("")
                    //   const orgDatan = await getOrganisationList();
                    //   if(orgDatan)
                    //   {
                    //     setorgarrNxt(orgDatan)
                    

                    //   }

                    // }
                  }}
                  style={{height:'50px',marginTop:'20px'}}
                  startIcon={<FiRefreshCw />}
                >
                  Refresh List 
                </Button> */}
                <Button
                                variant='contained'
                                color='primary'
                                style={{marginTop:'20px'}}
                                onClick={async() => { 
                                  getSearchParentByName()
                                }}
                                startIcon={<AiOutlineSearch />}
                              >
                                Search 
                  </Button>&nbsp;&nbsp;
                 <Button
                                variant='contained'
                                color='primary'
                                style={{marginTop:'20px'}}
                                onClick={async() => { 
                                  window.location.reload();
                                  // setorgname("")
                                  // renderedStudents = [];

                                  // const orgData = await getOrganisationList();
                                  // if(orgData)
                                  // {
                                  //   setorgarr(orgData)
                                  // }
                                }}
                                startIcon={<MdClear />}
                              >
                                Clear 
                              </Button>
              </Grid> 
        </Grid>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push(ADD_ORGANISATION);
        }}
        startIcon={<AddIcon />}
      >
        Add Organisation
      </Button> */}
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell style={{textAlign:'center'}}>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedCounselors}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ParentList;
