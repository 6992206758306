/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser(
    $input: CreateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    createChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser(
    $input: UpdateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    updateChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomUser(
    $input: DeleteChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    deleteChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
