import React from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Container,
  Chip,
  Link,
  Button,
  Divider,
  IconButton,
} from '@material-ui/core';
import viewStudentDetailsStyles from '../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById } from '../../../slices/studentSlice';
import { editStudent, MESSAGING } from '../../../constants/routes';

const useStyles = makeStyles(viewStudentDetailsStyles);

interface IViewStudentDetailsProps {
  student: User;
}

export const ViewStudentDetails: React.FC<IViewStudentDetailsProps> = ({
  student,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let renderedCounselors = student.studentGroups!.map((studentGroup) => {
    return (
      <Chip
        icon={<PersonIcon />}
        label={studentGroup.name}
        variant='outlined'
        style={{ marginRight: '0.2em' }}
      />
    );
  });
  let renderedDiagnoses = student.diagnoses!.map((diagnosis) => {
    return (
      <Chip
        label={diagnosis.name}
        variant='outlined'
        style={{ marginRight: '0.2em' }}
      />
    );
  });
  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={12}>
            <div className={classes.avatar}>
              <Avatar
                alt={student.name}
                src={student.imageUrl}
                className={classes.large}
              />
              <br />
            </div>
            <div className={classes.avatar}>
              <Typography variant='h6'>
                <strong>{student.name}</strong>
              </Typography>
              <br />
            </div>
            <div className={classes.emailDiv} style={{padding:'10px'}}>
              <p style={{fontSize:'12px'}}>{student.email}</p>
            </div>
          </Grid>
         
          <Grid item xs={12} md={12}>
            <div className={classes.avatar}>
              <Grid
                container
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
              >
                {/* <Grid item>
                  <IconButton onClick={() => history.push(MESSAGING)}>
                    <MessageIcon color='primary' />
                  </IconButton>
                </Grid> */}
                {/* <Grid item>
                  <IconButton
                    onClick={(e) => {
                      window.location.replace(`mailto:${student.email}`);
                      e.preventDefault();
                    }}
                  >
                    <EmailIcon color='primary' />
                  </IconButton>
                </Grid> */}
                <Grid item>
                  <IconButton
                    onClick={() => {
                      dispatch(getStudentById(student.id));
                      history.push(editStudent(student.id));
                    }}
                  >
                    <EditIcon color='primary' />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <List>
              <ListItem button>
                <ListItemText
                  id={''}
                  primary={
                    <Typography>
                      <strong>About</strong>{' '}
                    </Typography>
                  }
                  secondary={<Typography>{student.about}</Typography>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  id={''}
                  primary={
                    <Typography>
                      <strong>Diagnoses</strong>
                    </Typography>
                  }
                  secondary={<Typography>{renderedDiagnoses}</Typography>}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  id={''}
                  primary={
                    <Typography>
                      <strong>Admin</strong>
                    </Typography>
                  }
                  secondary={<Typography>{renderedCounselors}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
