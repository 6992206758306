import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
// import ViewStudentTasksTable from './ViewStudentTasksTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../app/rootReducer';
import { getGroceryListForStudent, getGroceryListForStudentRefresh } from '../../../slices/grocerySlice';
import PreGroceryListTable from './PreGroceryListTable';
//import { AddOrEditGroceryItem } from './AddOrEditGroceryItem/AddOrEditGroceryItem';

interface IPreGroceryListProps {}

const PreGroceryList: React.FC<IPreGroceryListProps> = ({}) => {
  const dispatch = useDispatch();
  const { studentId } = useParams<{ studentId: string }>();

  localStorage.setItem('urlRoute', "gocerylist");
  localStorage.setItem('urlId', studentId);

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(user_id)
    {
      dispatch(getGroceryListForStudentRefresh(studentId,user_id));

    }
    else
    {
      dispatch(getGroceryListForStudent(studentId));

    }
    return () => {};
  }, []);

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      style={{ height: '100vh' }}
    >
      <Grid item xs={12} md={10}>
        <PreGroceryListTable />
      </Grid>
      {/* <Grid item xs={12} md={4}> */}
      {/* {student && <ViewStudentDetails student={student} />} */}
      {/* <AddOrEditGroceryItem /> */}
      {/* </Grid> */}
    </Grid>
  );
};

export { PreGroceryList };
