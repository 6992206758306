import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AddTaskDetailsForm } from './AddTaskDetailsForm';
import {
  setName,
  setTaskTypeId,
  setTaskStep,
  addTaskImageToS3,
} from '../../../slices/taskSlice';
import {
  NAME_REQUIRED,
  TASK_TYPE_REQUIRED,
} from '../../../constants/formMessages';

interface IDispatchProps {
  setName: ActionCreatorWithPayload<string, string>;
  setTaskTypeId: ActionCreatorWithPayload<string, string>;
  setTaskStep: ActionCreatorWithPayload<number, string>;
  addTaskImageToS3: () => AppThunk;
}

export interface EnhancedAddTaskDetailsFormValues {
  name: string;
  taskTypeId: string;
  instructions: string[];
}
export interface EnhancedAddTaskDetailsFormProps extends RouteComponentProps {
  name?: string;
  taskTypeId?: string;
  instructions?: string[];
  setName: ActionCreatorWithPayload<string, string>;
  setTaskTypeId: ActionCreatorWithPayload<string, string>;
  setTaskStep: ActionCreatorWithPayload<number, string>;
  addTaskImageToS3: () => void;
}

const EnhancedAddTaskDetailsForm = withFormik<
  EnhancedAddTaskDetailsFormProps,
  EnhancedAddTaskDetailsFormValues
>({
  mapPropsToValues: (props) => ({
    name: props.name ? props.name : '',
    taskTypeId: props.taskTypeId ? props.taskTypeId : '',
    instructions: props.instructions ? props.instructions : [],
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    //setTaskTypeId: Yup.string().required(TASK_TYPE_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { setName, setTaskTypeId, setTaskStep, addTaskImageToS3 } = props;
    const { name, taskTypeId } = values;
    setName(name);
    setTaskTypeId(taskTypeId);
    setTaskStep(1);
    //addTaskImageToS3();
    setSubmitting(false);
    resetForm();
  },
  enableReinitialize: true,
  displayName: 'BasicForm',
})(AddTaskDetailsForm);

const EnhancedAddTaskDetailsFormWithRouter = withRouter(
  EnhancedAddTaskDetailsForm
);

export default connect<null, IDispatchProps>(null, {
  setName,
  setTaskTypeId,
  setTaskStep,
  addTaskImageToS3,
})(EnhancedAddTaskDetailsFormWithRouter);
