import React, { useState } from 'react';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';


import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Typography,
  TableHead,
  Tooltip,
  Chip,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';

import { GroceryList } from '../../../constants/models/GroceryList';
import { categoryList } from '../../../constants/category';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import {
  deleteIngredient,
  getIngredient,
  markIngredientAsComplete,
  markIngredientAsIncomplete,
} from '../../../slices/studentSlice';
import { Edit } from '@material-ui/icons';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import { CircularProgress } from '@mui/material';
// import { DeleteIngredientModal } from './DeleteIngredientModal';
// import { EditIngredientModal } from './EditIngredientModal';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import NoImageIcon from '@mui/icons-material/HideImage';
import { editGroceryListById, viewGroceryDetails } from '../../../constants/routes';
import { getGroceryList,deleteGroceryList, getGroceryListIngredients } from '../../../slices/grocerySlice';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


interface IPreGroceryRowProps {
  index: number;
  groceryList: GroceryList;
}

export const PreGroceryRow: React.FC<IPreGroceryRowProps> = ({
  groceryList,
  index,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { title,description, groceryImage, id } = groceryList;
  let renderedState;
  let renderedChip;
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
let titlenm;
if(title)
{
  titlenm=title;
}
else
{
  titlenm="";
}
let descriptionstr;
if(description)
{
    descriptionstr=description;
}
let gimg;
if(groceryImage)
 {
    gimg=groceryImage;
 }
 else
 {
    gimg="https://nflyte-1.s3.amazonaws.com/6090a404-846f-4d9f-93d2-8e2a4499d453.png";
}

function getConfirm(id:string)
{
  let chkcn=window.confirm("Do you want to delete this grocery list");
  if(chkcn)
  {
    dispatch(deleteGroceryList(id)); 
  }
}

  return (
    <React.Fragment>
      <StyledTableRow >

        <StyledTableCell align='center' style={{ border: 'none' }}>
                {gimg !== '' ? (
                    <a href={gimg} target='_blank'>
                    <img
                        src={gimg}
                        width="50" height="50"
                        className="rounded"
                    />
                    </a>
                ) : (
                    <NoImageIcon style={{ color: 'grey' }} />
                )}
        </StyledTableCell>
        
        <StyledTableCell align='center' style={{ border: 'none' }}>
            {titlenm}
        </StyledTableCell>
        <StyledTableCell align='center' style={{ border: 'none' }}>
          {descriptionstr}
        </StyledTableCell>
        <StyledTableCell
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
          }}
          align='center'
        >

            <Button
              style={{fontSize:"9px",marginLeft:"10px"}}

              variant='outlined'
              startIcon={<RemoveRedEyeIcon />}
              onClick={async () => {
                await dispatch(getGroceryListIngredients(id,history));
                localStorage.setItem('urlRoute', "view_grocery_details");
                localStorage.setItem('urlId', id);
                      history.push(viewGroceryDetails(id));
              }}
            >
              View grocery list
            </Button>
          {/* <IconButton
                  title='View Grocery Details'
                    onClick={async () => {
                      await dispatch(getGroceryListIngredients(id,history));
                      history.push(viewGroceryDetails(id));
                    }}
                  >
              <RemoveRedEyeIcon style={{ color: 'red' }} />
          </IconButton> */}
          <Button
              style={{fontSize:"9px",marginLeft:"10px"}}

              variant='outlined'
              startIcon={<EditIcon />}
              onClick={async () => {
                await dispatch(getGroceryList(id,history));
                history.push(editGroceryListById(id));
              }}
            >
              Edit grocery list
            </Button>
            {/* <IconButton
            size='small'
            onClick={async () => {
              await dispatch(getGroceryList(id,history));
              history.push(editGroceryListById(id));
            }}
          >
            <Edit
              style={{
                color: '#0092BC',
                // 'gold'
              }}
            />
          </IconButton> */}
          <Button
              style={{fontSize:"9px",marginLeft:"10px"}}

              variant='outlined'
              startIcon={<DeleteIcon />}
              onClick={async () => {
                await getConfirm(id);
              }}
            >
              Delete grocery list
            </Button>
          {/* <IconButton
            size='small'
            onClick={async () => {
             await getConfirm(id);
              
            }}
          >
            <DeleteIcon style={{ color: 'tomato' }} />
          </IconButton> */}
        </StyledTableCell>
        
      </StyledTableRow>
     
    </React.Fragment>
  );
};
