import { createStyles, Theme } from '@material-ui/core';

const viewStudentDetailsStyles = (theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      // backgroundColor: theme.palette.grey[800],
      // color: theme.palette.common.white,

      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      // backgroundImage: 'url(https://source.unsplash.com/random)',
      // backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '80%',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    avatar: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
    },
    emailDiv: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  });

export default viewStudentDetailsStyles;
