import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
// import ViewStudentTasksTable from './ViewStudentTasksTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootState } from '../../app/rootReducer';
import { getGroceryListForStudent } from '../../slices/studentSlice';
import GroceryListTable from './GroceryListTable';
import { AddOrEditGroceryItem } from './AddOrEditGroceryItem/AddOrEditGroceryItem';
import { getGroceryList, getGroceryListIngredients, getGroceryListIngredientsRefresh } from '../../slices/grocerySlice';

interface IGroceryListProps {}

const GroceryList: React.FC<IGroceryListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const { studentId } = useParams<{ studentId: string }>();
  const { groceryId } = useParams<{ groceryId: string }>();
  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  useEffect(() => {
    if(user_id)
    {
      dispatch(getGroceryListIngredientsRefresh(groceryId,user_id,history));
    }
    else
    {
      dispatch(getGroceryListIngredients(groceryId,history));
    }
    return () => {};
  }, []);

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      style={{ height: '100vh' }}
    >
      <Grid item xs={12} md={10}>
        <GroceryListTable />
      </Grid>
      {/* <Grid item xs={12} md={4}> */}
      {/* {student && <ViewStudentDetails student={student} />} */}
      {/* <AddOrEditGroceryItem /> */}
      {/* </Grid> */}
    </Grid>
  );
};

export { GroceryList };
