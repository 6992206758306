import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  Dialog,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedRegisterFormValues } from './EnhancedRegisterForm';
import { RootState } from '../../../app/rootReducer';
import { LOGIN, PRIVACY_POLICY, REGISTER, REGISTER_ORGANISATION } from '../../../constants/routes';
import LockIcon from '@mui/icons-material/Lock';
import { USER_ROLE } from '../../../constants/userRoles';
import { authStart } from '../../../slices/authSlice';
import * as REQUESTS from '../../../api/counselorRequests';
import SearchIcon from '@mui/icons-material/Search';
import { createOrganisation } from '../../../api/programManagerRequests';
import { setSuccessMsg } from '../../../slices/alertSlice';

interface IRegisterProps {}

const useStyles = makeStyles(formStyles);

interface IRegisterFormProps {}

export const RegisterForm: React.FC<
  IRegisterFormProps & FormikProps<EnhancedRegisterFormValues>
> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange,setFieldValue } =
    props;
  const handleRegisterSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };
  const { authLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
    };
  }, shallowEqual);

  

  const [inputDis,setInputDis]=useState(true)
  const [codeerr, setCodeerr] = useState("");

  function getchkrole()
  {
    console.log("chkrole--->"+values.role);
    if(values.role!==0)
    {
      setInputDis(true)
      checkOrgName()
    }
    else
    {
      setInputDis(false)
      checkOrgName()
    }
  }
  const  checkOrgName=(async ()=>{
    // if(values.schoolName==="" && values.role===0)
    // {
    //     authStart()
    //     setCodeerr("Organisation name is required")
    // }
    // else
    // {

      setCodeerr("")
      try {
              const orgData = await REQUESTS.getOrganisationByName(values.schoolName);

              if(orgData)
              { 
                console.log("test---->"+JSON.stringify(orgData))
                if (Object.keys(orgData).length > 0) {
                    setCodeerr("Organisation already exist")
                    setFieldValue('schoolName','')
                }
              }
              else
              {
                //console.log("test")
              }
            }
            catch (err) {
              throw err;
            }
    //}
  })
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenModal(false);

    //(setIngredient(null));
  };
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [firstchk, setfirstchk] = useState(false);
  const [programname, setprogramname] = useState("");
  const [tellus, settellus] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  const [firstnameerr, setfirstnameerr] = useState("");
  const [lastnameerr, setlastnameerr] = useState("");
  const [emailerr, setemailerr] = useState("");
  const [phonenoerr, setphonenoerr] = useState("");
  const [firstchkerr, setfirstchkerr] = useState("");
  const [programnameerr, setprogramnameerr] = useState("");
  const [telluserr, settelluserr] = useState("");
  const [flagname, setflag] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function addOrganisation()
{
  setflag("err")

  if(firstname==="")
  {
    setfirstnameerr("First name is required.");
    setflag("err")
  }
  else
  {
    setfirstnameerr("");
    setflag("")

  }

  if(lastname==="")
  {
    setflag("err")

    setlastnameerr("Last name is required.");
  }
  else
  {
    setlastnameerr("");
    setflag("")

  }

  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if(email==="")
  {
    setemailerr("Email is required.");
    setflag("err")
  }
  else
  {
    setemailerr("");
    setflag("")

  }
  if(!email.match(validRegex))
  {
    setemailerr("Invalid email address!");
    setflag("err")
  }
  else
  {
    setemailerr("");
    setflag("")

  }
  if(phoneno==="")
  {
    setphonenoerr("Phone no is required.");
    setflag("err")
  }
  else
  {
    setphonenoerr("");
    setflag("")

  }

  

  if(programname==="")
  {
    setprogramnameerr("Program manager name is required.");
    setflag("err")
  }
  else
  {
    setprogramnameerr("");
    setflag("")

  }

  if(tellus==="")
  {
    settelluserr("Field is required.");
    setflag("err")
  }
  else
  {
    settelluserr("");
    setflag("")

  }

  if(firstchk===false)
  {
    setfirstchkerr("Field is required.");
    setflag("err")
  }
  else
  {
    setfirstchkerr("");
    setflag("")

  }

  console.log("flag -"+flagname+'----- '+lastname+'----'+tellus)
  if(flagname==="err" || tellus==="" || firstchk===false || lastname==="" || firstname==="" || email==="" || programname==="" || phoneno==="")
  {
    console.log("not send"+lastname);

    return false;
  }
  else
  {
    //return true;
    console.log("check error true");
    
    const fullname=firstname+" "+lastname;
    if(email!=="" && flagname==="")
    {
      console.log("send");
      setIsLoading(true)
      const orgData = await createOrganisation(phoneno,fullname,email,programname,tellus,firstchk,countryCode);
      if(orgData)
      {
        console.log("result-->"+JSON.stringify(orgData))
          setfirstname("");
          setlastname("");
          setemail("");
          setphoneno("");
          setfirstchk(false);
          setprogramname("");
          settellus("");
          setOpenModal(false)
          dispatch(setSuccessMsg(`Organization created Succesfully and waiting for approval  !`));
          setIsLoading(false)
          //window.location.reload()
      }
      else
      {
        setemail("");
        setemailerr("Email already exist !");
            setflag("err")

      }
    }
   
  }
}


const checkcheckbox = (event:any) => {
  //alert(firstchk)
  setfirstchk(event.target.checked);
}
function redirectSolutions()
  {
    window.open('https://nflyte.com/terms/','_blank');
  }
  function redirectPrivacy()
  {
    window.open('https://nflyte.com/privacy-policy/','_blank');
  }
  return (
    <div>
      <div style={{ margin: '0px 0px 22px' }}>
        <Typography variant='body1' className={classes.loginText}>
          SIGNUP
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Create an account
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          Fill out the form to get started with NFlyte.
        </Typography>
        <br />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={7} xs={12}>
                <Typography variant='subtitle2'>
                Already have an account?{' '}
                <Link to={LOGIN} className={classes.link}>
                  Login
                </Link>
              </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
          {/* <Link to={REGISTER_ORGANISATION} className='btn btn-warning'>
          Are you an organisation ? <br/>Click here to sign up
                </Link> */}
          <a   onClick={(e) => {
                   setOpenModal(true)
                   //history.push(REGISTER_ORGANISATION)
                  }} 
                  className='btn btn-warning' >Are you an organization?<br/>Click to tell us more</a>

          </Grid>
        </Grid>
      </div>
      <form onSubmit={handleRegisterSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>

              <FormControl required className={classes.formControl}>
                <Typography variant='subtitle1' className={classes.label}>
                  Choose user type <span style={{color:'red'}}>*</span>
                </Typography>
                <Select
                  labelId='select-role'
                  value={values.role}
                  onChange={handleChange('role')}
                  variant='outlined'
                  onBlur={getchkrole}
                >
                  <MenuItem selected value={USER_ROLE.PARENT}>
                    Parent
                  </MenuItem>
                  {/* <MenuItem value={USER_ROLE.SUPER_ADMIN}>
                    Super Admin
                  </MenuItem>
                  <MenuItem value={USER_ROLE.PROGRAM_MANAGER}>
                    Program Manager
                  </MenuItem> */}
                  
                </Select>
              </FormControl>
          </Grid>
          {/* <Grid item md={12} xs={12} >
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter organization name
              </Typography>
              <TextField
                placeholder='Organization name'
                type='text'
                name='schoolName'
                variant='outlined'
                size='small'
                id="div_org_name"
                value={values.schoolName}
                onChange={handleChange}
                onBlur={checkOrgName}
                error={(errors.schoolName && touched.schoolName) as boolean}
                helperText={
                  errors.schoolName && touched.schoolName && errors.schoolName
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
                disabled={inputDis}
              />{' '}
            </FormControl>
            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{codeerr}</span>

          </Grid> */}
          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter your name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Name'
                type='text'
                name='name'
                variant='outlined'
                size='small'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.name && touched.name) as boolean}
                helperText={errors.name && touched.name && errors.name}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          
        </Grid>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter your email <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            id='emailAddress'
            placeholder='Email'
            type='email'
            name='email'
            variant='outlined'
            size='small'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.email && touched.email) as boolean}
            helperText={errors.email && touched.email && errors.email}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter your password <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='password'
                placeholder='Enter Password'
                type='password'
                name='password'
                variant='outlined'
                size='small'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete={'off'}
                error={(errors.password && touched.password) as boolean}
                helperText={
                  errors.password && touched.password && errors.password
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Re-enter your password <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Confirm password'
                type='password'
                variant='outlined'
                size='small'
                name='passwordConfirmation'
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  (errors.passwordConfirmation &&
                    touched.passwordConfirmation) as boolean
                }
                autoComplete={'off'}
                helperText={
                  errors.passwordConfirmation &&
                  touched.passwordConfirmation &&
                  errors.passwordConfirmation
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          
        </Grid><br/>
        <FormControl className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={6} xs={12}>
              <Typography variant='subtitle2'>
                By clicking the "Sign up" button you agree with our{' '}
                <Link  onClick={redirectSolutions} to={undefined} className={classes.link}>
                  {' '}
                  terms and conditions.{' '}
                </Link>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={authLoading}
                // fullWidth
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
      <Dialog
      
              open={openModal}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              style={{padding: '1rem', width: '100%'}}
              
            >
              <div className='row' style={{ padding: '1rem', width: '100%',height:"100%" }}>
               
               <div className='row' style={{ padding: '1rem', width: '100%' }}>
                 <div className='col-sm-12'>
                   <h5>NFlyte for Programs</h5>
                   <p style={{fontSize:'12px',color:'gray'}}>Thank you for your interest. We would love to know more about your program, so we can assist you better. You will be contacted by one of our NFlyte members soon. We would love to be part of the process of supported independence.
.</p>
                         
                 </div>
               </div>
               <Grid
                   container
                   direction='row'
                   justifyContent='space-between'
                   alignItems='center'
                   style={{padding:'10px'}}
                 >
                   <Grid item md={6} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter First Name <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter First Name'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={firstname}
                         onChange={(e) => setfirstname(e.target.value)}
                         
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstnameerr}</span>
                   </Grid>
                   <Grid item md={6} xs={12} style={{padding:'10px'}}>
                   <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Last Name <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter Last Name'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={lastname}
                         onChange={(e) => setlastname(e.target.value)}
                         
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{lastnameerr}</span>
                   </Grid>
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Email <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter Email'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={email}
                         onChange={(e) => setemail(e.target.value)}
                        
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{emailerr}</span>
                   </Grid>
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Phone No With Country Code <span style={{color:'red'}}>*</span>
                       </Typography>
                       <div className='row' style={{ width: '100%' }}>
                              <div className='col-sm-3'>
                              <TextField
                                    placeholder='+1'
                                    type='text'
                                    name='countryCode'
                                    variant='outlined'
                                    size='small'
                                    id="div_org_name"
                                    value={countryCode}
                                      style={{width:'100%'}}      
                                  onChange={(e) => setCountryCode(e.target.value)}
                                    
                                    
                                  />
                              </div>
                              <div className='col-sm-9'>
                              <TextField
                                  placeholder='Enter Phone No'
                                  type='number'
                                  name='schoolName'
                                  variant='outlined'
                                  size='small'
                                  id="div_org_name"
                                  value={phoneno}
                                  style={{width:'100%'}}      

                                  onInput = {(e:any) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                }}              
                                onChange={(e) => setphoneno(e.target.value)}
                                  
                                  
                                />{' '}
                              </div>
                        </div>                       
              
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{phonenoerr}</span>
                   </Grid>
                   {/* <Grid item md={1} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       
                      <input type='checkbox' style={{height:'15px',width:'15px'}}  onChange={checkcheckbox}/>
                     </FormControl>
                   </Grid> */}
                   {/* <Grid item md={11} xs={12} style={{padding:'0px',marginTop:'-11px'}}>
                   <span style={{}}>I am a representative of a program or organization </span> <span style={{color:'red'}}>*</span><br/>
                   <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstchkerr}</span>
           
                   </Grid> */}
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Program Name <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter Program Name'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={programname}
                         onChange={(e) => setprogramname(e.target.value)}
                        
                         InputLabelProps={{
                           classes: {
                             root: classes.heading,
                             focused: classes.cssFocused,
                           },
                         }}
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{programnameerr}</span>
                   </Grid>
                   
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                       Tell us about your program <span style={{color:'red'}}>*</span>
                       </Typography>
                       <textarea placeholder='Tell us about your program' rows={4} className='form-control' onChange={(e) => settellus(e.target.value)}></textarea>
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{telluserr}</span>
                   </Grid>
                   <Grid item md={1} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       
                      <input type='checkbox' style={{height:'15px',width:'15px'}}  onChange={checkcheckbox}/>
                     </FormControl>
                   </Grid>
                   <Grid item md={11} xs={12} style={{padding:'0px',marginTop:'-11px'}}>
                   <span style={{}}>By selecting ‘Submit’, you are confirming that you have read and agree to NFlyte’s 
                   <Link  onClick={redirectSolutions} to={undefined}  className={classes.link}>
                            {' '}
                            Terms of Use{' '}
                          </Link> 
                          and 
                  <Link  onClick={redirectPrivacy} to={undefined}  className={classes.link}>
                    {' '}
                    Privacy Policy.{' '}
                  </Link> .
                     </span> <span style={{color:'red'}}>*</span><br/>
                   <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstchkerr}</span>
           
                   </Grid>
                   <Grid item md={1} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       
                      <input type='checkbox' style={{height:'15px',width:'15px'}}/> 
                     </FormControl>
                   </Grid>
                   <Grid item md={11} xs={12} style={{padding:'0px'}}>
                   I would like to receive email notifications with the latest information, promotions and exclusive offers from NFlyte. 

                   </Grid>
                   <br/><br/>
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                         <p style={{fontSize:'12px',color:'gray'}}>You can unsubscribe any time. NFlyte is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us.
</p>
                         
                         {/* <Typography variant='subtitle2' style={{fontSize:'12px',color:'gray'}}>
                          By clicking the "Submit" button you agree with our{' '}
                          <Link  onClick={redirectSolutions} to={undefined}  className={classes.link}>
                            {' '}
                            terms and conditions.{' '}
                          </Link>
                        </Typography> */}
                   </Grid>
                   
               </Grid>
               <div className='row' style={{ padding: '1rem', width: '100%' }}>
                       <div className='col-sm-12'>
                       
                        </div>
                </div>
               
                 <div className='row' style={{ padding: '1rem', width: '100%' }}>
                       <div className='col-sm-4'>
                       <Button
                           className={classes.secondaryButton}
                           variant='contained'
                           color='primary'
                           onClick={(e) => {addOrganisation()}}
                           type='button'
                         >
                           Submit &nbsp;&nbsp;&nbsp;
                        {isLoading?<div >
                                        <div className="spinner-border text-white" role="status" style={{height:'20px',width:'20px'}}>
                                            <span style={{height:'20px',width:'20px'}} className="sr-only">Loading...</span>
                                        </div> 
                                    </div>:""}
                         </Button>
                       </div>
                         <div className='col-sm-3 text-right'>
                         <Button
                           className={classes.secondaryButton}
                           variant='contained'
                           color='primary'
                           onClick={(e) => {setOpenModal(false)}}
                         >
                           Cancel
                         </Button>
                         </div>
                   </div>
               </div>
           
            </Dialog>
    </div>
  );
};
