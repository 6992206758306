import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import { createDiagnosis } from '../../../slices/diagnosisSlice';
import { AddDiagnosisForm } from './AddDiagnosisForm';
import { NAME_REQUIRED } from '../../../constants/formMessages';

interface IDispatchProps {
  createDiagnosis: (name: string) => AppThunk;
}

export interface EnhancedAddDiagnosisFormValues {
  name: string;
}

export interface EnhancedAddDiagnosisFormProps extends RouteComponentProps {
  name?: string;
  createDiagnosis: (name: string) => void;
}

const EnhancedAddDiagnosisForm = withFormik<
  EnhancedAddDiagnosisFormProps,
  EnhancedAddDiagnosisFormValues
>({
  mapPropsToValues: (props) => ({
    name: props.name ? props.name : '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createDiagnosis } = props;
    const { name } = values;
    resetForm({});
    createDiagnosis(name);
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddDiagnosisForm);

const EnhancedAddDiagnosisFormWithRouter = withRouter(EnhancedAddDiagnosisForm);

export default connect<null, IDispatchProps>(null, {
  createDiagnosis,
})(EnhancedAddDiagnosisFormWithRouter);
