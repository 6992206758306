import React, { useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import formStyles from '../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
    getIngidiansListForStudent,
    deleterecipeIngredient,
    getrecipeIngredientById,
} from '../../slices/recipeingredientSlice';
import { StyledTableRow } from '../../components/StyledTableRow';
import { StyledTableCell } from '../../components/StyledTableCell';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel';
//import { Paper, Button } from '@mui/material';

import {
  addRecipeIngredients,    
  editRecipeIngredient

} from '../../constants/routes';

import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
//import { USER_ROLE } from '../../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import { getRecipeByIdRefresh } from '../../slices/recipeSlice';


interface IViewRecipeDetailsProps {}

const useStyles = makeStyles(formStyles);

export const ViewRecipeDetails: React.FC<IViewRecipeDetailsProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { recipeId } = useParams<{ recipeId: string }>();

  const { recipeLoading, rtags,recipeTags, recipe,recipeUser } = useSelector(
    (state: RootState) => {
      return {
        recipeLoading: state.recipe.loading,
        rtags: state.tag.tags,
        recipeTags:state.recipe.tags,
        recipe: state.recipe.recipe,
        recipeUser:state.recipe.recipe?.userId
      };
    },
    shallowEqual
  );
  const sliderStyle={
    width: "100%",
    height: "100%"
  }

  const authlocal_user_id = localStorage.getItem('user_id');


  console.warn("gquser---->"+JSON.stringify(authlocal_user_id));
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id)
    {
      if (recipeId) {
      //   const recipe_nxt = await REQUESTS.getRecipeById(recipeId,user_id);
      // dispatch(setRecipe(recipe_nxt));

       dispatch(getRecipeByIdRefresh(recipeId,user_id));
      

      }
    }
   
  }
  
  fetchMyAPI()
    return () => {};
  }, []);

  let loginUserId="";
  if(authlocal_user_id)
  {
    loginUserId=authlocal_user_id;
  }
  let renderedStudents;
  if (recipe) {
    let uid=recipeUser;
    console.warn("recipeReport---------->"+JSON.stringify(recipe))

    //console.warn("images-->"+JSON.stringify(recipe.recipeImages));

    let renderedIngred;
    renderedStudents =(<>
        <div className='row'>
          
          <div className='col-sm-12 card'>

            <div className='row card-body' style={{fontSize:'14px'}}>
                    <div className='col-sm-3 text-center'>
                      {recipe.recipeUrl !== '' ? (
                                      <a href={recipe.recipeUrl} target='_blank'>
                                        <img
                                          src={recipe.recipeUrl}
                                          width="150" height="150"
                                          className="rounded"
                                        />
                        </a>
                      ) : (
                        <NoImageIcon style={{ color: 'grey' }} />
                      )}
                  </div>
                  <div className='col-sm-9'>
                      <div className='row'>
                        <div className='col-sm-6'>
                              <label> Title </label> - 
                              <label> {recipe.recipeTitle}</label><br/><br/>
                              
                              <label> Description </label> - 
                              <label> {recipe.recipeDescription}</label>
                        </div>
                        <div className='col-sm-6'>
                              <label> Complete Status </label> - 
                                {recipe.isCompleted===true? <label>Completed</label>:<label>Not Completed</label>}<br/><br/>
                              
                              <label> Likes </label> -
                              <label> {recipe.likeCount}</label>
                        </div>
                        
                        <div className='col-sm-6'>
                              <label> Type </label> - 
                              <label> {recipe.TypeOfRecipe}</label>
                        
                        </div>
                        <div className='col-sm-6'>
                              <label> Preparation Time </label> - 
                              <label> {recipe.preparationTime}</label>
                        </div>
                      </div>
                  </div>
            </div>
          </div>
          <div className='col-sm-12'>
          {
           
          recipe.recipeImages.length > 0?
          
          <>
          <h4>More Images</h4>
          <div className='row card'>
          <div className='col-sm-12'>
                  {

                    recipe.recipeImages.map((recipeImage, index) => {

                      const { imageUrl }=recipeImage;
                      return (
                        <>
                          {imageUrl !== '' ? (
                            <a href={imageUrl} target='_blank' style={{marginLeft:"50px"}}>
                              <img
                                src={imageUrl}
                                width="100" height="100"
                                className="rounded"
                              />
                            </a>
                          ) : (
                            <NoImageIcon style={{ color: 'grey' }} />
                          )}
                          </>

                      )
                    })
                  }
                  
                  </div>


                  
          </div></>:<></>

          
        }
          </div>
        </div>
        {recipe.recipeNutrition && recipe.recipeNutrition.servings!==""?
            
            <>
            <hr/>
            <h4>Nutrition Facts</h4>
            <div className='row'>

              <div className='col-sm-12'>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{textAlign:'center'}} >Servings</th>
                      <th style={{textAlign:'center'}}>Calories Per Serving</th>
                      <th style={{textAlign:'center'}}>Total Carbs</th>
                      <th style={{textAlign:'center'}}>Protein</th>
                      <th style={{textAlign:'center'}}>Total Fat</th>
                      <th style={{textAlign:'center'}}>Total Sugar</th>
                      <th style={{textAlign:'center'}}>Sodium</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                      <tr >
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.servings?recipe.recipeNutrition.servings:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.caloriesPerServing?recipe.recipeNutrition.caloriesPerServing:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.totalCarbs?recipe.recipeNutrition.totalCarbs:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.protein?recipe.recipeNutrition.protein:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.totalFat?recipe.recipeNutrition.totalFat:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.totalSugar?recipe.recipeNutrition.totalSugar:"-"}</td>
                        <td style={{textAlign:'center'}}>{recipe.recipeNutrition.sodium?recipe.recipeNutrition.sodium:"-"}</td>
                      </tr>
                
                  </tbody>
                </table>
              </div>
            </div></>:<></>
          

          
        }
          {recipe.recipeIngridients.length > 0?
          <>
          <hr/>
          <h4>Ingredient Details</h4>
          <div className='row'>

            <div className='col-sm-12'>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{width:'10%'}}>S.No.</th>
                    <th style={{width:'20%'}}>Image</th>
                    <th style={{width:'30%'}}>Title</th>
                    <th style={{width:'20%'}}>Quantity</th>
                    <th style={{width:'20%'}}>Unit</th>
                  </tr>
                </thead>
                <tbody>
                {
                     
                      recipe.recipeIngridients.map((recipeIngredient, index) => {
                        return(
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {recipeIngredient.recipeingredientUrl !== '' ? (
                                                <a href={recipeIngredient.recipeingredientUrl} target='_blank'>
                                                  <img
                                                    src={recipeIngredient.recipeingredientUrl}
                                                    width="30" height="30"
                                                  />
                                                </a>
                                              ) : (
                                                <NoImageIcon style={{ color: 'grey' }} />
                                              )}
                              </td>
                              <td>{recipeIngredient.ingredientTitle}</td>
                              <td>{recipeIngredient.quantity}</td>
                              <td>{recipeIngredient.unit}</td>
                            </tr>
                        )
                      })
                }
                </tbody>
              </table>
            </div>
          </div></>:<></>

          
        }
        {recipe.recipeSteps.length > 0?
          <>
          <hr/>
          <h4>Step Details</h4>
          <div className='row'>

            <div className='col-sm-12'>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{width:'10%'}}>S.No.</th>
                    <th style={{width:'20%'}}>Image</th>
                    <th style={{width:'20%'}}>Title</th>
                    <th style={{width:'50%'}}>Description</th>
                  </tr>
                </thead>
                <tbody>
                {
                     
                      recipe.recipeSteps.map((recipeStep, index) => {
                        return(
                            <tr key={index}>
                              <td >{index + 1}</td>
                              <td>
                                {recipeStep.stepUrl !== '' ? (
                                                <a href={recipeStep.stepUrl} target='_blank'>
                                                  <img
                                                    src={recipeStep.stepUrl}
                                                    width="30" height="30"
                                                  />
                                                </a>
                                              ) : (
                                                <NoImageIcon style={{ color: 'grey' }} />
                                              )}
                              </td>
                              <td>{recipeStep.stepTitle}</td>
                              <td>{recipeStep.stepDescription}</td>
                            </tr>
                        )
                      })
                }
                </tbody>
              </table>
            </div>
          </div></>:<></>

          
        }        
        {recipe.recipeReport.length > 0?
          <>
          <hr/>
          <h4>Report Details</h4>
          <div className='row'>

            <div className='col-sm-12'>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{width:'10%'}}>S.No.</th>
                    <th style={{width:'20%'}}>Report By User</th>
                    <th style={{width:'20%'}}>Report Reason</th>
                    <th style={{width:'50%'}}>Date</th>
                  </tr>
                </thead>
                <tbody>
                {
                     
                      recipe.recipeReport.map((recipeReport, index) => {
                        return(
                            <tr key={index}>
                              <td >{index + 1}</td>
                              <td>
                              {recipeReport.user.name}
                              </td>
                              <td>{recipeReport.report_reason}</td>
                              <td>
                              {DateTime.fromJSDate(new Date(recipeReport.createdAt)).toLocaleString(DateTime.DATE_MED)}

                              </td>
                            </tr>
                        )
                      })
                }
                </tbody>
              </table>
            </div>
          </div></>:<></>

          
        }
      

       
            
    </>)
  } else  {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Recipe Details Found.
           
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    ); 
  } 

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>

      {/* <Typography variant='h6'>
        Recipes
      </Typography> */}
      <br />
     
     
      <div className='card card-primary'>
        <div className='card-header text-white' style={{backgroundColor:'#0092BC'}}>
          <h4>Recipe Details</h4>
        </div>
        <div className='card-body'>
        {renderedStudents}
        </div>
      </div>
    </div>
  );
};
export default ViewRecipeDetails;