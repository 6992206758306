import React, {useState,useEffect } from 'react';
import { RecipeNutrition } from '../constants/models/RecipeNutrition';
import { RECIPE_ROUTE, IMAGE_ROUTE } from '../constants/routes';
import { useParams } from 'react-router';

import API from './api';
// const [rrecipeId,setRecipeId]=useState("");
// const { recipeId } = useParams<{ recipeId: string | undefined }>();
// let rcheck: string | number | readonly string[] | undefined;
// if(recipeId)
// {
//   rcheck=recipeId;
//   setRecipeId(rcheck);

// }
export async function getrecipeNutritionById(id: string) {
  try {
    const res = await API.post<{ data: RecipeNutrition; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/getRecipeNutrition`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getrecipeNutritionsForProgram(programId: string) {
  try {
    console.log("program id-->"+programId);
    const res = await API.get<{ data: RecipeNutrition[]; success: boolean }>(
      `${RECIPE_ROUTE}/${programId}${RECIPE_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createrecipeNutrition(
  servings: string,
  caloriesPerServing: string,
  totalCarbs: string,
  protein: string,
  totalFat: string,
  totalSugar: string,
  sodium: string,
  recipeId:string,
) {

  try {
    const res = await API.post<{ data: RecipeNutrition; success: boolean }>(
      `${RECIPE_ROUTE}/create-recipeNutrition`,
      {
        servings,
        caloriesPerServing,
        totalCarbs,
        protein,
        totalFat,
        totalSugar,
        sodium,
        recipeId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updaterecipeNutrition(
  id: string,
  name: string,
  recipeNutritionUrl: string
) {
  try {
    const res = await API.post<{ data: RecipeNutrition; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/updaterecipeNutrition`,
      {
        name,
        recipeNutritionUrl,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export const getParentNutritionList = async (recipeId: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${RECIPE_ROUTE}/${recipeId}/showNutritions`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};