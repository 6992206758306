import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_RECIPE_INGREDIENT_VIEW } from '../../../constants/routes';
import { getrecipeIngredientById, getrecipeIngredientByIdRefresh } from '../../../slices/recipeingredientSlice';
import EnhancedEditRecipeIngredientForm from './EnhancedEditRecipeIngredientForm';
import IngidiansList from '../../IngridiansList/IngidiansList';

interface IEditRecipesIngredientProps {}

const EditRecipeIngredient: React.FC<IEditRecipesIngredientProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();

  const { ingredientId } = useParams<{ ingredientId: string | undefined }>();
  const { recipeIngredient, recipeIngredientLoading } = useSelector((state: RootState) => {
    return {
      recipeIngredient: state.recipeIngredient.recipeIngredient,
      recipeIngredientLoading: state.recipeIngredient.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_RECIPE_INGREDIENT_VIEW ? true : false;

  if (ingredientId) {
    localStorage.setItem('urlRoute', "edit_recipe_ingredient");
    localStorage.setItem('urlId', ingredientId);
  }
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    if(user_id)
    {
      if (ingredientId) {
        dispatch(getrecipeIngredientByIdRefresh(ingredientId));

      }
    }
    else
    {
      if (!isAddStudent && ingredientId) {
        dispatch(getrecipeIngredientById(ingredientId));
      }
    }
    // if (!isAddStudent && ingredientId) {
    //   dispatch(getrecipeIngredientById(ingredientId));
    // }
    return () => {};
  }, [isAddStudent, ingredientId, dispatch]);

  if(recipeIngredient)
  {
    console.warn("grc title"+JSON.stringify(recipeIngredient));
    localStorage.setItem('userData', JSON.stringify(recipeIngredient));
  }

  let renderedForm;
  // if (!isAddStudent && recipeIngredient) {
  //    console.log("re data-->"+recipeIngredient);

  //   const {
  //     recipeingredientUrl,
  //     ingredientTitle,
  //       quantity,
  //       unit,
  //       unitType,
  //       recipeId,
  //   } = recipeIngredient;
  //   renderedForm = (
  //     <EnhancedEditRecipeIngredientForm
  //     recipeingredientUrl={recipeingredientUrl}
  //     ingredientTitle={ingredientTitle}
  //     quantity={quantity}
  //     unit={unit}
  //     unitType={unitType}
  //     recipeId={recipeId}
      
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedEditRecipeIngredientForm />;
  //}

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
        {/* <IngidiansList /> */}

      </Container>
    </div>
  );
};
export default EditRecipeIngredient;
