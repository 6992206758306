import { User } from '../constants/models/User';
import { Program } from '../constants/models/Program';
import { DAY_REPORT_ROUTE } from '../constants/routes';

import API from './api';

export async function getReport(
  date: Date,
  programId: string,
  userId: string,
  timezone: string
) {
  try {
    const res = await API.post<{
      data: {
        date: Date;
        s3Url: string;
        program: Program;
        user: User;
        createdAt: Date;
        updatedAt: Date;
        id: string;
      };
      success: boolean;
    }>(`${DAY_REPORT_ROUTE}`, {
      date,
      programId,
      userId,
      timezone,
    });
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
