import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { AddOrEditRecipeStepForm } from './AddOrEditRecipeStepForm';
import { createrecipeStep } from '../../../slices/recipeStepsSlice';
import { useParams } from 'react-router';


interface IDispatchProps {
    
    createrecipeStep: (
    stepTitle: string,
    stepDescription: string,
    stepUrl: string,
    recipeId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditRecipeStepFormValues {
    stepTitle: string;
    stepDescription: string;
    stepUrl: string;
    recipeId: string;
}

export interface EnhancedAddOrEditRecipeStepFormProps extends RouteComponentProps {
    stepTitle?: string;
    stepDescription?: string;
    stepUrl?: string;
    recipeId?: string;
    createrecipeStep: (
    stepTitle: string,
    stepDescription: string,
    stepUrl: string,
    recipeId: string,
     history: History
  ) => void;
}

const EnhancedAddOrEditRecipeStepForm = withFormik<
  EnhancedAddOrEditRecipeStepFormProps,
  EnhancedAddOrEditRecipeStepFormValues
>({
  mapPropsToValues: (props) => ({
    stepTitle: props.stepTitle ? props.stepTitle : '',
    stepDescription: props.stepDescription ? props.stepDescription : '',
    stepUrl: props.stepUrl ? props.stepUrl : '',
    recipeId: props.recipeId ? props.recipeId : '',
   
  }),
  validationSchema: Yup.object().shape({
    stepDescription: Yup.string().required(DESCRIPTION_REQUIRED),
    stepTitle: Yup.string().required(NAME_REQUIRED),
    stepUrl: Yup.string().notRequired(),
    recipeId: Yup.string().notRequired(),
   
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createrecipeStep } = props;
    const {
        stepDescription,
        stepTitle,
        stepUrl,
        recipeId
    } = values;
    resetForm({});

    createrecipeStep(
        stepDescription,
        stepTitle,
        stepUrl,
        recipeId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditRecipeStepForm);

const EnhancedAddOrEditRecipeStepFormWithRouter = withRouter(
    EnhancedAddOrEditRecipeStepForm
);

export default connect<null, IDispatchProps>(null, {
    createrecipeStep,
})(EnhancedAddOrEditRecipeStepFormWithRouter);
