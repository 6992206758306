import React, {useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
} from '@material-ui/core';

import formStyles from '../../../assets/jss/components/formStyles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedEditRecipeFormValues } from './EnhancedEditRecipeForm';
import { useHistory, useLocation } from 'react-router';
import { addRecipeIngredients, ADD_RECIPE, ADD_RECIPE_VIEW, ADD_STUDENT, MY_RECIPES } from '../../../constants/routes';
//import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { addLibraryImageToS3, addRecipeToS3 } from '../../../api/recipeRequests';
import { getrecipeTags } from '../../../slices/tagSlice';
import { OptionsOrGroups } from 'react-select/dist/declarations/src';
import RecipeImagesListFom from '../RecipeImagesListFom';
import { addRecipeImagesToS3, getRecipeById } from '../../../slices/recipeSlice';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { setFileName } from '../../../slices/taskSlice';
import { addImageToS3 } from '../../../slices/commonSlice';
import Resizer from "react-image-file-resizer";
import { setSuccessMsg } from '../../../slices/alertSlice';


interface IEditRecipeFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const EditRecipeForm: React.FC<
  IEditRecipeFormProps & FormikProps<EnhancedEditRecipeFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAddStudent = location.pathname === ADD_RECIPE_VIEW;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const { recipeLoading, rtags,recipe,recipeData,commonUrl } = useSelector(
    (state: RootState) => {
      return {
        recipeLoading: state.recipe.loading,
        rtags: state.tag.tags,
        recipe:state.recipe.tags,
        recipeData: state.recipe.recipe,
        commonUrl:state.commonLists.commonUrl

      };
    },
    shallowEqual
  );
  
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };

  const userData = localStorage.getItem('userData');
let arrchk=[1,2,3];
  useEffect(() => {
    if(userData)
    {
     const userDt = JSON.parse(userData);
 
     //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('recipeTitle',userDt.recipeTitle?userDt.recipeTitle:'')
             setFieldValue('recipeUrl',userDt.recipeUrl?userDt.recipeUrl:'')
             setFieldValue('recipeDescription',userDt.recipeDescription?userDt.recipeDescription:'')
             setFieldValue('TypeOfRecipe',userDt.TypeOfRecipe?userDt.TypeOfRecipe:'')
             setFieldValue('tags',userDt.tags?userDt.tags:'')
             setFieldValue('preparationTime',userDt.preparationTime?userDt.preparationTime:'')
            
      // }
    }
     
   }, [arrchk.length]) 


  useEffect(() => {
    dispatch(getrecipeTags());
    return () => {};
  }, [rtags.length]);

  let renderedCounselorOptionsNext: string | { value: string; label: string; }[] |  undefined =[];

  let rdata: string[];
if(values.tags)
{
  console.log("tags-->"+JSON.stringify(values.tags))

          if(values.tags.length > 0)  
          {

                renderedCounselorOptionsNext= values.tags.map((tag:any) => {
                    
                      return {
                        value: tag.id,
                        label: tag.type,
                      };
                    });
          }
}
let renderedRtypeOptionsNext: string | { value: string; label: string; }[] |  undefined =[];

if(values.TypeOfRecipe)
{
  console.log("tags-->"+JSON.stringify(values.tags))

            renderedRtypeOptionsNext=[ { "label": values.TypeOfRecipe,
                                          "value": values.TypeOfRecipe
                                        }];
        
}
function getSetTagsEdit()
{
      if(values.tags.length > 0)  
          {
            setFieldValue(
              'tags',
              values.tags.map((tag:any) => tag.id)
            );
                renderedCounselorOptionsNext= values.tags.map((tag:any) => {
                  
                      return {
                        value: tag.id,
                        label: tag.type,
                      };
                    });
              
            //setFieldValue('counselors',renderedCounselorOptionsNext);
          }
}
  //let renderedRecipeTags: boolean | OptionsOrGroups<any, any> | undefined=[];
//let renderedRecipeTags;
// if(tags)
// {
//   if(recipeTag)
//   {
//         if(recipeTag.length > 0)  
//         {
//               renderedCounselorOptionsNext= recipeTag.map((option) => {
                  
//                     return {
//                       value: option.id,
//                       label: option.name,
//                     };
//                   });
            
//           //setFieldValue('counselors',renderedCounselorOptionsNext);
//         }
// }


  let renderedRecipeTags= rtags.map((tag) => {
    return {
      value: tag.id,
      label: tag.type,
    };
  });
//}

let recipeidnext="";
  if(recipeData)
  {
    recipeidnext=recipeData.id;
  }

  let renderType=[];
  // renderType=[
  //   { "label": "Other",
  //   "value": "Other"
  // },
  //   { "label": "Breakfast",
  //   "value": "Breakfast"
  // },
  // { "label": "Lunch",
  // "value": "Lunch"
  // },
  // { "label": "Dinner",
  //   "value": "Dinner"
  // }];
  renderType=["Other","Breakfast","Lunch","Dinner"];
  let renderedAltName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  renderedAltName = values.recipeTitle;
  renderedAvatar =
    values.recipeUrl !== 'no-url' ? values.recipeUrl : renderedAvatar;

  const [openModal, setOpenModal] = useState(false);
  let url:string[];
  const [recipeUrl1,setRecipeUrl1]= useState("");
  const [openModal1, setOpenModal1] = useState(false);
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  const handleSelectChange = (event:any) => {
    const selectedOption = event.target.value;
    setFieldValue('TypeOfRecipe',selectedOption);
  };
  // const [arrtg,setArrTg]=useState();
  // if(renderedCounselorOptionsNext)
  // {
  //   if(renderedCounselorOptionsNext.length > 0)
  //   {
  //     setArrTg(renderedCounselorOptionsNext);
  //   }
  // }
  console.log("tags-->"+JSON.stringify(values.TypeOfRecipe))
  const [imgerr, setImgErr] = useState("");
  const [ptimeerr, setPtimeErr] = useState("");

  function chkImageValidate()
  {
    if(!values.recipeUrl)
    {
        setImgErr("Image is required");
    }
    else
    {
      setImgErr("");

    }

    if(!values.TypeOfRecipe)
    {
      setPtimeErr("Recipe Type is required");
    }
    else
    {
      setPtimeErr("");

    }
  }
  async function getSaveIndraft(id:string) {
    let crecipe=window.confirm("Do you want save this recipe in draft.")
    if(crecipe)
    {
      history.push(MY_RECIPES);
      dispatch(setSuccessMsg(`Recipe saved in drafts successfully..!`));

    }
  }
  return (
    <div>
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      //history.push(ADD_RECIPE);
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>
      <div style={{ margin: '0px 0px 12px' }}>
        
        <Typography variant='h6' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          {isAddStudent ? 'Edit Recipe' : 'Edit Recipe'}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {isAddStudent ? 'Enter student details' : ''} */}
          {isAddStudent ? 'Enter Recipe Details' : ''}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          
        >
            <Grid item md={4} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 56, height: 56 }}
                /> */}
                {values.recipeUrl && values.recipeUrl !== 'no-url'? 
                  <img src={values.recipeUrl} style={{ width: 56, height: 56 }}/>:
                  <img src={SIMG} style={{ width: 56, height: 56 }}/>
                }
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem', fontSize:'12px',}}
                className={classes.label}
              >
                Add Recipe Picture
                <p style={{fontSize:'11px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
                <span style={{color:'red',fontWeight:'100'}}>{imgerr}</span>

              </Typography>
            </div>
          </Grid>
          <Grid item md={1} xs={12}>

          </Grid>
          <Grid item md={7} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Recipe Name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Title'
                type='text'
                name='recipeTitle'
                variant='outlined'
                size='small'
                value={values.recipeTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                //onKeyUp={getSetTagsEdit}
                error={(errors.recipeTitle && touched.recipeTitle) as boolean}
                helperText={errors.recipeTitle && touched.recipeTitle && errors.recipeTitle}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter Description <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='recipeDescription'
                placeholder='Description'
                type='text'
                name='recipeDescription'
                variant='outlined'
                size='small'
                value={values.recipeDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                //onKeyUp={getSetTagsEdit}
                error={(errors.recipeDescription && touched.recipeDescription) as boolean}
                helperText={errors.recipeDescription && touched.recipeDescription && errors.recipeDescription}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={4}>
            <FormControl required className={classes.formControl}>
              <Typography className={classes.label}>
              Recipe Type <span style={{color:'red'}}>*</span>
              </Typography>
              {/* <AsyncSelect
                cacheOptions
                defaultOptions={renderType}
                styles={customStyles}
                defaultInputValue={values.TypeOfRecipe}
                onChange={(option) => {
                  if (option) {
                    setFieldValue('TypeOfRecipe', option);
                   // getSetTagsEdit();

                  }
                }}
              /> */}
               <select className='form-control' value={values.TypeOfRecipe} onChange={handleSelectChange}>
        {/* <option value="">Select an option</option> */}
        <option value="Other">Other</option>
        <option value="Breakfast">Breakfast</option>
        <option value="Lunch">Lunch</option>
        <option value="Dinner">Dinner</option>
      </select>
      {/* <p>Selected Value: {values.TypeOfRecipe}</p> */}
      <span style={{color:'red',fontWeight:'100',fontSize:'12px'}}>{ptimeerr}</span>

            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Tags 
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                key={renderedRecipeTags.length}
                defaultOptions={renderedRecipeTags}
                styles={customStyles}
                //value={ta} 
                
                onChange={(option) => {
                  setFieldValue(
                    'tags',
                    option.map((option) => option.value)
                  );
                }}
                defaultValue={renderedCounselorOptionsNext.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  };
                })}
              />
              
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
              Preparation Time (In Minutes) <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Preparation Time'
                type='number'
                name='preparationTime'
                variant='outlined'
                size='small'
                value={values.preparationTime}
                onChange={handleChange}
                onBlur={handleBlur}
                //onKeyUp={getSetTagsEdit}
                error={(errors.preparationTime && touched.preparationTime) as boolean}
                helperText={errors.preparationTime && touched.preparationTime && errors.preparationTime}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>  
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
               {recipeData?
              <Grid item md={4} xs={12}>
                        <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal1(true)}>
              <img src={SIMG} style={{ width: 40, height: 40 }}/>
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem',fontSize:'12px' }}
                className={classes.label}
              >
                Add Recipe Images
                <p style={{fontSize:'11px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 100MB.</p>
              </Typography>
            </div>
          </Grid>:""

          }
           <Grid item md={1} xs={12}>

          </Grid>     
          <Grid item md={7} xs={12}>
          
            <RecipeImagesListFom />
          </Grid>
          
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >   
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={recipeLoading}
              // fullWidth
            >
             Update Recipe
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
              {recipeData && recipeData.isCompleted===false?
                <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='button'
                disabled={recipeLoading}
                // fullWidth
                onClick={() => {
                  getSaveIndraft(recipeData.id);
                }}
              >
                Save in draft 
              </Button>:""
              }
          </Grid>
          <Grid item md={3} xs={12}>
              {recipeData?
                <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='button'
                disabled={recipeLoading}
                // fullWidth
                onClick={() => {
                  history.push(addRecipeIngredients(recipeData.id));
                }}
              >
                Next
              </Button>:""
              }
          </Grid>
        </Grid>
        
       
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={async (files:any) => {
            try {
              if (files.length > 0) {
                // for(let i=0;i<files.length;i++)
                // {
                  let file = files[0];

                  Resizer.imageFileResizer(
                    file,
                    680,
                    420,
                    "JPEG",
                    100,
                    0,
                    async(uri:any) => {
                      //console.log(uri);
                      console.warn("Form data view--->" + JSON.stringify(uri));
                     let fdata= getImageUrl(uri);
                     //setImg(fdata);
                     const formData = new FormData();
                     formData.append('file', fdata);
                     //dispatch(addRecipeToS3(formData));
                     let urldatanxt = await addRecipeToS3(formData);
                     // setImage(url);
                     // url.push(urldata);
                     //console.log("res data---->"+urldatanxt)
                     //let jurl=JSON.stringify(urldatanxt)
                     setFieldValue('recipeUrl', urldatanxt);
                    },
                    "base64",
                    200,
                    200
                  );

                //   dispatch(setFileName(file.name));
                //   const formData = new FormData();
                //   formData.append('file', file);
                //   await dispatch(addImageToS3(formData,files));

                //   //const { url } = await addLibraryImageToS3(formData,files);
                //   // setImage(url);
                //  // url.push(urldata);
                //  console.log("img next-->"+commonUrl);

                //  setFieldValue('recipeUrl', commonUrl);

               // }
               // setFieldValue('recipeUrl', url);

              }
              setOpenModal(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />

<DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={10240000}
          filesLimit={5}
          open={openModal1}
          onClose={() => setOpenModal1(false)}
          onSave={async (files) => {
            try {
              if (files.length > 0) {
                for(let i=0;i<files.length;i++)
                {
                    //let file = files[0];
                    let file = files[i];
                    Resizer.imageFileResizer(
                      file,
                      680,
                      420,
                      "JPEG",
                      100,
                      0,
                      async(uri:any) => {
                        //console.log(uri);
                        console.warn("Form data view--->" + JSON.stringify(uri));
                       let fdata= getImageUrl(uri);
                       //setImg(fdata);
                       const formData = new FormData();
                       formData.append('file', fdata);
                       if(recipeidnext)
                        {
                          formData.append('recipeId', recipeidnext);
                        }     
                        dispatch(addRecipeImagesToS3(formData,recipeidnext,files));
                      
                      },
                      "base64",
                      200,
                      200
                    );
                    // const formData = new FormData();
                    // console.warn("Form data view--->"+JSON.stringify(formData));
                    // formData.append('file', file);
                    // if(recipeidnext)
                    // {
                    //   formData.append('recipeId', recipeidnext);
                    // }     
                    // dispatch(addRecipeImagesToS3(formData,recipeidnext,files));
                    //let urldata = await addRecipeImagesToS3(formData);
                    // setImage(url);
                  // url.push(urldata);
                  //setRecipeUrl1(urldata);
                  //setFieldValue('recipeUrl', urldata);
                }

               // setFieldValue('recipeUrl', url);

              }
              setOpenModal1(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
