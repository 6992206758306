import React from 'react';

import formStyles from '../../../assets/jss/components/formStyles';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { RootState } from '../../../app/rootReducer';
import { FormikProps } from 'formik';
import { EnhancedEnterEmailFormValues } from './EnhancedEnterEmailForm';
import { shallowEqual, useSelector } from 'react-redux';
import { LOGIN } from '../../../constants/routes';
import { DisplayFormikState } from '../../../components/DisplayFormikState';

interface IEnterEmailFormProps {}

const useStyles = makeStyles(formStyles);

export const EnterEmailForm: React.FC<
  IEnterEmailFormProps & FormikProps<EnhancedEnterEmailFormValues>
> = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { authLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 22px' }}>
        <Typography variant='body1' className={classes.loginText}>
          FORGOT PASSWORD
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Account Recovery
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          Enter your email, to recover your account.
        </Typography>
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter your email <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            id='emailAddress'
            placeholder=''
            type='email'
            name='email'
            variant='outlined'
            size='small'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.email && touched.email) as boolean}
            helperText={errors.email && touched.email && errors.email}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={6} xs={12}>
              <Typography variant='subtitle2'>
                <Link to={LOGIN} className={classes.link}>
                  Login instead?
                </Link>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={authLoading}
                // fullWidth
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};
