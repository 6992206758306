import { Video } from './../constants/models/Video';
import { VIDEO_ROUTE, PROGRAM_ROUTE } from './../constants/routes';

import API from './api';

export async function getVideoById(id: string) {
  try {
    const res = await API.get<{ data: Video; success: boolean }>(
      `${VIDEO_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getVideosForProgram(programId: string) {
  try {
    const res = await API.get<{ data: Video[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${VIDEO_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createVideo(
  description: string,
  videoUrl: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: Video; success: boolean }>(
      `${VIDEO_ROUTE}`,
      { description, videoUrl, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateVideo(
  id: string,
  description: string,
  videoUrl: string
) {
  try {
    const res = await API.post<{ data: Video; success: boolean }>(
      `${VIDEO_ROUTE}/${id}/updateVideo`,
      {
        description,
        videoUrl,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteVideo(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${VIDEO_ROUTE}/${id}/deleteVideo `
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
