import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { SECONDARY } from '../../constants/colors';

interface InfoListItemProps {
  onClick: () => void;
  title: string;
}

export const InfoListItem: React.FC<InfoListItemProps> = ({
  onClick,
  title,
}) => {
  return (
    <div
      className='sidebar-contact'
      // to={`/chat/${chatRoom.id}`}
      onClick={onClick}
    >
      <div
        className='sidebar-contact__avatar-wrapper'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: SECONDARY,
          }}
          className='avatar'
        >
          <GroupIcon style={{ color: 'white' }} />
        </div>

        {/* <img
            src={
              user.imageUrl
                ? user.imageUrl
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            alt={
              user.imageUrl
                ? user.imageUrl
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            className="avatar"
          /> */}
      </div>
      <div className='sidebar-contact__content'>
        <div className='sidebar-contact__top-content'>
          <h2 className='sidebar-contact__name'> {title} </h2>
          {/* <span className="sidebar-contact__time">{user.about}</span> */}
        </div>
      </div>
    </div>
  );
};
