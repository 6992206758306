import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect } from 'react';
import {
  shallowEqual,
  useSelector,
  useDispatch,
  RootStateOrAny,
} from 'react-redux';
import { onCreateMessage, onUpdateChatRoom } from '../../graphql/subscriptions';
import {
  getUserChatRooms,
  updateChatRoomMessage,
} from '../../slices/chatRoomSlice';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Sidebar } from '../../components/Messaging/Sidebar';
import HomePage from './HomePage';
import ChatRoom from './ChatRoom';
import { CHAT_ROOM } from '../../constants/routes';
import { USER_ROLE } from '../../constants/userRoles';
import { getStudentsForParent, getStudentsForProgram } from '../../slices/studentSlice';
interface ChatListProps {}

export const ChatList: React.FC<ChatListProps> = ({}) => {
  const dispatch = useDispatch();
  const {
    auth: { graphQlUser: user },
    chatRoom: { allChatRooms, lastMessage, chatRoom, role },
  } = useSelector((state: RootStateOrAny) => {
    return {
      auth: state.auth,
      chatRoom: state.chatRoom,
      role: state.auth.role,

    };
  }, shallowEqual);
  let userRole = '0';

  if (role === USER_ROLE.PROGRAM_MANAGER) {
    userRole = '0';
  } else if (role === USER_ROLE.COUNSELOR) {
    userRole = '1';
  } else if (role === USER_ROLE.PARENT) {
    userRole = '3';
  }
  const authlocal = localStorage.getItem('userRole');

console.log('check parent users--->'+authlocal);
  useEffect(() => {
    if(authlocal!=="3")
    {
      dispatch(getStudentsForProgram());

    }
    else
    {
      dispatch(getStudentsForParent());

    }
   
  }, []);
  // React.useEffect(() => {
  //   dispatch(getUserChatRooms(user));
  // }, []);
  // React.useEffect(() => {
  //   dispatch(getUserChatRooms(user));
  // }, [lastMessage]);

  // React.useEffect(() => {
  //   const subscription = API.graphql(
  //     graphqlOperation(onCreateMessage)
  //     // @ts-ignore
  //   ).subscribe({
  //     next: (data: any) => {
  //       const newMessage = data.value.data.onCreateMessage;
  //       //   console.log('egiuegf', newMessage);
  //       dispatch(
  //         updateChatRoomMessage(newMessage.chatRoomId, newMessage, user)
  //       );
  //       dispatch(getUserChatRooms(user));
  //     },
  //   });
  //   return () => subscription.unsubscribe();
  // });
  // React.useEffect(() => {
  //   const subscription = API.graphql(
  //     graphqlOperation(onUpdateChatRoom)
  //     // @ts-ignore
  //   ).subscribe({
  //     next: (data: any) => {
  //       const newMessage = data.value.data.onUpdateChatRoom;
  //       //   console.log('egiuegf', newMessage);
  //       // dispatch(updateChatRoomMessage(newMessage.chatRoomId, newMessage, user));
  //       dispatch(getUserChatRooms(user));
  //     },
  //   });
  //   return () => subscription.unsubscribe();
  // });
  // if (allChatRooms === null)
  //   return (
  //     <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
  //       Loading...
  //     </div>
  //   );

  let renderedHomePage;
  if (chatRoom) {
    renderedHomePage = <ChatRoom />;
  } else {
    renderedHomePage = <HomePage />;
  }
  return (
    <div className='app'>
      {/* <p className="app__mobile-message"> Only available on desktop 😊. </p> */}

      <div className='app-content'>
        <Sidebar />
        {renderedHomePage}
      </div>
    </div>
  );
};
//<Router>
{
  /* <Switch>
<Route path={CHAT_ROOM} component={ChatRoom} />
<Route component={HomePage} />
</Switch> */
}
//</Router>
