import { Ingredient } from './../constants/models/Ingredient';
import { INGREDIENT_ROUTE } from './../constants/routes';
import API from './api';

export async function getIngredientById(id: string) {
  try {
    const res = await API.get<{ data: Ingredient; success: boolean }>(
      `${INGREDIENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createIngredient(
  name: string,
  category: string,
  unit: string,
  price: number,
  quantity: number,
  isCompleted: boolean,
  groceryListId: string
) {
  try {
    const res = await API.post<{ data: Ingredient; success: boolean }>(
      `${INGREDIENT_ROUTE}`,
      {
        name,
        category,
        unit,
        price,
        quantity,
        isCompleted,
        groceryListId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateIngredientById(
  id: string,
  name: string,
  category: string,
  unit: string,
  price: number,
  quantity: number,
  isCompleted: boolean,
  groceryListId: string
) {
  try {
    const res = await API.post<{ data: Ingredient; success: boolean }>(
      `${INGREDIENT_ROUTE}/${id}/updateIngredient`,
      {
        name,
        category,
        unit,
        price,
        quantity,
        isCompleted,
        groceryListId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteIngredientById(id: string) {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${INGREDIENT_ROUTE}/${id}/deleteIngredient`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function markIngredientAsComplete(id: string) {
  try {
    const res = await API.post<{ data: Ingredient; success: boolean }>(
      `${INGREDIENT_ROUTE}/${id}/complete`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function markIngredientAsIncomplete(id: string) {
  try {
    const res = await API.post<{ data: Ingredient; success: boolean }>(
      `${INGREDIENT_ROUTE}/${id}/incomplete`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

// export async function getChildForParent(parentId: string) {
//   try {
//     const res = await API.get<{ data: User[]; success: boolean }>(
//       `${PARENT_ROUTE}/${parentId}${STUDENT_ROUTE}`
//     );
//     return res.data.data;
//   } catch (err) {
//     throw err;
//   }
// }
