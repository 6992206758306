import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Grid,
  Select,
  OutlinedInput,
} from '@material-ui/core';

import formStyles from '../../../assets/jss/components/formStyles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedEditStudentFormValues } from './EnhancedEditStudentForm';
import { useHistory, useLocation,useParams } from 'react-router';
import { ADD_STUDENT } from '../../../constants/routes';
import { getDiagnosesForProgram, getDiagnosesForProgramRefresh } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { getCounselorsForProgram, getCounselorsForProgramRefreshOrganization, getCounselorsForProgramRefreshParent, setCounselors } from '../../../slices/counselorSlice';
import { OptionsOrGroups, GroupBase } from 'react-select/dist/declarations/src';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Javascript } from '@mui/icons-material';
import { getSearchOrganisationBySuperAdminorList, getSearchSuperAdminListByUser } from '../../../api/programManagerRequests';


interface IEditStudentFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const EditStudentForm: React.FC<
  IEditStudentFormProps & FormikProps<EnhancedEditStudentFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  
  const isAddStudent = location.pathname === ADD_STUDENT;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const { student, studentLoading, diagnoses, counselors, organisation } = useSelector(
    (state: RootState) => {
      return {
        student: state.student.student,
        studentLoading: state.student.loading,
        diagnoses: state.diagnosis.diagnoses,
        counselors: state.counselor.counselors,
        organisation: state.organisation.organisation,
      };
    },
    shallowEqual
  );

//console.log("check stud profile data--->"+JSON.stringify(student));
const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');
const urlRoute = localStorage.getItem('urlRoute');
const urlId = localStorage.getItem('urlId');
const userData = localStorage.getItem('userData');


const { studentId } = useParams<{ studentId: string | undefined }>();

  let sg=[];
   //sg=student;
   useEffect(() => {
    if(userData)
    {
     const userDt = JSON.parse(userData);
     console.log("call function----->"+userDt);
 
     //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('name',userDt.name?userDt.name:'')
             setFieldValue('email',userDt.email?userDt.email:'')
             setFieldValue('address',userDt.address?userDt.address:'')
             setFieldValue('street',userDt.street?userDt.street:'')
             setFieldValue('city',userDt.city?userDt.city:'')
             setFieldValue('country',userDt.country?userDt.country:'')
             setFieldValue('postalCode',userDt.postalCode?userDt.postalCode:0)
             setFieldValue('about',userDt.about?userDt.about:'')
             let digarr=[];
             if(userDt.diagnoses && userDt.diagnoses.length >0)
             {
              for(let i=0;i<userDt.diagnoses.length;i++)
              {
                digarr.push(userDt.diagnoses[i].id)
              }
             }

             let counselorarr=[];
             if(userDt.studentGroups && userDt.studentGroups.length >0)
             {
              for(let i=0;i<userDt.studentGroups.length;i++)
              {
                counselorarr.push(userDt.studentGroups[i].id)
              }
             }
             setFieldValue('diagnoses',digarr)
            setFieldValue('counselors',counselorarr)
      // }
    }
     
   }, [])
  useEffect( () => {
    async function fetchMyAPI() {
        if(user_id)
        {
          dispatch(getDiagnosesForProgramRefresh(user_id));
          if(organisation)
          {
            console.log("testing")
          
          }
          else
          {
            if(counselors.length===0 && authlocal!=="4"){
              if(user_id && authlocal=="0")
              {
                //dispatch(getStudentsForProgramRefresh(user_id));
                dispatch(getCounselorsForProgramRefreshOrganization(user_id));
                //dispatch(getStudentsForProgramRefresh(user_id));
              }
              else
              {
              dispatch(getCounselorsForProgramRefreshParent(user_id));
              }
            }
            else
            {
              if(studentId)
              {
                if(user_id && authlocal=="0")
                {
                  //dispatch(getStudentsForProgramRefresh(user_id));
                  dispatch(getCounselorsForProgramRefreshOrganization(user_id));
                  //dispatch(getStudentsForProgramRefresh(user_id));
                }
                else
                {
                  const orgAdmins = await getSearchSuperAdminListByUser(studentId);
                  if(orgAdmins)
                    {
                      dispatch(setCounselors({ data: orgAdmins }));
              
                    }
                  }
              }
            
            }
          }
      
        }
        else
        {
          dispatch(getDiagnosesForProgram());
          if(organisation)
          {
            console.log("testing")
          }
          else
          {
            if(counselors.length===0 && authlocal!=="4"){
                if(user_id && authlocal=="0")
                {
                  //dispatch(getStudentsForProgramRefresh(user_id));
                  dispatch(getCounselorsForProgramRefreshOrganization(user_id));
                  //dispatch(getStudentsForProgramRefresh(user_id));
                }
                else
                {
                  dispatch(getCounselorsForProgram());
                }
            }
            else
            {
              if(studentId)
              {
                if(user_id && authlocal=="0")
                {
                  //dispatch(getStudentsForProgramRefresh(user_id));
                  dispatch(getCounselorsForProgramRefreshOrganization(user_id));
                  //dispatch(getStudentsForProgramRefresh(user_id));
                }
                else
                {
                    const orgAdmins = await getSearchSuperAdminListByUser(studentId);
                    if(orgAdmins)
                      {
                        dispatch(setCounselors({ data: orgAdmins }));
                
                      }
                }
              }
            
            } 
          }
      
        }
      }
  
      fetchMyAPI()
    return () => {};
  }, [diagnoses.length, counselors.length]);//[diagnoses.length, counselors.length]

  let renderedDiagnosesOptions = diagnoses.map((diagnosis) => {
    return {
      value: diagnosis.id,
      label: diagnosis.name,
    };
    
  });
  //let loadOptions: () => void;
  let renderedDignosisOptionsNext: string | { value: string; label: string; }[] | ((inputValue: string, callback: (options: OptionsOrGroups<{ value: string; label: string; }, GroupBase<{ value: string; label: string; }>>) => void) => void | Promise<OptionsOrGroups<{ value: string; label: string; }, GroupBase<{ value: string; label: string; }>>>) | undefined =[];
  //console.warn("Dignosis renderedCounselorOptionsNext values------------------->"+JSON.stringify(student));

  if(student)
  {
    let digarr=student?.diagnoses;
      if(digarr)  
      {
        if(digarr.length > 0)  
        {
        
          renderedDignosisOptionsNext= digarr.map((option) => {
          
            return {
              value: option.id,
              label: option.name,
            };
          });
          //setFieldValue('counselors',renderedCounselorOptionsNext);
        }
      }
  }
  //console.warn("Counselors renderedCounselorOptionsNext values------------------->"+renderedCounselorOptionsNext);


  let renderedCounselorOptionsNext: string | { value: string; label: string; }[] | ((inputValue: string, callback: (options: OptionsOrGroups<{ value: string; label: string; }, GroupBase<{ value: string; label: string; }>>) => void) => void | Promise<OptionsOrGroups<{ value: string; label: string; }, GroupBase<{ value: string; label: string; }>>>) | undefined =[];

 let op;
  if(student)
  {
    if(student.studentGroups.length > 0)  
    {
      //   let  loadOptions = () => {student.studentGroups.map((studentGroups) => {
      //       return {
      //         value: studentGroups.id,
      //         label: studentGroups.name,
      //       };
      //     });
      // };
      // op=loadOptions;
      
      //  console.warn("Counselors values------------------->"+JSON.stringify(loadOptions));

      renderedCounselorOptionsNext= student.studentGroups.map((option) => {
      
        return {
          value: option.id,
          label: option.name,
        };
      });
      //setFieldValue('counselors',renderedCounselorOptionsNext);
    }
  }
function getadddig()
{

} 
 // console.warn("Counselors renderedCounselorOptionsNext values------------------->"+renderedCounselorOptionsNext);

   let renderedCounselorOptions = counselors.map((counselor) => {
    return {
      value: counselor.id,
      label: counselor.name,
    };
  });
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
 // const [selectedValues, setSelectedValues] = useState<string[]>([]);
// Define your options array
const options: Option[] = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
  // Add more options as needed
];

// Event handler for select change
const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  setFieldValue('diagnoses',selectedOptions);
};

  return (
    <div>
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid><br/>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          {/* Students */}
          Users
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {'Edit Student profile'} */}
                 {'Edit User profile'}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {'Edit student details'} */}
              {/* {'Edit user details'} */}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter user name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Name'
                type='text'
                name='name'
                variant='outlined'
                size='small'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.name && touched.name) as boolean}
                helperText={errors.name && touched.name && errors.name}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter user email <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='emailAddress'
                placeholder='Email'
                type='email'
                name='email'
                variant='outlined'
                size='small'
                disabled={true}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.email && touched.email) as boolean}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {counselors.length > 0?
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Choose Admins
              </Typography>
              <AsyncSelect
                isMulti
                cacheOptions
                //loadOptions={op}
                //loadOptions={renderedCounselorOptionsNext?this.renderedCounselorOptionsNext:[]}
                defaultOptions={renderedCounselorOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'counselors',
                    option.map((option) => option.value)
                  );
                }}
                defaultValue={renderedCounselorOptionsNext.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  };
                })}
                //value={renderedCounselorOptionsNext}
                id="seladmin"
              />

            </FormControl>
          </Grid>:""
          }
          {diagnoses.length > 0?
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Choose Diagnosis
              </Typography>
             <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions={renderedDiagnosesOptions}
                styles={customStyles}
                onChange={(option) => {
                  setFieldValue(
                    'diagnoses',
                    option.map((option) => option.value)
                  );
                }}
                defaultValue={renderedDignosisOptionsNext.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  };
                })}
              /> 
              

            </FormControl>
          </Grid>:""
          }
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Address
              </Typography>
              <TextField
                id='address'
                placeholder='Address'
                type='text'
                name='address'
                variant='outlined'
                size='small'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.address && touched.address) as boolean}
                helperText={errors.address && touched.address && errors.address}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                City
              </Typography>
              <TextField
                placeholder='City'
                type='text'
                name='city'
                variant='outlined'
                size='small'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.city && touched.city) as boolean}
                helperText={errors.city && touched.city && errors.city}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  State/Province/Region
                </Typography>
                <TextField
                  id='state'
                  placeholder='State'
                  type='text'
                  name='state'
                  variant='outlined'
                  size='small'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.state && touched.state) as boolean}
                  helperText={errors.state && touched.state && errors.state}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Country
                </Typography>
                <TextField
                  placeholder='Country'
                  type='text'
                  name='country'
                  variant='outlined'
                  size='small'
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.country && touched.country) as boolean}
                  helperText={
                    errors.country && touched.country && errors.country
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Postal Code
                </Typography>
                <TextField
                  placeholder='Postal Code'
                  type='text'
                  name='postalCode'
                  variant='outlined'
                  size='small'
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.postalCode && touched.postalCode) as boolean}
                  helperText={
                    errors.postalCode && touched.postalCode && errors.postalCode
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                About
              </Typography>
              <TextField
                placeholder='About'
                type='text'
                name='about'
                variant='outlined'
                size='small'
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.about && touched.about) as boolean}
                helperText={errors.about && touched.about && errors.about}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            {/* <Typography variant='subtitle2'>
              {false
                ? `The login credentials will be sent to the student's email
              ${values.email}`
                : ''}
            </Typography> */}
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={studentLoading}
              // fullWidth
            >
              {'Update'} User Details
            </Button>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};
