import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
  FormControl,
  Dialog,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramCounselorRefresh,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
  setStudents,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_STUDENT,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import { changeProgramStatus, chkProgramStatus, getOrganisationById, getOrganisationList, getProgramManagerByOrgId, getSearchOrganisationBySuperAdminorList } from '../../../api/programManagerRequests';
import { FiRefreshCcw } from 'react-icons/fi';
import { Autocomplete } from '@mui/material';
import { getSearchOrganisationBySuperAdminUserist } from '../../../api/studentRequests';
import { setLoading } from '../../../slices/chatRoomSlice';
import { createProgramManagerBySuperAdmin, updateProgramManager, updateProgramManagerBySuperAdmin } from '../../../slices/programManagerSlice';
import Resizer from "react-image-file-resizer";

import { DropzoneDialog } from 'material-ui-dropzone';
import { AiFillSetting } from 'react-icons/ai';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import { setErrorMsg, setSuccessMsg } from '../../../slices/alertSlice';
import { AiOutlinePlusCircle} from 'react-icons/ai';
import { Organisation } from '../../../constants/models/Organisation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getParentById } from '../../../api/parentRequests';
import { getCounselorsForProgramRefreshParent } from '../../../slices/counselorSlice';



interface IViewParentDetailsProps {}

const useStyles = makeStyles(formStyles);

const ViewParentDetails: React.FC<IViewParentDetailsProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {  role,programManager,counselors,students } = useSelector(
    (state: RootState) => {
      return {
        role: state.auth.role,
        programManager:state.programManager.programManager,
        counselors:state.counselor.counselors,
        students:state.student.students,
      };
    },
    shallowEqual
  );

  const { parentId } = useParams<{ parentId: string | undefined }>();


const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');
 //console.log("student details - > "+students);
 localStorage.setItem('urlRoute', "edit_program");
 const [orgarr_nxt,setorgarrNxt]=useState(Object);
 const [progarr,setProgramarr]=useState([]);
 const [name,setname]=useState("");
 const [email,setemail]=useState("");
 
 const [programId,setProgramId]=useState("");
 
 const [nameerr, setnameerr] = useState("");
 const [emailerr, setemailerr] = useState("");
 
 const [programnameerr, setprogramnameerr] = useState("");


 console.log("orgid-->"+JSON.stringify(orgarr_nxt))

 
 const [cstatus,setCstatus]=useState("");
 const [isLoading, setIsLoading] = useState(false);
 const [orgSelect,setorgSelect]=useState("");
 const [imageUrl,setImageUrl]=useState("");
 

 const [openModal, setOpenModal] = useState(false);


const [firstnameerr, setfirstnameerr] = useState("");

useEffect(() => {
    async function fetchMyAPI() {
    if(parentId)
    {
        const orgDatanxt = await getParentById(parentId);
        if(orgDatanxt)
        {
          localStorage.setItem('userData', JSON.stringify(orgDatanxt));
         
          //

         //let orgnewdata=JSON.parse(orgDatanxt)
          //
          console.log("orgdta---->"+JSON.stringify(orgarr_nxt.id))
          //setname(orgarr_nxt.name)
          const userData = localStorage.getItem('userData');
          if(userData)
          {

           const userDt = JSON.parse(userData);
           setorgarrNxt(userDt)
           setProgramId(userDt.id)
           dispatch(getCounselorsForProgramRefreshParent(userDt.id));
           dispatch(getChildForParentRefresh(userDt.id, history));           
          }
          
            //setname(orgDatanxt[0].name)
        }
      
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);
  
 

  
 


async function getProgramManagerAdd()
{
  let flag=false;
  if(name==="")
  {
    setnameerr("Name is required.");
    flag=true;
  }
  else
  {
    setnameerr("");
    flag=false;

  }

  if(email==="")
  {
    setemailerr("Email is required.");
    flag=true;
  }
  else
  {
    setemailerr("");
    flag=false;

  }
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if(!email.match(validRegex))
  {
    setemailerr("Invalid email address!");
    flag=true;
  }
  else
  {
    setemailerr("");
    flag=false;

  }
  console.log("flag -"+flag)
  if(flag===true)
  {
    return false;
  }
  else{
    //return true;
    console.log("check error true");
    
    
      const orgData = await dispatch(createProgramManagerBySuperAdmin(programId,name,email,history))

      if(orgData)
      {
        console.log("result-->"+JSON.stringify(orgData))
         // setfirstname("");
         
          //window.location.reload()
      }
      else
      {
        // setemail("");
        // setemailerr("Email already exist !");
        flag=true;
      }
    
   
  }
}

async function getInactiveAdmin(e:any,cnt:any,id:any) 
{
  setCstatus(e.target.value)

  let c1=window.confirm("Do you want to change status !");
  if(c1)
  {
    //alert(e.target.value)
    let orstatus=e.target.value;
    
      const orgData = await changeProgramStatus(id,orstatus);
      if(orgData)
      {
        
        dispatch(setSuccessMsg(`Admin `+orstatus+` successfully !`));
        setUserData();

      }
    
      
  }
}
async function getInactiveUser(e:any,cnt:any,id:any) 
{
  setCstatus(e.target.value)

  let c1=window.confirm("Do you want to change status !");
  if(c1)
  {
    //alert(e.target.value)
    let orstatus=e.target.value;
    
      const orgData = await changeProgramStatus(id,orstatus);
      if(orgData)
      {
        
        dispatch(setSuccessMsg(`User `+orstatus+` successfully !`));
        setUserData();

      }
    
      
  }
}
async function setUserData()
{
        if(parentId)
        {
            const orgDatanxt = await getParentById(parentId);
            if(orgDatanxt)
            {
              localStorage.setItem('userData', JSON.stringify(orgDatanxt));
         
              setorgarrNxt(orgDatanxt)
            
              console.log("orgdta---->"+JSON.stringify(orgarr_nxt.id))
              //setname(orgarr_nxt.name)
              const userData = localStorage.getItem('userData');
              if(userData)
              {
    
               const userDt = JSON.parse(userData);
               setorgarrNxt(userDt)
               setProgramId(userDt.id)
               dispatch(getCounselorsForProgramRefreshParent(userDt.id));
               dispatch(getChildForParentRefresh(userDt.id, history));           
              }
            }

        }
        //window.location.reload()
}
let renderedCounselors;
  if (counselors.length > 0) {
    renderedCounselors = counselors.map((counselor, index) => {
      const { name,
        email, 
        schoolName: schoolName, 
        status,
        organisationId,
        id } = counselor;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          
          <StyledTableCell>
                <select value={status} className='form-control' style={{padding:'10px'}} onChange={(e)=>getInactiveAdmin(e,index,id)}>
                    <option value={"Active"} selected={status==="Active"?true:false}>Active </option>
                    <option value={"Inactive"} selected={status==="Inactive"?true:false}>Inactive </option>
                    <option value={"Delete"} selected={status==="Delete"?true:false}>Delete </option>
                  </select>
              </StyledTableCell>
         
        </StyledTableRow>
      );
    });
  } else if (progarr.length === 0) { 
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Admin found.
            {/* <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Program Manager?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  let renderedUsers;
  if (students.length > 0) {
    renderedUsers = students.map((student, index) => {
      const { name,
        email, 
        schoolName: schoolName, 
        status,
        organisationId,
        id } = student;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          
          <StyledTableCell>
                <select value={status} className='form-control' style={{padding:'10px'}} onChange={(e)=>getInactiveUser(e,index,id)}>
                    <option value={"Active"} selected={status==="Active"?true:false}>Active </option>
                    <option value={"Inactive"} selected={status==="Inactive"?true:false}>Inactive </option>
                    <option value={"Delete"} selected={status==="Delete"?true:false}>Delete </option>
                  </select>
              </StyledTableCell>
         
        </StyledTableRow>
      );
    });
  } else if (progarr.length === 0) { 
    renderedUsers = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No User found.
            {/* <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Program Manager?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedUsers = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  const handleClose = () => {
    setOpenModal(false);

    //(setIngredient(null));
  };

  
  
  if(isLoading===false)
    {
        
        return (
            
            <div style={{maxWidth:'100%'}}>
                    <div style={{ margin: '0px 0px 32px' }}>
                        <div className='row'>
                            <Button
                                color='primary'
                                startIcon={
                                  <ArrowBackIosIcon
                                    fontSize='small'
                                    style={{ marginLeft: '20px' }}
                                  />
                                }
                                onClick={(e) => {
                                  history.go(-1);
                                }}
                                size='small'
                              >
                                Back
                              </Button>
                        </div><br/>
                        <Typography variant='h4' className='card' style={{backgroundColor:'white',padding:'15px'}}>
                            <h1>Parent  Details</h1> 
                        </Typography><br/>
                        <div className='card'>
                            <div className='cord-body' style={{padding:'20px'}}>
                                    <div className='row'>
                                        <div className='col-sm-12'> 
                                  <div className='row'>
                                        <div className='col-sm-9'>
                                            <h6><u>Parent Details</u></h6>
                                        </div>
                                        
                                    </div>
                                                <div className='row'>
                                                  <div className='col-sm-4'>
                                                      {orgarr_nxt.imageUrl && orgarr_nxt.imageUrl!== ''? 
                                                      <img src={orgarr_nxt.imageUrl} style={{ width: 56, height: 56 }}/>:
                                                      <img src={SIMG} style={{ width: 56, height: 56 }}/>
                                                      }
                                                    </div>
                                                    <div className='col-sm-4'>
                                                       Name - {orgarr_nxt.name}
                                                    </div>
                                                    <div className='col-sm-4'>
                                                       Email - {orgarr_nxt.email}
                                                    </div>
                                                   
                                                </div>
                                                <div className='row'>
                                                  <div className='col-sm-4'>

                                                    </div>
                                                  <div className='col-sm-4'>
                                                       Phone No - {orgarr_nxt.phoneno}
                                                    </div>
                                                    <div className='col-sm-4'>
                                                       Status - {orgarr_nxt.status}
                                                    </div>
                                                    
                                                </div>
                                                <hr />
                                                <div className='row'>
                                                    <div className='col-sm-9'>
                                                      <h6><u>Admin Details</u></h6>
                                                    </div>
                                                    {/* <div className='col-sm-3'>
                                                      <Button
                                                          className={classes.secondaryButton}
                                                          variant='contained'
                                                          color='primary'
                                                          type='button'
                                                          // disabled={renderedLoading}
                                                          // fullWidth
                                                          onClick={(e:any) => {
                                                            setOpenModal(true)
                                                          }}
                                                          >
                                                          
                                                          <AiOutlinePlusCircle />&nbsp;&nbsp;&nbsp;&nbsp; Add Program Manager
                                                          </Button>
                                                    </div> */}
                                                </div><br/>
                                                
                                                <div className='row'>
                                                    <div className='col-sm-12' style={{overflowY:'scroll',height:'300px'}}>
                                                    <TableContainer component={Paper}>
                                                      <Table aria-label='customized table'>
                                                        <TableHead>
                                                          <TableRow>
                                                            <StyledTableCell>S.No.</StyledTableCell>
                                                            {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
                                                            <StyledTableCell>Name</StyledTableCell>
                                                            <StyledTableCell>Email</StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>{renderedCounselors}</TableBody>
                                                      </Table>
                                                    </TableContainer>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className='row'>
                                                    <div className='col-sm-9'>
                                                      <h6><u>User Details</u></h6>
                                                    </div>
                                                    {/* <div className='col-sm-3'>
                                                      <Button
                                                          className={classes.secondaryButton}
                                                          variant='contained'
                                                          color='primary'
                                                          type='button'
                                                          // disabled={renderedLoading}
                                                          // fullWidth
                                                          onClick={(e:any) => {
                                                            setOpenModal(true)
                                                          }}
                                                          >
                                                          
                                                          <AiOutlinePlusCircle />&nbsp;&nbsp;&nbsp;&nbsp; Add Program Manager
                                                          </Button>
                                                    </div> */}
                                                </div><br/>
                                                
                                                <div className='row'>
                                                    <div className='col-sm-12' style={{overflowY:'scroll',height:'300px'}}>
                                                    <TableContainer component={Paper}>
                                                      <Table aria-label='customized table'>
                                                        <TableHead>
                                                          <TableRow>
                                                            <StyledTableCell>S.No.</StyledTableCell>
                                                            {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
                                                            <StyledTableCell>Name</StyledTableCell>
                                                            <StyledTableCell>Email</StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>{renderedUsers}</TableBody>
                                                      </Table>
                                                    </TableContainer>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    <Dialog
      
                      open={openModal}
                      onClose={handleClose}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                      style={{padding: '1rem', width: '100%'}}
                      
                    >
              <div className='row' style={{ padding: '1rem', width: '100%',height:"100%" }}>
               
               <div className='row' style={{ padding: '1rem', width: '100%' }}>
                 <div className='col-sm-12'>
                   <h5>Add New Admin</h5>
                 </div>
               </div>
               <Grid
                   container
                   direction='row'
                   justifyContent='space-between'
                   alignItems='center'
                   style={{padding:'10px'}}
                 >
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Full Name <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter Full Name'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={name}
                         onChange={(e) => setname(e.target.value)}
                         
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{nameerr}</span>
                   </Grid>
                   <Grid item md={12} xs={12} style={{padding:'10px'}}>
                     <FormControl
                       required
                       className={classes.formControl}
                       style={{ width: '100%' }}
                     >
                       <Typography variant='subtitle1' className={classes.label}>
                         Enter Email <span style={{color:'red'}}>*</span>
                       </Typography>
                       <TextField
                         placeholder='Enter Email'
                         type='text'
                         name='schoolName'
                         variant='outlined'
                         size='small'
                         id="div_org_name"
                         value={email}
                         onChange={(e) => setemail(e.target.value)}
                         
                         
                       />{' '}
                     </FormControl>
                     <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{emailerr}</span>
                   </Grid>
                   
               </Grid>
               
                 <div className='row' style={{ padding: '1rem', width: '100%' }}>
                       <div className='col-sm-3'>
                       <Button
                           className={classes.secondaryButton}
                           variant='contained'
                           color='primary'
                           onClick={(e) => {getProgramManagerAdd()}}
                         >
                           Submit
                         </Button>
                       </div>
                         <div className='col-sm-3 text-right'>
                         <Button
                           className={classes.secondaryButton}
                           variant='contained'
                           color='primary'
                           onClick={(e) => {setOpenModal(false)}}
                         >
                           Cancel
                         </Button>
                         </div>
                   </div>
               </div>
           
            </Dialog>
                   
            </div>
        );
    }
    else
    {
        return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
        </div>;
    }
};
export default ViewParentDetails;
