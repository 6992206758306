import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router';
import { RootState } from '../../app/rootReducer';
import { LOGIN } from '../../constants/routes';
import { USER_ROLE } from '../../constants/userRoles';
//import { getCheckAuthParent, loadUserForGraphQl, login, loginWithGraphQl, setIsAuthenticated, setRole } from '../../slices/authSlice';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import setAuthToken from '../../utils/setAuthToken';
import { getProgramForProgramManager, getProgramManagerById } from '../../slices/programManagerSlice';
import { getChildForParent, getChildForParentRefresh, getParentById } from '../../slices/parentSlice';
import { getCheckAuthParent, setIsAuthenticated, setRole } from '../../slices/authSlice';
import { getDocumentsForProgram } from '../../slices/documentSlice';
import { getImagesForProgram } from '../../slices/imageSlice';
import { getQuestionsForProgram } from '../../slices/questionSlice';
import { getCounselorsForProgramRefreshParent } from '../../slices/counselorSlice';


interface IParentRouteProps extends RouteProps {}

const ParentRoute: React.FC<IParentRouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated, role } = useSelector((state: RootState) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      role: state.auth.role,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const graphQlUserr = localStorage.getItem('graphQlUser');
  console.warn("check auth local"+authlocal);
  const authlocaltoken = localStorage.getItem('token');
  const grapUser = localStorage.getItem('userEmail');
  const grapUserPassword = localStorage.getItem('password');
  const user_id = localStorage.getItem('user_id');
  const dispatch=useDispatch();
  const history=useHistory();
  const [stateAuth,setStateAuth] = useState(false)
  let chkauth:string;

  if(isAuthenticated)
  {
    chkauth="yes";

  }
  else
  {
    chkauth="no";

  }
if(authlocal)
{
  

}
else
{
  //setIsAuthenticated(true);
}

  let eml:string;

  if(grapUser)
  {
    eml=grapUser;
  }
  let urol:string;
  if(authlocal)
  {
    urol=authlocal;
  }

  let upassword:string;
  if(grapUserPassword) 
  {
    upassword=grapUserPassword;
  }

  let userr_id:string;
if(chkauth==="no")
{
  if(user_id && authlocaltoken )
  {
    if(authlocal && authlocal==="3")
    {
        console.log("parent route calling...");
        userr_id=user_id;
        setAuthToken(authlocaltoken);
        dispatch(setRole(USER_ROLE.PARENT));
        dispatch(getParentById(user_id));
        dispatch(setIsAuthenticated(true));
        dispatch(getChildForParentRefresh(user_id, history));
        //dispatch(getCounselorsForProgramRefreshParent(user_id));
    }
    

    // dispatch(getChildForParentRefresh(user_id, history));
    // dispatch(getCounselorsForProgramRefreshParent(user_id));

    //dispatch(getDocumentsForProgram());
    //dispatch(getImagesForProgram());
    //dispatch(getQuestionsForProgram());
  }
}
  
// let tkn:string;
// if(authlocaltoken)
// {
//   tkn=authlocaltoken;
// }

  // useEffect(()=>{
  //   if(!isAuthenticated  && urol==="3")
  //   {
  //     dispatch(getCheckAuthParent(eml,userr_id,tkn,history,chkauth));
  //   }
  // },[]); 
  //setAuthToken(token);
      
console.warn("token---->"+JSON.stringify(authlocaltoken));
 //isAuthenticated && role === USER_ROLE.PARENT

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: LOGIN,
                state: {
                  from: location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ParentRoute;
