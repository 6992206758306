/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      imageUrl
      email
      username
      about
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoomUser = /* GraphQL */ `
  subscription OnCreateChatRoomUser {
    onCreateChatRoomUser {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoomUser = /* GraphQL */ `
  subscription OnUpdateChatRoomUser {
    onUpdateChatRoomUser {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoomUser = /* GraphQL */ `
  subscription OnDeleteChatRoomUser {
    onDeleteChatRoomUser {
      id
      userId
      chatRoomId
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      groupAdmin {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          groupAdminId
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom {
    onUpdateChatRoom {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      groupAdmin {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          groupAdminId
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom {
    onDeleteChatRoom {
      id
      isGroup
      groupName
      groupImageUri
      groupDescription
      groupAdminId
      groupAdmin {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        userId
        chatRoomId
        isMedia
        mediaType
        length
        fileName
        fileType
        user {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoom {
          id
          isGroup
          groupName
          groupImageUri
          groupDescription
          groupAdminId
          lastMessageId
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      createdAt
      content
      userId
      chatRoomId
      isMedia
      mediaType
      length
      fileName
      fileType
      user {
        id
        name
        imageUrl
        email
        username
        about
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        isGroup
        groupName
        groupImageUri
        groupDescription
        groupAdminId
        groupAdmin {
          id
          name
          imageUrl
          email
          username
          about
          createdAt
          updatedAt
        }
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          userId
          chatRoomId
          isMedia
          mediaType
          length
          fileName
          fileType
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
