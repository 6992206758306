import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { TransitionGroup } from 'react-transition-group';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import NoImageIcon from '@mui/icons-material/HideImage';

import {
  deleteRecipeTagType,
  getRecipeTagTypesForProgram,
  getRecipeTagTypesForProgramRefresh,
} from '../../../slices/recipeTagTypeSlice';

interface IDisplayRecipeTagProps {}

export const DisplayRecipeTag: React.FC<IDisplayRecipeTagProps> = ({}) => {
  const dispatch = useDispatch();
  const { recipeTagTypes } = useSelector((state: RootState) => {
    return {
      recipeTagTypes: state.recipeTagType.recipeTagTypes,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(user_id)
    {
      dispatch(getRecipeTagTypesForProgramRefresh(user_id));
    }
    else
    {
      dispatch(getRecipeTagTypesForProgram());
    }
  }, [recipeTagTypes.length]);
  let renderedTaskTypes;
  if (recipeTagTypes.length > 0) {
    renderedTaskTypes = recipeTagTypes.map((recipeTagType, index) => {
      const { type, recipetypeUrl, id } = recipeTagType;
      return (
        <Collapse key={id}>
          <ListItem
            style={{ width: '100%' }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                title="Delete"
                onClick={() => {
                  dispatch(deleteRecipeTagType(id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText >
                   {recipetypeUrl !== '' ? (
                      <a href={recipetypeUrl} target='_blank'>
                        <img
                          src={recipetypeUrl}
                          width="70" height="70"
                        />
                      </a>
                    ) : (
                      <NoImageIcon style={{ color: 'grey' }} />
                    )}
              </ListItemText>

            <ListItemText primary={type} />
          </ListItem>
        </Collapse>
      );
    });
  }

  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>{renderedTaskTypes}</TransitionGroup>
        </List>
      </Box>
    </div>
  );
};
