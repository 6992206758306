import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import {
  setChatRoom,
  setChatRoomUser,
  setChatRoomUsers,
  setGroupUsers,
  setUser,
} from '../../slices/chatRoomSlice';
import { decryptMessage } from '../../utils/encryption';

interface ChatListItemProps {
  // message: any | null;
  // name: string;
  // isGroup: boolean | null;
  // lastMessage: any | null;
  // nextMessage: any | null;
  // chattingWith: any | null;
  // index: number;
  // myRef: any;
  chatRoom: any;
  chatRoomUser: any;
  index: number;
}

export const ChatListItem: React.FC<ChatListItemProps> = ({
  chatRoom,
  chatRoomUser,
  index,
}) => {
  const history = useHistory();
  const { allChatRooms, currentUser,  chatRoom2  } = useSelector((state: RootState) => {
    return {
      currentUser: state.auth.graphQlUser,
      allChatRooms: state.chatRoom.allChatRooms,
      chatRoom2: state.chatRoom.chatRoom
    };
  }, shallowEqual);
  //let  ct2=chatRoom2;
  const dispatch = useDispatch();
  if (chatRoom === undefined || chatRoom === null) {
    chatRoom=chatRoom2;
    return <></>;
  }
  console.log("chatroom--->"+JSON.stringify(chatRoom.chatRoomUsers));

  // const user =
  //   // //@ts-ignore
  //   chatRoom.chatRoomUsers.items[1] === undefined
  //     ? currentUser
  //     : // @ts-ignore
  //     chatRoom.chatRoomUsers.items[0].user
  //     ? // @ts-ignore
  //       chatRoom.chatRoomUsers.items[0].user.id !== currentUser.id
  //     : // @ts-ignore
  //       chatRoom.chatRoomUsers.items[1].user;

  // const user = chatRoom.chatRoomUsers.items[1].user

  var user:any;
  if ((chatRoom.chatRoomUsers.items[0].user != undefined) && (chatRoom.chatRoomUsers.items[0].user.id == currentUser.id) ){
    user = chatRoom.chatRoomUsers.items[1].user
  }else {
    user  = chatRoom.chatRoomUsers.items[0].user
  }
  const username = chatRoom.isGroup ? chatRoom.groupName : user.name;

  return (
    <div
      className='sidebar-contact'
      onClick={async (e) => {
        e.preventDefault();
        if (chatRoom.isGroup) {
          (async () => {
            //  @ts-ignore
            await dispatch(
              setGroupUsers(
                chatRoom.chatRoomUsers.items.map((item: any) => item.user)
              )
            );
            await dispatch(setChatRoom(chatRoom));
            await dispatch(
              setChatRoomUser(
                chatRoom.chatRoomUsers.items.find(
                  (c: any) => c.user.id === currentUser.id
                )
              )
            );
            await dispatch(setChatRoomUsers(chatRoom.chatRoomUsers.items));
          })();
        } else {
          (async () => {
            await dispatch(setUser(user));
            await dispatch(setChatRoom(chatRoom));
            await dispatch(setChatRoomUser(chatRoomUser));
          })();
        }
      }}
    >
      <div className='sidebar-contact__avatar-wrapper'>
        <img
          src={
            chatRoom.isGroup
              ? chatRoom.groupImageUri
              : user.imageUrl
              ? user.imageUrl
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
          }
          alt={
            chatRoom.isGroup
              ? chatRoom.groupImageUri
              : user.imageUrl
              ? user.imageUrl
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
          }
          className='avatar'
        />
      </div>
      <div className='sidebar-contact__content'>
        <div className='sidebar-contact__top-content'>
          <h2 className='sidebar-contact__name'>
            {`${username.slice(0, 20)}${username.length > 20 ? '...' : ''}`}{' '}
          </h2>
          <span className='sidebar-contact__time'>
            {chatRoom.lastMessage !== null &&
              moment(chatRoom.lastMessage.createdAt).fromNow()}
          </span>
        </div>
        {/* <div className="sidebar-contact__bottom-content"> */}
        {/* <p className="sidebar-contact__message-wrapper"> */}
        {chatRoom.lastMessage !== null && chatRoom.lastMessage !== undefined && (
          <div
            style={{
              flexDirection: 'row',
              color: 'gray',
              marginTop: '-1rem',
              fontSize: '0.8rem',
            }}
            // className={`sidebar-contact__message ${"sidebar-contact__message--unread"}`}
          >
            {chatRoom.isGroup && chatRoom.lastMessage
              ? chatRoom.lastMessage.user.id === currentUser.id
                ? 'You: '
                : chatRoom.lastMessage.user.name + ': '
              : // chatRoom.chatRoomUsers.items
                //     .map((item: any) => item.user)
                //     .find((u: any) => u.id === chatRoom.lastMessage.user.id)
                //     .name + ': '
                ''}

            {chatRoom.lastMessage && chatRoom.lastMessage.isMedia ? (
              <>
                {/* <MaterialIcons
                    name={
                      // @ts-ignore
                      chatRoom.lastMessage.mediaType === "image"
                        ? "insert-photo"
                        : // @ts-ignore
                        chatRoom.lastMessage.mediaType === "video"
                        ? "videocam"
                        : // @ts-ignore
                        chatRoom.lastMessage.mediaType === "audio"
                        ? "mic"
                        : chatRoom.lastMessage.mediaType === "file"
                        ? "document"
                        : "mic"
                    }
                    size={18}
                    color="rgb(140,140,140)"
                  />{" "} */}
                {/* @ts-ignore*/}
                {chatRoom.lastMessage.mediaType === 'video'
                  ? 'Video'
                  : // @ts-ignore
                  chatRoom.lastMessage.mediaType === 'image'
                  ? 'Photo'
                  : // @ts-ignore
                  chatRoom.lastMessage.mediaType === 'audio'
                  ? 'Audio'
                  : chatRoom.lastMessage.mediaType === 'file'
                  ? chatRoom.lastMessage.fileName.slice(0, 20) + '...'
                  : 'unidentified'}
              </>
            ) : (
              <>
                {`${decryptMessage(chatRoom.lastMessage.content).slice(0, 20)}${
                  decryptMessage(chatRoom.lastMessage.content).length > 20
                    ? '...'
                    : ''
                }`}
              </>
            )}
          </div>
        )}
        {/* </p> */}
        {/* </div> */}
      </div>
    </div>
  );
};
