import {
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Modal,
  Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useHistory } from 'react-router';
import { emptySelectedUsers, exitGroup } from '../../slices/chatRoomSlice';
import { SelectContactsForGroup } from '../../features/Messaging/SelectContactsForGroup';
import { ViewGroupMembers } from '../../features/Messaging/ViewGroupMembers';
import { SECONDARY } from '../../constants/colors';
import { SearchOutlined,  Close as AddOutlined } from '@material-ui/icons';

interface HeaderProps {
  user: any;
  isGroup: boolean;
}

const Header: React.FC<HeaderProps> = ({ user, isGroup }) => {
  const dispatch = useDispatch();
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [openMembersModal, setOpenMembersModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    chatRoom: { chatRoom, chatRoomUser, chatRoomUsers, chatRoomMessages },
    currentUser,
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      currentUser: state.auth.graphQlUser,
    };
  }, shallowEqual);

  return (
    <header
      className='header chat__header'
      style={{ background: '#2C7AA4', height: '70.3px' }}
    >
      <div className='chat__avatar-wrapper'>
        <img
          src={isGroup ? user.groupImageUri : user.imageUrl}
          alt={isGroup ? user.groupImageUri : user?.name}
          className='avatar'
        />
      </div>

      <div className='chat__contact-wrapper'>
        <h2 className='chat__contact-name' style={{ color: 'white' }}>
          {isGroup ? user.groupName : user?.name}
          <br />
        </h2>
      </div>
      {/* @ts-ignore */}
      {chatRoom.isGroup && isGroup && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'flex-end',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleClick}>
            <MoreVertIcon style={{ color: 'white' }} />
          </IconButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {/* @ts-ignore */}
            {chatRoom.groupAdmin.id === currentUser.id && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setOpenGroupModal(true);
                }}
              >
                Add Members
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenMembersModal(true);
              }}
            >
              View Members
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setLoading(true);
                await dispatch(
                  exitGroup(
                    chatRoomUser,
                    chatRoomUsers,
                    chatRoom,
                    currentUser,
                    chatRoomMessages,
                    history
                  )
                );
                setLoading(false);
                setAnchorEl(null);
              }}
            >
              Exit Group{' '}
              {loading && (
                <CircularProgress
                  style={{
                    color: SECONDARY,
                    fontSize: '10px',
                    marginLeft: '0.7rem',
                  }}
                />
              )}
            </MenuItem>
          </Menu>
        </div>
      )}
      <Modal
        open={openGroupModal}
        onClose={() => {
          setOpenGroupModal(false);
          dispatch(emptySelectedUsers());
        }}
        style={{ border: 'none' }}
        disableAutoFocus={true}
      >
        <div
          style={{
            marginTop: '5rem',
            outline: 'none',
            margin: '5rem auto',
          }}
        >
          
          <SelectContactsForGroup
            setOpenGroupModal={setOpenGroupModal}
            filterOn={true}
          />
        </div>
      </Modal>
      <Modal
        open={openMembersModal}
        onClose={() => {
          setOpenMembersModal(false);
          dispatch(emptySelectedUsers());
        }}
        style={{ border: 'none' }}
        disableAutoFocus={true}
      >
        <div
          style={{
            marginTop: '5rem',
            outline: 'none',
            margin: '5rem auto',
          }}
        >
           
          <ViewGroupMembers 
            setOpenMembersModal={setOpenMembersModal}
          />
        </div>
      </Modal>
    </header>
  );
};

export default Header;
