import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ViewStudentTasksTable from './ViewStudentTasksTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../app/rootReducer';
import {
  deleteTask,
  getTasksForStudent,
  getStudentTasksForDate,
  setStudentId,
  setSelectedDate,
} from '../../../slices/taskSlice';
import { getStudentById } from '../../../slices/studentSlice';
import { ViewStudentDetails } from './ViewStudentDetails';
interface IViewStudentTasksProps {}

const ViewStudentTasks: React.FC<IViewStudentTasksProps> = ({}) => {
  const dispatch = useDispatch();
  const { studentId } = useParams<{ studentId: string }>();
  const { tasks, student, selectedDate } = useSelector((state: RootState) => {
    return {
      tasks: state.task.tasks,
      student: state.student.student,
      selectedDate: state.task.selectedDate,
    };
  }, shallowEqual);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDate(new Date()));
    };
  }, []);

  localStorage.setItem('urlRoute', "user_tasks");
  localStorage.setItem('urlId', studentId);

  // useEffect(() => {
  //   if (tasks.length === 0) {
  //     dispatch(getStudentTasksForDate(studentId, selectedDate));
  //   }
  //   if (!student) {
  //     dispatch(getStudentById(studentId));
  //   }
  //   dispatch(setStudentId(studentId));
  //   return () => {};
  // }, [tasks.length]);

  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(setSelectedDate(new Date()));

    if(user_id && studentId)
    {
      dispatch(getStudentTasksForDate(studentId, selectedDate));
      dispatch(getStudentById(studentId));

    }
    else
    {
      if(studentId)
      {
        dispatch(getStudentTasksForDate(studentId, selectedDate));
        if (tasks.length === 0) {
          dispatch(getStudentTasksForDate(studentId, selectedDate));
        }
        if (!student) {
          dispatch(getStudentById(studentId));
        }
        dispatch(setStudentId(studentId));
      }
    }
  }
    fetchMyAPI()
return () => {};
}, []);//recipes.length
  useEffect(() => {
    dispatch(getStudentTasksForDate(studentId, selectedDate));
  }, [selectedDate]);//

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      style={{ height: '100vh' }}
    >
      <Grid item xs={12} md={9}>
        <ViewStudentTasksTable />
      </Grid>
      <Grid item xs={12} md={3}>
        {student && <ViewStudentDetails student={student} />}
      </Grid>
    </Grid>
  );
};

export default ViewStudentTasks;
