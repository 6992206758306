import React, { useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import formStyles from '../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
    getIngidiansListForStudent,
    deleterecipeIngredient,
    getrecipeIngredientById,
} from '../../slices/recipeingredientSlice';
import { StyledTableRow } from '../../components/StyledTableRow';
import { StyledTableCell } from '../../components/StyledTableCell';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel';
//import { Paper, Button } from '@mui/material';

import {
  addRecipeIngredients,    
  editRecipeIngredient

} from '../../constants/routes';

import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
//import { USER_ROLE } from '../../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import { deleteRecipeImage } from '../../slices/recipeSlice';


interface IRecipeImagesListFomProps {}

const useStyles = makeStyles(formStyles);

export const RecipeImagesListFom: React.FC<IRecipeImagesListFomProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { recipeId } = useParams<{ recipeId: string }>();

  const { recipeLoading, rtags,recipeTags, recipe } = useSelector(
    (state: RootState) => {
      return {
        recipeLoading: state.recipe.loading,
        rtags: state.tag.tags,
        recipeTags:state.recipe.tags,
        recipe: state.recipe.recipe,

      };
    },
    shallowEqual
  );
  const sliderStyle={
    width: "100%",
    height: "100%"
  }
  function getDeleteImage(id:string,recipeId:string)
  {
    let cm=window.confirm("Do you want delete this image!");
    if(cm)
    {
      dispatch(deleteRecipeImage(id,recipeId));
    }
  }
  let renderedStudents;
  if (recipe) {
    console.warn("recipeReport---------->"+JSON.stringify(recipe))

    //console.warn("images-->"+JSON.stringify(recipe.recipeImages));

    let renderedIngred;
    renderedStudents =(<>
                        <Grid
                              container
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                            >   
        
          {
           
          recipe.recipeImages.length > 0?
          
          <>
          
                  {

                    recipe.recipeImages.map((recipeImage, index) => {

                      const { id,imageUrl }=recipeImage;
                      return (
                        <>
                             
                              <Grid item md={1} xs={1}>
                                 {imageUrl!==recipe.recipeUrl? <IconButton
                                    onClick={async (e) => {
                                      await getDeleteImage(id,recipeId);                                     
                                    }}
                                    style={{height:'5px',width:'5px',marginTop:'25px',marginRight:'-60px'}}
                                  >
                                    <DeleteIcon style={{ color: 'red' }} />
                                  </IconButton>:""}
                              
                          {imageUrl !== '' ? (
                            // <a href={imageUrl} target='_blank' style={{marginLeft:"50px"}}>
                              <img
                                src={imageUrl}
                                className="rounded"
                                style={{height:'30px',width:'30px',marginLeft:'5px'}}
                              />
                              
                            // </a>
                          ) : (
                            <NoImageIcon style={{ color: 'grey' }} />
                          )}
                          </Grid> 

                          </>

                        

                      )
                    })
                  }
                  
                 </>:<></>

          
        }
        </Grid>  
    </>
    )
  } else  {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            {/* No Images Found. */}
           
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    ); 
  } 

  return (
    <div>

      {/* <Typography variant='h6'>
        Recipes
      </Typography> */}
      <br />
     
     
      {/* <div className='card card-primary'>
        <div className='card-header text-white' style={{backgroundColor:'#0092BC'}}>
          <h6>Recipe Images</h6>
        </div>
        <div className='card-body'> */}

        {renderedStudents}
        {/* </div>
      </div> */}
    </div>
  );
};
export default RecipeImagesListFom;