import store, { AppThunk } from "./../app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as REQUESTS from "../api/videoRequests";
import { Video } from "../constants/models/Video";
import { setErrorMsg } from "./alertSlice";
import { addLibraryImageToS3 } from "../api/imageRequests";

export interface videoState {
  loading: boolean;
  error: string | null;
  video: Video | null;
  videos: Video[];
  videoUrl: string;
  isVideoModalOpen: boolean;
}

export const initialState: videoState = {
  loading: false,
  error: null,
  video: null,
  videos: [],
  videoUrl: "",
  isVideoModalOpen: false,
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    videoStart(state) {
      state.loading = true;
      state.error = null;
    },
    setVideo(state, action: PayloadAction<Video | null>) {
      state.video = action.payload;
    },
    setVideos(state, action: PayloadAction<{ data: Video[] }>) {
      const { data } = action.payload;
      state.videos = data.map((video) => video);
    },
    setIsVideoModalOpen(state, action: PayloadAction<boolean>) {
      state.isVideoModalOpen = action.payload;
    },
    setVideoUrl(state, action: PayloadAction<string>) {
      state.videoUrl = action.payload;
    },
    videoFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : "some error";
    },
    videoComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  videoStart,
  setVideo,
  setVideos,
  setIsVideoModalOpen,
  setVideoUrl,
  videoFailure,
  videoComplete,
} = videoSlice.actions;

export default videoSlice.reducer;

export const getVideoById =
  (videoId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(videoStart());
      const video = await REQUESTS.getVideoById(videoId);
      dispatch(setVideo(video));
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getVideosForProgramRefresh = (user_id: string): AppThunk => async (dispatch) => {
    try {
      dispatch(videoStart());
     
      let program_de_id=user_id;
        
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const videos = await REQUESTS.getVideosForProgram(programId);
        dispatch(setVideos({ data: videos }));
      }
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getVideosForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(videoStart());
    // const { program } = store.getState().program;
    // const { parent } = store.getState().parent;
    // const { counselor } = store.getState().counselor;
    //   const { programManager } = store.getState().programManager;

    //   const { superAdmin } = store.getState().superAdmin;

    const authlocal = localStorage.getItem('userRole');
    const user_id = localStorage.getItem('user_id');
  
        let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id;
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const videos = await REQUESTS.getVideosForProgram(programId);
      dispatch(setVideos({ data: videos }));
    }
    dispatch(videoComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(videoFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createVideo =
  (description: string, videoUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(videoStart());
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;
      // const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

      let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id;
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const video = await REQUESTS.createVideo(
          description,
          videoUrl,
          programId
        );
        dispatch(setVideo(video));
        dispatch(setIsVideoModalOpen(true));
        dispatch(getVideosForProgram());
      }
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateVideo =
  (videoId: string, description: string, videoUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(videoStart());
      await REQUESTS.updateVideo(videoId, description, videoUrl);
      dispatch(getVideosForProgram());
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteVideo =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(videoStart());
      await REQUESTS.deleteVideo(id);
      dispatch(getVideosForProgram());
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const addVideoToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(videoStart());
      let data = await addLibraryImageToS3(formData);
      dispatch(setVideoUrl(data));
      dispatch(videoComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(videoFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
