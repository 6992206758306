import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

interface IPrivacyPolicyProps {}
const source = `
# Privacy Policy

Effective as of January 1, 2022

.

This Privacy Policy is designed to help you understand how NFlyte LLC and its subsidiaries and affiliates (collectively, “NFlyte,” “we,” “us,” or “our”), the owner and operator of the NFlyte mobile application (the “Application”), handles your personal information when you use the Application.

The Application helps seniors and adult/adolescent users with developmental disabilities to manage their independent living activities through visual schedules and prompts while giving primary caregivers visibility into their progress through success scorecard functionality.



NFlyte is committed to protecting your privacy, and we only use, collect, and disclose your personal information as described in this Privacy Policy or when we have obtained your consent. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it.  If you do not agree with our policies and practices, your choice is not to use our Application. By accessing or using this Application, you agree to this Privacy Policy.  This Policy may change from time to time. Your continued use of this Application after we make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates.

**Index**

**What this Privacy Policy Covers**


## This Privacy Policy describes how we collect, use and share personal information we collect if you use the Application.  This Privacy Policy only applies to the Application and any information collected offline by NFlyte directly related to your use of the Application, such as when you use our services.  It does not apply to any other NFlyte or third party websites, services, applications, or offline activities.


# Personal Information We Collect {#personal-information-we-collect}


## Information you give us.  Personal information we may collect from you through the Application or offline related to your use of the Application includes:



* **Contact information.**  We may collect your contact details, such as your first and last name, email address, and phone number.
* **Account information. **If you use features of the Application that require a user account, you will be required to provide an email address and create a password.
* **Demographic information.**  We may collect demographic information that you chose to add to your profile, such as your gender, age, birth date, or race/ethnicity.
* **Educational information.**  We collect your education level, and we may collect additional information from you, such as educational institutions you have attended.
* **Diagnosis information**. If you engage with our counseling services, we will also collect information regarding your particular disability.
* **Survey information.  **You may have the opportunity to provide answers to survey questions through the Application.  If you choose to participate, we will collect your responses to these questions.
* **User generated content. **We may collect content or other information that you may provide or create when you interact with the Application, such as your goals, interests or preferences, and information you may provide.**  **
* **Communications with others.**  We may collect or provide you access through the Application to systems that collect your communications with individuals through the Application, such as your communications with our coaches, representatives of institutions or other users, and our interactive chat feature.
* **Correspondence with us.**  We may collect information about you that you provide when you use the Application’s chat features, request information from us or otherwise correspond with us.  If you choose to correspond with us this way, we will collect any additional personal information you choose share via these features.


## Information automatically collected.  Our servers and third party service providers may automatically record certain information about how you use the Application and devices you use to access the Application.  This information may include your Internet Protocol (IP) address and information about the device, such as: device identifier, model and device type, name and version of the operating system, mobile carrier, settings and language of the device, Internet service provider, referring links, approximate geographic location, precise geographic location (if you consent through your device settings), the pages or features of the Application that you browse and the time you spend on those pages or features, the frequency with which you use the Application, and the actions you take in the Application.  We collect this information in server logs, within the Application, and using third party service providers who operate their own Privacy Policies.  Device and usage information will be shared with Mixpanel, Google Analytics, Apple Analytics, and Crashlytics (Fabric, a Google Company) for analytics and crash reporting.

**Information collected from third party services.**  The Application may allow you to log into the Application with your credentials from a third party service (e.g., Google).  If you connect to the Application with your third party service account, you authorize us to access and use the information that you agreed the service could provide to us based on your settings for that service.  We will access, use and share that information in accordance with this Privacy Policy.  In most cases you can use the service’s settings to revoke our access to your information in it.

**Information collected from third party companies.**  We may receive personal information about you from our business partners, our parent, affiliate, or subsidiary companies or from other third party sources.  We may combine this information with the information we have already collected through the Application and will handle it in accordance with this Privacy Policy.


# How We Use Your Personal Information {#how-we-use-your-personal-information}

We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:


## To operate the Application.  We use your personal information:



* to provide, operate and improve the Application;
* to communicate with you about the Application, including by sending you announcements, reminders, suggestions, updates, security alerts, and support and administrative messages;
* to manage your account, including to verify your identity and provide you with access to your account should you become locked out or forget your login and password;
* to provide you with requested services, such as scheduling a counseling session or providing counseling services;
* to provide you with information related to your skills assessment and interests;
* to understand your needs and interests, and personalize your experience with the Application; and
* to respond to your requests, questions and feedback related to the Application.


## For research and development.  We analyze use of the Application to study trends and users’ movements related to the Application, gather demographic information about our user base, improve the Application and develop new products and services.


## To create anonymous data.  We may create aggregated and other anonymous data from our users’ information.  We make personal information into anonymous data by removing information that makes the data personally identifiable.  We may use this anonymous data and share it with third parties to understand and improve the Application, promote our business and for other lawful business purposes.  When in aggregate form, this information can no longer be used to personally identify you.


## For compliance, fraud prevention and safety.  We may use your personal information and share it with government officials, law enforcement or private parties, as we believe appropriate to:



* protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);
* protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity;
* comply with applicable laws; court orders, subpoenas and other legal process; and requests from government authorities; and
* where permitted by law in connection with a legal investigation.

**To send notifications.**  Notifications may be delivered to you by email at the address you provided when you created your account or as later updated. We will not ask you for your personal information, account username, and password, or any of your credit or debit card information via email. We will have no responsibility for any misuse of such information if you provide such information via email.


# How We Share your Personal Information {#how-we-share-your-personal-information}

We do not share your personal information with third parties without your consent, except in the following circumstances or as described in this Privacy Policy:

**Service providers.**  We may share your personal information with third party companies and individuals as needed for them to help us provide the Application or support our business activities (such as application analytics, email delivery, payment processing, marketing/advertising, IT providers, technical support, and legal and other professional advice).

**For compliance, fraud prevention and safety. **We may share your personal information for the compliance, fraud prevention and safety purposes.

**Business transfers.**  We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business deal (or potential business deal) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution.


# Your Rights and Choices


# Opt out of text messages.  If you ask us to communicate with you via text message or consent to receive text messages, you can always opt out of future text messages by replying “STOP” to one of the messages received.


# Changes to your personal information.  You may update your email address at any time by editing the information in your profile.


# Closing your account. If you wish to close your account with the Application, please send your request via email to support@NFlyte.com, and, if applicable, we will remove your personal information and profile from the active databases for the Application you request.  Even after deletion, your personal information may remain in our backups or archive records, and we may retain certain information relevant to compliance, fraud prevention and safety purposes.  All retained personal information will continue to be subject to this Privacy Policy.


# For California Residents: Your California Privacy Rights {#for-california-residents-your-california-privacy-rights}


# California residents can request and obtain from us once a year and free of charge a list of all third parties to which NFlyte has disclosed certain information during the preceding calendar year for the third parties’ direct marketing purposes.  If you are a California resident and want such a list, please contact us support@NFlyte.com.  For all requests, you must put the statement “Your California Privacy Rights” in the message field of your request, as well as your name, street address, city, state, and zip code.  Please note that we will not accept these requests by telephone, email or fax, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.


# Other Important Privacy Information {#other-important-privacy-information}


## Third party sites and services.  The Application may contain links to other websites and services operated by third parties.  In addition, features, widgets or portions of the Application may be integrated on other sites and applications.  These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party.  We do not control third party websites, applications or services, and are not responsible for their actions.  Other websites and services follow different rules regarding their collection, use and sharing of your personal information.  We encourage you to read their privacy policies to learn more.


## Security practices.  The security of your personal information is important to us.  We employ a number of organizational, technical and physical safeguards designed to protect the personal information we collect.  However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.    If you have a user account for the Application, you are solely responsible for maintaining and protecting the confidentiality of your account username, password and other account information in your possession.


## User Generated Content.  We may make available on the Application, or link to, features that allow you to share information online (for example, through messages or feedback).  Please be aware that, whenever you voluntarily disclose personal information online or to others, the information may become public and can be collected and used by others.  We have no control over, and take no responsibility for, the use, storage or dissemination of any such publicly-disclosed personal information.


## Use of Application by visitors outside of the United States.  The Application is intended for users in the United States and are not intended to be used by users in other countries.  Any information we obtain about you in connection with your use of the Application may be processed and stored in, and subject to the laws of, the United States or other countries.  If you use the Application from a location outside the United States, you: (a) acknowledge that the data protection laws of other countries, including the United States, may provide a less comprehensive or protective standard of protection than those in your country, and (b) consent to all actions taken by us with respect to your information in compliance with the Privacy Policy and applicable privacy laws.


## Children.  The Application are not directed at, and we do not knowingly collect personal information from, anyone under the age of 13.


## Changes to this Privacy Policy.  We reserve the right to modify this Privacy Policy at any time.  If we make changes to this Privacy Policy, we will post them on the Application and indicate the effective date of the change.  If we make material changes to this Privacy Policy, we will notify you by email or through the Application.


# How to Contact Us {#how-to-contact-us}


# Please direct any questions or comments about this Policy to:


# NFlyte, LLC

23 Hopetown Road

Mt Pleasant SC 29464

support@NFlyte.com

`;

export const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = () => {
  return (
    <div >
    <br/><br/><br/><br/><br/>
      <MarkdownPreview style={{padding:'10px'}} source={source} /> 
    </div>
  );
};
