import React, {useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
} from '@material-ui/core';

import formStyles from '../../../assets/jss/components/formStyles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedEditRecipeIngredientFormValues } from './EnhancedEditRecipeIngredientForm';
import { useHistory, useLocation, useParams } from 'react-router';
import { editRecipeIngredient, ADD_RECIPE_INGREDIENT_VIEW } from '../../../constants/routes';
//import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { addLibraryImageToS3, addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Resizer from "react-image-file-resizer";

interface IEditRecipeIngredientFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const EditRecipeIngredientForm: React.FC<
IEditRecipeIngredientFormProps & FormikProps<EnhancedEditRecipeIngredientFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAddStudent = location.pathname === ADD_RECIPE_INGREDIENT_VIEW;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    handleReset

  } = props;
  const { recipeIngridientLoading, recipeIngredient } = useSelector(
    (state: RootState) => {
      return {
        recipeIngridientLoading: state.recipeIngredient.loading,
        recipeIngredient: state.recipeIngredient.recipeIngredient,
      };
    },
    shallowEqual
  );
  
  const userData = localStorage.getItem('userData');

  useEffect(() => {
    if(userData)
    {
     const userDt = JSON.parse(userData);
     console.log("call function----->"+userDt);
 
     //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('recipeingredientUrl',userDt.recipeingredientUrl?userDt.recipeingredientUrl:'')
             setFieldValue('ingredientTitle',userDt.ingredientTitle?userDt.ingredientTitle:'')
             setFieldValue('quantity',userDt.quantity?userDt.quantity:'')
             setFieldValue('unit',userDt.unit?userDt.unit:'')
             setFieldValue('unitType',userDt.unitType?userDt.unitType:'')
             setFieldValue('recipeId',userDt.recipeId?userDt.recipeId:'')
            
      // }
    }
     
   }, []) 


  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };

  let renderedAltName: string | undefined = '';
  let renderedAvatar: string | undefined = '/static/images/avatar/1.jpg';
  renderedAltName = values.ingredientTitle;
  renderedAvatar =
    values.recipeingredientUrl !== 'no-url' ? values.recipeingredientUrl : renderedAvatar;
  const [openModal, setOpenModal] = useState(false);
  //let rrid=useParams<{ recipeId: string }>();
  const { recipeId } = useParams<{ recipeId: string | undefined }>();
  let rcheck: string | number | readonly string[] | undefined;
  if(recipeId)
  {
    rcheck=recipeId;
    let formData = new FormData();  
    formData.append('recipeId', rcheck); 
  }
  function sethandlerecipeid(){
    console.warn("------->"+values.recipeId);
   // setFieldValue('recipeId', rcheck);
    setFieldValue('recipeId', values.recipeId);
  }

  let renderUnit=[];
  renderUnit=[
    { "label": "Kg",
    "value": "Kg"
  },
  { "label": "g",
  "value": "g"
  },
  { "label": "lb",
    "value": "lb"
  },
  { "label": "oz",
    "value": "oz"
  }];
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  return (
     <div>
     <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>
      <div style={{ margin: '0px 0px 32px' }}>
        
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          {isAddStudent ? 'Edit Ingredient' : 'Edit Ingredient'}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {isAddStudent ? 'Enter student details' : ''} */}
          {isAddStudent ? 'Enter ingredient details' : ''}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={12} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton onClick={() => setOpenModal(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 56, height: 56 }}
                /> */}
                {values.recipeingredientUrl && values.recipeingredientUrl !== 'no-url'? 
                  <img src={values.recipeingredientUrl} style={{ width: 56, height: 56 }}/>:
                  <img src={SIMG} style={{ width: 56, height: 56 }}/>
                }
              </IconButton>
              <Typography
                variant='subtitle1'
                style={{ marginLeft: '0.6rem' }}
                className={classes.label}
              >
                Add Ingredient Picture
                <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Item Name <span style={{color:'red'}}>*</span>
              </Typography>
              {/* <input
                placeholder='Recipe sId'
                type='hidden'
                name='recipeId'
                value={rcheck}
               
              /> */}
              <TextField
                placeholder='Title'
                type='text'
                name='ingredientTitle'
                variant='outlined'
                size='small'
                value={values.ingredientTitle}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
                error={(errors.ingredientTitle && touched.ingredientTitle) as boolean}
                helperText={errors.ingredientTitle && touched.ingredientTitle && errors.ingredientTitle}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                 Quantity <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='quantity'
                placeholder='Quantity'
                type='text'
                name='quantity'
                variant='outlined'
                size='small'
                value={values.quantity}
                onChange={handleChange}
                onBlur={sethandlerecipeid}
                error={(errors.quantity && touched.quantity) as boolean}
                helperText={errors.quantity && touched.quantity && errors.quantity}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                 Unit Type <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='unit'
                placeholder='Unit'
                type='text'
                name='unit'
                variant='outlined'
                size='small'
                value={values.unit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.unit && touched.unit) as boolean}
                helperText={errors.unit && touched.unit && errors.unit}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={12}>
            <FormControl required className={classes.formControl}>
              <Typography className={classes.label}>
                Unit Type
              </Typography>
              <AsyncSelect
                cacheOptions
                defaultOptions={renderUnit}
                styles={customStyles}
                defaultInputValue={values.unit}
                onChange={(option) => {
                  if (option) {
                    setFieldValue('unit', option.value);
                  }
                }}
              />
            </FormControl>
          </Grid> */}
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
               disabled={recipeIngridientLoading}
              // fullWidth
            >
              {isAddStudent ? 'Edit' : 'Add'} Ingredient
            </Button>
          </Grid>
         
         
        </Grid>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={async (files:any) => {
            try {
              if (files.length > 0) {
                let file = files[0];

                Resizer.imageFileResizer(
                  file,
                  680,
                  420,
                  "JPEG",
                  100,
                  0,
                  async(uri:any) => {
                    //console.log(uri);
                    console.warn("Form data view--->" + JSON.stringify(uri));
                   let fdata= getImageUrl(uri);
                   //setImg(fdata);
                   const formData = new FormData();
                   formData.append('file', fdata);
                   //dispatch(addRecipeToS3(formData));
                   let urldatanxt = await addRecipeToS3(formData);
                   // setImage(url);
                   // url.push(urldata);
                   //console.log("res data---->"+urldatanxt)
                   //let jurl=JSON.stringify(urldatanxt)
                   setFieldValue('recipeingredientUrl', urldatanxt);
                  },
                  "base64",
                  200,
                  200
                );

                // let file = files[0];
                // const formData = new FormData();
                // console.warn("Form data view--->"+JSON.stringify(formData));
                // formData.append('file', file);
                //  //dispatch(addRecipeToS3(formData));
                // const url = await addLibraryImageToS3(formData,files);
                // // setImage(url);
                // setFieldValue('recipeingredientUrl', url);
                sethandlerecipeid();
              }
              setOpenModal(false);
            } catch (err) {
              console.log(err);
            }
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
     
    </div>
  );
};
