import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
  setStudents,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_STUDENT,
  ENQUIRY_LIST,
  PROGRAM_MANAGER_LIST,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import { createOrganisationBySuperAmin, getOrganisationList, getSearchOrganisationBySuperAdminorList, updateOrganisationBySuperAmin } from '../../../api/programManagerRequests';
import { FiRefreshCcw } from 'react-icons/fi';
import { Autocomplete } from '@mui/material';
import { getSearchOrganisationBySuperAdminUserist } from '../../../api/studentRequests';
import { setLoading } from '../../../slices/chatRoomSlice';
import { getProgramForProgramManagerRefresh, getProgramManagerByIdRefresh, updateProgramManager, updateProgramManagerBySuperAdmin } from '../../../slices/programManagerSlice';
import Resizer from "react-image-file-resizer";

import { DropzoneDialog } from 'material-ui-dropzone';
import { AiFillSetting } from 'react-icons/ai';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import { setSuccessMsg } from '../../../slices/alertSlice';


interface IAddOrganisationToProgramProps {}

const useStyles = makeStyles(formStyles);

const AddOrganisationToProgram: React.FC<IAddOrganisationToProgramProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { students, selectedDate, role, counselor,programManager } = useSelector(
    (state: RootState) => {
      return {
        students: state.student.students,
        selectedDate: state.task.selectedDate,
        role: state.auth.role,
        counselor: state.counselor.counselor,
        programManager:state.programManager.programManager
      };
    },
    shallowEqual
  );

  
console.warn("chk login");

const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');


const { programMangerId } = useParams<{ programMangerId: string | undefined }>();

  useEffect(() => {
    dispatch(setSelectedDate(new Date()));
    const userData = localStorage.getItem('userData');
    console.log("call function----->"+userData);

  if(userData)
  {
   const userDt = JSON.parse(userData);

   //loadDataOnlyOnce(userData);
     // if(studentLoading)
     // {
          
           setProgramId(userDt.id?userDt.id:'')
           dispatch(getProgramManagerByIdRefresh(userDt.id))
          
    // }
  }

  }, []);
  //console.log("student details - > "+students);
  localStorage.setItem('urlRoute', "edit_program");
  
  const [programId,setProgramId]=useState("");
  
  
 // console.log("----->"+renderedStudents_nxt)
  const [cstatus,setCstatus]=useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orgSelect,setorgSelect]=useState("");
  const [imageUrl,setImageUrl]=useState("");
   let renderedName = 'Program Manager';
// if(programManager && programManager.imageUrl !== 'no-url')
// {
//     setImageUrl(programManager.imageUrl)
// }
const [firstname, setfirstname] = useState("");
const [lastname, setlastname] = useState("");
const [email, setemail] = useState("");
const [phoneno, setphoneno] = useState("");
const [firstchk, setfirstchk] = useState(false);
const [programname, setprogramname] = useState("");
const [tellus, settellus] = useState("");

const [firstnameerr, setfirstnameerr] = useState("");
const [lastnameerr, setlastnameerr] = useState("");
const [emailerr, setemailerr] = useState("");
const [phonenoerr, setphonenoerr] = useState("");
const [firstchkerr, setfirstchkerr] = useState("");
const [programnameerr, setprogramnameerr] = useState("");
const [telluserr, settelluserr] = useState("");


async function addOrganisation()
{
  let flag=false;
  if(firstname==="")
  {
    setfirstnameerr("First name is required.");
    flag=true;
  }
  else
  {
    setfirstnameerr("");
    flag=false;

  }

  if(lastname==="")
  {
    setlastnameerr("Last name is required.");
    flag=true;
  }
  else
  {
    setlastnameerr("");
    flag=false;

  }
  if(phoneno==="")
  {
    setphonenoerr("Phone no is required.");
    flag=true;
  }
  else
  {
    setphonenoerr("");
    flag=false;

  }
  if(tellus==="")
  {
    settelluserr("Field is required.");
    flag=true;
  }
  else
  {
    settelluserr("");
    flag=false;

  }

  

  console.log("flag -"+flag)
  if(flag===true)
  {
    return false;
  }
  else{
    //return true;
    console.log("check error true"+programManager);
    
    const fullname=firstname+" "+lastname;
    if(programManager)
    {
      const orgData = await updateOrganisationBySuperAmin(firstname,lastname,tellus,phoneno,programManager.id);
      if(orgData)
      {
         console.log("result-->"+JSON.stringify(orgData))
          setfirstname("");
          setlastname("");
          setemail("");
          setphoneno("");
          setfirstchk(false);
          setprogramname("");
          settellus("");
          //window.location.reload()
          dispatch(setSuccessMsg(`Organisation Added Successfully !`));

          history.push(PROGRAM_MANAGER_LIST)
      }
      else
      {
        // setemail("");
        // setemailerr("Email already exist !");
        flag=true;
      }
    }
   
  }
}
let pname="";
if(programManager)
{
    pname=programManager.name;
}

  if(isLoading===false)
    {
        
        return (
            
            <div style={{maxWidth:'100%'}}>
                    <div style={{ margin: '0px 0px 32px' }}>
                        <Typography variant='body1' className={classes.loginText}>
                        {renderedName} 
                        </Typography>
                        <Typography variant='h4' className={classes.welcomeBackText}>
                        Add Organisation To {pname}
                        </Typography><br/><hr/><br/>
                    <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        >
                        <Grid item md={4} xs={12}
                            style={{ width: '100%', paddingRight: '0.4em' }}

                            >
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', padding: '0.7em' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                Enter First Name
                            </Typography>
                            <TextField
                                placeholder='Enter First Name'
                                type='text'
                                name='name'
                                variant='outlined'
                                size='small'
                                value={firstname}
                                onChange={(e) => setfirstname(e.target.value)}

                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            /><br/>
                            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstnameerr}</span>

                            </FormControl>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                Enter Last Name
                            </Typography>
                            <TextField
                                placeholder='Enter Last Name'
                                type='text'
                                name='name'
                                variant='outlined'
                                size='small'
                                value={lastname}
                                onChange={(e) => setlastname(e.target.value)}

                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            /><br/>
                            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{lastnameerr}</span>

                            </FormControl>

                        </Grid>
                        <Grid item md={8} xs={12} style={{padding:'10px'}}>
                            <FormControl
                                required
                                className={classes.formControl}
                                style={{ width: '100%' }}
                            >
                                <Typography variant='subtitle1' className={classes.label}>
                                Enter Phone No <span style={{color:'red'}}>*</span>
                                </Typography>
                                <TextField
                                placeholder='Enter Phone No'
                                type='number'
                                name='schoolName'
                                variant='outlined'
                                size='small'
                                id="div_org_name"
                                value={phoneno}
                                onInput = {(e:any) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                }}              
                                onChange={(e) => setphoneno(e.target.value)}
                                
                                
                                />{' '}
                            </FormControl>
                            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{phonenoerr}</span>
                        </Grid>
                        <Grid item md={8} xs={12} style={{padding:'10px'}}>
                            <FormControl
                                required
                                className={classes.formControl}
                                style={{ width: '100%', paddingRight: '0.4em' }}
                                >
                                <Typography variant='subtitle1' className={classes.label}>
                                Tell us about your program <span style={{color:'red'}}>*</span>
                                </Typography>
                                <textarea placeholder='Tell us about your program' rows={4} className='form-control' onChange={(e) => settellus(e.target.value)}></textarea>
                            </FormControl>
                            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{telluserr}</span>
                        </Grid>
                        {/* <Grid item md={2} xs={12}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button  
                                        className={classes.secondaryButton}
                                        variant='contained'
                                        color='primary'
                            
                            style={{fontSize:'14px'}}  onClick={ () => {
                                    //   history.push(CHANGE_PASSWORD);
                                    }}>Change Password &nbsp;&nbsp;&nbsp;&nbsp;<AiFillSetting /> </Button>
                        </Grid> */}
                    </Grid>
                    </div>
                    <div className='row' style={{ padding: '1rem', width: '100%' }}>
                        <div className='col-sm-3'>
                                <Button
                                    className={classes.secondaryButton}
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => {addOrganisation()}}
                                >
                                    Submit
                                </Button>
                        </div>
                        <div className='col-sm-3 text-right'>
                        
                        </div>
                    </div>
                    
            </div>
        );
    }
    else
    {
        return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
        </div>;
    }
};
export default AddOrganisationToProgram;
