import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';

import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Container,
  Chip,
  Link,
  Divider,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import viewStudentDetailsStyles from '../../../assets/jss/components/viewStudentDetailsStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../constants/models/User';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getStudentById, setIngredient } from '../../../slices/studentSlice';
import { editStudent } from '../../../constants/routes';
import { Ingredient } from '../../../constants/models/Ingredient';
import { EnhancedAddOrEditGroceryItemFormValues } from './EnhancedAddOrEditGroceryItemForm';
import { FormikProps, ErrorMessage } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { categoryList } from '../../../constants/category';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { UnitPicker } from './UnitPicker';
import { Close } from '@material-ui/icons';

interface AddOrEditGroceryItemFormProps {}

const useStyles = makeStyles(viewStudentDetailsStyles);
const useStyles2 = makeStyles(formStyles);

export const AddOrEditGroceryItemForm: React.FC<
  AddOrEditGroceryItemFormProps &
    FormikProps<EnhancedAddOrEditGroceryItemFormValues>
> = (props) => {
  const classes = { ...useStyles(), ...useStyles2() };
  const history = useHistory();
  const dispatch = useDispatch();
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  const getCategoryIcon = (category: string) => {
    const foundCategory = categoryList.find((c) => c.name === category)
      ? categoryList.find((c) => c.name === category)
      : { icon: HealthAndSafetyIcon, color: 'blue' };
    const CategoryIcon = foundCategory?.icon;
    return <CategoryIcon style={{ color: foundCategory?.color,height:'25px',width:'25px' }} />;
  };
  const { ingredient } = useSelector((state: RootState) => ({
    ingredient: state.student.ingredient,
  }));
  const categories = categoryList.map((category) => {
    return { label: category.name, value: category.name };
  });
  const [defaultCategory, setDefaultCategory] = React.useState(null);
  // useEffect(() => {
  //   setDefaultCategory(
  //     categories.find((c) => c.value === values.category)
  //       ? categories.find((c) => c.value === values.category)
  //       : null
  //   );
  // },[ingredient]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        // style={{ padding: '1rem' }}
      >
        <Grid item xs={12} md={12}>
          <FormControl
            required
            className={classes.formControl}
            style={{
              width: '100%',
              // paddingRight: '0.4em',
              // padding: '0 2rem',
              position: 'relative',
            }}
          >
            {/* {ingredient !== null && (
              <IconButton
                style={{ position: 'absolute', top: '-33px', right: '18px' }}
                onClick={() => dispatch(setIngredient(null))}
              >
                <Close />
              </IconButton>
            )} */}
            <Typography variant='subtitle1' className={classes.label}>
              Enter Ingredient name <span style={{color:'red'}}>*</span>
            </Typography>
            <TextField
              placeholder='Name'
              type='text'
              name='name'
              variant='outlined'
              size='small'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.name && touched.name) as boolean}
              helperText={errors.name && touched.name && errors.name}
              InputLabelProps={{
                classes: {
                  root: classes.heading,
                  focused: classes.cssFocused,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl
            required
            className={classes.formControl}
            style={{
              width: '100%',
              // paddingRight: '0.4em',
              position: 'relative',
              // padding: '0 2rem',
            }}
          >
            <Typography variant='subtitle1' className={classes.label}>
              Choose Category
            </Typography>
            <AsyncSelect
              cacheOptions
              defaultOptions={categories}
              // @ts-ignore
              value={
                categories.find((c) => c.value === values.category)
                  ? categories.find((c) => c.value === values.category)
                  : null
              }
              styles={customStyles}
              onChange={(option) => {
                setFieldValue('category', option?.value);
              }}
            />

            <div style={{ position: 'absolute', right: 75, top: 45,height:'150px'  }}>
              {getCategoryIcon(values.category)}
            </div>
            <ErrorMessage name='category'>
              {(msg) => <div style={{ color: '#ED4134' }}>{msg}</div>}
            </ErrorMessage>

            {/* <Select
                labelId="select-role"
                value={values.counselors}
                onChange={handleChange('counselors')}
                multiple
                input={<OutlinedInput label="Counselors" />}
              >
                {counselors.map((counselor) => (
                  <MenuItem key={counselor.name} value={counselor.id}>
                    {counselor.name}
                  </MenuItem>
                ))}
              </Select> */}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
          >
            <Grid item xs={4} md={4}>
              <FormControl
                required
                className={classes.formControl}
                style={{
                  width: '100%',
                  paddingRight: '0.4em',
                  // paddingLeft: '2rem',
                }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Unit
                </Typography>
                <TextField
                  placeholder='Unit'
                  type='text'
                  name='unit'
                  variant='outlined'
                  size='small'
                  value={values.unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.unit && touched.unit) as boolean}
                  helperText={errors.unit && touched.unit && errors.unit}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                required
                className={classes.formControl}
                style={{
                  width: '100%',
                  // paddingRight: '0.4em',
                  // padding: '0 2rem',
                  // paddingRight: '2rem',
                }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Quantity
                </Typography>
                <TextField
                  placeholder='Quantity'
                  type='text'
                  name='quantity'
                  variant='outlined'
                  size='small'
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.quantity && touched.quantity) as boolean}
                  helperText={
                    errors.quantity && touched.quantity && errors.quantity
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                  style={{ textAlign: 'center' }}
                  InputProps={{
                    inputProps: {
                      style: {
                        textAlign: 'center',
                      },
                    },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              'quantity',
                              parseInt(values.quantity.toString()) + 1
                            );
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              'quantity',
                              values.quantity <= 1
                                ? 1
                                : parseInt(values.quantity.toString()) - 1
                            );
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <UnitPicker
              setFieldValue={setFieldValue}
              field='unit'
              value={values.unit}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl
            required
            className={classes.formControl}
            style={{
              width: '100%',
              // paddingRight: '0.4em',
              // padding: '0 2rem'
            }}
          >
            <Typography variant='subtitle1' className={classes.label}>
              Price
            </Typography>
            <TextField
              placeholder='Price'
              type='number'
              name='price'
              variant='outlined'
              size='small'
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.price && touched.price) as boolean}
              helperText={errors.price && touched.price && errors.price}
              InputLabelProps={{
                classes: {
                  root: classes.heading,
                  focused: classes.cssFocused,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          // style={{ padding: '0 130px' }}
        >
          <Button
            className={classes.secondaryButton}
            variant='contained'
            color='primary'
            type='submit'
            // disabled={studentLoading}
            // fullWidth
          >
            {/* {isAddStudent ? 'Add' : 'Edit'} Student */}
            {ingredient ? 'Edit' : 'Add'} Ingredient
          </Button>
        </Grid>

        {debug ? <DisplayFormikState {...props} /> : ''}
      </Grid>
    </form>
  );
};
