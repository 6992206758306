import store, { AppThunk } from '../app/store';
import { viewIngridians,ADD_RECIPE, addRecipeSteps, editNutrintion, addRecipeIngredients } from './../constants/routes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/recipeIngredientRequests';
//import { recipe } from '../constants/models/recipe';
import { RecipeIngredient } from '../constants/models/RecipeIngredient';
import { History } from 'history';
import { useParams } from 'react-router';


export interface recipeIngredientState {
  loading: boolean;
  error: string | null;
  recipeIngredient: RecipeIngredient | null;
  recipeIngredients: RecipeIngredient[];
  recipeingredientUrl: string;
  isrecipeIngredientModalOpen: boolean;
}

export const initialState: recipeIngredientState = {
  loading: false,
  error: null,
  recipeIngredient: null,
  recipeIngredients: [],
  recipeingredientUrl: '',
  isrecipeIngredientModalOpen: false,
};

const recipeIngredientSlice = createSlice({
  name: 'recipeIngredient',
  initialState,
  reducers: {
    recipeIngredientStart(state) {
      state.loading = true;
      state.error = null;
    },
    setrecipeIngredient(state, action: PayloadAction<RecipeIngredient | null>) {
      state.recipeIngredient = action.payload;
    },
    setrecipeIngredients(state, action: PayloadAction<{ data: RecipeIngredient[] }>) {
      const { data } = action.payload;
      state.recipeIngredients = data.map((recipeIngredient) => recipeIngredient);
      console.warn("state-->"+state.recipeIngredients);
    },
    setIsrecipeIngredientModalOpen(state, action: PayloadAction<boolean>) {
      state.isrecipeIngredientModalOpen = action.payload;
    },
    setrecipeingredientUrl(state, action: PayloadAction<string>) {
      state.recipeingredientUrl = action.payload;
    },
    recipeIngredientFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    recipeIngredientComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  recipeIngredientStart,
  setrecipeIngredient,
  setrecipeIngredients,
  setIsrecipeIngredientModalOpen,
  setrecipeingredientUrl,
  recipeIngredientFailure,
  recipeIngredientComplete,
} = recipeIngredientSlice.actions;

export default recipeIngredientSlice.reducer;

export const getrecipeIngredientByIdRefresh =
  (ingredientId: string): AppThunk =>
  async (dispatch) => {
    try {
     // dispatch(setrecipeIngredient(RecipeIngredient | null));

      dispatch(recipeIngredientStart());
      const recipeIngredient = await REQUESTS.getrecipeIngredientById(ingredientId);
      dispatch(setrecipeIngredient(recipeIngredient));
      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };


export const getrecipeIngredientById =
  (ingredientId: string): AppThunk =>
  async (dispatch) => {
    try {
     // dispatch(setrecipeIngredient(RecipeIngredient | null));

      dispatch(recipeIngredientStart());
      const recipeIngredient = await REQUESTS.getrecipeIngredientById(ingredientId);
      dispatch(setrecipeIngredient(recipeIngredient));
      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getrecipeIngredientsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeIngredientStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      console.log("program id ---> "+program_de_id);
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const recipeIngredients = await REQUESTS.getrecipeIngredientsForProgram(programId);
      dispatch(setrecipeIngredients({ data: recipeIngredients }));
      
    }
    dispatch(recipeIngredientComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeIngredientFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getIngidiansListForStudent =
  (recipeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeIngredientStart());
     
      const recipeIngredients = await REQUESTS.getParentIngridiansList(
        recipeId
      );
      console.warn(recipeIngredients);
      dispatch(setrecipeIngredients({ data: recipeIngredients }));

      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const createrecipeIngredient =
  ( 
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId:string,
    history: History
   ): AppThunk =>
  async (dispatch) => {
    // const { recipeId } = useParams<{ recipeId: string | undefined }>();
    try {
      dispatch(recipeIngredientStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      
      // let recipe_de_id="";
      //   if(recipeId)
      //   {
      //     recipe_de_id=recipeId;
      //   }
       
        //if (recipe_de_id) {
        //let recipeId = recipe_de_id;//program.id;
        const recipeIngredient = await REQUESTS.createrecipeIngredient(
          recipeingredientUrl,
          ingredientTitle,
          quantity,
          unit,
          unitType,
          recipeId
        );
        //dispatch(setrecipeIngredient(recipeIngredient));
        
        dispatch(setSuccessMsg(`Ingredient added successfully..!`));
      //}
      //dispatch(getrecipeIngredientsForProgram());
      dispatch(setrecipeingredientUrl(''));
      dispatch(recipeIngredientComplete());
      dispatch(getIngidiansListForStudent(recipeId));
      //history.push(addRecipeIngredients(recipeId));
     // history.push(editNutrintion(recipeId));

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updaterecipeIngredient =
  (
    id: string | undefined,
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId: string,
    history: History
    ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeIngredientStart());
      await REQUESTS.updaterecipeIngredient(
        id,
        recipeingredientUrl,
        ingredientTitle,
        quantity,
        unit,
        unitType,
        recipeId,
         );
      //dispatch(getrecipeIngredientsForProgram());
      dispatch(recipeIngredientComplete());

      dispatch(getIngidiansListForStudent(recipeId));
      history.push(addRecipeIngredients(recipeId));

     // history.push(viewIngridians(recipeId));

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleterecipeIngredient =
  (id: string,recipeId: string,history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeIngredientStart());
      await REQUESTS.deleterecipeIngredient(id,recipeId);
      console.warn("get recipe id----->"+recipeId);
      //history.push(viewIngridians(recipeId));
     // history.push(ADD_RECIPE);
     dispatch(getIngidiansListForStudent(recipeId));

      //dispatch(getIngidiansListForStudent(recipeId));
      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const addrecipeIngredientToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      console.warn(formData);

      dispatch(recipeIngredientStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) 
        {
          formData.append('programId', program_de_id);
          let data = await REQUESTS.addrecipeIngredientToS3(formData);
            
          dispatch(setrecipeingredientUrl(data));
        } 
      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const updateIngredientPositionFromWeb = (stepPostision: RecipeIngredient[],history: History
    ): AppThunk => async (dispatch) => {
    try {
      dispatch(recipeIngredientStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        
        const recipeSteps = await REQUESTS.updateIngredientPositionFromWeb(stepPostision);
        //dispatch(setrecipeSteps({ data: recipeSteps }));
        if(recipeSteps.length>0)
        {
          dispatch(setSuccessMsg(`Recipe ingredients updated  successfully..!`));


        }
      }
      dispatch(recipeIngredientComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeIngredientFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };