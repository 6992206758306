import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
  setStudents,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_STUDENT,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh, updateParentBySuperAdmin } from '../../../slices/parentSlice';
import { getOrganisationList, getSearchOrganisationBySuperAdminorList } from '../../../api/programManagerRequests';
import { FiRefreshCcw } from 'react-icons/fi';
import { Autocomplete } from '@mui/material';
import { getSearchOrganisationBySuperAdminUserist } from '../../../api/studentRequests';
import { setLoading } from '../../../slices/chatRoomSlice';
import { updateProgramManager, updateProgramManagerBySuperAdmin } from '../../../slices/programManagerSlice';
import Resizer from "react-image-file-resizer";

import { DropzoneDialog } from 'material-ui-dropzone';
import { AiFillSetting } from 'react-icons/ai';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


interface IEditParentProps {}

const useStyles = makeStyles(formStyles);

const EditParent: React.FC<IEditParentProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { students, selectedDate, role, parent } = useSelector(
    (state: RootState) => {
      return {
        students: state.student.students,
        selectedDate: state.task.selectedDate,
        role: state.auth.role,
        parent:state.parent.parent
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(setRecipeId(""));
    dispatch(setNotiTime({data:[]}));
  }, []);
console.warn("chk login");

const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');

 
  //console.log("student details - > "+students);
  localStorage.setItem('urlRoute', "edit_program");
  const [orgarr,setorgarr]=useState(students);
  const [orgarr_nxt,setorgarrNxt]=useState([]);
  const [name,setname]=useState("");
  const [email,setemail]=useState("");
  const [address,setaddress]=useState("");
  const [street,setStreet]=useState("");
  const [city,setcity]=useState("");
  const [country,setcountry]=useState("");
  const [postalCode,setPostcode]=useState(0);
  const [state,setState]=useState("");
  const [programId,setProgramId]=useState("");
  useEffect(() => {
    dispatch(setSelectedDate(new Date()));
    const userData = localStorage.getItem('userData');
    console.log("call function----->"+userData);

  if(userData)
  {
   const userDt = JSON.parse(userData);

   //loadDataOnlyOnce(userData);
     // if(studentLoading)
     // {
           setname(userDt.name?userDt.name:'')
           setemail(userDt.email?userDt.email:'')
           setaddress(userDt.address?userDt.address:'')
           setStreet(userDt.street?userDt.street:'')
           setcity(userDt.city?userDt.city:'')
           setcountry(userDt.country?userDt.country:'')
           setPostcode(userDt.postalCode?userDt.postalCode:0)
           setImageUrl(userDt.imageUrl?userDt.imageUrl:'no-url')
           setState(userDt.state?userDt.state:'')
           setProgramId(userDt.id?userDt.id:'')
           
          
    // }
  }
  }, []);

  


  
 // console.log("----->"+renderedStudents_nxt)
  const [cstatus,setCstatus]=useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orgSelect,setorgSelect]=useState("");
  const [imageUrl,setImageUrl]=useState("");
 
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  
  //console.log("org data-->"+JSON.stringify(orgarr))
  let renderedStudents;

  const [openModal, setOpenModal] = useState(false);
  let renderedName = 'Parent';
// if(programManager && programManager.imageUrl !== 'no-url')
// {
//     setImageUrl(programManager.imageUrl)
// }


const [firstnameerr, setfirstnameerr] = useState("");


async function addOrganisationSave()
{
  let flag=false;
  if(name==="")
  {
    setfirstnameerr("Name is required.");
    flag=true;
  }
  else
  {
    setfirstnameerr("");
    flag=false;

  }

 

  console.log("flag -"+flag)
  if(flag===true)
  {
    return false;
  }
  else{
    //return true;
    console.log("check error true");
    
    
      const orgData = await dispatch(updateParentBySuperAdmin(programId,name,email,address,street,city,state,country,postalCode,imageUrl,history))

      if(orgData)
      {
        console.log("result-->"+JSON.stringify(orgData))
         // setfirstname("");
         
          //window.location.reload()
      }
      else
      {
        // setemail("");
        // setemailerr("Email already exist !");
        flag=true;
      }
    
   
  }
}
console.log("name"+name)
  if(isLoading===false)
    {
        
        return (
            
            <div style={{maxWidth:'100%'}}>
                    <div className='row'>
                        <div className='col-sm-12'>
                          <Button
                              color='primary'
                              startIcon={
                                <ArrowBackIosIcon
                                  fontSize='small'
                                  style={{ paddingRight: '0px' }}
                                />
                              }
                              onClick={(e) => {
                                //history.push(ADD_RECIPE);
                                history.go(-1);
                              }}
                              size='small'
                            >
                              Back
                          </Button>
                          </div>
                    </div><br/>
                    <div style={{ margin: '0px 0px 32px' }}>
                        <Typography variant='body1' className={classes.loginText}>
                        {renderedName} 
                        </Typography>
                        <Typography variant='h4' className={classes.welcomeBackText}>
                        Edit {renderedName} profile
                        </Typography>
                        <Typography variant='body1' className={classes.loginText}>
                        Edit {renderedName} details
                        </Typography>
                    </div>
                    <form >
                        <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        >
                        <Grid item md={10} xs={12}>
                            <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                            >
                            <IconButton onClick={() => setOpenModal(true)}>
                                {/* <Avatar
                                src={renderedAvatar}
                                alt={renderedAltName}
                                style={{ width: 56, height: 56 }}
                                /> */}
                                {imageUrl && imageUrl!== ''? 
                                <img src={imageUrl} style={{ width: 56, height: 56 }}/>:
                                <img src={SIMG} style={{ width: 56, height: 56 }}/>
                                }
                                
                            </IconButton>
                            <Typography
                                variant='subtitle1'
                                style={{ marginLeft: '0.6rem' }}
                                className={classes.label}
                            >
                                Change Profile Picture
                                <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>
                            </Typography>
                            </div>
                        </Grid>
                        {/* <Grid item md={2} xs={12}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button  
                                        className={classes.secondaryButton}
                                        variant='contained'
                                        color='primary'
                            
                            style={{fontSize:'14px'}}  onClick={ () => {
                                    //   history.push(CHANGE_PASSWORD);
                                    }}>Change Password &nbsp;&nbsp;&nbsp;&nbsp;<AiFillSetting /> </Button>
                        </Grid> */}
                        </Grid>
                        <br />
                        <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        >
                        <Grid item md={6} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                Enter {renderedName} name
                            </Typography>
                            <TextField
                                placeholder='Name'
                                type='text'
                                name='name'
                                variant='outlined'
                                size='small'
                                value={name}
                                
                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            />
                            </FormControl>
                            <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstnameerr}</span>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl required className={classes.formControl}>
                            <Typography variant='subtitle1' className={classes.label}>
                                Enter {renderedName} email
                            </Typography>
                            <TextField
                                id='emailAddress'
                                placeholder='Email'
                                type='email'
                                name='email'
                                variant='outlined'
                                size='small'
                                value={email}
                                
                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            />
                            </FormControl>
                        </Grid>
                        </Grid>
                        {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        >
                        <Grid item md={6} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                Choose Counselors
                            </Typography>
                            <AsyncSelect
                                isMulti
                                cacheOptions
                                defaultOptions={renderedCounselorOptions}
                                styles={customStyles}
                                onChange={(option) => {
                                setFieldValue(
                                    'counselors',
                                    option.map((option) => option.value)
                                );
                                }}
                            />

                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl required className={classes.formControl}>
                            <Typography variant='subtitle1' className={classes.label}>
                                Choose Diagnosis
                            </Typography>
                            <AsyncSelect
                                isMulti
                                cacheOptions
                                defaultOptions={renderedDiagnosesOptions}
                                styles={customStyles}
                                onChange={(option) => {
                                setFieldValue(
                                    'diagnoses',
                                    option.map((option) => option.value)
                                );
                                }}
                            />

                            </FormControl>
                        </Grid>
                        </Grid> */}
                        <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        >
                        <Grid item md={9} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', paddingRight: '0.4rem' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                Address
                            </Typography>
                            <TextField
                                id='address'
                                placeholder='Address'
                                type='text'
                                name='address'
                                variant='outlined'
                                size='small'
                                value={address}
                            
                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                City
                            </Typography>
                            <TextField
                                placeholder='City'
                                type='text'
                                name='city'
                                variant='outlined'
                                size='small'
                                value={city}
                                
                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            />
                            </FormControl>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid item md={4} xs={12}>
                            <FormControl
                                required
                                className={classes.formControl}
                                style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                                <Typography variant='subtitle1' className={classes.label}>
                                State/Province/Region
                                </Typography>
                                <TextField
                                id='state'
                                placeholder='State'
                                type='text'
                                name='state'
                                variant='outlined'
                                size='small'
                                value={state}
                                
                                InputLabelProps={{
                                    classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                    },
                                }}
                                />
                            </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                            <FormControl
                                required
                                className={classes.formControl}
                                style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                                <Typography variant='subtitle1' className={classes.label}>
                                Country
                                </Typography>
                                <TextField
                                placeholder='Country'
                                type='text'
                                name='country'
                                variant='outlined'
                                size='small'
                                 value={country}
                                
                                InputLabelProps={{
                                    classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                    },
                                }}
                                />
                            </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                            <FormControl
                                required
                                className={classes.formControl}
                                style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                                <Typography variant='subtitle1' className={classes.label}>
                                Postal Code
                                </Typography>
                                <TextField
                                placeholder='Postal Code'
                                type='text'
                                name='postalCode'
                                variant='outlined'
                                size='small'
                                 value={postalCode}
                                
                                
                                InputLabelProps={{
                                    classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                    },
                                }}
                                />
                            </FormControl>
                            </Grid>
                        </Grid>
                        </Grid>
                        {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        >
                        <Grid item md={12} xs={12}>
                            <FormControl
                            required
                            className={classes.formControl}
                            style={{ width: '100%', paddingRight: '0.4em' }}
                            >
                            <Typography variant='subtitle1' className={classes.label}>
                                About
                            </Typography>
                            <TextField
                                placeholder='About'
                                type='text'

                                name='about'
                                variant='outlined'
                                size='small'
                                value={values.about}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={(errors.about && touched.about) as boolean}
                                helperText={errors.about && touched.about && errors.about}
                                InputLabelProps={{
                                classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                },
                                }}
                            />
                            </FormControl>
                        </Grid>
                        </Grid> */}

                        <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        >
                        <Grid item md={9} xs={12}>
                            {/* <Typography variant='subtitle2'>
                            {false
                                ? `The login credentials will be sent to the student's email
                            ${values.email}`
                                : ''}
                            </Typography> */}
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button
                            className={classes.secondaryButton}
                            variant='contained'
                            color='primary'
                            type='button'
                            // disabled={renderedLoading}
                            // fullWidth
                            onClick={(e:any) => {addOrganisationSave()}}
                            >
                             
                            Update Details
                            </Button>
                        </Grid>
                        </Grid>
                        <DropzoneDialog
                        acceptedFiles={['image/jpeg,image/png,image/jpg']}
                        cancelButtonText={'Cancel'}
                        submitButtonText={'Submit'}
                        maxFileSize={5242880}
                        filesLimit={1}
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        // onSave={async (files) => {
                        //   try {
                        //     if (files.length > 0) {
                        //       let file = files[0];
                        //       const formData = new FormData();
                        //       formData.append('file', file);
                        //       // dispatch(addSubTaskImageToS3(formData));
                        //       const url = await addLibraryImageToS3(formData);
                        //       // setImage(url);
                        //       setFieldValue('imageUrl', url);

                                

                        //     }
                        //     setOpenModal(false);
                        //   } catch (err) {
                        //     console.log(err);
                        //   }
                        // }}
                        onSave={async (files) => {
                            try {
                            if (files.length > 0) {
                                // for(let i=0;i<files.length;i++)
                                // {
                                let file = files[0];
                                Resizer.imageFileResizer(
                                file,
                                680,
                                420,
                                "JPEG",
                                100,
                                0,
                                async(uri:any) => {
                                    console.warn("Form data view--->" + JSON.stringify(uri));
                                let fdata= getImageUrl(uri);
                                const formData = new FormData();
                                formData.append('file', fdata);
                                let urldatanxt = await addRecipeToS3(formData);
                                setImageUrl(urldatanxt)
                                //setFieldValue('imageUrl', urldatanxt);
                                },
                                "base64",
                                200,
                                200
                                );
                            }
                            setOpenModal(false);
                            } catch (err) {
                            console.log(err);
                            }
                        }}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        />
                    </form>
            </div>
        );
    }
    else
    {
        return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
        </div>;
    }
};
export default EditParent;
