import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { viewNutrintion } from '../../../constants/routes';
import { getrecipeNutritionById, getrecipeNutritionByIdRefresh } from '../../../slices/recipeNutritionSlice';
import EnhancedAddOrEditRecipeNutritionForm from './EnhancedAddOrEditRecipeNutritionForm';
import { Button } from 'bootstrap';
//import { useParams, } from 'react-router';


interface IAddRecipeNutritionProps {}

const AddRecipeNutrition: React.FC<IAddRecipeNutritionProps> = ({}) => {
    const location = useLocation();
  const dispatch = useDispatch();
  const { recipeId } = useParams<{ recipeId: string | undefined }>();

  
  //const { recipeNutritionId } = useParams<{ recipeNutritionId: string | undefined }>();
  const { recipeNutrition } = useSelector((state: RootState) => {
    return {
      recipeNutrition: state.recipeNutrition.recipeNutrition,
      recipeNutritionLoading: state.recipeNutrition.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === viewNutrintion ? true : false;
  if (recipeId) {
    localStorage.setItem('urlRoute', "edit_recipe_nutritions");
    localStorage.setItem('urlId', recipeId);
  }
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    if(user_id)
    {
      if (recipeId) {
        dispatch(getrecipeNutritionByIdRefresh(recipeId));

      }
    }
    else
    {
      if (recipeId) {
        dispatch(getrecipeNutritionById(recipeId));
        localStorage.setItem('urlRoute', "recipe_nutrition");
        localStorage.setItem('urlId', recipeId);
      }
    }
    
    return () => {};
  }, []);

  if(recipeNutrition)
  {
    console.warn("grc title"+JSON.stringify(recipeNutrition));
    localStorage.setItem('userData', JSON.stringify(recipeNutrition));
  }

  let renderedForm;
  // if (!isAddStudent && recipeNutrition) {

  //   const {
  //     servings,
  //     caloriesPerServing,
  //     totalCarbs,
  //     protein,
  //     totalFat,
  //     totalSugar,
  //     sodium,
  //       recipeId,
  //   } = recipeNutrition;
  //  // console.warn("recipe nutrition----------->"+JSON.stringify(carbohydrades));
  //   renderedForm = (
  //     <EnhancedAddOrEditRecipeNutritionForm
  //     servings={servings}
  //     caloriesPerServing={caloriesPerServing}
  //     totalCarbs={totalCarbs}
  //     protein={protein}
  //     totalFat={totalFat}
  //     totalSugar={totalSugar}
  //     sodium={sodium}
  //     recipeId={recipeId}
      
  //     />
  //   );
  // } else {
    renderedForm = <EnhancedAddOrEditRecipeNutritionForm />;
  //}

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
         
        {renderedForm}
      </Container>
    </div>
  );
};
export default AddRecipeNutrition;
