import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedCreateProgramFormValues } from './EnhancedCreateProgramForm';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';

const useStyles = makeStyles(formStyles);

interface ICreateProgramFormProps {}

export const CreateProgramForm: React.FC<
  ICreateProgramFormProps & FormikProps<EnhancedCreateProgramFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;

  const { programLoading } = useSelector((state: RootState) => {
    return {
      programLoading: state.program.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant="body1" className={classes.loginText}>
          Intake
        </Typography>
        <Typography variant="h4" className={classes.welcomeBackText}>
          Program Details
        </Typography>
        <Typography variant="body1" className={classes.loginText}>
          What is your program about?
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Enter Program Name
          </Typography>
          <TextField
            label="Program Name"
            type="text"
            placeholder=""
            name="schoolName"
            variant="outlined"
            size="small"
            value={values.schoolName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.schoolName && touched.schoolName) as boolean}
            helperText={
              errors.schoolName && touched.schoolName && errors.schoolName
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />{' '}
        </FormControl>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Enter number of students
          </Typography>
          <TextField
            label="Student count"
            type="number"
            placeholder=""
            name="numberOfStudents"
            variant="outlined"
            size="small"
            value={values.numberOfStudents}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              (errors.numberOfStudents && touched.numberOfStudents) as boolean
            }
            helperText={
              errors.numberOfStudents &&
              touched.numberOfStudents &&
              errors.numberOfStudents
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />{' '}
        </FormControl>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Enter program type
          </Typography>
          <TextField
            label="Program Type"
            type="string"
            placeholder=""
            name="programType"
            variant="outlined"
            size="small"
            value={values.programType}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.programType && touched.programType) as boolean}
            helperText={
              errors.programType && touched.programType && errors.programType
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />{' '}
        </FormControl>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={programLoading}
              // fullWidth
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
