import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  FormControl,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import formStyles from '../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { StyledTableCell } from '../../components/StyledTableCell';
import { StyledTableRow } from '../../components/StyledTableRow';
import { ADD_TASK } from '../../constants/routes';
// import { TaskRow } from './TaskRow';
// import { DeleteTaskModal } from './DeleteTaskModal';
// import { ViewStudentDetails } from './ViewStudentDetails';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { setSelectedDate } from '../../slices/taskSlice';
import { getReportForStudent } from '../../slices/studentSlice';
import { CircularProgress } from '@mui/material';
import { IngredientRow } from './IngredientRow';
import { AddIngredientModal } from './AddIngredientModal';
import {
  AddCircle,
  AddCircleOutline,
  PlusOneOutlined,
} from '@material-ui/icons';
import NoImageIcon from '@mui/icons-material/HideImage';


interface IViewGroceryListTableProps {}

const useStyles = makeStyles(formStyles);

const GroceryListTable: React.FC<IViewGroceryListTableProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { groceryList } = useSelector((state: RootState) => {
    return {
      groceryList: state.groceryLists.groceryList,
    };
  }, shallowEqual);

  // useEffect(() => {
  //   return () => {};
  // }, [reportUrl]);
console.warn("grclist--->"+JSON.stringify(groceryList?.ingredients));
  let renderedIngredients;
  if (groceryList?.ingredients.length === 0) {
    renderedIngredients = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No ingredient found.{' '}
            {/* <Link to={ADD_TASK} className={classes.link}>
              Add Task?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else if (
    groceryList?.ingredients !== undefined &&
    groceryList?.ingredients.length > 0
  ) {
    renderedIngredients = groceryList?.ingredients.map((ingredient, index) => {
      return <IngredientRow ingredient={ingredient} index={index} />;
    });
  } else {
    renderedIngredients = (
      <StyledTableRow >
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  let titlenm;
  if(groceryList)
  {
    titlenm=groceryList.title;
  }
  else
  {
    titlenm="";
  }
  let descriptionstr;
  if(groceryList)
  {
      descriptionstr=groceryList.description;
  }
  let gimg;
  if(groceryList)
   {
      gimg=groceryList.groceryImage;
   }
   else
   {
      gimg="https://nflyte-1.s3.amazonaws.com/6090a404-846f-4d9f-93d2-8e2a4499d453.png";
  }
  return (
    <>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
           <Grid item md={12} xs={12}>
           <Button
              color='primary'
              startIcon={
                <ArrowBackIosIcon
                  fontSize='small'
                  style={{ paddingRight: '0px' }}
                />
              }
              onClick={(e) => {
                history.go(-1);
              }}
              size='small'
            >
              Back
            </Button>
           </Grid>
      </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
           <Grid item md={12} xs={12}>
              <h5>Grocery List Details</h5>
           </Grid>
      </Grid>
      <div className='card'>
        <div className='card-body'>
         <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={1} xs={12}>

          </Grid>
           <Grid item md={2} xs={12}>
                  {gimg !== '' ? (
                    <a href={gimg} target='_blank'>
                    <img
                        src={gimg}
                        width="100" height="100"
                        className="rounded"
                    />
                    </a>
                ) : (
                    <NoImageIcon style={{ color: 'grey' }} />
                )}

           </Grid>
           <Grid item md={3} xs={12}>
           Title : - {titlenm}
           </Grid>
           <Grid item md={5} xs={12}>
           Description : - {descriptionstr}


           </Grid>
        </Grid>
        </div>
      </div>
        <hr/><br/>
        <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
           <Grid item md={10} xs={12}>
              
           </Grid>
           <Grid item md={2} xs={12}>
            <Button
              color='primary'
              variant='contained'
              style={{marginLeft:'50px' }}
              startIcon={
                <AddCircleOutline
                  fontSize='small'
                  style={{ paddingRight: '0px'}}
                />
              }
              onClick={(e) => {
                // history.go(-1);
                setOpenModal(true);
              }}
              size='small'
            >
              Add Item
            </Button>
            </Grid>
        </Grid>
       </div><br/>
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
           <Grid item md={12} xs={12}>
              <h5>Ingredient List</h5>
           </Grid>
      </Grid>
      
      <TableContainer component={Paper}>
        <Table aria-label='customized table' style={{overflowX:"hidden",overflowY:"scroll",height:"500px;"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>
                <em>Name</em>
              </StyledTableCell>
              <StyledTableCell align='center' style={{ textAlign: 'center' }}>
                <em>Category</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Unit</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Quantity</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Price</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Status</em>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <em>Actions</em>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedIngredients}</TableBody>
        </Table>
      </TableContainer><br/><br/>
      <AddIngredientModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

export default GroceryListTable;
