import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  IMAGE_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  PREPARATION_TIME_REQUIRED,
  RECIPE_TYPE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../constants/formMessages';
import { AddOrEditRecipeForm } from './AddOrEditRecipeForm';
import { createRecipe } from '../../slices/recipeSlice';

interface IDispatchProps {
    
    createRecipe: (
    recipeUrl: string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    userId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedAddOrEditRecipeFormValues {
    recipeUrl: string;
    recipeTitle: string;
    recipeDescription: string;
    TypeOfRecipe: string,
    tags: string[];
    preparationTime: string;
    userId: string;
}

export interface EnhancedAddOrEditRecipeFormProps extends RouteComponentProps {
    recipeUrl?: string;
    recipeTitle?: string;
    recipeDescription?: string;
    TypeOfRecipe?: string;
    tags?: string[];
    preparationTime?: string;
    userId?: string;
  createRecipe: (
    recipeUrl: string,
    recipeTitle: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    userId: string,
     history: History
  ) => void;
}

const EnhancedAddOrEditRecipeForm = withFormik<
  EnhancedAddOrEditRecipeFormProps,
  EnhancedAddOrEditRecipeFormValues
>({
  mapPropsToValues: (props) => ({
    recipeUrl: props.recipeUrl ? props.recipeUrl : '',
    recipeTitle: props.recipeTitle ? props.recipeTitle : '',
    recipeDescription: props.recipeDescription ? props.recipeDescription : '',
    TypeOfRecipe: props.TypeOfRecipe ? props.TypeOfRecipe : '',
    tags: props.tags ? props.tags : [],
    preparationTime: props.preparationTime ? props.preparationTime : '',
    userId: props.userId ? props.userId : '',
   
  }),
  validationSchema: Yup.object().shape({
    recipeUrl: Yup.string().required(IMAGE_REQUIRED),
    recipeTitle: Yup.string().required(NAME_REQUIRED),
    recipeDescription: Yup.string().required(DESCRIPTION_REQUIRED),
   // TypeOfRecipe: Yup.string().required(RECIPE_TYPE_REQUIRED),
    tags: Yup.array().of(Yup.string()),
    preparationTime: Yup.string().required(PREPARATION_TIME_REQUIRED),
    userId: Yup.string().notRequired(),
    // city: Yup.string().notRequired(),
    // country: Yup.string().notRequired(),
    // postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createRecipe } = props;
    const {
        recipeUrl,
        recipeTitle,
        recipeDescription,
        TypeOfRecipe,
        tags,
        preparationTime,
        userId
    } = values;
    resetForm({});
    createRecipe(
         recipeUrl,
        recipeTitle,
        recipeDescription,
        TypeOfRecipe,
        tags,
        preparationTime,
        userId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddOrEditRecipeForm);

const EnhancedAddOrEditRecipeFormWithRouter = withRouter(
  EnhancedAddOrEditRecipeForm
);

export default connect<null, IDispatchProps>(null, {
    createRecipe,
})(EnhancedAddOrEditRecipeFormWithRouter);
