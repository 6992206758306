import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { deleteIngredient } from '../../slices/studentSlice';
import theme from '../../app/theme';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from 'react-router';

interface IDeleteIngredientModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  id: string;
  groceryId:string;
}

export const DeleteIngredientModal: React.FC<IDeleteIngredientModalProps> = ({
  openModal,
  setOpenModal,
  id,
  groceryId
}) => {
  const dispatch = useDispatch();
const history= useHistory();

const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{
            color: theme.palette.primary.main,
            backgroundColor: 'white',
          }}
        >
          Delete Ingredient
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: 'white',
          }}
        >
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this ingredient?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: 'white',
          }}
        >
          <Button variant='text' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              dispatch(deleteIngredient(id,groceryId,history));
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
