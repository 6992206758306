import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, IconButton, List } from '@material-ui/core';
import { RootState } from '../../../app/rootReducer';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeStudentId } from '../../../slices/taskSlice';
import { TransitionGroup } from 'react-transition-group';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
interface IDisplayStudentsProps {}

export const DisplayStudents: React.FC<IDisplayStudentsProps> = ({}) => {
  const dispatch = useDispatch();

  const { students, studentIds } = useSelector((state: RootState) => {
    return {
      students: state.student.students,
      studentIds: state.task.studentIds,
    };
  }, shallowEqual);
  let renderedStudentsLists;
  useEffect(() => {
    return () => {};
  }, []);//studentIds.length
  console.warn("student ids length------->"+studentIds)

  if (studentIds.length > 0) {
    renderedStudentsLists = students
      .filter((student) => {
        return studentIds.includes(student.id);
      })
      .map((student) => {
        const { name, email, id, imageUrl } = student;
        return (
          <Collapse key={id}>
            <ListItem style={{ width: '80%' }}>
              <ListItemAvatar>
                <Avatar alt={name} src={imageUrl} />
              </ListItemAvatar>
              <ListItemText primary={name} secondary={email} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  title="Delete"
                  onClick={() => {
                    dispatch(removeStudentId(id));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Collapse>
        );
      });
  }

  return (
    <div style={{ width: '80%' }}>
      <List>
        <TransitionGroup>{renderedStudentsLists}</TransitionGroup>
      </List>
    </div>
  );
};
