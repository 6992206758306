import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../app/rootReducer';
import {
  getAllUsers,
  createChatRoom,
  setFilteredAllUsers,
} from '../../slices/chatRoomSlice';
import { MESSAGING } from '../../constants/routes';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,

} from '@material-ui/core';
import { SearchOutlined,  Close as AddOutlined,
} from '@material-ui/icons';
import formStyles from '../../assets/jss/components/formStyles';
import { ContactListItem } from '../../components/Messaging/ContactListItem';
import { InfoListItem } from '../../components/Messaging/InfoListItem';

const useStyles = makeStyles(formStyles);

interface SelectContactProps {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenGroupModal: Dispatch<SetStateAction<boolean>>;
  setOpenAddGroupModal: Dispatch<SetStateAction<boolean>>;
}

export const SelectContact: React.FC<SelectContactProps> = ({
  setOpenModal,
  setOpenAddGroupModal,
  setOpenGroupModal,
}) => {
  const classes = useStyles();
  const {
    chatRoom: { allUsers, filteredAllUsers },
    auth: { graphQlUser: currentUser },
    programUsers
  } = useSelector((state: RootState) => {
    return {
      chatRoom: state.chatRoom,
      auth: state.auth,
      programUsers: state.student.students
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [externalLoading, setExternalLoading] = useState<boolean>(false);
  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  useEffect(() => {
    dispatch(setFilteredAllUsers({ query }));
  }, [query]);
  if (
    allUsers === null ||
    filteredAllUsers === null ||
    filteredAllUsers === undefined
  ) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading...
      </div>
    );
  }
  let filtuser: string[]=[];
  if(programUsers)
  {
    for(let j=0;j<programUsers.length;j++)
    {
      filtuser.push(programUsers[j].id);
    
    }
  }
console.log("filtuser-->"+filtuser);
  return (
    <div>
      <Grid
        container
        justify='center'
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
            
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent  style={{height:'550px'}}>
            <Grid
                container
                spacing={3}
                
              >
                  <Grid item xs={10} sm={10} md={10}>

                </Grid>

                <Grid item xs={2} sm={2} md={2}>

                <IconButton
                      style={{ marginLeft: '0.5rem',width:'20%' }}
                      size='medium'
                      onClick={() => setOpenModal(false)}
                    >
                      <AddOutlined style={{ color: 'blue' }} />
                    </IconButton>
                </Grid>
              </Grid>
              <Typography
                variant='h5'
                style={{ margin: '1rem auto', textAlign: 'center' }}
                component='h2'
              >
                Select Contact
              </Typography> 
              
              <TextField
                onChange={handleChange}
                value={query}
                style={{
                  width: '100%',
                  background: 'rgba(255,255,255,1)',
                }}
                variant='outlined'
                size='small'
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search'
              />
              
              <InfoListItem
                title='New Group'
                onClick={() => {
                  setOpenModal(false);
                  setOpenGroupModal(true);
                }}
              />
              <div style={{ maxHeight: '350px', overflow: 'scroll' }}>
                {/* @ts-ignore */}
                {allUsers // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id).length ===
                  0 && (
                  <Typography
                    variant='h6'
                    style={{ margin: '1rem 0rem', textAlign: 'center' }}
                  >
                    No contacts found
                  </Typography>
                )}

                {/* @ts-ignore */}
                {allUsers // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id).length !==
                  0 &&
                  filteredAllUsers
                  // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id).length ===
                  0 && (
                  <Typography
                    variant='h6'
                    style={{ margin: '1rem 0rem', textAlign: 'center' }}
                  >
                    No contact found
                  </Typography>
                )}
                {/* @ts-ignore */}

                {filteredAllUsers
                  // @ts-ignore
                  ?.filter((user: any) => user.id !== currentUser.id)
                  .map((user: any) => {
                    if(filtuser.find(usr => usr === user.id))
                    {
                        return (
                          <ContactListItem
                            contact={user}
                            key={user.id}
                            useLoading={true}
                            onClick={async () => {
                              if (!externalLoading) {
                                setExternalLoading(true);
                                await dispatch(
                                  createChatRoom(user, currentUser, history)
                                );
                                setOpenModal(false);
                                setExternalLoading(false);
                                history.push(MESSAGING);
                              } else {
                                console.log('WAITTT');
                              }
                            }}
                          />
                        );
                      }
                  })}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
