import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Diagnosis } from './../constants/models/Diagnosis';
import { setErrorMsg } from './alertSlice';
import * as REQUESTS from '../api/diagnosisRequests';

export interface diagnosisState {
  loading: boolean;
  error: string | null;
  diagnosis: Diagnosis | null;
  diagnoses: Diagnosis[];
}

export const initialState: diagnosisState = {
  loading: false,
  error: null,
  diagnosis: null,
  diagnoses: [],
};
const diagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    diagnosisStart(state) {
      state.loading = true;
      state.error = null;
    },
    setDiagnosis(state, action: PayloadAction<Diagnosis | null>) {
      state.diagnosis = action.payload;
    },
    setDiagnoses(state, action: PayloadAction<{ data: Diagnosis[] }>) {
      const { data } = action.payload;
      state.diagnoses = data.map((diagnosis) => diagnosis);
    },
    diagnosisFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    diagnosisComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  diagnosisStart,
  setDiagnoses,
  setDiagnosis,
  diagnosisComplete,
  diagnosisFailure,
} = diagnosisSlice.actions;

export default diagnosisSlice.reducer;

export const getDiagnosisById =
  (diagnosisId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(diagnosisStart());
      const diagnosis = await REQUESTS.getDiagnosisById(diagnosisId);
      dispatch(setDiagnosis(diagnosis));
      dispatch(diagnosisComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(diagnosisFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const getDiagnosesForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(diagnosisStart());
    const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
  
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const diagnoses = await REQUESTS.getDiagnosesForProgram(programId);
      dispatch(setDiagnoses({ data: diagnoses }));
    }
    dispatch(diagnosisComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(diagnosisFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getDiagnosesForProgramRefresh = (proggram_pass_id:string): AppThunk => async (dispatch) => {
  try {
    dispatch(diagnosisStart());
    const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      let program_de_id=proggram_pass_id;
      
  
    if (program_de_id) {
    let programId = program_de_id;//program.id;
      const diagnoses = await REQUESTS.getDiagnosesForProgram(programId);
      dispatch(setDiagnoses({ data: diagnoses }));
    }
    dispatch(diagnosisComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(diagnosisFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createDiagnosis =
  (name: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(diagnosisStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;

      const { superAdmin } = store.getState().superAdmin;

      let program_de_id="";
      if(superAdmin?.id)
      {
        program_de_id=superAdmin.id;
      } 
      else if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      else if(counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(programManager?.id)
      {
        program_de_id=programManager.id;
      }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const diagnosis = await REQUESTS.createDiagnosis(name, programId);
        dispatch(setDiagnosis(diagnosis));
        dispatch(getDiagnosesForProgram());
      }
      dispatch(diagnosisComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(diagnosisFailure(error));
      dispatch(setErrorMsg(error));
    }
  };

export const deleteDiagnosis =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(diagnosisStart());
      await REQUESTS.deleteDiagnosis(id);
      dispatch(getDiagnosesForProgram());
      dispatch(diagnosisComplete());
    } catch (err: any) {
      const { error } = err.response.data;
      dispatch(diagnosisFailure(error));
      dispatch(setErrorMsg(error));
    }
  };
