import { combineReducers } from '@reduxjs/toolkit';
import alertReducer from '../slices/alertSlice';
import authReducer from '../slices/authSlice';
import chatRoomReducer from '../slices/chatRoomSlice';
import counselorReducer from '../slices/counselorSlice';
import diagnosisReducer from '../slices/diagnosisSlice';
import documentRedcuer from '../slices/documentSlice';
import recipeRedcuer from '../slices/recipeSlice';
import recipeIngredientRedcuer from '../slices/recipeingredientSlice';
import recipeStepsRedcuer from '../slices/recipeStepsSlice';
import recipeNutritionsRedcuer from '../slices/recipeNutritionSlice';
import eventReducer from '../slices/eventSlice';
import imageReducer from '../slices/imageSlice';
import libraryItemReducer from '../slices/libraryItemSlice';
import programManagerReducer from '../slices/programManagerSlice';
import programReducer from '../slices/programSlice';
import questionReducer from '../slices/questionSlice';
import studentReducer from '../slices/studentSlice';
import taskReducer from '../slices/taskSlice';
import taskTypeReducer from '../slices/taskTypeSlice';
import videoReducer from '../slices/videoSlice';
import parentReducer from '../slices/parentSlice';
import tagReducer from '../slices/tagSlice';
import recipeTagReducer from '../slices/recipeTagTypeSlice';
import ingredientsReducer from '../slices/ingridiansSlice';
import groceryReducer from '../slices/grocerySlice';
import commonReducer from '../slices/commonSlice';
import superAdminReducer from '../slices/superAdminSlice';
import organisationReducer from '../slices/organisationSlice';


const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  chatRoom: chatRoomReducer,
  counselor: counselorReducer,
  diagnosis: diagnosisReducer,
  document: documentRedcuer,
  recipe: recipeRedcuer,
  recipeIngredient: recipeIngredientRedcuer,
  recipeStep: recipeStepsRedcuer,
  recipeNutrition: recipeNutritionsRedcuer,
  event: eventReducer,
  image: imageReducer,
  libraryItem: libraryItemReducer,
  program: programReducer,
  programManager: programManagerReducer,
  question: questionReducer,
  student: studentReducer,
  task: taskReducer,
  taskType: taskTypeReducer,
  recipeTagType: recipeTagReducer,
  video: videoReducer,
  parent: parentReducer,
  tag: tagReducer,
  ingredients: ingredientsReducer,
  groceryLists: groceryReducer,
  commonLists: commonReducer,
  superAdmin: superAdminReducer,
  organisation: organisationReducer,

});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
