import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { TransitionGroup } from 'react-transition-group';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  deleteDiagnosis,
  getDiagnosesForProgram,
  getDiagnosesForProgramRefresh,
} from '../../../slices/diagnosisSlice';

interface IDisplayDiagnosisProps {}

export const DisplayDiagnosis: React.FC<IDisplayDiagnosisProps> = ({}) => {
  const dispatch = useDispatch();
  const { diagnosisLoading, diagnoses } = useSelector((state: RootState) => {
    return {
      diagnosisLoading: state.diagnosis.loading,
      diagnoses: state.diagnosis.diagnoses,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(user_id)
    {
      dispatch(getDiagnosesForProgramRefresh(user_id));
    }
    else
    {
      dispatch(getDiagnosesForProgram());

    }
    return () => {};
  }, [diagnoses.length]);
  let renderedDiagnoses;
  if (diagnoses.length > 0) {
    renderedDiagnoses = diagnoses.map((diagnosis, index) => {
      const { name, id } = diagnosis;
      return (
        <Collapse key={id}>
          <ListItem
            style={{ width: '100%' }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                title="Delete"
                onClick={() => {
                  dispatch(deleteDiagnosis(id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={name} />
          </ListItem>
        </Collapse>
      );
    });
  }
  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>{renderedDiagnoses}</TransitionGroup>
        </List>
      </Box>
    </div>
  );
};
