import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import AsyncSelect from 'react-select/async';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {
  Grid,
  Container,
  CssBaseline,
  TextField,
  makeStyles,
  Typography,
  FormControl,
  IconButton,
  Button,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import formStyles from '../../../assets/jss/components/formStyles';
import { FormikProps } from 'formik';
import { EnhancedAddTaskDetailsFormValues } from './EnhancedAddTaskDetailsForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { getTaskTypesForProgram } from '../../../slices/taskTypeSlice';
import { getRecipesAll } from '../../../slices/recipeSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  addInstruction,
  removeInstruction,
  setFileName,
  setImageBinary,
  setAddedAttachment,
  toggleIsSpecial,
  addSubTask,
  addStudentId,
  setImageUrl,
} from '../../../slices/taskSlice';
import { 
  addImageToS3,
} from '../../../slices/commonSlice';
import { DropzoneDialog } from 'material-ui-dropzone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DisplayInstructions } from './DisplayInstructions';
import { getrecipeTags } from '../../../slices/tagSlice';
import { viewStudentTask } from '../../../constants/routes';
import Resizer from "react-image-file-resizer";
import { addRecipeToS3 } from '../../../api/recipeRequests';
import  SIMG  from '../../../assets/images/sampleimg.png'; 

const useStyles = makeStyles(formStyles);

interface IAddTaskDetailsFormProps {}

export const AddTaskDetailsForm: React.FC<
  IAddTaskDetailsFormProps & FormikProps<EnhancedAddTaskDetailsFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { studentId } = useParams<{ studentId: string | undefined }>();

  const [open, setOpen] = useState(false);

  const [instruction, setInstruction] = useState<string>('');
  const [fname,setFname]=useState("");
  let debug = false;

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;

  const {
    taskLoading,
    taskTypes,
    attachment,
    imageUrl,
    fileName,
    isRecurring,
    isSpecial,
    instructions,
    recipes,
    studentIds
  } = useSelector((state: RootState) => {
    return {
      taskLoading: state.task.loading,
      taskTypes: state.taskType.taskTypes,
      attachment: state.task.attachment,
      fileName: state.task.fileName,
      imageUrl: state.task.imageUrl,
      isRecurring: state.task.isRecurring,
      isSpecial: state.task.isSpecial,
      instructions: state.task.instructions,
      recipes: state.recipe.recipes,
      studentIds: state.task.studentIds,

    };
  }, shallowEqual);
  console.warn("student ids first------->"+studentIds[0])

  // useEffect(() => {
  //   dispatch(addStudentId(studentIds[0]));
  //   return () => {};
  // }, [studentIds.length]);
let studid="";
if(studentId)
{
  studid=studentId;
}
localStorage.setItem('urlRoute', "user_add_task");

  useEffect(() => {
    dispatch(getTaskTypesForProgram());
    return () => {};
  }, [taskTypes.length]);

  useEffect(() => {
    dispatch(getrecipeTags());
    //dispatch(getRecipesAll());

    return () => {};
  }, [recipes.length]);
  
  let renderedTaskTypes = taskTypes.map((taskType) => {
    return {
      value: taskType.id,
      label: taskType.name,
    };
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  } 
  const [imgerr, setImgErr] = useState("");
  const [img,setImg] = useState("");
  function chkImageValidate()
  {
    if(!values.taskTypeId)
    {
        setImgErr("Task type is required");
    }
    else
    {
      setImgErr("");

    }
  }
  return (
    <div style={{scrollBehavior:'smooth' }}>
      <div style={{ margin: '0px 0px 12px'}}>
        <Typography variant='h4' className={classes.welcomeBackText}>
          <em>Add Task</em>
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Task Name{' '} <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            placeholder='Eg. Brush your teeth'
            name='name'
            type='text'
            variant='outlined'
            size='small'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.name && touched.name) as boolean}
            helperText={errors.name && touched.name && errors.name}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Task Type <span style={{color:'red'}}>*</span>
          </Typography>
          <AsyncSelect
            cacheOptions
            defaultOptions={renderedTaskTypes}
            styles={customStyles}
            //value={ta}
            onChange={(option) => {
              if (option) {
                setFieldValue('taskTypeId', option.value);
              }
            }}
          />
          <span style={{color:'red'}}>{imgerr}</span>

        </FormControl>
        <Typography variant='subtitle1' className={classes.label1}>
          Critcial Task
        </Typography>
        <Typography variant='subtitle2'>
         
          <em>Is this is a critical task?</em>
          <Switch
            checked={isSpecial}
            onChange={() => {
              dispatch(toggleIsSpecial());
            }}
            color='primary'
            name=''
          />
        </Typography>
        <Typography variant='subtitle1' className={classes.label1}>
          Attachment
        </Typography>

        <Typography variant='subtitle2'>
        <br/>
        <div className='row'>
              <div className='col-sm-2'>
                  {/* {!attachment ? (
                  // <Button
                  //   startIcon={<PhotoCamera />}
                  //   color='primary'
                  //   component='span'
                  //   size='large'
                  //   onClick={() => {
                  //     setOpen(true);
                  //   }}
                  //   disabled={attachment}
                  // >
                  //   Upload
                  // </Button>
                  <IconButton onClick={() => setOpen(true)}>
                     
                    
                        <img src={SIMG} style={{ width: 56, height: 56 }}/>
                      
                    </IconButton>
                ) : (
                  <>
                    {imageUrl? 
                        <img src={imageUrl} style={{ width: 56, height: 56 }}/>:
                        <img src={imageUrl} style={{ width: 56, height: 56 }}/>
                      }
                  </>
                )} */}
                <IconButton onClick={() => setOpen(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 100, height: 100 }}
                /> */}
                 {imageUrl? 
                        <img src={imageUrl} style={{ width: 56, height: 56 }}/>:
                        <img src={SIMG} style={{ width: 56, height: 56 }}/>
                      }
              </IconButton>

            </div>
            <div className='col-sm-10'>
            <em>Show how the task is done?</em><br/>
            <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>

            </div>
           
          </div><br/>
         
        </Typography>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            if (files.length > 0) {
              let file = files[0];
              setFname(file.name);

              Resizer.imageFileResizer(
                file,
                680,
                420,
                "JPEG",
                100,
                0,
                async(uri:any) => {
                  //console.log(uri);
                  console.warn("Form data view--->" + JSON.stringify(uri));
                 let fdata= getImageUrl(uri);
                 //setImg(fdata);
                 const formData = new FormData();
                 formData.append('file', fdata);
                 //dispatch(addRecipeToS3(formData));
                 let urldatanxt = await addRecipeToS3(formData);
                 dispatch(setImageUrl(urldatanxt));
                 dispatch(setAddedAttachment(true));

                 // setImage(url);
                 // url.push(urldata);
                 //console.log("res data---->"+urldatanxt)
                 //let jurl=JSON.stringify(urldatanxt)

                },
                "base64",
                200,
                200
              );
              // dispatch(setFileName(file.name));
              // const formData = new FormData();
              // formData.append('file', file);
               //dispatch(addImageToS3(formData,files));
            }
            setOpen(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12}>
            <FormControl
              required
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Instructions
              </Typography>
              <Grid container={true}>
                <TextField
                  type='text'
                  size='small'
                  placeholder='Eg. Plug the vacuum cleaner'
                  name='name'
                  variant='outlined'
                  value={instruction}
                  onChange={(e) => {
                    setInstruction(e.target.value);
                  }}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          color='primary'
                          onClick={(e) => {
                            //   dispatch(addInstruction(instruction));
                            dispatch(
                              addSubTask({
                                name: instruction,
                                completedImageUrl: 'no-url',
                              })
                            );

                            setInstruction('');
                          }}
                          disabled={instruction === ''}
                        >
                          <AddCircleOutlineIcon color='primary' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (instruction !== '') {
                        //dispatch(addInstruction(instruction));
                        dispatch(
                          addSubTask({
                            name: instruction,
                            completedImageUrl: '',
                          })
                        );
                        setInstruction('');
                      }
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                  helperText={'Press enter to add an instruction'}
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <DisplayInstructions />
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={
                taskLoading || values.taskTypeId === '' || values.name === ''
              }
              onClick={ (e) => {
               // dispatch(addStudentId(studid));
   
              // history.push(EDIT_PARENT);
             }}
              // fullWidth
            >
              Next
            </Button>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
      <br/><br/><br/><br/><br/><br/>

    </div>
  );
};
