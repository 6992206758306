import React from 'react';
import {
  Grid,
  Container,
  CssBaseline,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Typical from 'react-typical';
import DOWN_SYNDROME from '../../assets/images/down-syndrome.png';
import GRAPHIC_5 from '../../assets/images/screenshots/5.png';
import aboutStyles from '../../assets/jss/components/aboutStyles';

interface IForSchoolsProps {}

const useStyles = makeStyles(aboutStyles);

const ForSchools: React.FC<IForSchoolsProps> = ({}) => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Typography className={classes.heading}>For Schools</Typography>
      {/* <Typography className={classes.subHeading}>
        <Typical wrapper="b" steps={['This is what we offer schools', 5000]} />
      </Typography> */}
      <br />
      <br />
      <br />
      <Container>
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <img src={DOWN_SYNDROME} className={classes.img} />
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description}>
              <em>
                NFlyte is an application made specifically for post-secondary
                inclusive programs to supplement independent living programs
                through technology. Program Directors have complete visibility
                into the progress of each student on their daily living skills
                and can group students to be viewed by cohort or counselor. The
                Success Scorecard shows progress or areas of concern in their
                daily tasks and emotional well-being. Students can upload photos
                showing a completed task and watch how they have improved over
                time. Send notifications for group activities, individual notes
                of encouragement, set meetings to be added to their daily
                planner and upload important documents, like bus routes, into
                their resource library. NFlyte is designed specifically for
                neurodiverse students and the programs that support them.
              </em>
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ForSchools;
