import { IMAGE_ROUTE, PROGRAM_ROUTE } from './../constants/routes';
import { LibraryImage } from '../constants/models/LibraryImage';

import API from './api';

export async function getImageById(id: string) {
  try {
    const res = await API.get<{ data: LibraryImage; success: boolean }>(
      `${IMAGE_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getImagesForProgram(programId: string) {
  try {
    const res = await API.get<{ data: LibraryImage[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${IMAGE_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createImage(
  description: string,
  imageUrl: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: LibraryImage; success: boolean }>(
      `${IMAGE_ROUTE}`,
      { description, imageUrl, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateImage(
  id: string,
  description: string,
  imageUrl: string
) {
  try {
    const res = await API.post<{ data: LibraryImage; success: boolean }>(
      `${IMAGE_ROUTE}/${id}/updateImage`,
      {
        description,
        imageUrl,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteImage(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${IMAGE_ROUTE}/${id}/deleteImage`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addLibraryImageToS3(formData: FormData) {
  try { 
        const res = await API.post<{ data: string; success: boolean }>(
          `${IMAGE_ROUTE}/add-image_next`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
         return res.data.data;
  } catch (err) {
    throw err;
  }
}


function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }