import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
  TITLE_REQUIRED,
} from '../../../../constants/formMessages';
import { AddGroceryListForm } from './AddGroceryListForm';
import { createGroceryList } from '../../../../slices/grocerySlice';
import { useParams } from 'react-router';


interface IDispatchProps {
    createGroceryList: (
    title: string,
    description: string,
    groceryImage: string,
    studentId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedAddGroceryListFormValues {
    title: string;
    description: string;
    groceryImage: string;
    studentId: string;
}

export interface EnhancedAddGroceryListFormProps extends RouteComponentProps {
    title?: string;
    description?: string;
    groceryImage?: string;
    studentId?: string;
    createGroceryList: (
    title: string,
    description: string,
    groceryImage: string,
    studentId: string,
     history: History
  ) => void;
}

const EnhancedAddGroceryListForm = withFormik<
  EnhancedAddGroceryListFormProps,
  EnhancedAddGroceryListFormValues
>({
  mapPropsToValues: (props) => ({
    title: props.title ? props.title : '',
    description: props.description ? props.description : '',
    groceryImage: props.groceryImage ? props.groceryImage : '',
    studentId: props.studentId ? props.studentId : '',
   
  }),
  validationSchema: Yup.object().shape({
    description: Yup.string().required(DESCRIPTION_REQUIRED),
    title: Yup.string().required(TITLE_REQUIRED),
    groceryImage: Yup.string().notRequired(),
    studentId: Yup.string().notRequired(),
   
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, createGroceryList } = props;
    const {
      title,
      description,
        groceryImage,
        studentId
    } = values;
    resetForm({});

    createGroceryList(
      title,
      description,
        groceryImage,
        studentId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(AddGroceryListForm);

const EnhancedAddGroceryListFormWithRouter = withRouter(
    EnhancedAddGroceryListForm
);

export default connect<null, IDispatchProps>(null, {
    createGroceryList,
})(EnhancedAddGroceryListFormWithRouter);
