import { PROGRAM_ROUTE, RECIPE_ROUTE } from './../constants/routes';
import { RECIPE_TAG_ROUTE } from '../constants/routes';
import { RecipeTagType } from './../constants/models/RecipeTagType';
import API from './api';

export async function getRecipeTagTypeById(id: string) {
  try {
    const res = await API.get<{ data: RecipeTagType; success: boolean }>(
      `${RECIPE_TAG_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getRecipeTagTypesForProgram(programId: string) {
  try {
    const res = await API.post<{ data: RecipeTagType[]; success: boolean }>(
      `${RECIPE_ROUTE}/${programId}/getRecipeType`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createRecipeTagType(type: string, recipetypeUrl: string, programId: string) {
  try {
    const res = await API.post<{ data: RecipeTagType; success: boolean }>(
      `${RECIPE_ROUTE}/addRecipeType`,
      { type:type, recipetypeUrl, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
} 

export async function updateRecipeTagType(
  id: string,
  type: string,
  programId: string
) {
  try {
    const res = await API.post<{ data: RecipeTagType; success: boolean }>(
      `${RECIPE_TAG_ROUTE}/${id}/updateRecipeTagType`,
      { type, programId }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteRecipeTagType(id: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/deleteType`
    );
  } catch (err) {
    throw err;
  }
}
