import React from 'react';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import { EventModel } from '../constants/models/EventModel';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import addHours from 'date-fns/addHours';
import startOfHour from 'date-fns/startOfHour';
import 'react-big-calendar/lib/css/react-big-calendar.css';

interface ICalendarComponentProps {
  events: EventModel[];
}
export const CalendarComponent: React.FC<ICalendarComponentProps> = ({
  events,
}) => {
  let mappedEvents = events.map((event) => {
    return {
      id: event.id,
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
    };
  });
  const locales = {
    'en-US': enUS,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  return (
    <>
      <Calendar
        events={mappedEvents}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={Views.MONTH}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '500px' }}
      />
    </>
  );
};
