import React from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import EnhancedCreateProgramForm from './EnhancedCreateProgramForm';
import useMediaQuery from '@mui/material/useMediaQuery';

import GRAPHIC from '../../../assets/images/register_graphic.svg';
import theme from '../../../app/muiTheme';

interface ICreateProgramProps {}

const CreateProgram: React.FC<ICreateProgramProps> = ({}) => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div style={{ paddingTop: '10vh' }}>
      <Container style={{ padding: '0.4em', height: '70vh' }}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={12} md={6}>
            <CssBaseline />
            <div style={{ padding: '1em' }}>
              <EnhancedCreateProgramForm />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ padding: '1em', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default CreateProgram;
