import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import TaskIcon from '@mui/icons-material/Task';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import FaceIcon from '@mui/icons-material/Face';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddTaskIcon from '@mui/icons-material/AddTask';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import AddVideo from '@mui/icons-material/VideoLibrary';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {
  ADD_COUNSELOR,
  ADD_DIAGNOSIS,
  ADD_EVENT,
  ADD_QUESTIONS,
  ADD_STUDENT,
  ADD_TASK,
  ADD_TASK_TYPE,
  COUNSELOR_PROFILES,
  STUDENT_PROFILES,
  VIEW_CALENDAR,
  ADD_IMAGES,
  ADD_VIDEOS,
  ADD_DOCUMENTS,
  ADD_RECIPE,
  MESSAGING,
  ADD_RECIPE_TAG,
  ADD_TASK_WITH_USER,
  SUPERADMIN_COUNSELOR_PROFILES,
  SUPERADMIN_STUDENT_PROFILES,
  ENQUIRY_LIST,
  PROGRAM_MANAGER_LIST,
  SUPERADMIN_PARENT_PROFILES
} from '../../../constants/routes';
import { Chat } from '@material-ui/icons';
import { BsTags } from 'react-icons/bs';
import { BiCookie } from 'react-icons/bi';
import { CgOrganisation } from 'react-icons/cg';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { RiParentLine } from 'react-icons/ri';

export const superAdminRoutes = [
  {
    id: 'Super Admin',
    children: [ 
      {
        // id: 'Counselor Profiles',
        id: 'Organization Enquiries',
        route: ENQUIRY_LIST,
        icon: <CgOrganisation />,
      },
      {
        // id: 'Counselor Profiles',
        id: 'Program Managers',
        route: PROGRAM_MANAGER_LIST,
        icon: <MdOutlineAdminPanelSettings style={{color:'#fff'}} />,
      },
      {
        // id: 'Counselor Profiles',
        id: 'Admin Profiles',
        route: SUPERADMIN_COUNSELOR_PROFILES,
        icon: <AccountBoxIcon />,
      },
      {
        // id: 'Student Profiles',
        id: 'User Profiles',
        route: SUPERADMIN_STUDENT_PROFILES,
        icon: <FaceIcon />,
      },
      // {
      //   // id: 'Student Profiles',
      //   id: 'Parent Profiles',
      //   route: SUPERADMIN_PARENT_PROFILES,
      //   icon: <RiParentLine />,
      // },
      // {
      //   id: 'Messaging',
      //   route: MESSAGING,
      //   icon: <Chat />,
      // },
      // {
      //   id: 'Calendar',
      //   route: VIEW_CALENDAR,
      //   icon: <CalendarTodayIcon />,
      // },

      // { id: 'Add Event', route: ADD_EVENT, icon: <EventAvailableIcon /> },
      // {
      //   id: 'Add Task',
      //   route: ADD_TASK_WITH_USER,
      //   icon: <AddTaskIcon />,
      // },
      {
        id: 'Add Diagnosis',
        route: ADD_DIAGNOSIS,
        icon: <HealthAndSafetyIcon />,
      },
      {
        id: 'Add Task Type',
        route: ADD_TASK_TYPE,
        icon: <TaskIcon />,
      },
      {
        id: 'Cookbook',
        route: ADD_RECIPE,
        icon: <BiCookie />,
      },
      {
        id: 'Cookbook Tags',
        route: ADD_RECIPE_TAG,
        icon: <BsTags />,
      },
    ],
  },
  {
    id: 'Library',
    children: [
      {
        id: 'Questions',
        route: ADD_QUESTIONS,
        icon: <QuestionAnswerIcon />,
      },
      {
        id: 'Images',
        route: ADD_IMAGES,
        icon: <AddPhoto />,
      },
      {
        id: 'Videos',
        route: ADD_VIDEOS,
        icon: <AddVideo />,
      },
      {
        id: 'Documents',
        route: ADD_DOCUMENTS,
        icon: <UploadFileIcon />,
      },
      
    ],
  },
];
