import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  Quantity_IS_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
  Unit_IS_REQUIRED,
} from '../../../constants/formMessages';
import { EditRecipeIngredientForm } from './EditRecipeIngredientForm';
import { updaterecipeIngredient } from '../../../slices/recipeingredientSlice';
import { useParams } from 'react-router';
import { RecipeIngredient } from '../../../constants/models/RecipeIngredient';
import { RootState } from '../../../app/rootReducer';


interface IDispatchProps {
    
  updaterecipeIngredient: (
    id: string | undefined,
    recipeingredientUrl: string,
    ingredientTitle: string,
    quantity: string,
    unit: string,
    unitType: string,
    recipeId: string,
    history: History
  ) => AppThunk;
}

export interface EnhancedEditRecipeIngredientFormValues {
  recipeingredientUrl: string;
  ingredientTitle: string;
  quantity: string;
  unit: string,
  unitType: string,
  recipeId: string;
}

export interface EnhancedEditRecipeIngredientFormProps extends RouteComponentProps {
  recipeingredientUrl?: string;
  ingredientTitle?: string;
  quantity?: string;
  unit?: string;
  unitType?: string;
  recipeId?: string;
  recipeIngredient?:RecipeIngredient;
  updaterecipeIngredient: (
    id: string | undefined,
      recipeingredientUrl: string,
      ingredientTitle: string,
      quantity: string,
      unit: string,
      unitType: string,
      recipeId: string,
      history: History
  ) => void;
}

const EnhancedEditRecipeIngredientForm = withFormik<
EnhancedEditRecipeIngredientFormProps,
EnhancedEditRecipeIngredientFormValues
>({
  mapPropsToValues: (props) => ({
    recipeingredientUrl: props.recipeingredientUrl ? props.recipeingredientUrl : '',
    ingredientTitle: props.ingredientTitle ? props.ingredientTitle : '',
    quantity: props.quantity ? props.quantity : '',
    unit: props.unit ? props.unit : '',
    unitType: props.unitType ? props.unitType : '',
    recipeId: props.recipeId ? props.recipeId : '',
   
  }),
  validationSchema: Yup.object().shape({
    ingredientTitle: Yup.string().required(NAME_REQUIRED),
    recipeingredientUrl: Yup.string().notRequired(),
    quantity: Yup.string().required(Quantity_IS_REQUIRED),
    unit: Yup.string().required(Unit_IS_REQUIRED),
    unitType: Yup.string().notRequired(),
    recipeId: Yup.string().notRequired(),
    // city: Yup.string().notRequired(),
    // country: Yup.string().notRequired(),
    // postalCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    const { history, updaterecipeIngredient, recipeIngredient } = props;
    const {
      recipeingredientUrl,
      ingredientTitle,
        quantity,
        unit,
        unitType,
        recipeId
    } = values;
    resetForm({});

    updaterecipeIngredient(
      recipeIngredient?.id,
      recipeingredientUrl,
        ingredientTitle,
        quantity,
        unit,
        unitType,
        recipeId,
        history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditRecipeIngredientForm);

const EnhancedEditRecipeIngredientFormWithRouter = withRouter(
  EnhancedEditRecipeIngredientForm
);

const mapStateToProps = (state: RootState) => ({
  recipeIngredient: state.recipeIngredient.recipeIngredient,
});
export default connect(mapStateToProps, {
  updaterecipeIngredient,
  // @ts-ignore
})(EnhancedEditRecipeIngredientFormWithRouter);


