const DEVELOPMENT = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_PGYalroNI',
    APP_CLIENT_ID: '5krjkjd5d3mnm6daqb4cbsjtrp',
  },
  messaging: {
    ENCRYPTION_PARAPHRASE: '1234567890ABCDEF0987654321',
  },
  amplifyConfig: {
    aws_project_region: 'us-east-1',
    // "aws_cognito_identity_pool_id": "us-east-1:314cc913-b824-4d32-a326-be2d57f09305",
    aws_cognito_identity_pool_id:
      'us-east-1:c406804b-2f97-4bc6-801e-d8bc3a697aad',
    aws_cognito_region: 'us-east-1',
    // "aws_user_pools_id": "us-east-1_7RRLTN1V7",
    aws_user_pools_id: 'us-east-1_PGYalroNI',
    // "aws_user_pools_web_client_id": "5osfofvb31rfgmb0hhjg90g68j",
    aws_user_pools_web_client_id: '5krjkjd5d3mnm6daqb4cbsjtrp',
    oauth: {},
    // "aws_appsync_graphqlEndpoint": "https://4g2674vufbeclnuqukgesqwooa.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_graphqlEndpoint:
      'https://qeceejqevvhpzn4qzwgdvcma7u.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  ENVIRONMENT: 'development',
};

const PRODUCTION = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_PGYalroNI',
    APP_CLIENT_ID: '5krjkjd5d3mnm6daqb4cbsjtrp',
  },
  messaging: {
    ENCRYPTION_PARAPHRASE: '1234567890ABCDEF0987654321',
  },
  amplifyConfig: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: '',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_PGYalroNI',
    aws_user_pools_web_client_id: '5krjkjd5d3mnm6daqb4cbsjtrp',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://qeceejqevvhpzn4qzwgdvcma7u.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  ENVIRONMENT: 'production',
};

//let config = process.env.NODE_ENV === 'development' ? DEVELOPMENT : PRODUCTION;

// STaging
let config = process.env.NODE_ENV === 'development' ? PRODUCTION : PRODUCTION;

export default config;
